import { createApp } from 'vue/dist/vue.esm-bundler'
import i18n from '@js/i18n.js';
import App from './App.vue'
import router from '@js/router.js'
import store from '@js/store/store.js'
import mitt from 'mitt'
import { VueMasonryPlugin } from "vue-masonry/src/masonry.plugin.js";
import utils from '@js/utils.js';

const emitter = mitt()

// Moment.locale(i18n.locale);

let app = createApp(App)

// globally import Base components
const requireComponent = require.context(
  // Look for files in the current directory
  "./components",
  // Do not look in subdirectories
  false,
  // Only include "_base-" prefixed .vue files
  /Base[A-Z]\w+\.(vue)$/
);
requireComponent.keys().forEach(fileName => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Remove the file extension from the end
  const componentName = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
  // Globally register the component
  app.component(componentName, componentConfig.default || componentConfig);
});

app.directive('click-outside', {
  mounted(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});


app.provide('emitter', emitter)
app.use(router)
	.use(store)
	.use(VueMasonryPlugin)
	.use(i18n)
	.mount('#app')

window.addEventListener('resize', () => {
  document.body.classList.add('resize-no-anim');
  setImmediate(() => {
    document.body.classList.remove('resize-no-anim');
  });
});
