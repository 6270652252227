/**
	these are vue-router routes, but with added metadata for SSR.
	For each route that has a 'name' and isn't disabled with page: false,
	a unique index.html is generated.

	{
		path...
		name...
		component...

		meta: {
			title: (string),
			meta: { (string): (string) },

			en: {
				title: (string),
				meta: { (string): (string) },
			},
			it: {
				title: (string),
				meta: { (string): (string) },
			},
		}
	}

	The page 'title' and 'meta' tags can then be set,
	(see https://github.com/jantimon/html-webpack-plugin#options)
*/

const defaultMeta = {
	meta: {
		// 'og:title': (inserito automaticamente)
		// 'og:description': (inserito automaticamente)
		'og:type': 'article',
		'og:image': 'https://multi.unipv.it/images/preview.jpg',

		'twitter:card': 'summary_large_image',
		// 'twitter:title': (inserito automaticamente)
		// 'twitter:description': (inserito automaticamente)
		// 'twitter:image': (inserito automaticamente)
	},
	it: {
		title: 'Multi – Museo multimediale della lingua italiana',
		meta: {
			author: 'Multi – Museo multimediale della lingua italiana',
			description: 'Il Multi è un museo virtuale che si propone di valorizzare il variegato patrimonio immateriale della storia della lingua italiana attraverso una fruizione dei contenuti inclusiva e interattiva.',
		},
	},
	en: {
		title: 'Multi – Multimedia museum of the Italian language',
		meta: {
			author: 'Multi – Multimedia museum of the Italian language',
			description: 'Multi is a digital and interactive museum dedicated to the history of the Italian language.',
		},
	},
};

const routes = [
	{
		path: '/:lang',
		children: [
			{
				path: '',
				name: 'main',
				page: false,
				children: [
					{
						path: '/:lang/',
						name: 'home',
						component: () => import(/* webpackChunkName: "ViewHome" */'@views/ViewHome.vue'),
						meta: {
							it: {
								meta: {
									description: 'Il Multi è un museo digitale e interattivo dedicato alla storia e al racconto della lingua italiana.',
									'og:description': defaultMeta.it.meta.description,
								},
							},
							en: {
								meta: {
									description: 'Multi is a digital and interactive museum dedicated to the history of the Italian language.',
									'og:description': defaultMeta.en.meta.description,
								},
							}
						},
					},
					{
						path: '/:lang/collezione/',
						name: 'archive',
						component: () => import(/* webpackChunkName: "ViewArchive" */'@views/ViewArchive.vue'),
						meta: {
							it: {
								title: 'Collezione',
								meta: {
									description: 'Esplora i reperti del Multi: immagini, video e audio per scoprire la storia della lingua italiana.',
								},
							},
							en: {
								title: 'Collection',
								meta: {
									description: 'Explore the artifacts of Multi: images, videos, and audio to uncover the history of the Italian language.',
								},
							},
						},
					},
					{
						path: '/:lang/sala-serianni/',
						name: 'serianni_hall',
						component: () => import(/* webpackChunkName: "ViewArchive" */'@views/ViewArchive.vue'),
						meta: {
							it: {
								title: 'Sala Luca Serianni',
								meta: {
									description: 'La sezione del Multi dedicata agli interventi del linguista e filologo Luca Serianni',
								},
							},
							en: {
								title: 'Luca Serianni Hall',
								meta: {
									description: 'The section of Multi dedicated to the contributions of linguist and philologist Luca Serianni',
								},
							},
						},
					},
					{
						path: '/:lang/collezione/:id/',
						name: 'artifact',
						page: false,
						component: () => import(/* webpackChunkName: "ViewArtifact" */'@views/ViewArtifact.vue')
					},
					{
						path: '/:lang/pdf-viewer-page',
						name: 'PdfViewer',
						component: () => import(/* webpackChunkName: "ViewPdfViewer" */'@views/ViewPdfViewer.vue'),	
					},
					{
						path: '/:lang/articoli/',
						name: 'articles',
						component: () => null, // import(/* webpackChunkName: "ViewArticles" */'@views/ViewArticles.vue'),
						meta: {
							it: { title: 'Articoli' },
							en: { title: 'Articles' },
						},
					},
					{
						path: '/:lang/articoli/:slug/',
						page: false,
						name: 'article',
						component: () => import(/* webpackChunkName: "ViewArticle" */'@views/ViewArticle.vue')
					},

					// TEST
					{
						path: '/:lang/test_page',
						component: () => import(/* webpackChunkName: "ViewTest" */'@views/ViewTest.vue')
					},
					{
						path: '/:lang/test_timeline',
						component: () => import(/* webpackChunkName: "ViewTimeline" */'@views/ViewTimeline.vue')
					},
					{
						name: 'ViewTestGame',
						path: '/:lang/test_game',
						component: () => import('@views/ViewTestGame.vue')
					}
				]
			},

			// PERCORSI
			{
				path: '/:lang/il-multi/',
				name: 'about',
				component: () => import(/* webpackChunkName: "ViewAbout" */'@views/ViewAbout.vue'),
				meta: {
					it: {
						title: 'Il Museo',
						meta: {
							description: 'Scopri di più sul Multi – Museo multimediale della lingua italiana, le sue sezioni e la sua collezione.',
						},
					},
					en: {
						title: 'The Museum',
						meta: {
							description: 'Discover more about Multi – Multimedia Museum of the Italian Language, its sections, and its collection.',
						},
					},
				},
			},
			{
				path: '/:lang/percorso/come-nasce-lingua-italiana/',
				name: 'scrolly atrio',
				component: () => import(/* webpackChunkName: "ViewScrollyAtrio" */'@views/ViewScrollyAtrio.vue'),
				meta: {
					it: {
						title: 'Come è nata la lingua italiana?',
						meta: {
							description: 'Come tutte le lingue naturali, l’italiano non “nasce” dal nulla, ma è il frutto di una graduale, lenta evoluzione. Vediamo insieme come.',
							'og:image': 'https://multi.unipv.it/images/previews/00_Atrio.jpg',
						},
					},
					en: {
						title: 'How Was the Italian Language Born?',
						meta: {
							description: 'Just like any natural language, Italian doesn\'t "emerge" out of nothing, but rather it is the product of a gradual, slow evolution. Let\'s explore together how it came to be.',
							'og:image': 'https://multi.unipv.it/images/previews/en/00_Atrio.jpg',
						},
					},
				},
			},
			{
				path: '/:lang/percorso/chi-stabilito-regole-italiano/',
				name: 'scrolly norma',
				component: () => import(/* webpackChunkName: "ViewScrollyNorma" */'@views/ViewScrollyNorma.vue'),
				meta: {
					it: {
						title: 'Chi ha stabilito le regole dell’italiano?',
						meta: {
							description: 'Vi aspetta un’avventura piena di sorprese. Incontrerete splendidi manoscritti e libri antichi, strane pale e bagni in Arno. E molto altro. Cominciamo?',
							'og:image': 'https://multi.unipv.it/images/previews/01_Norma.jpg',
						},
					},
					en: {
						title: 'Who Established the Rules of the Italian Language?',
						meta: {
							description: 'Get ready for an adventure full of surprises. You will encounter splendid manuscripts and ancient books, peculiar shovels and baths in the Arno River, and much more. Shall we begin?',
							'og:image': 'https://multi.unipv.it/images/previews/en/01_Norma.jpg',
						},
					},
				},
			},
			{
				path: '/:lang/percorso/come-circolato-italiano-scritto/',
				name: 'scrolly scritto',
				component: () => import(/* webpackChunkName: "ViewScrollyScritto" */'@views/ViewScrollyScritto.vue'),
				meta: {
					it: {
						title: 'Come è circolato l’italiano scritto?',
						meta: {
							description: 'Il viaggio dell’italiano scritto è degno di romanzo d’avventura. All’inizio circola quasi segretamente, poi esce allo scoperto su libri, lettere, fogli di giornale, per arrivare infine sugli schermi di un computer o di uno smartphone. Scopriamo questo viaggio insieme.',
							'og:image': 'https://multi.unipv.it/images/previews/02_Scritto.jpg',
						},
					},
					en: {
						title: 'How Did Written Italian Circulate?',
						meta: {
							description: 'The journey of written Italian is worthy of an adventure novel. At the beginning, it circulates almost secretly, then it emerges openly in books, letters, newspaper pages, and eventually reaches the screens of computers or smartphones. Let\'s explore this journey together.',
							'og:image': 'https://multi.unipv.it/images/previews/en/02_Scritto.jpg',
						},
					},
				},
			},
			{
				path: '/:lang/percorso/come-diffuso-italiano-parlato/',
				name: 'scrolly parlato',
				component: () => import(/* webpackChunkName: "ViewScrollyVoce" */'@views/ViewScrollyVoce.vue'),
				meta: {
					it: {
						title: 'Come si è diffuso l’italiano parlato?',
						meta: {
							description: 'Il viaggio che ha portato alla lingua comune, è stato lungo e avvincente. A voce la lingua viaggia veloce. Di bocca in bocca. Di persona in persona. Cambia, si adatta, si rinnova. E con lei un’intera comunità.',
							'og:image': 'https://multi.unipv.it/images/previews/03_Voce.jpg',
						},
					},
					en: {
						title: 'How Did Spoken Italian Circulate?',
						meta: {
							description: 'The journey that led to the common language was long and captivating. Orally, the language travels swiftly. From mouth to mouth. From person to person. It changes, adapts, and renews itself. And along with it, an entire community evolves.',
							'og:image': 'https://multi.unipv.it/images/previews/en/03_Voce.jpg',
						},
					},
				},
			},
			{
				path: '/:lang/percorso/come-suona-italiano-popolare/',
				name: 'scrolly popolare',
				component: () => import(/* webpackChunkName: "ViewScrollyPopolare" */'@views/ViewScrollyPopolare.vue'),
				meta: {
					it: {
						title: 'Come suona l’italiano di chi non sa l’italiano?',
						meta: {
							description: 'Per secoli le persone scarsamente alfabetizzate hanno cercato di appropriarsi dell’italiano per le proprie esigenze pratiche e comunicative. Che ne dite di andare a conoscere alcune delle storie più interessanti?',
							'og:image': 'https://multi.unipv.it/images/previews/04_Popolare.jpg',
						},
					},
					en: {
						title: 'What Italian Do Illiterates Speak?',
						meta: {
							description: 'For centuries, poorly literate individuals have tried to appropriate the Italian language for their practical and communicative needs. What do you think about getting to know some of the most interesting stories?',
							'og:image': 'https://multi.unipv.it/images/previews/en/04_Popolare.jpg',
						},
					},
				},
			},
			{
				path: '/:lang/percorso/quali-lingue-parlano-italia/',
				name: 'scrolly dialetti',
				component: () => import(/* webpackChunkName: "ViewScrollyDialetti" */'@views/ViewScrollyDialetti.vue'),
				meta: {
					it: {
						title: 'Quali lingue si parlano in Italia?',
						meta: {
							description: 'In Italia convivono molte lingue. Ancora oggi, nel nuovo Millennio, in Italia si parlano (si scrivono, si ascoltano) anche i dialetti e le lingue di minoranza. Scopriamo insieme quali sono.',
							'og:image': 'https://multi.unipv.it/images/previews/05_Dialetti.jpg',
						},
					},
					en: {
						title: 'Which Languages Are Spoken in Italy?',
						meta: {
							description: 'In Italy, many languages coexist. Even today, in the new millennium, dialects and minority languages are spoken (written, and listened to) in Italy. Let\'s discover together what they are.',
							'og:image': 'https://multi.unipv.it/images/previews/en/05_Dialetti.jpg',
						},
					},
				},
			},
			{
				path: '/:lang/percorso/lingua-italiana-nel-mondo/',
				name: 'scrolly nel mondo',
				component: () => import(/* webpackChunkName: "ViewScrollyMondo" */'@views/ViewScrollyMondo.vue'),
				meta: {
					it: {
						title: 'Come si è diffusa la lingua italiana nel mondo?',
						meta: {
							description: 'L’italiano circola in tutto il mondo da secoli. Con l’italiano si è cittadini e cittadine del mondo, vediamo insieme come.',
							'og:image': 'https://multi.unipv.it/images/previews/06_Mondo.jpg',
						},
					},
					en: {
						title: 'How Did Italian Spread Throughout the World?',
						meta: {
							description: 'Italian has been circulating worldwide for centuries. With Italian, we become citizens of the world. Let\'s explore together how this has happened.',
							'og:image': 'https://multi.unipv.it/images/previews/en/06_Mondo.jpg',
						},
					},
				},
			},

			// TEST
			{
				path: '/:lang/test_icons',
				component: () => import(/* webpackChunkName: "ViewTestIcons" */'@views/ViewTestIcons.vue')
			},
			{
				path: '/:lang/test_fonts',
				component: () => import(/* webpackChunkName: "ViewTestFonts" */'@views/ViewTestFonts.vue')
			},
		],
	},
	{ redirect: '/it/' },
];

module.exports = { routes, defaultMeta };
