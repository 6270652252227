<template>
	<div
		:class="'menu-wrapper '+(active ? '' : 'hidden')"
		@wheel="noProp"
		@touchstart="noProp"
		@touchmove="noProp"
		@touchcancel="noProp"
	>
		<nav class="menu-content stack-vertical">
			<div class="menu-languages stack-horizontal">
				<a
					:href="$router.resolve({params:{lang:'it'}}).href"
					@click.prevent="changeLocale($event, 'it')"
					:class="$i18n.locale == 'it' ? 'active' : ''"
				>Italiano</a>
				<a
					:href="$router.resolve({params:{lang:'en'}}).href"
					@click.prevent="changeLocale($event, 'en')"
					:class="$i18n.locale == 'en' ? 'active' : ''"
				>English</a>
			</div>
			<div class="menu-main stack-vertical">
				<a :href="$router.resolve({name:'home'}).href" @click="e => toggleSubmenu(e, 'paths')">{{utils.capitalizeWords($t('menu_percorsi'))}}</a>
				<ol :class="`sub stack-vertical ${submenu == 'paths' ? '' : 'hidden'}`">
					<li v-for="scrolly in scrollys">
						<router-link :to="`/${$route.params.lang}/percorso/${scrolly.attributes.link}/`" class="capitalize-first">
							{{utils.localizedScrollyTitle(scrolly, scrollies, $i18n.locale)}}
						</router-link>
					</li>
				</ol>
				<hr />
				<a :href="$router.resolve({name:'home'}).href" @click="e => toggleSubmenu(e, 'archive')">{{utils.capitalizeWords($t('menu_collezione'))}}</a>
				<ol :class="`sub stack-vertical ${submenu == 'archive' ? '' : 'hidden'}`">
					<li><router-link :to="{name:'archive'}" class="capitalize-first">
							{{$t('menu_collezione_all')}}
						</router-link></li>
					<li><router-link :to="{name: 'serianni_hall', query: {show_all_serianni: 'true'}}" class="capitalize-first">
							{{$t('menu_collezione_serianni')}}
						</router-link></li>
				</ol>
				<hr />
				<router-link :to="{name:'home', hash: '#articles'}">{{utils.capitalizeWords($t('menu_articoli'))}}</router-link>
				<hr />
				<a :href="$router.resolve({name:'about'}).href" @click="e => toggleSubmenu(e, 'about')">{{utils.capitalizeWords($t('menu_il_museo'))}}</a>
				<ol :class="`sub stack-vertical ${submenu == 'about' ? '' : 'hidden'}`">
					<li v-for="section in about.sections">
						<router-link :to="{ name: 'about', query: {sectionId:section.id}}">{{section.title[$i18n.locale]}}</router-link>
					</li>
				</ol>
			</div>
			<div style="flex-grow: 1;"></div>
			<div class="menu-footer stack-vertical">
				<router-link class="home-link" :to="{name:'home'}">
					<img :src="logoMulti" alt="Museo multimediale della lingua italiana" />
				</router-link>
				<p>{{$t('menu_credits')}}</p>
				<div class="menu-logos stack-horizontal">
					<a href="https://portale.unipv.it/" target="_blank"><img :src="uniLogos['UNIPV']" alt="Università di Pavia" /></a>
					<a href="https://www.unior.it/"     target="_blank"><img :src="uniLogos['UNIOR']" alt="Università di Napoli - L'orientalie" /></a>
					<a href="http://www.unitus.it/"     target="_blank"><img :src="uniLogos['UNITS']" alt="Università degli studi della Tuscia" /></a>
				</div>
				<hr />
				<div class="menu-footer-links stack-horizontal">
					<a target="_blank" href="mailto:multi@unipv.it">{{utils.capitalizeFirst($t('menu_contattaci'))}}</a>
					<a target="_blank" href="https://portale.unipv.it/it#editCookieSettings">{{utils.capitalizeFirst($t('menu_impostazioni_cookies'))}}</a>
					<a target="_blank" href="https://privacy.unipv.it/">{{utils.capitalizeFirst($t('menu_privacy'))}}</a>
					<a target="_blank" href="https://portale.unipv.it/it/accessibilita">{{utils.capitalizeFirst($t('menu_accessibilita'))}}</a>
					<span>&copy;2023 Multi</span>
				</div>
			</div>
		</nav>
		<div class="menu-backdrop" @click="toggleMenu"></div>
		<div class="menu-cont" data-align="center">
			<div class="menu-actions">
				<base-button-rounded icon="menu-8-2" class="relative menu-burger" @button-clicked="toggleMenu" />
			</div>
			<slot></slot>
			<router-link v-if="showParenthesis" :to="{name:'home'}">
				<base-icon name="multi_logo_horizontal" class="home-logo" />
			</router-link>
			<router-link v-else class="home-icon" :to="{name:'home'}">
				<div v-html="require('@assets/images/menu/multi.svg')"></div>
			</router-link>
			<div class="menu-spacer" v-if="showParenthesis"></div>
		</div>
	</div>
</template>

<script type="text/javascript">
import { mapState, mapGetters, mapActions } from 'vuex';
import { gsap } from 'gsap/dist/gsap.min.js';
import utils from '@js/utils.js';
import scrollies from '@static/scrollies.js';

export default {
	name: 'InterfaceMenu',
	props: {
		showParenthesis: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			active: false,
			submenu: null,
			about: scrollies.scrollies.find(s => s.nameCode === 'il-multi'),
			scrollies: scrollies.scrollies,
			utils,
		}
	},
	computed: {
		...mapGetters(['isMobile']),
		...mapState(['scrollys']),
		logoMulti() {
			return require('@assets/images/menu/multi_cartiglio.png');
		},
		uniLogos() {
			return {
				UNIPV: require('@assets/images/menu/UNIPV.png'),
				UNIOR: require('@assets/images/menu/UNIOR.png'),
				UNITS: require('@assets/images/menu/UNITS.png'),
			};
		},
		iconTL() {
			const tl = gsap.timeline({ ease: 'none' });
			let duration = 0.25;
			tl.to('.menu-burger .t', {y: 9, duration}, 0);
			tl.to('.menu-burger .b', {y: -9, duration}, 0);
			tl.set('.menu-burger .m', {stroke: 'none'}, duration);

			const start = duration;
			duration = 0.25;
			tl.to('.menu-burger .t', {rotate: 45, svgOrigin: '0 0', duration}, start);
			tl.to('.menu-burger .b', {rotate: -45, svgOrigin: '0 0', duration}, start);

			return tl;
		},
	},
	watch: {
		$route (to, from){
			this.active = false;
				this.toggleSubmenu({preventDefault: () => {}}, 'paths')
				this.toggleSubmenu({preventDefault: () => {}}, 'archive')
				this.toggleSubmenu({preventDefault: () => {}}, 'about')
		},
		active (to) {
			const duration = this.iconTL.duration();
			if (to) {
				gsap.to(this.iconTL, { time: duration, duration, ease: 'power1.out' }).play();
			} else {
				gsap.to(this.iconTL, { time: 0, duration, ease: 'power1.out', onComplete:() => this.iconTL.pause() }).play();
			}
		},
	},
	methods: {
		...mapActions(['initStore']),
		toggleMenu(e) {
			if (e) e.preventDefault();
			this.active = !this.active;
		},
		toggleSubmenu(e, name) {
			e.preventDefault();
			this.submenu = this.submenu == name ? null : name;
		},
		changeLocale(e, code) {
			e.preventDefault();

			// if (code == 'en') {
			// 	window.alert('The English version of the Multi – Multimedia Museum of the Italian Language will be available in the coming weeks. We look forward to your visit!');
			// 	return;
			// }

			if (code == this.$root.$i18n.locale) return;
			location.href = this.$router.resolve({ params: { lang: code }, query: this.$route.query }).href;
		},
		noProp(e) {
			e.stopPropagation();
		},
	},
	async mounted() {
		await this.initStore();
	}
}
</script>

<style lang="scss">

	@import '@css/variables.scss';

	.menu-wrapper {
		position: fixed;
		left: 0; top: 0; right: 0; bottom: 0;
		z-index: 50;
		color: var(--color-white);

		display: flex;
		overflow-y: auto;
		background: rgba(0,0,0,0.6);
		align-items: flex-start; // unclear why this works?

		transition: background 0.5s;

		&.hidden {
			background: transparent;
			pointer-events: none;
			overflow-y: hidden;
		}
	}

	.menu-backdrop {
		flex: 1 1 0;
		cursor: pointer;
		align-self: stretch;

		.menu-wrapper.hidden & {
			pointer-events: none;
			cursor: unset;
		}
	}

	.menu-content {
		margin-left: var(--menu-size);
		padding: 3rem;
		gap: var(--s3);
		background-color: var(--color-brand-blue);
		pointer-events: all;
		min-height: 100%;

		z-index: 0;
		transition: transform 0.5s;

		.menu-wrapper.hidden & {
			transform: translate(-100%, 0);
		}

		body.resize-no-anim & {
			transition: none;
		}

		hr {
			border-top: 1px solid currentColor;
			width: 100%;
		}

		.menu-languages {
			gap: var(--s2);

			.active {
				font-weight: 500;
				color: var(--color-rose);
			}
		}

		.menu-main {
			gap: 0;

			hr {
				max-width: 290px;
			}

			a {
				display: inline-block;
				transition: transform 0.8s;

				&:hover {
					transform: translateX(30px);
				}
			}

			hr {
				margin: var(--s2) 0;
			}
		}

		.sub {
			gap: 0.5rem;
			overflow: hidden;
			max-height: 50vh;

			> :first-child {
				margin-top: var(--s3);
			}

			transition: max-height 0.5s, opacity 0.5s;

			&.hidden {
				max-height: 0;
				opacity: 0;
			}
		}

		.menu-footer {
			gap: var(--s2);

			p {
				// dont drive flex cross-axis
				width: 0;
				min-width: 100%;
			}

			hr {
				margin: 0;
				margin-top: var(--s2);
			}

			.home-link > img {
				height: 4rem;
			}
		}

		.menu-logos {
			gap: var(--s2);
			min-width: max-content;

			img {
				width: 10rem;
			}
		}

		.menu-footer-links {
			justify-content: space-between;

			span {
				color: var(--color-dark-gray);
			}
		}

		.menu-languages > a, .menu-footer-links > a {
			border-bottom: 2px solid transparent;

			&:hover {
				border-color: currentColor;
			}
		}
	}

	.menu-cont {
		position: fixed;
		left: 0;
		top: 0;
		width: var(--menu-size);
		height: 100%;

		display: flex;
		flex-direction: column;
		padding: 3rem 0;
		justify-content: space-between;
		background-color: var(--color-brand-blue);
		pointer-events: all;
		z-index: 1;

		.home-logo {
			width: unset;

			> svg {
				height: 50px;
				transform: rotate(-90deg);
			}
		}

		.home-icon {
			align-self: stretch;
			margin: 0 0.75rem;
		}

		.menu-nav-lines {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			pointer-events: none;
		}
	}

	.menu-actions {
		display: flex;
		flex-direction: column;
		gap: var(--s3);
		z-index: 2;

		.menu-burger {
			width: var(--s3);
			height: var(--s3);
		}
	}

/*	.menu-burger-1,
	.menu-burger-2,
	.menu-burger-3 {
		position: absolute;
		top: 0;
		left: 0;
		height: 0.15rem;
		width: 100%;
		background-color: var(--color-white);
		opacity: 1;
		transform-origin: top;
		transition-property: opacity, transform;
		transition-duration: .2s;
	}*/

/*	.menu-burger .menu-burger-1 {
		top: 0;
	}
	.menu-burger .menu-burger-2 {
		top: 40%;
	}
	.menu-burger .menu-burger-3 {
		top: 80%;
	}*/

/*	.menu-cont.open .menu-burger-1 {
		transform: translateY(0.76rem) rotate(45deg);
	}

	.menu-cont.open .menu-burger-2 {
		opacity: 0;
	}

	.menu-cont.open .menu-burger-3 {
		transform: translateY(-0.76rem) rotate(-45deg);
	}*/

	.parenthesis-cont .svg-icon {
		--svg-icon-color: var(--color-white);
	}

	@media (max-width: $breakpoint-sm) {
		.menu-wrapper {
			width: 100%;
			height: var(--menu-size);
			overflow-y: visible;
		}

		.menu-cont {
			padding: 0 1.25rem;
			flex-direction: row-reverse;
			justify-content: space-between;
			transition-property: top;
			transition-duration: .2s;

			width: 100%;
			height: var(--menu-size);

			.home-logo > svg {
				height: 32px;
				transform: none;
			}

			.menu-spacer {
				width: var(--s3);
			}
		}

		.menu-actions {
			flex-direction: row-reverse;
			align-items: center;
			gap: 1.5rem;
			height: 1.5rem;
		}

		.menu-burger, .menu-search {
			width: 1.5rem;
			height: 1.5rem;
		}

		.parenthesis-cont {
			height: 1.5rem;
		}

		.menu-content {
			position: fixed;
			top: var(--menu-size);
			left: 0;
			right: 0;
			bottom: 0;

			overflow-y: auto;
			margin-left: 0;
			padding: 1.5rem;
			gap: var(--s1);

			> * {
				flex: 0 0 auto;
			}

			.menu-wrapper.hidden & {
				transform: translate(0, -100%);
			}

			.menu-logos, .menu-footer-links {
				flex-direction: column;
				gap: var(--s0);
			}
			.menu-footer-links {
				gap: 0;
			}

			.menu-footer {
				.home-link > img {
					height: unset;
					width: 100%;
				}
			}
		}
	}

</style>
