<template>
	
		<router-view/>

		<!-- <view-no-mobile-access v-show="typeScreen == 'mobile' || typeScreen == 'tablet'"/> -->
		<interface-cookies />
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import utils from '@js/utils.js';
import ViewNoMobileAccess from '@views/ViewNoMobileAccess.vue';
import InterfaceCookies from '@components/InterfaceCookies'
export default {
  name: 'App',
  components: {
		ViewNoMobileAccess,
		InterfaceCookies
  },
  data() {
  	return {
  		menuHeight: 0,
  		prevY: 0,
  		direction: 'up'
  	}
  },
  computed: {
  	...mapState({
  		mapState: state => state.menuSize,
  		typeScreen: state => state.typeScreen
  	})
  },
  methods: {
  	...mapMutations(['setTypeScreen']),
  	menuMobileListener() {
  		// console.log('mobile listener', this.scrollingItem.scrollY, this.prevY, this.menuHeight)
  		if(this.scrollingItem.scrollY > this.prevY && this.scrollingItem.scrollY > this.menuHeight && this.direction == 'up') {
  			document.querySelector('.menu-cont').style.top = `-${this.menuHeight}px`;
  			this.direction = 'down'
  		} else if(this.scrollingItem.scrollY < this.prevY && this.scrollingItem.scrollY > 0 && this.direction == 'down'){
  			this.direction = 'up'
  			document.querySelector('.menu-cont').style.top = `0`;
  		}
  		this.prevY = this.scrollingItem.scrollY;
  	},
  	appHeight: () => {
  		console.log('set app height mobile')
			const doc = document.documentElement
			doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  	},
  	async addMenuMobileListener() {
  		await utils.isElLoaded('.menu-cont')
  		this.menuHeight = document.querySelector('.menu-cont').getBoundingClientRect().height
  		switch(this.$router.currentRoute.value.name) {
  			case 'ViewScrolly':
  				this.scrollingItem = document.querySelector('.scroll-container-window')
  				break;
  		}
  		this.prevY = this.scrollingItem.scrollY;
  		window.addEventListener('wheel', this.menuMobileListener)
  	},
  	redirectToNoMobile() {
  		if(window.innerWidth < 1128) {
  			this.$router.replace('/no-mobile-access')
  		}
  	}
  },	
  mounted() {
  	let onResize = e => {
  		if(window.innerWidth <= 1024) {
  			this.setTypeScreen('mobile')
  		}else if(window.innerWidth <= 1128){
  			this.setTypeScreen('tablet')
  		}else {
  			this.setTypeScreen('desktop')
  		}
  	}
  	window.addEventListener('resize', onResize)
  	onResize();

  	// this.redirectToNoMobile()
  	// if(this.typeScreen == 'mobile') {
  	// 	console.log('initialize mobile listener')
  	// 	this.addMenuMobileListener()
  	// }
  }
}
</script>

<style src="@/css/main.scss" lang="scss"></style>
<style lang="scss">
	html, body, #app {
		width: 100%;
		height: 100%;
	}

	body.home,
	body.archive,
	body.artifact,
	body.article {
		&, & #app {
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
		}
  }
</style>

