/* 
	BUTTON MIXIN
	common Vue component properties among buttons
*/

export default {
	props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    iconActive: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    activeProp: {
      type: Boolean,
      required: false,
      default: false
    },
    clickOutsideDeactivated: {
			type: Boolean,
			default: false
		}
	},
  data() {
    return {
      active: false
    }
  },
  computed: {
    iconCurrent() {
      return this.active ? this.iconActive : this.icon;
    },
    activable() {
      return this.isDropdown; // this.iconActive != null;
    }
  },
  watch: {
    activeProp: {
      // immediate: true,
      handler(n) {
        this.setActive(n);
      }
    }
  },
  methods: {
    setActive(val) {
      this.active = val;
      this.$emit("state-change", this.active);
    },
    toggleActive() {
      if (this.activable && !this.disabled) {
        this.setActive(!this.active);
      }
    },
    deactivate() {
      this.setActive(false);
    },
    clickOutsideHandler(event) {
    	event.stopPropagation();

    	if(this.clickOutsideDeactivated) return

      if (this.$el.contains(event.relatedTarget)) return;
      if (this.active) this.deactivate();
    },
    clickHandler(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.activable) {
        this.toggleActive();
      } else {
        this.$emit('clicked');
      }
    }
  }
}
