import { nextTick } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import i18n from '@js/i18n.js';
import { routes, defaultMeta } from '@js/routes.js';
import utils from '@js/utils.js';
import LocaleContainer from "@views/LocaleContainer";
import PageMain from "@views/PageMain";
// import ViewHome from "@views/ViewHome";

routes[0].component = LocaleContainer;
routes[0].children.find(r => r.name == 'main').component = PageMain;

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,

	async scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			await window._multi_loading;
			// return { el: to.hash }
			// vue-router actually fails to scroll on a non-top-level, so we do it ourselves
			document.querySelector(to.hash).scrollIntoView();
		}
	},
	// scrollBehavior(to, from, savedPosition) {
	//	 // always scroll to top
	//	 // hack found here: https://stackoverflow.com/a/57212324/12920606
	//	 document.getElementById('app').scrollIntoView();
	// }
})

router.afterEach((to, from) => {
	nextTick(() => {
		const no_anim = document.body.classList.contains('resize-no-anim');

		document.body.className = to.name;
		document.body.classList.toggle('resize-no-anim', no_anim);
		document.body.classList.toggle('no-avif', !utils.areAvifSupported());
		document.body.classList.toggle('no-avif-anim', !utils.areAnimatedAvifSupported());


		const lang = i18n.global.locale;
    const title = (to.meta && to.meta[lang]?.title) ?? to.meta?.title ??
    							defaultMeta[lang]?.title ?? defaultMeta.title;
		const meta = Object.assign({},
		                           defaultMeta.meta, defaultMeta[lang]?.meta,
		                           to.meta?.meta, (to.meta && to.meta[lang]?.meta));

		document.title = title;
		Object.keys(meta).forEach((key) => {
			let elem = document.querySelector(`meta[name="${key}"]`);
			if (elem == null) {
				elem = document.createElement('meta');
				elem.name = key;
				document.head.append(elem);
			}
			elem.content = meta[key];
		});

		if(window.localStorage.getItem('cookies') == 'yes-cookies') {
			console.log('track page')
			_paq.push(['trackPageView']);
		}
	});
});

export default router
