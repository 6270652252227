export default {
	stagesData: [
		// --------- FIRST SCROLLY ----------
		{
			id: 0,
			scrollyId: 0,
			name: {
				it: 'intro',
				en: 'intro'
			}
		},
		{
			id: 1,
			scrollyId: 0,
			name: {
				it: 'le lettere',
				en: 'epistolary writing'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 874,
			audioSpotEn: 765,
			documentation: {
				bibliography: [
					{text: "Giuseppe Antonelli, _Comunque anche Leopardi diceva le parolacce_, Mondadori, Milano, 2014."},
					{text: "Giuseppe Antonelli, _Lettere familiari di mittenti cólti di primo Ottocento: il lessico_, “Studi di lessicografia italiana”, 18 (2001), pp. 123-226."},
					{text: "Nicola De Blasi, _Tra scritto e parlato. Venti lettere mercantili meridionali e toscane del primo Quattrocento_, Liguori, Napoli, 1982."},
					{text: "_Le carte messaggiere. Retorica e modelli di comunicazione epistolare. Per un indice dei libri di lettere del Cinquecento_, a cura di Amedeo Quondam, Bulzoni, Roma, 1981."},
					{text: "Luigi Matt, _Epistolografia letteraria_, in _Storia dell’italiano scritto_, a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. II, _Prosa letteraria_, Carocci, Roma, 2014, pp. 253-82."},
					{text: "Armando Petrucci, _Scrivere lettere. Una storia plurimillenaria_, Laterza, Roma-Bari, 2008."},
				],
				sitography: [
					{text: {it: "lettere e epistolografia", en: "About Epistolary Writing"}, link: "https://www.treccani.it/enciclopedia/lettere-e-epistolografia_(Enciclopedia-dell'Italiano)/"},
				]
			}
		},
		{
			id: 2,
			scrollyId: 0,
			name: {
				it: 'trattato',
				en: 'trattato'
			},
			historicalMoment: {
				it: 'dopo il 500',
				en: 'after 500'
			},
			audioSpotIt: 30,
			documentation: {
				bibliography: [
					{text: 'Carol Kidwell, Pietro Bembo. Lover, linguist, cardinal, McGill-Queen\'s University Press, Montreal, 2004 two'},
					{text: 'Giuseppe Patota, La quarta corona. Pietro Bembo e la codificazione dell’italiano scritto, il Mulino, Bologna, 2017'},
					{text: 'Atti del seminario di studi, Prose della volgar lingua di Pietro Bembo, a cura di Silvia Morgana, Mario Piotti, Massimo Prada, Cisalpino, Milano, 2001'}
				],
				sitography: [
					{text: 'Luca Serianni, Pietro Bembo – Le pillole della Dante, Enciclopedia Infinita'},
					{text: 'Rai Letteratura, più incentrato sul Bembo collezionista'},
					{text: 'Giulio Ferroni, Il Cinquecento – Le pillole della Dante, Enciclopedia Infinita'},
				]
			}
		},
		{
			id: 3,
			scrollyId: 0,
			name: {
				it: 'terza tappa',
				en: 'third stage'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 80,
			documentation: {
				bibliography: [
					{text: 'Carol Kidwell, Pietro Bembo. Lover, linguist, cardinal, McGill-Queen\'s University Press, Montreal, 2004 three'},
					{text: 'Giuseppe Patota, La quarta corona. Pietro Bembo e la codificazione dell’italiano scritto, il Mulino, Bologna, 2017'},
					{text: 'Atti del seminario di studi, Prose della volgar lingua di Pietro Bembo, a cura di Silvia Morgana, Mario Piotti, Massimo Prada, Cisalpino, Milano, 2001'}
				],
				sitography: [
					{text: 'Luca Serianni, Pietro Bembo – Le pillole della Dante, Enciclopedia Infinita'},
					{text: 'Rai Letteratura, più incentrato sul Bembo collezionista'},
					{text: 'Giulio Ferroni, Il Cinquecento – Le pillole della Dante, Enciclopedia Infinita'},
				]
			}
		},
		{
			id: 4,
			scrollyId: 0,
			name: {
				it: 'bibliografia e sitografia',
				en: 'bibliography and sitography'
			}
		},
		// --------- SECOND SCROLLY - ATRIO ----------
		{
			id: 5,
			scrollyId: 1,
			name: {
				it: 'introduzione',
				en: 'introduction'
			}
		},
		{
			id: 6,
			scrollyId: 0,
			name: {
				it: 'atrio delle origini',
				en: 'hall of Origins'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 0,
			audioSpotEn: 0,
			documentation: {
				bibliography: [
					{text: "Arrigo Castellani, _I più antichi testi italiani. Edizione e commento_, Pàtron, Bologna, 1976."},
					{text: "Vittorio Formentin, Antonio Ciaralli, _Un frammento di «canzone di donna» in volgare dell’alto medioevo_, “Lingua e Stile”, LVII (2022), pp. 3-37."},
					{text: "Rita Librandi, _Profilo storico della lingua italiana_, Carocci, Roma, 2023."},
					{text: "Ludovica Maconi, Mirko Volpi, _Antichi documenti dei volgari italiani_, Carocci, Roma, 2022."},
					{text: "Livio Petrucci, _Il problema delle Origini e i più antichi testi italiani_, in _Storia della lingua italiana_, a cura di Luca Serianni e Pietro Trifone, vol. III, _Le altre lingue_, Einaudi, Torino, 1994, pp. 5-73."},
					{text: "Domenico Proietti, _«Kelle terre». Storia, lingua e toponomastica nei giudicati campani del X secolo_, Aracne, Roma, 2020."},
				],
				sitography: [
					{text: {it: 'Giuseppe Patota, _Quando, come e dove è nato l’italiano?_', en: 'Giuseppe Patota, When, how and where was Italian born?'}, link: 'https://www.youtube.com/watch?v=tCslYHnm030 '},
					{text: {it: 'Luca Serianni, _L’italiano dal latino a oggi_', en: 'Luca Serianni, The Italian from Latin to the present day'}, link: 'https://www.youtube.com/watch?v=uKYGD5lBGHc'},
				]
			}
		},
		{
			id: 7,
			scrollyId: 0,
			name: {
				it: 'bibliografia e sitografia',
				en: 'bibliography and sitography'
			}
		},
		// ------------ SCROLLY ITALIANO SCRITTO ---------
		{
			id: 8,
			scrollyId: 1,
			name: {
				it: 'intro',
				en: 'intro'
			},
			audioSpotIt: 0,
			audioSpotEn: 0
		},
		{
			id: 9,
			scrollyId: 1,
			name: {
				it: 'Supporti di scrittura',
				en: 'writing media'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 27,
			audioSpotEn: 29,
			documentation: {
				bibliography: [
					{text: "Massimo Arcangeli, _Sciacquati la bocca. Parole, gesti e segni dalla pancia degli italiani_, il Saggiatore, Milano, 2018."},
					{text: "Francesco Ascoli, _La penna in mano. Per una storia della cultura manoscritta in età moderna_, Olschki, Firenze, 2020."},
					{text: "Francesca Geymonat, _Scritture esposte_, in _Storia dell’italiano scritto_, a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. III, _Italiano dell’uso_, Carocci, Roma, 2014, pp. 57-100."},
					{text: "Armando Petrucci, _Breve storia della scrittura latina_, Bagatto Libri, Roma, 1992, pp. 156-61, 194-97."},
					{text: "Luca Serianni, Lucilla Pizzoli, _Storia illustrata della lingua italiana_, Carocci, Roma, 2017."},
					{text: "Stefano Telve, _Il parlato trascritto_, in _Storia dell’italiano scritto_, a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. III, _Italiano dell’uso_, Carocci, Roma, 2014, pp. 15-56."},
				],
				sitography: [
					{text: {it: 'Storia della macchina da scrivere', en: "About Tipewriter"}, link: 'http://www.typewriterstory.com/storia/'},
					{text: {it: 'Sui _Memorial bolognesi_', en: "About the _Memoriali Bolognesi_"}, link: 'https://www.treccani.it/enciclopedia/memoriali-bolognesi_%28Enciclopedia-Dantesca%29/'},
				]
			}
		},
		{
			id: 10,
			scrollyId: 1,
			name: {
				it: 'il trattato',
				en: 'treatises'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 1344,
			audioSpotEn: 1187,
			documentation: {
				bibliography: [
					{text: "Maria Luisa Altieri Biagi, _Galileo e la terminologia tecnico-scientifica_, Olschki, Firenze, 1965."},
					{text: "Marcello Aprile, _Trattatistica_, in _Storia dell’italiano scritto_, a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. II, _Prosa letteraria_, Carocci, Roma, 2014, pp. 73-118."},
					{text: "Alessandro Aresti, _Lingua delle arti e lingua degli artisti in Italia fra Medioevo e Rinascimento_, Cesati, Firenze, 2019."},
					{text: "Marco Biffi, _Italiano delle arti_, in _Italiano per il mondo. Banca, commerci, cultura, arti, tradizioni_, a cura di Giada Mattarucco, Accademia della Crusca, Firenze, 2012, pp. 53-72."},
					{text: "_L’italiano e la scienza tra Medioevo e Rinascimento. Le vie della lingua, della letteratura, dell’arte_, a cura di Lorenzo Baccini, Francesco Brenna, Barbara Fanini, Giulio Vaccaro, Giulia Virgilio, Valerio Zanetti, Cesati, Firenze, 2022."},
					{text: "Paola Manni, _Le parole della finanza e del commercio_, in _Italiano per il mondo. Banca, commerci, cultura, arti, tradizioni_, a cura di Giada Mattarucco, Accademia della Crusca, Firenze, 2012, pp. 23-52."},
					{text: "Giuseppe Patota, _L'universo in italiano. La lingua degli scritti copernicani di Galileo_, il Mulino, Bologna, 2022."},
					{text: "Stefano Telve, _Il linguaggio delle scienze (fisica, matematica, scienze naturali)_, in _Linguaggi specialistici dell’italiano_, a cura di Riccardo Gualdo e Stefano Telve, Carocci, Roma, 2011, pp. 217-82."},
				],
				sitography: [
					{text: {it: "Linguaggi specialistici", en: "Specialized Languages"}, link: "https://www.treccani.it/enciclopedia/linguaggi-specialistici_%28XXI-Secolo%29/"},
					{text: {it: "Lingua della scienza", en: "Language of Science 	"}, link: "https://www.treccani.it/enciclopedia/lingua-della-scienza_%28Enciclopedia-dell%27Italiano%29/" }
				]
			}
		},
		{
			id: 11,
			scrollyId: 1,
			name: {
				it: 'i giornali',
				en: 'newspapers'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 1862,
			audioSpotEn: 1648,
			documentation: {
				bibliography: [
					{text: "Giuseppe Antonelli, _L’italiano nella società della comunicazione_, il Mulino, Bologna, 2007."},
					{text: "Ilaria Bonomi, _L’italiano giornalistico. Dall’inizio del ’900 ai quotidiani on line_, Cesati, Firenze, 2002."},
					{text: "Francesca Gatta, _Il giornalismo_, in _Storia dell’italiano scritto_, a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. III, _Italiano dell’uso_, Carocci, Roma, 2014, pp. 293-47."},
					{text: "Riccardo Gualdo, _L’italiano dei giornali_, Carocci, Roma, 2007."},
				],
				sitography: [
					{text: {it: "giornali, lingua dei in \"Enciclopedia dell'Italiano\" (treccani.it)", en: "Language of Newspapers"}, link: "https://www.treccani.it/enciclopedia/lingua-dei-giornali_%28Enciclopedia-dell%27Italiano%29/"}
				]
			}
		},
		{
			id: 12,
			scrollyId: 1,
			name: {
				it: 'il web',
				en: 'the web'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 2330,
			audioSpotEn: 2068,
			documentation: {
				bibliography: [
					{text: "Massimo Arcangeli, _All’alba di un nuovo Medioevo. Comunicazione e informazione al tempo di internet_, Lit Edizioni, Roma, 2016."},
					{text: "_L’e-taliano. Scriventi e scritture nell’era digitale_, a cura di Sergio Lubello, Cesati, Firenze, 2016."},
					{text: "_L’italiano e la rete, le reti per l’italiano_, a cura di Giuseppe Patota, Fabio Rossi, Accademia della Crusca/goWare, Firenze, 2018."},
					{text: "Claudio Nobili, _I gesti dell’italiano_, Carocci, Roma, 2019."},
					{text: "Elena Pistolesi, _Scritture digitali_, in _Storia dell’italiano scritto_, a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. III, _Italiano dell’uso_, Carocci, Roma, 2014, pp. 349-75."},
					{text: "Elena Pistolesi, _L’italiano del web: social network, blog & co_, Cesati, Firenze, 2022."},
					{text: "_Se telefonando... ti scrivo. L’italiano al telefono, dal parlato al digitato. I giovani e la lingua_, a cura di Domenico De Martino, Nicoletta Maraschio, Accademia della Crusca, Firenze, 2010."},
					{text: "Stefania Spina, _Openpolitica. Il discorso dei politici italiani nell’era di Twitter_, Franco Angeli, Milano, 2012."},
					{text: "Mirko Tavosanis, _L’italiano del web_, Carocci, Roma, 2011."},
				],
				sitography: [
					{text: {it: "Lingua e web", en: "Language and Web"}, link: "https://www.treccani.it/enciclopedia/lingua-e-web_%28Enciclopedia-Italiana%29/" }
				]
			}
		},
		{
			id: 13,
			scrollyId: 0,
			name: {
				it: 'bibliografia e sitografia',
				en: 'bibliography and sitography'
			}
		},
		// -------------------- NORMA ------------
		{
			id: 14,
			scrollyId: 1,
			name: {
				it: 'intro',
				en: 'intro'
			},
			audioSpotIt: 0,
			audioSpotEn: 0,
		},
		{
			id: 15,
			scrollyId: 1,
			name: {
				it: 'Le Tre Corone: Dante',
				en: 'The Three Crowns: Dante'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 231,
			audioSpotEn: 102,
			documentation: {
				bibliography: [
					{text: "Franca Brambilla Ageno, _Studi danteschi_, Antenore, Padova, 1990."},
					{text: "Alberto Casadei, _Dante. Storia avventurosa della_ Divina Commedia _dalla selva oscura alla realtà aumentata_, il Saggiatore, Milano, 2020."},
					{text: "Alberto Casadei, Paolo Gervasi, _La voce di Dante. Performance dantesche tra teatro, tv e nuovi media_, Sossella, Roma, 2021."},
					{text: "Gianfranco Contini, _Un’idea di Dante. Saggi danteschi_, Einaudi, Torino, 1970."},
					{text: "_Dante. Un’epopea pop_, a cura di Giuseppe Antonelli con la collaborazione di Giovanni Battista Boccardo e Federico Milone, Silvana Editoriale, Cinisello Balsamo, 2021."},
					{text: "Paola Manni, _La lingua di Dante_, il Mulino, Bologna, 2013."},
				],
				sitography: [
					{text: {it: "Su vita e opere di Dante, con un’ampia raccolta di manoscritti consultabile", en: "On Dante's life and works, with an extensive collection of manuscripts available for consultation"}, link: "https://www.danteonline.it/index.html"},
					{text: {it: "Società dantesca italiana", en: 'Italian Dante Society'}, link: "https://www.dantesca.it/"},
					{text: {it: "Enciclopedia dantesca-Elenco voci", en: "Encyclopaedia of Dante - List of entries"}, link: "https://www.treccani.it/enciclopedia/elenco-opere/Enciclopedia_Dantesca"},
					{text: {it: "Dante e il cinema", en: "Dante and the cinema"}, link: "https://www.danteeilcinema.com/cinema/"},
					{text: {it: "Corpus delle opere volgari e latine", en: "Corpus of Italian and Latin Works"}, link: "https://dantenetwork.it/"},
					{text: {it: "Illuminated Dante Project su manoscritti danteschi provvisti di immagini", en: "Illuminated Dante Project on Dante manuscripts with images"}, link: "https://www.dante.unina.it/public/frontend/index"},
					{text: {it: "Sito dantesco della Columbia University", en: "Columbia University Dante site"}, link: "https://digitaldante.columbia.edu/"},
					{text: {it: "Dartmouth Dante Project-database di commenti danteschi", en: "Dartmouth Dante Project. Archive of Dante commentaries"}, link: "https://dante.dartmouth.edu](https://dante.dartmouth.edu/"},
					{text: {it: "Vocabolario dantesco", en: "Dante Vocabulary"}, link: "http://www.vocabolariodantesco.it/"},
					// {text: {it: "", en: "_Divine Commedy_ translated by Robert and Jean Hollander"}}
					{text: {it: "Umberto Eco intervista Beatrice", en: "Umberto Eco interviews Beatrice"}, link: "https://www.raiplaysound.it/audio/2020/04/Le-interviste-impossibili---Umberto-Eco-incontra-Beatrice-eb6ea2e2-0d0a-44d8-b3cf-4d39282d6219.html"}
				]
			}
		},
		{
			id: 16,
			scrollyId: 1,
			name: {
				it: 'Le Tre Corone: Petrarca',
				en: 'The Three Crowns: Petrarca'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 1110,
			audioSpotEn: 1047,
			documentation: {
				bibliography: [
					{text: "Gianfranco Contini, _Preliminari sulla lingua del Petrarca_, in Id., _Varianti e altra linguistica_, Einaudi, Torino, 1970."},
					{text: "Giuseppe Savoca, _Il Canzoniere di Petrarca tra codicologia ed ecdotica_, Olschki, Firenze, 2008."},
					{text: "Maurizio Vitale, _La lingua del Canzoniere (“Rerum vulgarium fragmenta”) di Francesco Petrarca_, Antenore, Padova, 1996."},
					{text: {it: "Ernest Hatch Wilkins, _Vita del Petrarca_, a cura di Remo Ceserani, Feltrinelli, Milano, 2003 (nuova edizione).", en: "Ernest Hatch Wilkins, _The Making of the Canzoniere and Other Petrarchan Studies_, Storia e Letteratura, Roma, 1951"}},
					{text: "Andrea Zanzotto, _Petrarca fra il palazzo e la cameretta_, in Id., _Fantasie di avvicinamento_, Mondadori, Milano 1991, pp. 261-71."},
				],
				sitography: [
					{text: "Museo Petrarchesco Piccolomineo", link: "https://museopetrarchesco.it/visita-il-museo/"},
					{text: {it: "Vita e opere di Petrarca, con interventi di Amedeo Quondam e Lina Bolzoni", en: "Life and Works of Petrarch, with contributions by Amedeo Quondam and Lina Bolzoni"}, link: "https://www.raicultura.it/letteratura/articoli/2018/12/Letteratura---Petrarca---seconda-parte-035432cd-e01d-481c-9ced-ca452e6331f9.html"},
					{text: {it: "Luca Serianni su Petrarca e la decodifica della lirica", en: "Luca Serianni on Petrarch and the decoding of lyric poetry"}, link: "https://www.youtube.com/watch?v=nO2jqGv-f8M"},
					{text: "Treccani Scuola-Biografia di Petrarca", link: "https://www.youtube.com/watch?v=Qh_MlrKrlSk"},
					{text: {en: "_Canzoniere_ translated in English"}, link: "https://www.poetryintranslation.com/PITBR/Italian/Petrarchhome.php"}
				]
			}
		},
		{
			id: 17,
			scrollyId: 1,
			name: {
				it: 'Le Tre Corone: Boccaccio',
				en: 'The Three Crowns: Boccaccio'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 1728,
			audioSpotEn: 1724,
			documentation: {
				bibliography: [
					{text: "Marco Bardini, _Boccaccio pop. Usi, riusi e abusi del_ Decameron _nella contemporaneità_, ETS, Pisa, 2020."},
					{text: "Francesco Bruni, _Boccaccio. L'invenzione della letteratura mezzana_, il Mulino, Bologna, 1990."},
					{text: "Paola Manni, _La lingua di Boccaccio_, il Mulino, Bologna, 2016."},
					{text: "Maurizio Vitale, Vittore Branca, _Il capolavoro del Boccaccio e due diverse redazioni_, Istituto veneto di scienze lettere ed arti, Venezia, 2002, 2 voll."},
				],
				sitography: [
					{text: "Ente Nazionale Giovanni Boccaccio", link: "http://www.enteboccaccio.it](http://www.enteboccaccio.it/"},
					{text: "American Boccaccio Association", link: "http://www.brown.edu/Departments/Italian_Studies/heliotropia/"},
					{text: "Decameron Web-Brown University", link: "http://www.brown.edu/Departments/Italian_Studies/dweb/index.php"},
					{text: {it: "Decameron Ipertestuale dell’Università di Zurigo", en: "Hypertextual Decameron-University of Zurich"}, link: "http://www.rose.uzh.ch/static/decameron/index3"},
					{text: {en: "_Decameron_ translated by James Macmullen Rigg"}, link: "https://www.brown.edu/Departments/Italian_Studies/dweb/texts/DecIndex.php?lang=eng"}
				]
			}
		},
		{
			id: 18,
			scrollyId: 1,
			name: {
				it: 'Pietro Bembo:\nla quarta corona',
				en: 'The Fourth Crown: Pietro Bembo'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 2304,
			audioSpotEn: 2225,
			documentation: {
				bibliography: [
					{text: "Carol Kidwell, _Pietro Bembo. Lover, linguist, cardinal_, McGill-Queen's University Press, Montreal, 2004."},
					{text: "Giuseppe Patota, _La quarta corona. Pietro Bembo e la codificazione dell’italiano scritto_, il Mulino, Bologna, 2017."},
					{text: "Susan Nalezyty, _Pietro Bembo and the Intellectual Pleasures of a Renaissance Writer and Art Collector_, Yale University Press, New Haven, 2017."},
					{text: "Prose della volgar lingua _di Pietro Bembo_, Atti del seminario di studi, a cura di Silvia Morgana, Mario Piotti, Massimo Prada, Cisalpino, Milano, 2001."},
				],
				sitography: [
					{text: {it: "Luca Serianni su Pietro Bembo", en: "Luca Serianni on Pietro Bembo"}, link: "https://www.youtube.com/watch?v=er_SXhU7xrg"},
					{text: {it: "Pietro Bembo’s works in other languages", en: "Pietro Bembo’s works in other languages"}, link: "https://www.newitalianbooks.it/pietro-bembo-in-different-languages/"},
					{text: "Bembo collezionista di manoscritti e opere d’arte", link: "https://www.youtube.com/watch?v=gERlUC6laSA"},
				]
			}
		},
		{
			id: 19,
			scrollyId: 1,
			name: {
				it: "L'Accademia della Crusca",
				en: "The Crusca Academy"
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 3012,
			audioSpotEn: 2976,
			documentation: {
				bibliography: [
					{text: "Alessandro Manzoni, _Postille al Vocabolario della Crusca nell’edizione veronese_, a cura di Dante Isella, Centro nazionale di studi manzoniani, Milano, 2005."},
					{text: "Claudio Marazzini, _L’ordine delle parole. Storia di vocabolari italiani_, il Mulino, Bologna, 2017."},
					{text: "_Il_ vocabolario degli Accademici della Crusca _(1612) e la storia della lessicografia italiana_, a cura di Lorenzo Tomasin, Cesati, Firenze, 2013."},
				],
				sitography: [
					{text: {it: "Presentazione ufficiale di Nicoletta Maraschio", en: "Official presentation by Nicoletta Maraschio, former President of the Crusca Academy"}, link: "https://youtu.be/El082IS7HkE"},
					{text: {it: "Trailer del documentario _La fabbrica dell’italiano_", en: "Trailer of the documentary _La fabbrica dell'italiano_"}, link: "https://www.youtube.com/watch?v=nHC26ct6HFU"}
				]
			}
		},
		{
			id: 20,
			scrollyId: 1,
			name: {
				it: "Alessandro Manzoni",
				en: "Alessandro Manzoni"
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'secolo di riferimento'
			},
			audioSpotIt: 3724,
			audioSpotEn: 3704,
			documentation: {
				bibliography: [
					{text: {en: "Alessandro Manzoni, _The Betrothed_, translated by Archibald Colquhoun, Everyman's Library/Dutton, London/New York, 1965."}},
					{text: "Mariarosa Bricchi, _Manzoni prosatore. Un percorso linguistico_, Carocci, Roma, 2021."},
					{text: "_Manzoni_, a cura di Paola Italia, Carocci, Roma, 2020."},
					{text: "Alessandro Manzoni, _Scritti linguistici inediti_, a cura di Angelo Stella e Maurizio Vitale, Centro nazionale studi manzoniani, Milano, 2000, 2 voll."},
					{text: "Alessandro Manzoni, _Scritti linguistici editi_, a cura di Angelo Stella e Maurizio Vitale, Centro nazionale studi manzoniani, Milano, 2000."},
					{text: "Giovanni Nencioni, _La lingua di Manzoni_, il Mulino, Bologna, 1993."},
					{text: "Raffaello Palumbo Mosca, _L’ombra di don Alessandro. Manzoni nel Novecento_, Inschibboleth, Roma, 2020."},
					{text: "Luciano Parisi, _Come abbiamo letto Manzoni. Interpreti novecenteschi_, Edizioni dell’Orso, Alessandria, 2008."},
				],
				sitography: [
					{text: {it: "Sugli scritti e la biblioteca di Manzoni", en: "On Manzoni's writings and library"}, link: "https://www.alessandromanzoni.org](https://www.alessandromanzoni.org/"},
					{text: {it: "Casa Manzoni-Mostre virtuali", en: "Casa Manzoni-Virtual Tour"}, link: "https://www.casadelmanzoni.it/content/mostre-virtuali"},
					{text: {it: "Bibliografia manzoniana", en: "Bibliography"}, link: "https://www.alessandromanzoni.org/bibliografia"},
					{text: {it: "Edizioni 1825-’27 e 1840-’42", en: "1825-'27 and 1840-'42 Editions"}, link: "http://projects.dharc.unibo.it/philoeditor/"},
					{text: {it: "_I Promessi sposi_ in Europa e nel mondo", en: "_The Betrothed_ in Europe and the World"}, link: "https://movio.beniculturali.it/dsglism/IpromessisposiinEuropaenelmondo/it/](https://movio.beniculturali.it/dsglism/IpromessisposiinEuropaenelmondo/it/56/riletture-trasposizioni-parodie"},
				]
			}
		},
		{
			id: 21,
			scrollyId: 0,
			name: {
				it: 'bibliografia e sitografia',
				en: 'bibliography and sitography'
			}
		},
		{
			id: 22,
			name: {
				it: 'intro',
				en: 'intro'
			},
			audioSpotIt: 0,
			audioSpotEn: 0
		},
		{
			id: 23,
			name: {
				it: "Prima dell'unità d'Italia",
				en: "The languages of Italy before unification",
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 38,
			audioSpotEn: 40,
			documentation: {
				bibliography: [
					{text: "Daniele Baglioni, _Italoromanzo in caratteri arabi in un diploma magrebino del Trecento_, in _Contatti di lingue - Contatti di scritture_ (a cura di Daniele Baglioni e Olga Tribolato), Edizioni Ca’ Foscari, Venezia, 2015, pp. 177-196."},
					{text: "Daniele Baglioni, _Il veneziano «de là da mar». Contesti, testi, dinamiche di contatto linguistico e culturale_, De Gruyter, Berlin/Boston, 2019."},
					{text: "Emanuele Banfi, Gabriele Iannàccaro, _Lo spazio linguistico italiano e le “lingue esoticheˮ: rapporti e reciproci influssi_, Atti del XXXIX congresso internazionale di studi della Società di Linguistica Italiana (Milano, 22-24 settembre 2005), a cura di E. Banfi e G. Iannàccaro, Bulzoni, Roma, 2006."},
					{text: "Emanuele Banfi, _Lingue d’Italia fuori d’Italia. Europa, Mediterraneo e Levante dal Medioevo all’età moderna_, Il Mulino, Bologna, 2014."},
					{text: "Francesco Bruni, _Italiano all’estero e italiano sommerso. Una lingua senza impero_, in _Storia della lingua e storia_, a cura di G. Alfieri, Cesati, Firenze, pp. 179-198."},
					{text: "Francesco Bruni, _L’italiano fuori d’Italia_, Cesati, Firenze, 2013."},
					{text: "Guido Cifoletti, _La lingua franca mediterranea_, Unipress, Padova, 1989."},
					{text: "Gianfranco Folena, _L’italiano in Europa. Esperienze linguistiche del Settecento_, Cesati, Firenze, 2020<sup>2</sup> (prima edizione: Einaudi, Torino, 1983)."},
					{text: "Giorgio Manganelli, _Lettere italiane di George Gordon Byron_, Guida, Napoli, 1985."},
					{text: "Giada Mattarucco, _Prime Grammatiche d’italiano per francesi (secoli XVI-XVII)_, Accademica della Crusca, Firenze, 2003."},
					{text: "Matteo Motolese, _Italiano lingua delle arti_, il Mulino, Bologna, 2012."},			
				],
				sitography: [
					{text: {it: "Italiano come lingua franca", en: "Italian as _lingua franca_"}, link: "https://www.treccani.it/enciclopedia/lingua-franca-italiano-come_%28Enciclopedia-dell%27Italiano%29/"},
					{text: {it: "Mediterraneo e lingua italiana", en: "Mediterranean Sea and Italian"}, link: "https://www.treccani.it/enciclopedia/mediterraneo-e-lingua-italiana_%28Enciclopedia-dell%27Italiano%29/"},
				]
			}
		},
		{
			id: 24,
			name: {
				it: "Ex colonie",
				en: "Italian and The Former Colonies",
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 814,
			audioSpotEn: 783,
			documentation: {
				bibliography: [
					{text: "Giorgio Banti, Moreno Vergari, _Italianismi lessicali in saho_, “Ethnorêma”, 4 (2005), pp. 67-93."},
					{text: "Daniele Comberiati, Laura Mengozzi, _Storie condivise nell’Italia contemporanea. Narrazioni e performance transculturali_, Carocci, Roma, 2023."},
					{text: "Daniele Comberiati, _Narrazioni postcoloniali. Il caso italiano_, “Altreitalie”, 44 (2012), pp. 67-85."},
					{text: "Nicola Labanca, _Oltremare. Storia dell’espansione coloniale italiana_, il Mulino, Bologna, 2002."},
					{text: "Luisa Revelli, _Italofonie migranti del Corno d’Africa: immaginari sociolinguistici meticci_, “Italiano LinguaDue”, 2 (2019), pp. 77-92."},
					{text: "Laura Ricci, _La lingua dell’impero. Comunicazione, letteratura e propaganda_, Carocci, Roma, 2005."},
					{text: "Laura Ricci, _La debole «italificazione» delle ex colonie africane: sulla manualistica didattica per la Libia e il Corno d’Africa_, “Testi e linguaggi”, 11 (2017), pp. 87-100."},
					{text: "Raymond Siebetcheu, _Diffusione e didattica dell’italiano in Africa. Dal periodo (pre)coloniale agli scenari futuri_, Pacini, Pisa, 2021."},
					{text: "Barbara Turchetta, _Il mondo in italiano. Varietà e usi internazionali della lingua_, Laterza, Roma-Bari, 2005."},
					{text: "Alessandro Volterra, _La giustizia coloniale in Eritrea tra diritto comune e diritto consuetudinario_, “Africa”, LXIII/1 (2008), pp. 82-107."},
					{text: "Alessandro Volterra, _Sudditi coloniali. Ascari eritrei 1935-1941_, Franco Angeli, Milano, 2005."},
				],
				sitography: [
					{text: {it: 'Italiano come pidgin', en: "Italian as pidgin"}, link: 'https://www.treccani.it/enciclopedia/italiano-come-pidgin_%28Enciclopedia-dell%27Italiano%29/#:~:text=L%27espressione%20lingua%20pidgin%20'},
				]
			}
		},
		{
			id: 25,
			name: {
				it: "Emigrazione",
				en: "Emigration",
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 1751,
			audioSpotEn: 1669,
			documentation: {
				bibliography: [
					{text: "Tullio De Mauro, _Storia linguistica dell’Italia unita_, Laterza, Bari, 1963."},
					{text: "Massimo Palermo, _Linguistica italiana_, il Mulino, Bologna, 2020<sup>2</sup>."},
					{text: "Elton Prifti, _Italoamericano. Italiano e inglese in contatto negli USA. Analisi diacronica variazionale e migrazionale_, De Gruyter, Berlin, 2016."},
					{text: "Elton Prifti, _Americanismi d’Italia, italianismi d’America. Cenni sulle tracce lessicali della Grande Emigrazione_, “Testi e linguaggi”, 11 (2017), pp. 183-196."},
					{text: "Eugenio Salvatore, _Emigrazione e lingua italiana. Studi linguistici_, Pacini, Pisa, 2017."},
					{text: "Raymond Siebetcheu, _Diffusione e didattica dell’italiano in Africa. Dal periodo (pre)coloniale agli scenari futuri_, Pacini, Pisa, 2021."},
					{text: "Barbara Turchetta, _Il mondo in italiano. Varietà e usi internazionali della lingua_, Laterza, Roma-Bari, 2005."},
					{text: "Massimo Vedovelli, _Storia linguistica dell’emigrazione italiana nel mondo_, Carocci, Roma, 2011."},
				],
				sitography: [
					{text: {it: "Italiano dell'emigrazione", en: "Emigration Italian"}, link: "https://www.treccani.it/enciclopedia/italiano-dell-emigrazione_%28Enciclopedia-dell%27Italiano%29/"},
					{text: {it: "Italiano come pidgin", en: "Italian as pidgin"}, link: "https://www.treccani.it/enciclopedia/italiano-come-pidgin_%28Enciclopedia-dell%27Italiano%29/#:~:text=L%27espressione%20lingua%20pidgin%20"},
					{text: {it: "Italoamericano", en: "Italian American"}, link: "https://www.treccani.it/enciclopedia/italoamericano_%28Enciclopedia-dell%27Italiano%29/"},
				]
			}
		},
		{
			id: 26,
			name: {
				it: "Italianismi e pseudoitalianismi",
				en: "Italianisms and pseudo-Italianisms",
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 2288,
			audioSpotEn: 2157,
			documentation: {
				bibliography: [
					{text: "Giuseppe Brincat, _Malta, una storia linguistica_, presentazione di F. Bruni, Le mani, Genova, 2003."},
					{text: "Raffaella Bombi, _Interferenze linguistiche. Tra anglicismi e italianismi_, Edizioni dell’Orso, Alessandria, 2020."},
					{text: "Rita Librandi (a cura di), _L’italiano: strutture, usi, varietà_, Carocci, Roma, 2019."},
					{text: "Matteo Motolese, _Italiano lingua delle arti_, il Mulino, Bologna, 2012."},
					{text: "Massimo Palermo, _Linguistica italiana_, il Mulino, Bologna, 2020<sup>2</sup>."},
					{text: "Lucilla Pizzoli, _Italiano e italianismi nel mondo: osservazioni sulla ricerca dei neologismi_, in Bombi R. (a cura di), _Italiano nel mondo: per una nuova visione_, Forum, Udine, 2018, pp. 153-58."},
					{text: "Harro Stammerjohann, _Dizionario degli italianismi in francese, inglese, tedesco_, Accademia della Crusca, Firenze, 2008."},
					{text: "Vidos Benedek E., _Storia delle parole marinaresche italiane passate in francese. Contributo storico linguistico all’espansione della lingua nautica italiana_, Olschki, Firenze, 1939."},
					{text: "Vidos Benedek E., _Prestito, espansione e migrazione dei termini tecnici nelle lingue romanze e non romanze. Problemi, metodo e risultati_, Olschki, Firenze, 1939."},
				],
				sitography: [
					{text: {it: "OIM - Osservatorio degli Italianismi nel Mondo", en: "OIM - Observatory of Italianisms in the world"}, link: "https://www.italianismi.org/"},
					{text: {it: "TImmagine dell'Italia, immagine dell'italiano", en: "Image of Italy, Image of Italian"}, link: "https://www.treccani.it/magazine/lingua_italiana/speciali/mondo/pizzoli.html"},
					{text: {it: "Assaggi da un dizionario di italianismi nel mondo", en: "Samples from a dictionary of Italianisms in the world"}, link: "https://www.treccani.it/magazine/lingua_italiana/speciali/mondo/rossi.html"},
					{text: {it: "Italianismi", en: "Italianisms"}, link: "https://www.treccani.it/enciclopedia/italianismi_%28Enciclopedia-dell%27Italiano%29/#:~:text=Un%20italianismo%20%C3%A8%20un%20prestito,i%20%E2%9E%94%20e%20lo%20pseudoprestito"},
					{text: {it: "Vivit - Il portale dell’italiano nel mondo", en: "Vivit (The portal of Italian in the world)"}, link: "https://www.viv-it.org/lingua/dialetti-altri-idiomi-italia"},
				]
			}
		},
		{
			id: 27,
			name: {
				it: "Immigrazione",
				en: "Immigration",
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 2851,
			audioSpotEn: 2678,
			documentation: {
				bibliography: [
					{text: "Carla Bagna _et al._, _Le lingue dei cittadini stranieri_, in _Vita e percorsi di integrazione degli immigrati in Italia_, ISTAT, 2018, Roma, pp. 219-244."},
					{text: "Silvia Dal Negro, Piera Molinelli, _Comunicare nella torre di babele. Repertori plurilingui nell’Italia di oggi_, Carocci, Roma, 2004."},
					{text: "Massimo Palermo, Monica Barni, _Multilinguismo in Italia. Nuove minoranze, lingue dell’immigrazione_, in N. Maraschio, D. De Martino e N. Stanchina (a cura di), _Esperienze di multilinguismo in atto_, Atti del Convegno di Firenze (21.23 maggio 2009), Accademia della Crusca, Firenze, 2010."},
					{text: "Massimo Palermo, _Linguistica italiana_, il Mulino, Bologna, 2020<sup>2</sup>."},
					{text: "C. Perrone, _Loro e noi. L’esperienza letteraria in italiano degli immigrati: la sindrome del ritorno in «Nonno Dio e gli spiriti danzanti» di Pap Khouma_, in _Italiani e stranieri nella tradizione letteraria_, Atti del Convegno di Montepulciano (8-10 ottobre 2007), Salerno, 2009, Roma, pp. 463-504."},
					{text: "Massimo Vedovelli (a cura di), _L’italiano dei nuovi italiani_, Atti del XIV Convegno nazionale GISCEL (Siena, 7-9 aprile 2016), Franco Angeli, Milano, 2017."},
				],
				sitography: [
					{text: {it: "L’ultimo murale di Torpignattara “Il Tempio delle Culture Migranti”", en: "Torpignattara's latest mural. “The Temple of Migrant Cultures”"}, link: "https://roma.repubblica.it/cronaca/2021/01/19/news/roma_l_ultimo_murale_di_torpignattara_il_tempio_delle_culture_migranti_-283233562/"},
					{text: {it: "Banca dati degli Scrittori immigrati in Lingua Italiana e della Letteratura italiana della Migrazione Mondiale", en: "Database of Immigrant Writers in the Italian Language and Italian Literature of World Migration"}, link: "https://www.basili-limm.it"}
				]
			}
		},
		{
			id: 28,
			name: {
				it: 'bibliografia e sitografia',
				en: 'bibliography and sitography'
			}
		},
		{
			id: 29,
			scrollyId: 1,
			name: {
				it: "La scuola",
				en: "The School",
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 4479,
			audioSpotEn: 4368,
			documentation: {
				bibliography: [
					{text: "_L'Appendix Probi. Nuove ricerche_, a cura di Francesco Lo Monaco e Piera Molinelli, Sismel Edizioni del Galluzzo, Firenze, 2007."},
					{text: "Maria Catricalà, _Le grammatiche scolastiche dell’italiano edite dal 1860 al 1918_, Accademia della Crusca, Firenze, 1991."},
					{text: "Tullio De Mauro, _Scuola e linguaggio. Questioni di educazione linguistica_, Editori Riuniti, Roma, 1977."},
					{text: "Stefano Gensini, _Breve storia dell’educazione linguistica dall’Unità a oggi_, Carocci, Roma, 2005."},
					{text: {en: "Lorenzo Milani, _Letter to a Teacher_, Random House, New York/Penguin Books, London, 1970."}},
					{text: "Matteo Motolese, _L'eccezione fa la regola. Sette storie di errori che raccontano l’italiano_, Garzanti, Milano, 2022."},
					{text: "Luca Serianni, Giuseppe Benedetti, _Scritti sui banchi. L’italiano a scuola tra alunni e insegnanti_, Carocci, Roma, 2009."},
					{text: "Luca Serianni, _La norma sommersa_, “Lingua e Stile”, XLII (2007), pp. 283-95."},
					{text: "Pietro Trifone, _Malalingua. L’italiano scorretto da Dante a oggi_, il Mulino, Bologna, 2007."},
					{text: "_Una di lingua, una di scuola. Imparare l’italiano dopo l’Unità. Testi autori documenti_, a cura di Giuseppe Polimeni, Franco Angeli, Milano, 2012."},
					{text: "_Verso l’italiano. Percorsi e strategie di acquisizione_, a cura di Anna Giacalone Ramat, Carocci, Roma, 2003."},
				],
				sitography: [
					{text: {it: "Dieci tesi per l’educazione linguistica democratica", en: "Ten Theses for Democratic Language Education"}, link: "https://giscel.it/dieci-tesi-per-leducazione-linguistica-democratica/"},
					{text: {it: "Banca dati degli Scrittori Immigrati in Lingua Italiana e della Letteratura Italiana della Migrazione Mondiale", en: "Database of Immigrant Writers in the Italian Language and Italian Literature of World Migration"}, link: "https://www.basili-limm.it](https://www.basili-limm.it/"},
					{text: {it: "Corpus digitale delle scritture scolastiche", en: "Digital Corpus of School Writings"}, link: "http://codissc.it"},
					{text: {it: "Matteo Motolose sulla regola", en: "Matteo Motolese on the rule"}, link: "https://www.raicultura.it/letteratura/articoli/2022/09/Matteo-Motolese-Leccezione-fa-la-regola-4fa641be-5f26-497f-af04-7825c7de4dd0.html"},
					{text: {en: "Extracts from _Letter to a Teacher_"}, link: "https://unesdoc.unesco.org/ark:/48223/pf0000052139"}
				]
			}
		},
		{
			id: 30,
			name: {
				it: "intro",
				en: "intro"
			},
			audioSpotIt: 0,
			audioSpotEn: 0,
		},
		{
			id: 31,
			name: {
				it: "Predicazione",
				en: "Preaching"
			},
			audioSpotIt: 43,
			audioSpotEn: 49,
			documentation: {
				bibliography: [
					{text: "Rosa Casapullo, _La lingua della predicazione_, in Ead., _Il Medioevo_, il Mulino, Bologna, 1999, pp. 175-82."},
					{text: "Vittorio Coletti, _Parole dal pulpito. Chiesa e movimenti religiosi tra latino e volgare nell’Italia del Medioevo e del Rinascimento_, Marietti, Casale Monferrato, 1983."},
					{text: "Michele Colombo, _Predicazione e oratoria politica_, in _Storia dell’italiano scritto_, a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. III, _Italiano dell’uso_, Carocci, Roma, 2014, pp. 261-92."},
					{text: "Carlo Delcorno, _«Quasi quidam cantus». Studi sulla predicazione medievale_, a cura di Giovanni Baffetti, Olschki, Firenze, 2009."},
					{text: "Rita Librandi, _L’italiano nella comunicazione della Chiesa e nella diffusione della cultura religiosa_, in _Storia della lingua italiana_, a cura di Luca Serianni e Pietro Trifone, vol. I, _I luoghi della codificazione_, Einaudi, Torino, 1993, pp. 335-81."},
					{text: "Rita Librandi, _La letteratura religiosa_, il Mulino, Bologna, 2012."},
					{text: "Rita Librandi, _L’italiano della Chiesa_, Carocci, Roma, 2017."},
					{text: "Rita Librandi (a cura di), _La Chiesa e l’italiano: un cammino nel tempo e nel mondo_, il Mulino, Bologna, 2021."},
				],
				sitography: [
					{text: {it: "Lingua della predicazione", en: "Language of Preaching"}, link: "https://www.treccani.it/enciclopedia/predicazione-e-lingua_%28Enciclopedia-dell%27Italiano%29/#:~:text=Insegnavano%20la%20religione%20e%20la,sulle%20conseguenze%20per%20chi%20disubbidisce."},
					{text: {it: "Lingua della Chiesa", en: "Language of Church"}, link: "https://www.treccani.it/enciclopedia/chiesa-e-lingua_%28Enciclopedia-dell%27Italiano%29/"}
				]
			}
		},
		{
			id: 32,
			name: {
				it: "Musica",
				en: "Music"
			},
			audioSpotIt: 639,
			audioSpotEn: 680,
			documentation: {
				bibliography: [
					{text: "Giuseppe Antonelli, _Ma cosa vuoi che sia una canzone. Mezzo secolo di italiano cantato_, il Mulino, Bologna, 2010."},
					{text: "Ilaria Bonomi, Edoardo Buroni, _La lingua dell’opera lirica_, il Mulino, Bologna, 2017."},
					{text: "Vittorio Coletti, Lorenzo Coveri, _Da San Francesco al rap: l’italiano in musica_, Gedi, Roma, 2016."},
					{text: "Gianfranco Folena, _Una lingua per la musica_, in Id., _L’italiano in Europa. Esperienze linguistiche del Settecento_, Einaudi, Torino, 1983, pp. 219-55."},
					{text: "Fabio Rossi, _L' opera italiana: lingua e linguaggio_, Carocci, Roma, 2018."},
					{text: "Luca Zuliani, _L’italiano della canzone_, Carocci, Roma, 2018."},
				],
				sitography: [
					{text: {it: "Lingua della musica", en: "Language of Music"}, link: "https://www.treccani.it/enciclopedia/musica-e-lingua_%28Enciclopedia-dell%27Italiano%29/#:~:text=Musica%20e%20lingua%20sono%20unite,bens%C3%AC%20musica%20e%20lingua%20parlata."}
				]
			}
		},
		{
			id: 33,
			name: {
				it: "Teatro",
				en: "Theater"
			},
			audioSpotIt: 1211,
			audioSpotEn: 1287,
			documentation: {
				bibliography: [
					{text: "Maria Luisa Altieri Biagi_, La lingua in scena_, Zanichelli, Bologna, 1980."},
					{text: "Luca D’Onghia, _Drammaturgia_, in _Storia dell'italiano scritto_, a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. II, _Prosa letteraria_, Carocci, Roma, 2014, pp. 153-202"},
					{text: "Gianfranco Folena, _L’esperienza linguistica di Carlo Goldoni_, in Id., _L’italiano in Europa. Esperienze linguistiche del Settecento_, Einaudi, Torino, 1983."},
					{text: "Claudio Giovanardi, Pietro Trifone, _La lingua del teatro_, il Mulino, Bologna, 2015."},
					{text: "Luca Serianni, _Lettura linguistica di «Pensaci, Giacomino!»_, “Studi linguistici italiani”, XVII (1991), pp. 55-70."},
					{text: "Stefania Stefanelli, _Va in scena l’italiano_, Cesati, Firenze, 2006."},
					{text: "Pietro Trifone, _L’italiano a teatro_, in _Storia della lingua italiana_, a cura di Luca Serianni e Pietro Trifone, vol. II, _Scritto e parlato_, Einaudi, Torino, 1994, pp. 81-159."},
					{text: "Pietro Trifone, _L’italiano a teatro. Dalla commedia rinascimentale a Dario Fo_, Istituti editoriali e poligrafici internazionali, Roma-Pisa, 2000."},
					{text: "Tobia Zanon, _Teatro in versi_, in _Storia dell'italiano scritto_. a cura di Giuseppe Antonelli, Matteo Motolese e Lorenzo Tomasin, vol. I, _Poesia_, Carocci, Roma, 2014, pp. 323-51."},
				],
				sitography: [
					{text: {it: "Lingua del teatro", en: "Language of Theatre"}, link: "https://www.treccani.it/enciclopedia/teatro-e-lingua_(Enciclopedia-dell%27Italiano)/#:~:text=La%20lingua%20del%20testo%20teatrale,il%20pubblico%20e%20la%20scena."}
				]
			}
		},
		{
			id: 34,
			name: {
				it: "Radio",
				en: "Radio"
			},
			audioSpotIt: 1667,
			audioSpotEn: 1693,
			documentation: {
				bibliography: [
					{text: "Elena Atzori, _La parola alla radio. Il linguaggio dell’informazione radiofonica_, Cesati, Firenze, 2002."},
					{text: "Elena Atzori, _La lingua della radio in onda e in rete_, Cesati, Firenze, 2017."},
					{text: "Gianni Isola, _Abbassa la tua radio, per favore… Storia dell’ascolto radiofonico nell’Italia fascista_, La Nuova Italia, Firenze, 1990."},
					{text: "_Gli italiani trasmessi. La radio_. Atti del Convegno (Firenze, Villa Medicea di Castello, 13-14 maggio 1994), Accademia della Crusca, Firenze, 1997."},
					{text: "_La lingua italiana e i mass media_, a cura di Ilaria Bonomi, Andrea Masini, Silvia Morgana, Carocci, Roma, 2003."},
					{text: "Sergio Raffaelli, _La norma linguistica alla radio nel periodo fascista_, in _Gli italiani trasmessi. La radio_, pp. 31-67."},
					{text: "Riccardo Tesi, _Linguaggio politico e propaganda radiofonica. L’italiano di “Radio Londra”_, in _Gli italiani trasmessi. La radio_, pp. 69-106."},
				],
				sitography: [
					{text: {it: "Lingua della radio", en: "Language of Radio"}, link: "https://www.treccani.it/enciclopedia/radio-e-lingua_%28Enciclopedia-dell%27Italiano%29/"},
					{text: {it: "V. Della Valle, V. Gandolfo, documentario sulla lingua del fascismo", en: "V. Della Valle, V. Gandolfo, about the Language of Fascism"}, link: "https://cinecitta.com/IT/it-it/news/45/5057/me-ne-frego-il-fascismo-e-la-lingua-italiana.aspx"}
				]
			}
		},
		{
			id: 35,
			name: {
				it: "Televisione",
				en: "Television"
			},
			audioSpotIt: 2036,
			audioSpotEn: 2093,
			documentation: {
				bibliography: [
					{text: "Marco Centorrino, _La rivoluzione satellitare. Come Sky ha cambiato la televisione italiana_, Franco Angeli, Milano, 2006."},
					{text: "Pierangela Diadori, _L’italiano televisivo_, Bonacci, Roma, 1994."},
					{text: "_Gli italiani del piccolo schermo. Lingua e stili comunicativi nei generi televisivi_, a cura di Gabriella Alfieri e Ilaria Bonomi, Cesati. Firenze, 2008."},
					{text: "Enrico Menduini, _I linguaggi della radio e della televisione_, Laterza, Roma-Bari, 2002."},
					{text: "Laura Nacci, _La lingua della televisione_, in _La lingua italiana e i mass media_, a cura di Ilaria Bonomi, Andrea Masini, Silvia Morgana, Carocci, Roma, 2003, pp. 67-92."},
				],
				sitography: [
					{text: {it: "Lingua della televisione", en: "Language of Television"}, link: "https://www.treccani.it/enciclopedia/televisione-e-lingua_%28Enciclopedia-dell%27Italiano%29/#:~:text=Non%20poche%20sono%20le%20espressioni,trasmissioni%20e%20personaggi%20del%20presente."}
				]
			}
		},
		{
			id: 36,
			name: {
				it: "bibliografia",
				en: "bilbiography"
			},
			audioSpotIt: 0,
			audioSpotEn: 0,
			documentation: {
				bibliography: [
					{text: ""}
				],
				sitography: [
					{text: "", link: ""}
				]
			}
		},
		// DIALETTI
		{
			id: 401,
			scrollyId: 4,
			name: {
				it: 'Le lingue\ndell’Italia antica',
				en: 'The languages of Ancient Italy'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 32,
			audioSpotEn: 32,
			documentation: {
				bibliography: [
					{text: "Valentina Casella, Maria Federica Petraccia, _The roman Senate as arbiter during the second century BC. Two exemplary case studies: the Cippus Abellanus and the Polcevera Tablet_, Brepols, Turnhout, 2019."},
					{text: "Silvio Ferri, _L'iscrizione «_sicula_» di Centuripe e le sue esigenze archeologiche_, “Latomus”, 15 (1956), pp. 285-89."},
					{text: "Anna Marinetti, _Venetico_, “Palaeohispanica. Revista sobre lenguas y culturas de la Hispania Antigua”, 20 (2020), pp. 367-401."},
					{text: "Riccardo Massarelli, _Le defixiones nel mondo etrusco_, in _Forme e strutture della religione nell’Italia mediana antica_, a cura di Augusto Ancillotti, Alberto Calderini, Riccardo Massarelli, «L'Erma» di Bretschneider, Roma, 2016, pp. 517-32."},
					{text: "Maurizio Paoletti, _“Kleom(b)rotos, figlio di Dexilaos, (mi) dedicò”. L’offerta di un atleta vincitore ad Olimpia nel santuario di Francavilla Marittima_, “Analecta Romana Instituti Danici”, XLIII (2019), pp. 7-24."},
					{text: "Aldo Luigi Prosdocimi, _Le lingue dominanti e i linguaggi locali_, in _Lo spazio letterario di Roma antica_, a cura di Guglielmo Cavallo, Paolo Fedeli, Andrea Giardina, vol. II, Salerno Editrice, Roma, 1989."},
					{text: "Mario Russo, _Punta della Campanella. Epigrafe rupestre osca e reperti vari dell'Athenaion_, in _Monumenti antichi. Serie miscellanea-Volume III_, a cura di Paola Zancani Monturo, Accademia nazionale dei Lincei, Roma, 1990."},
					{text: "Alberto Varvaro, _Il latino e la formazione delle lingue romanze_, il Mulino, Bologna, 2014."},
				],
				sitography: [
					{text: {it: 'Sulle scritture antiche del Mediterraneo', en: "On the Ancient Mediterranean Scriptures"}, link: 'https://mnamon.sns.it/'},
				]
			}
		},
		{
			id: 402,
			scrollyId: 4,
			name: {
				it: 'Le aree\ndialettali',
				en: 'The dialect areas of Italy'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 510,
			audioSpotEn: 462,
			documentation: {
				bibliography: [
					{text: "Francesco Avolio, _Lingue e dialetti d’Italia_, Carocci, Roma, 2009."},
					{text: "Carla Marcato, _Dialetto, dialetti e italiano_, il Mulino, Bologna, 2007."},
					{text: "Mari D’Agostino, _Sociolinguistica dell’Italia contemporanea_, il Mulino, Bologna, 2007."},
					{text: "Michele Loporcaro, _Profilo linguistico dei dialetti italiani_, Laterza, Roma-Bari, 2009."},
					{text: "Giovan Battista Pellegrini, _Carta dei dialetti d'Italia_, Litografia Artistica Cartografica, Firenze, 1977."},
					{text: "Gerhard Rohlfs, _Grammatica storica della lingua italiana e dei suoi dialetti_, il Mulino, Bologna, 2021, 3 voll. (nuova edizione)."},
				],
				sitography: [
					{text: {it: 'Atlante linguistico ed etnografico dell’Italia e della Svizzera meridionale', en: "Linguistic and Ethnographic Atlas of Italy and Southern Switzerland"}, link: 'https://navigais-web.pd.istc.cnr.it/'},
				]
			}
		},
		{
			id: 403,
			scrollyId: 4,
			name: {
				it: 'I tratti dei\ndialetti italiani',
				en: 'The Features of Italian Dialects'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 903,
			audioSpotEn: 860,
			documentation: {
				bibliography: [
					{text: 'Francesco Avolio, _Lingue e dialetti d\'Italia_, Carocci, Roma, 2009.'},
					{text: 'Giuliano Bernini, Federica Guerini, Gabriele Iannàccaro, _La presenza dei dialetti italo-romanzi nel paesaggio linguistico. Ricerche e riflessioni_, Aisberg, Bergamo, 2021.'},
					{text: 'Gaetano Berruto, _Sociolinguistica dell’italiano contemporaneo,_ Carocci, Roma, 1987.'},
					{text: 'Mari D’Agostino, _Sociolinguistica dell’Italia contemporanea_, il Mulino, Bologna, 2007.'},
					{text: 'Claudio Marazzini, _La lingua italiana_. _Profilo storico_, il Mulino, Bologna, 2002.'},
					{text: 'Carla Marcato, _Dialetto, dialetti e italiano,_ il Mulino, Bologna, 2007.'},
					{text: 'Carla Marcato, _La dialettalità in scritture esposte a Venezia_, “Italica”, 95 (2018), pp. 585-99.'},
					{text: 'Michele Loporcaro, _Profilo linguistico dei dialetti italiani_, Laterza, Roma, 2009.'},
					{text: 'Gerhard Rohlfs, _Grammatica storica della lingua italiana e dei suoi dialetti_, il Mulino, Bologna, 2021, 3 voll. (nuova edizione).'},
				],
				sitography: [
					{text: {it: 'Aree linguistiche', en: "Linguistic Areas"}, link: 'https://www.treccani.it/enciclopedia/aree-linguistiche_%28Enciclopedia-dell%27Italiano%29/'},
					{text: {it: 'Tratti linguistici toscani', en: "Tuscan Linguistic Features"}, link: 'https://www.viv-it.org/schede/toscana-tratti-linguistici'},
				]
			}
		},
		{
			id: 404,
			scrollyId: 4,
			name: {
				it: 'La produzione\nartistica in dialetto',
				en: 'Literary production in dialect'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 1520,
			audioSpotEn: 1547,
			documentation: {
				bibliography: [
					{text: 'Hermann W. Aller, _La festa delle lingue_, Carocci, Roma, 2002.'},
					{text: 'Davide Bozzo, _Il plurilinguismo nella canzone dialettale italiana da De André a Van De Sfroos: questioni teoriche e ricadute didattiche_, “Italiano LinguaDue”, 2 (2020).'},
					{text: 'Lorenzo Coveri, _Lingua e dialetto nella canzone popolare italiana recente_, in _Analisi e canzoni, Atti del Convegno di studio_ (Trento, 12-14 maggio 1995), a cura di Rossana Dalmonte, Università degli Studi di Trento, Trento, 1996, pp. 25-38.'},
					{text: 'Edoardo Firpo, _Tutte le poesie_, a cura di Bruno Cicchetti, Eligio Imarisio, San Marco dei Giustiniani, Genova, 2004.'},
					{text: 'Gianfranco Folena, _L’esperienza linguistica di Carlo Goldoni_, “Lettere Italiane”, 10 (1958), pp. 21-54'},
					{text: 'Carlo Goldoni, _Tutte le opere_, a cura di Giuseppe Ortolani, Mondadori, Milano, 1935-1956, 14 voll.'},
					{text: '_La poesia in dialetto. Storia e testi dalle origini al Novecento_, a cura di Franco Brevini, Mondadori, Milano, 1999.'},
					{text: 'Vito Pandolfi, _Lo spettacolo del secolo_, Nistri-Lischi, Pisa, 1953.'},
					{text: 'Pier Paolo Pasolini, _Poesie a Casarsa_, Ronzani, Dueville (VI), 2019 (prima edizione Libreria antiquaria Mario Landi, Bologna 1942).'},
					{text: 'Gigi Proietti, _La mia università? È stata il Tufello_, “La Repubblica”, 4/12/2003.'},
					{text: 'Roberto Sottile, _L’uso del dialetto nella canzone. Studi, tendenze, prospettive di ricerca_, in _L’italiano lungo le vie della musica: la canzone_, a cura di Lorenzo Coveri, Pierangela Diadori, Cesati, Firenze, 2020, pp. 15-22.'},
				],
				sitography: [
					{text: {it: 'Vita di Eduardo De Filippo', en: "Life of Eduardo De Filippo"}, link: 'https://www.treccani.it/enciclopedia/eduardo-de-filippo_%28Dizionario-Biografico%29/'},
					{text: {it: 'Intervista a Gigi Proietti', en: "Interview with Gigi Proietti"}, link: 'https://ricerca.repubblica.it/repubblica/archivio/repubblica/2003/04/12/la-mia-universita-stata-il-tufello.html'},
					{text: {it: 'Sulla lingua di Carlo Goldoni', en: "On Goldoni’s Language"}, link: 'https://www.viv-it.org/schede/lingua-di-goldoni-ragioni-e-caratteristiche'},
				]
			}
		},
		{
			id: 405,
			scrollyId: 4,
			name: {
				it: 'L\'italiano regionale',
				en: 'Regional Italian'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 2137,
			audioSpotEn: 2272,
			documentation: {
				bibliography: [
					{text: 'Nicola De Blasi, _Geografia e storia dell’italiano regionale_, il Mulino, Bologna, 2014.'},
					{text: 'Rita Librandi, _Nuovi plurilinguismi nella narrativa meridionale?_, in _Dialetti: per parlare e parlarne, Atti del I Convegno Internazionale di Dialettologia - Progetto A.L.Ba._ (Potenza-Matera 30-31 ottobre 2008), a cura di Patrizia Del Puente, Osanna Edizioni, Potenza, 2016, pp. 77-92.'},
					{text: '_L’italiano al cinema, l’italiano nel cinema_, a cura di Giuseppe Patota, Fabio Rossi, goWare, Firenze, 2017.'},
					{text: '_L\'italiano nelle regioni. Lingua nazionale e identità regionali_, a cura di Francesco Bruni, UTET, Torino, 1992, 2 voll.'},
					{text: 'Sergio Raffaelli, _Il parlato cinematografico e televisivo_, in _Storia della lingua italiana, vol. II: Scritto e parlato_, a cura di Luca Serianni, Pietro Trifone, Einaudi, Torino, 1994.'},
					{text: 'Sergio Raffaelli, _Il cinema in cerca della lingua. Vent’anni di parlato filmico in Italia (1945-1965)_, in _Identità italiana e identità europea nel cinema italiano dal 1945 al miracolo economico_, a cura di Gian Piero Brunetta, Fondazione Giovanni Agnelli, Torino, 1996, pp. 309-335.'},
					{text: 'Fabio Rossi, _Lingua italiana e cinema_, Carocci, Roma, 2007.'},
					{text: 'Roberto Sottile, _La parola dialettale nell’opera di Leonardo Sciascia: il caso di “taddema”_, “Italiano digitale”, 15 (2020).'},
					{text: 'Carolina Stromboli, _Il dialetto sul grande schermo. Il napoletano nella storia del cinema italiano_, Cesati, Firenze, 2022.'},
				],
				sitography: [
					{text: {it: 'Italiano regionale', en: "Regional Italian"}, link: 'https://www.treccani.it/enciclopedia/italiano-regionale_%28Enciclopedia-dell%27Italiano%29/'},
					{text: {it: 'Intervista a Sergio Raffaelli', en: "Interview with Sergio Raffaelli"}, link: 'https://www.treccani.it/magazine/lingua_italiana/speciali/cinema/raffaelli_ravesi.html'},
					{text: {it: 'Sulla lingua di Camilleri', en: "On Camilleri’s Language"}, link: 'http://www.vigata.org/dizionario/camilleri_linguaggio.html'},
				]
			}
		},
		// POPOLARE
		{
			id: 601,
			scrollyId: 6,
			name: {
				it: 'Tra i “semicolti”',
				en: 'Among the “Semi-Educated”'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 3.5,
			audioSpotEn: 4.3,
			documentation: {
				bibliography: [
					{text: 'Francesco Bruni, _Traduzione, tradizione e diffusione della cultura: contributo alla lingua dei semicolti_, “Quaderni storici”, 38 (1978), pp. 523-54.'},
					{text: 'Lanfranco Caretti, _Il “fidato” Elia e altre note alfieriane_, Liviana, Padova, 1961.'},
					{text: 'Maria Corti, _Il servo Elia_, in Ead., _Nuovi metodi e fantasmi_, Feltrinelli, Milano, 2001.'},
					{text: 'Paolo D’Achille, _L’italiano dei semicolti_, in _Storia della lingua italiana_, a cura di Luca Serianni e Pietro Trifone, vol. II, _Scritto e parlato_, Einaudi, Torino, 1994, pp. 41-79.'},
					{text: 'Paolo D’Achille, _Italiano dei semicolti e italiano regionale. Tra diastratia e diatopia_, libreriauniversitaria.it, Padova, 2022.'},
					{text: 'Michele Di Sivo, _Mano da strega. La scrittura di Bellezza Orsini_ (1528), “Mélanges de l’École Française de Rome”, 131-132 (2019), pp. 361-68.'},
					{text: 'Michele Di Sivo, _Bellezza Orsini. La costruzione di una strega_, Roma nel Rinascimento, Roma, 2016.'},
					{text: {it: 'Carlo Ginzburg, _Il formaggio e i vermi. Il cosmo di un mugnaio del \'500_, Adelphi, Milano, 2019 (nuova edizione).', en: 'Carlo Ginzburg, _The Cheese and the Worms_, Johns Hopkins University Press, Baltimore, 1980'}},
					{text: '_Il cannone e la forca. Atti originali del processo agli insorti del 14 gennaio 1747_, a cura di Luigi Grasso, Quasar, Milano, 2011.'},
					{text: 'Tina Matarrese, _Storia della lingua italiana. Il Settecento_, il Mulino, Bologna, 1993, pp. 281-87.'},
					{text: 'Virginia Maddalena Satragno, _Lettere dalla Val Bormida 1858-1860. Un’edizione di testi in italiano popolare_, tesi di laurea, Università di Genova, relatore Enrico Testa, a.a. 2006-2007.'},
					{text: 'Enrico Testa, _L’italiano nascosto. Una storia linguistica e culturale_, Einaudi, Torino, 2014.'},
					{text: 'Pietro Trifone, _La confessione di Bellezze Ursini “strega” nella campagna romana del Cinquecento_, "Contributi di filologia dell’Italia mediana", II (1988), pp. 76-136.'},
				],
				sitography: [
					{text: {it: 'Michele Di Sivo sulla vicenda di Bellezze Orsini', en: 'Michele Di Sivo on the story of Bellezze Orsini'}, link: 'https://www.youtube.com/watch?v=up3d5raDsY4&t=3m'},
					{text: {it: 'Intervista a Carlo Ginzburg su Menocchio', en: 'Interview with Carlo Ginzburg on Menocchio'}, link: 'https://www.youtube.com/watch?v=ZRltrt8La2E'},
				]
			}
		},
		{
			id: 602,
			scrollyId: 6,
			name: {
				it: 'Al fronte',
				en: 'At the Front'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 972.89,
			audioSpotEn: 859,
			documentation: {
				bibliography: [
					{text: 'Sergio Bozzola, _Tra un’ora la nostra sorte. Le lettere dei condannati a morte e dei deportati della Resistenza_, Carocci, Roma, 2013.'},
					{text: 'Franco Castelli, Emilio Jona, Alberto Lovatto, _Al rombo del cannon. Grande Guerra e canto popolare_, Neri Pozza, Vicenza, 2018.'},
					{text: 'Tullio De Mauro, _Storia linguistica dell’Italia unita_, Laterza, Roma-Bari, 1963.'},
					{text: 'Leo Spitzer, _Lettere di prigionieri di guerra italiani. 1915-1918_, a cura di Lorenzo Renzi, il Saggiatore, Milano, 2016 (nuova edizione).'},
					{text: 'Mirko Volpi, _«Sua Maestà è una pornografia!». Italiano popolare, giornalismo e lingua della politica tra la Grande Guerra e il referendum del 1946_, libreriauniversitaria.it, Padova, 2014.'},
				],
				sitography: [
					{text: '', link: ''},
					{text: {it: 'Materiali Rai sulla Grande Guerra', en: 'Rai materials on the Great War'}, link: 'https://www.raicultura.it/webdoc/grande-guerra/index.html#indice'},
					{text: {it: 'Lettere dal fronte 1914-1918', en: 'Letters from the Front 1914-1918'}, link: 'http://www.centenario1914-1918.it/it/canale/lettere-dal-fronte'},
					{text: {it: 'Documenti storici sulla Prima e Seconda guerra mondiale', en: 'Historical documents on the First and Second World Wars'}, link: 'http://www.memoriediguerra.it/site'},
					{text: {it: 'Testimonianze sulla Grande Guerra', en: 'Testimonies on the Great War'}, link: 'http://www.vocidellagrandeguerra.it'},
					{text: {it: 'Immagini, canzoni e reperti iconografici 1914-1918', en: 'Images, songs and iconographic materials 1914-1918'}, link: 'https://www.14-18.it/ '},
					{text: {it: 'Ultime lettere di condannati a morte e di deportati della Resistenza italiana', en: 'Last letters of condemned prisoners and deportees of the Italian Resistance'}, link: 'http://www.ultimelettere.it'},
				]
			}
		},
		{
			id: 603,
			scrollyId: 6,
			name: {
				it: 'Diari',
				en: 'Diaries'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 1991,
			audioSpotEn: 1849,
			documentation: {
				bibliography: [
					{text: 'Clelia Marchi, _Il tuo nome nella neve. Gnanca na busia_, il Saggiatore, Milano, 2012 (nuova edizione).'},
					{text: 'Mario Perrotta, _Il paese dei diari_, Terre di mezzo, Milano, 2009.'},
					{text: 'Vincenzo Rabito, _Terra matta_, a cura di Evelina Santangelo e Luca Ricci, Einaudi, Torino, 2007.'},
					{text: 'Luisa T., _I quaderni di Luisa. Diario di una resistenza casalinga_, Terre di mezzo, Milano, 2017.'},
				],
				sitography: [
					{text: '', link: ''},
					{text: {it: 'Fondazione Archivio Diaristico nazionale', en: 'National Diary Archive of Pieve Santo Stefano'}, link: 'http://www.archiviodiari.org/index.php/home.html'},
					{text: {it: '*Terra matta* di Vincenzo Rabito', en: '_Terra Matta_ by Vincenzo Rabito'}, link: 'https://www.vincenzorabito.com/media/ '},
					{text: {it: 'Archivio Ligure della Scrittura Popolare', en: 'Ligurian Archive of Popular Writing'}, link: 'https://alsp.unige.it'},
					{text: {it: 'Archivio della Scrittura Popolare-Museo storico del Trentino', en: 'Popular Writing Archive-Trentino Historical Museum'}, link: 'http://900trentino.museostorico.it/Archivio-della-Scrittura-Popolare'},
					{text: {it: 'DIMMI-Storie migranti', en: 'DIMMI-Migrant Stories'}, link: 'https://www.dimmidistoriemigranti.it'},
				]
			}
		},
		{
			id: 604,
			scrollyId: 6,
			name: {
				it: 'Al cinema',
				en: 'On Film'
			},
			historicalMoment: {
				it: 'secolo di riferimento',
				en: 'before 500'
			},
			audioSpotIt: 2773,
			audioSpotEn: 2576,
			documentation: {
				bibliography: [
					{text: 'Patricia Bianchi, Nicola De Blasi, Carolina Stromboli, _Massimo Troisi, un napoletano moderno_, Cesati, Firenze, 2020.'},
					{text: 'Claudio Giunta, _Diventare Fantozzi_, in Id., _Una sterminata domenica. Saggi sul paese che amo_, il Mulino, Bologna, 2013.'},
					{text: 'Sergio Raffaelli, _Parole di film. Studi cinematografici 1961-2010_, a cura di Massimo Fanfani, Cesati, Firenze, 2015.'},
					{text: 'Fabio Rossi, _La lingua in gioco. Da Totò a lezione di retorica_, Bulzoni, Roma, 2002.'},
				],
				sitography: [
					{text: '', link: ''},
					{text: {it: 'Claudio Giunta su Fantozzi', en: 'Claudio Giunta on Fantozzi'}, link: 'http://claudiogiunta.it/2013/11/diventare-fantozzi/'},
					{text: {it: 'Giuliana Fiorentino su Totò, Peppino e la sociolinguistica', en: 'Giuliana Fiorentino on Totò, Peppino and sociolinguistics'}, link: 'https://www.treccani.it/magazine/lingua_italiana/articoli/scritto_e_parlato/Toto_Peppino.html'},
				]
			}
		},
	]
}
