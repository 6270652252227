import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'
import messages from '@js/messages.js'

const i18n = createI18n({
  locale: 'it', // set locale
  messages, // set locale messages
  datetimeFormats: {
    'it': {
      default: {
        locale: 'it-IT',
        dateFormat: '[[Day]] [[Month]]. [[Year]]',
        year: 'numeric', month: 'short', day: 'numeric'
      },
    },
    'en': {
      default: {
        locale: 'it-IT',
        dateFormat: '[[Day]] [[Month]]. [[Year]]',
        year: 'numeric', month: 'short', day: 'numeric'
      },
    },
  },
})

export default i18n;