<template>
    <div class="svg-icon" v-html="svg"></div>
</template>
<script type="text/javascript">

export default {
  props: {
    name: {
      type: String,
      required: true,
    }
  },
  computed: {
    svg() {
      return require(`@assets/icons/${this.name}.svg`)
    }
  }
}
</script>

<style type="text/css">
.svg-icon {
  width: 32px;
  height: 32px;
  --svg-icon-color: var(--color-black-text);
}
.svg-icon.big {
  width: 52px;
  height: 52px;
/*  --svg-icon-color: blue;*/
}
.svg-icon.inline {
  display: inline-block;
  height: 1em;
  width: 1em;
  vertical-align: -0.2em;
}
.svg-icon .svg-color-stroke path {
  stroke: var(--svg-icon-color);
}
.svg-icon .svg-color-fill path {
  fill: var(--svg-icon-color);
}
</style>
