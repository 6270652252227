import inDepthItems from '@static/inDepthItems.js';
import stagesData from '@static/stagesData.js';
import utils from '@js/utils.js';

export default {
	scrollies: [
		{
			nameCode: 'il-multi',
			sections: [
				{
					id: 'introduzione',
					title: { 
						it: 'Introduzione',
						en: 'Introduction'
					},
					style: 'background: var(--color-rose)',
					caption: {
						it: `Il Multi – Museo multimediale della lingua italiana è un museo virtuale che si propone di valorizzare il variegato patrimonio immateriale della storia della lingua italiana attraverso una fruizione dei contenuti inclusiva e interattiva, volta anche a non specialisti e non italofoni.
Realizzato grazie a un finanziamento del Ministero dell’Università e della Ricerca,  il progetto nasce dal lavoro di progettazione condotto da tre distinte unità di ricerca, Università di Napoli “L’Orientale”, Università della Tuscia (Viterbo) e Università degli Studi di Pavia (con ruolo di coordinamento), in collaborazione con lo studio Dotdotdot cui si deve la realizzazione del Multi, dalla progettazione dell’identità e dell’esperienza utente fino allo sviluppo della piattaforma.`,
						en: `Multi – Multimedia Museum of the Italian Language is a virtual museum whose purpose is to enhance the richly varied intangible heritage of the history of the Italian language through an inclusive and interactive use of its content. It is aimed both at non-specialists and non-Italian speakers.
Funded by a grant from the Italian Ministry of University and Research, the project is the result of work, from conception to implementation, undertaken by three separate research units, the University of Naples “L'Orientale”, the University of Tuscia (Viterbo) and the University of Pavia (as coordinator), in collaboration with studio Dotdotdot, which was responsible for creating the Multi web platform, from the design of its identity and user experience to its development.`
					},
					steps: [{}],
					numSteps: 1,
				},
				{
					id: 'il-museo',
					title: { 
						it: 'Il Museo multimediale',
						en: 'The Multimedia museum' 
					},
					style: 'background: var(--color-gold)',
					caption: {
						it: `Il Multi si propone di raccontare, preservare e contestualizzare il patrimonio immateriale unico della lingua italiana, in una modalità che permuta l’offerta tipica dei musei fisici con le opportunità del digitale. Così il museo fisico ha una collezione permanente, così il Multi si compone di percorsi narrativi curatoriali; archivi, biblioteche e depositi, spesso presenti nei musei fisici, sono sostituiti dai reperti della collezione digitale; l’attività di laboratori e workshop è affidata alla presenza di contenuti interattivi come i giochi; mentre all’attività museale, costituita tipicamente da mostre temporanee, conferenze e talk, fa da contrappunto la sezione dedicata all’attualità, attraverso articoli, video e podcast condivisibili anche via social media. Tutta l’esperienza di visita è disponibile sia in italiano che in inglese: una scelta che consente di mettere gratuitamente a disposizione di un’utenza internazionale documenti spesso inaccessibili per la loro natura o stato di conservazione, e che al tempo stesso ambisce a valorizzare risorse già disponibili online ma perlopiù ignote al pubblico dei non addetti ai lavori, quali dizionari, archivi e biblioteche digitali.`,
						en: `Multi aims to narrate, preserve and contextualise the unique intangible heritage of the Italian language, in a way that substitutes what physical museums offer with the opportunities provided by digital assets. Just as a physical museum has a permanent collection, so Multi consists of curatorial narrative paths. The archives, libraries and repositories that are often present in physical museums are replaced by the exhibits of its digital collection; interactive content, including games, takes the place of workshops and courses; other museum activities such as temporary exhibitions, events and talks are offset by the section dedicated to news and stories that features articles, videos and podcasts, which can also be shared via social media. The entire visiting experience is available in both Italian and English. This means that documents that are often inaccessible due to their nature or state of preservation are freely available to a global audience. At the same time the intention is to enhance the resources that are already available online but which are mostly unknown to the general public, such as dictionaries, archives and digital libraries.`
					},
					steps: [{}],
					numSteps: 1,
				},
				{
					id: 'percorsi',
					title: { 
						it: 'I percorsi',
						en: 'Itineraries' 
					},
					style: 'background: var(--color-red)',
					caption: {
						it: `All’origine del progetto Multi vi è l’idea della lingua come di uno spazio liberamente percorribile: una città (prendendo a prestito una celebre immagine di Wittgenstein) le cui strade corrispondono, fuor di metafora, ai tracciati e agli snodi compiuti da una lingua nel proprio percorso storico. Per questo, il Multi si articola in sei percorsi di visita (più un atrio introduttivo) tra loro autonomi, ciascuno dei quali propone al visitatore un racconto multimediale per schermate dedicato a una questione o aspetto fondamentali nella storia della lingua italiana, dalle origini alla contemporaneità. Il visitatore si trova immerso in una dimensione narrativa caratterizzata da elementi coinvolgenti – interattivi ed esplorabili – con brevi testi spesso correlati da approfondimenti, curiosità e informazioni dettagliate come didascalie di un museo.`,
						en: `Underlying the Multi project is the idea of language as a freely traversable space: a city (to borrow Wittgenstein’s famous metaphor) whose streets correspond to the paths and junctions followed by a language in its historical journey. Multi is therefore divided into six distinct visitor routes (plus an introductory atrium), each of which offers visitors a multimedia story, organised by screens, devoted to a fundamental issue or aspect in the history of the Italian language, from its origins to the present day. Visitors can immerse themselves in a narrative dimension through interactive and explorable elements that engage them, with short texts, often along with in-depth descriptions, curious facts and detailed information like labels beside artefacts in a museum.`
					},
					steps: [{}],
					numSteps: 1,
				},
				{
					id: 'collezione',
					title: { 
						it: 'La collezione',
						en: 'The collection' 
					},
					style: 'background: var(--color-gray)',
					caption: {
						it: `All’interno della sua architettura digitale, il Multi presenta materiali espositivi di varia natura (immagini, animazioni grafiche, riproduzioni digitali di documenti e manoscritti in alta qualità, estratti video, registrazioni vocali, musiche, mappe interattive, attività ludiche, articoli) che vanno a costituire i reperti del museo, ovvero gli elementi che formano la collezione museale. I reperti si trovano inseriti nella narrazione dei percorsi ma possono essere recuperati attraverso la collezione del Museo: qui, le riproduzioni di manoscritti, i filmati audiovisivi o le opere d’arte, corredati di didascalie con tutti i dati tecnici, si combinano in nuove geometrie significanti, fra accostamenti e salti temporali. Inoltre la collezione raccoglie una serie di reperti “bonus” utili agli approfondimenti individuali.`,
						en: `Multi’s digital structure contains exhibition materials of various kinds (images, motion graphics, high quality digital reproductions of documents and manuscripts, videoclips, voice recordings, music, interactive maps, playful activities, articles), which make up the museum's exhibits, i.e. the objects that form the museum’s collection. The exhibits are included in the narration of the routes but can also be accessed through the museum’s collection. Here, reproductions of manuscripts, audiovisual clips and works of art, together with labels with object information, are juxtaposed in new meaningful combinations. In addition, the collection contains a number of “bonus” exhibits that are useful for further study.
Project funded by the Special Supplementary Research Fund (FiSR 2019) of the Italian Ministry of University and Research (MUR)`
					},
					steps: [{}],
					numSteps: 1,
				},
				{
					id: 'partecipanti',
					title: { 
						it: 'Partecipanti', 
						en: 'Contributors', 
					},
					style: 'background: var(--color-white)',
					caption: {
						it: `#### Università degli Studi di Pavia


Giuseppe Antonelli: coordinatore scientifico nazionale e dell’unità di ricerca; progettazione dei contenuti

Mirko Volpi: coordinamento operativo; progettazione dei contenuti

Pietro Benzoni: progettazione dei contenuti

Giovanni Battista Boccardo: progettazione dei contenuti

Lucilla Pizzoli (Università degli Studi Internazionali di Roma): progettazione dei contenuti

Giacomo Micheletti: realizzazione dei contenuti

Stella Poli: realizzazione dei contenuti

Flavio Santi: realizzazione dei contenuti e revisione dei testi

Andrea Tullio Canobbio: ricerca iconografica, gestione dei diritti d’autore

Francesco Terzago: realizzazione delle sezioni ludico-interattive

Rodney De Souza: traduzione dei testi in lingua inglese

#### Università degli Studi di Napoli “L’Orientale”

Rita Librandi: coordinatrice scientifica dell’unità di ricerca; progettazione dei contenuti (fino al 31 ottobre 2022)

Daniele D’Aguanno: coordinatore scientifico dell’unità di ricerca; progettazione dei contenuti (dal 1 novembre 2022)

Paolo Miccoli: realizzazione dei contenuti

Maria Teresa Venturi: realizzazione dei contenuti

Chiara Longo: ricerca iconografica

Maria Savarese: ricerca iconografica, gestione dei diritti d’autore

Mario Miele: consulenza informatica

#### Università della Tuscia di Viterbo


Stefano Telve: coordinatore scientifico dell’unità di ricerca; progettazione dei contenuti

Riccardo Gualdo: progettazione dei contenuti

Roberta De Noto: realizzazione dei contenuti

Andrea Cortesi: realizzazione dei contenuti

Sara Calculli: ricerca e indicizzazione dei materiali relativi a Luca Serianni

#### Dotdotdot


Laura Dellamotta: supervisione e coordinamento generale del progetto

Giovanna Gardi: coordinamento amministrativo

Alessandro Masserdotti: supervisione del progetto digitale e dell’esperienza utente

Fabrizio Pignoloni: supervisione del progetto creativo

Nicola Buccioli: coordinamento operativo e project management

Federica Mandelli: storytelling nella fase di concept di progetto

Mirko Balducci: designer lead, progettazione esperienza utente e direzione artistica

Mariasilvia Poltronieri: progettazione esperienza utente e progettazione grafica

Francesca Mauri: progettazione esperienza utente e progettazione grafica

Daniele Ciminieri: developer lead, sviluppo backend

Davide Bonafede: sviluppo frontend

Sol Bekic: sviluppo frontend`,
						en: `#### University of Pavia

Giuseppe Antonelli: national scientific and research unit coordinator; content design

Mirko Volpi: operational coordination; content design

Pietro Benzoni: content design

Giovanni Battista Boccardo: content design

Lucilla Pizzoli (Università degli Studi Internazionali di Roma): content design

Giacomo Micheletti: content development

Stella Poli: content development

Flavio Santi: content development and text revision

Andrea Tullio Canobbio: photo research, rights & permissions

Francesc Terzago: development of interactive playful sections

Rodney De Souza: translation of texts into English



#### University of Naples “L'Orientale”

Rita Librandi: research unit coordinator; content design (until 31 October 2022)

Daniele D’Aguanno: research unit coordinator; content design (from 1 November 2022)

Paolo Miccoli: content development

Maria Teresa Venturi: content development

Chiara Longo: photo research

Maria Savarese: photo research, rights & permissions

Mario Miele: IT consultant



#### University of Tuscia (Viterbo)

Stefano Telve: research unit coordinator; content design

Riccardo Gualdo: content design

Andrea Cortesi: content development

Roberta De Noto: content development

Sara Calculli: research and indexing of materials related to Luca Serianni



#### Dotdotdot

Laura Dellamotta: general supervision and project coordination

Giovanna Gardi: administrative coordination

Alessandro Masserdotti: digital project and user experience supervision

Fabrizio Pignoloni: creative project supervision

Nicola Buccioli: operational coordination and project management

Federica Mandelli: storytelling in the project concept phase

Mirko Balducci: lead designer, user experience design and art direction

Mariasilvia Poltronieri: user experience design and graphic design

Francesca Mauri: user experience design and graphic design

Daniele Ciminieri: lead developer, backend development

Davide Bonafede: frontend development

Sol Bekic: frontend development`,
					},
					steps: [{}],
					numSteps: 1,
				},
			],
		},
		// ATRIO
		{
			id: 1,
			nameCode: 'come-nasce-lingua-italiana',
			nameArray: {
				it: ['come', 'è nata', 'la lingua', 'italiana?'],
				en: ['How Was', 'the Italian', 'Language', 'Born?']
			},
			name: {
				it: 'Come è nata la lingua italiana?',
				en: 'How Was the Italian Language Born?'
			},
			description: {
				it: 'Come tutte le lingue naturali, l’italiano non “nasce” dal nulla, ma è il frutto di una graduale, lenta evoluzione di una lingua precedente: il latino. Il passaggio dal latino a quello che diventerà l’italiano comincia nei primi secoli dell’era cristiana ed è testimoniato da alcuni preziosissimi documenti di epoca medievale risalenti all\'VIII-X secolo.',
				en: 'Like all natural languages, Italian was not “born” from nothing, but was the result of a slow, gradual evolution of an earlier language – Latin. The transition from Latin to what was to become Italian began in the first centuries of the Christian era and it is attested in some very precious documents from the Middle Ages dating back to the 8th-10th centuries.',
			},
			introImages: [
				require('@assets/images/percorsi/atrio/intro/' + utils.manageImagesExtension('img_intro_01.png')),
				require('@assets/images/percorsi/atrio/intro/' + utils.manageImagesExtension('img_intro_03.png')),
				require('@assets/images/percorsi/atrio/intro/' + utils.manageImagesExtension('img_intro_02.png')),
			],
			artifactsList: [
				{section: 's2', artifacts: [14, 15]},
				{section: 's4', artifacts: [10]},
				{section: 's5', artifacts: [12]},
				{section: 's6', artifacts: [6]},
				{section: 's7', artifacts: [11]}
			],
			sections: [
				{
					id: 's1',
					isFixed: true,
					name: 'introduzione',
					stage: {
						...stagesData.stagesData.find(el => el.id == 5)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's2',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 6)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'right',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: '##Pupa qui bela is...\n\n Qualche parola la riconoscete. *Pupa, bela*.\n Non è il latino classico di Cicerone o Virgilio. È latino volgare, tipico dei ceti più umili (il “volgo”, da cui *volgare*), ma usato nella tarda latinità anche dai colti per la comunicazione di tutti i giorni. Da questo latino derivano le lingue europee medievali, dette appunto anche volgari.',
						en: '##Pupa Qui Bela Is...\n\n A few words you recognise. *Pupa, bela* . It is not the classical Latin of Cicero or Virgil. It is Vulgar Latin, typical of the more humble classes (the \'vulgo\', hence *vulgar*), but also used in late Latinity by the educated for everyday communication. From this Latin came the medieval European languages, also known as vulgar.'
					},
					// artifact: {
					// 	id: 16,
					// 	pos: {
					// 		x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
					// 		y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
					// 	}
					// },
					remarkablePoints: [
						{
							textBefore: {
								it: "PUPA QUI BELA IS TIBI ME MISIT QUI TUUS EST VAL\[E]: “ragazza che sei bella, a te mi mandò chi è tuo: salve”. _PUPA_ per PUPILLA, _BEL\[L]A_ per PULCHRA, _IS_ per ES (‘tu sei’) sono tratti di latino volgare.",
								en: "PUPA QUI BELA IS TIBI ME MISIT QUI TUUS EST VAL[E]: “Girl you are beautiful, to you I sent who is yours: hello”. PUPA for PUPILLA, BEL[L]A for PULCHRA, IS for ES (‘you are’) are characteristic of Vulgar Latin."
							},
							posX: '35%', 
							posY: '50%', 
							caption: 'traduzione', 
							position: 'top' 
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 4)
				},
				{
					id: 's3',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 6)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-map',
					caption: {
						it: '## Dove e quando\n\n La “Romània” è l’area europea, dalle coste atlantiche al fiume Dnestr, in cui a partire dalla tarda latinità si svilupparono le lingue romanze. Questo panorama linguistico non coincide con l’area di massima diffusione del latino (che in epoca imperiale comprendeva anche la parte meridionale della Gran Bretagna e le coste dell’Africa settentrionale), e nei secoli è andato incontro a cambiamenti.',
						en: '## Where and when\n\n The term _Romània_ describes the frontiers of the Roman Empire, the area in Europe which stretched from the Atlantic coast to the Dniester river, in which the Romance languages developed from the late Latin period onwards.\n This geolinguistic area does not coincide with the area where Latin was most widespread (which in imperial times also included the southern part of Great Britain and the coasts of North Africa), and has undergone changes over the centuries.'
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: 'width: 0; pointer-events: none;'
				},
				{
					id: 's4',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 6)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: '## I due sovrani giurano\n\n Contro il fratello Lotario I (imperatore del Sacro Romano Impero), Carlo il Calvo e Ludovico il Germanico si giurano reciproca fedeltà insieme ai rispettivi eserciti.\n In particolare, il giuramento di Ludovico costituisce il primo testo scritto a noi noto che testimonia il ricorso consapevole a una lingua romanza.\n In francese.\n Siamo nel lontano 842. ',
						en: '## Two Sovereigns Swear an Oath\n\n Louis the German and Charles the Bald swear mutual allegiance, together with their respective armies, against their brother Lothar I (Emperor of the Holy Roman Empire).\n The Oath taken by Louis is the first written record known to us attesting the conscious use of a Romance language.\n The French.\n The year is 842 AD.'
					},
					template: 'left',
					artifact: {
						id: 10,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 5),
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's5',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 6)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: {
						it: '## Un antichissimo quiz\n\n “Spingeva avanti i buoi, arava un prato bianco, reggeva un aratro bianco e seminava un seme nero…” \nGià, vi trovate di fronte a un vero e proprio indovinello. E non uno qualunque: si tratta del celebre *Indovinello veronese*, una breve nota scritta in un codice spagnolo dell’VIII-IX secolo da un ignoto amanuense di Verona.',
						en: '## An Ancient Riddle\n\n “He pushed forward oxen, a white meadow he ploughed, a white plough he held and a black seed he sowed...”\n That’s right, this is a genuine riddle. And not just any riddle – it is the famous Veronese Riddle, a short note written in an 8th-9th century Spanish codex by an unknown Veronese scribe.'
					},
					template: 'left',
					artifact: {
						id: 12,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 6),
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's6',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 6)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-cement',
					caption: {
						it: '## «Fui eo, madre, in civitate, vidi onesti iovene»\n\n Nel 2022 Vittorio Formentin e Antonio Ciaralli scoprono un’antichissima traccia di poesia popolare in volgare italiano settentrionale: il frammento di Würzburg (dalla città tedesca dove è conservato il codice che lo tramanda).',
						en: '## «Fui eo, madre, in civitate, vidi onesti iovene»\n\n In 2022, scholars Vittorio Formentin and Antonio Ciaralli discovered a very old piece of popular poetry in northern Italian vernacular known as the Fragment of Würzburg (from the German city where the manuscript is preserved). '
					},
					template: 'left',
					artifact: {
						id: 6,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 7),
					remarkablePoints: [
						{
							textBefore: {
								it: 'Sono andata, madre, in città, [dove] vidi dei giovani onesti',
								en: 'I went, mother, to the city, [where] I saw honest young men',
							}, 
							posX: '35%', 
							posY: '40%', 
							caption: 'traduzione', 
							position: 'bottom'
						}
					],
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's7',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 6)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## Tra i due litiganti...\n\n … si comincia a scrivere in volgare! Proprio così: da una disputa avvenuta nel 960 a Capua tra un ricco possidente e il monastero di Montecassino, abbiamo l’“atto di nascita” della lingua italiana.\n È il Placito, cioè ‘sentenza’ (dal latino _placitum_ ‘decisione, sentenza’), di Capua.",
						en: "## One Begins to Write in “Italian Vernacular”…\n\n ... as two disputants argue! That's right. We have the “birth certificate” of the Italian language from a dispute that took place in 960 in Capua between a wealthy landowner and the Monastery of Montecassino.\n The document is known as the _Placito_ – i.e. sentence (from the Latin _placitum_, ‘decision, sentence’) – of Capua."
					},
					// curiosity: {
					// 	it: 'Il Placito di Capua (o Placito capuano) è il primo di una serie di contese quasi identiche, con formule molto simili.\n\n La “modularità” della vicenda (un privato, senza prove, rivendica delle terre che, grazie alle testimonianze di alcuni monaci, vengono poi legalmente riconosciute ai monasteri benedettini) ha fatto sorgere dubbi sulla veridicità dei fatti riportati: quasi fossero “messinscene” cui i cittadini consenzienti si prestavano, in contese che fruttavano grandi annessioni ai monasteri della zona.',
					// 	en: 'The Placito di Capua (or Placito capuano) is the first in a series of almost identical contentions, with very similar formulas.\ The “modularity” of the affair (a private individual, without evidence, claims lands that, thanks to the testimony of some monks, are then legally recognised as belonging to the Benedictine monasteries) has raised doubts as to the veracity of the facts reported: almost as if they were “shams” to which consenting citizens lent themselves, in contentions that yielded large annexations to the monasteries in the area.'
					// },
					template: 'left',
					artifact: {
						id: 11,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 8),
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's8',
					name: 'bibliography',
					stage: {
						...stagesData.stagesData.find(el => el.id == 7)
					},
					steps: [
						{}
					],
					numSteps: 1,
					sectionStyle: 'bg-extra-light-gold',
					caption: {
						it: 'L\'italiano circola con la scrittura epistolare, un importante mezzo di comunicazione utile a rispondere a esigenze pratiche. Scrittura di getto, schiettezza e spontaneità sono tra le caratteristiche della scrittura delle lettere, che accolgono, perciò, anche diversi tratti linguistici tipici della lingua parlata.',
						en: 'Italian circulates with epistolary writing, an important means of communication serving practical needs. Spur-of-the-moment writing, directness and spontaneity are among the characteristics of letter-writing, which therefore also includes various linguistic traits typical of the spoken language.'
					},
					template: 'left',
				}
			]
		},
		// ITALIANO SCRITTO
		{
			id: 2,
			nameCode: 'come-circolato-italiano-scritto',
			nameArray: {
				it: ['come è', 'circolato', 'l\'italiano', 'scritto?'],
				en: ['How Did', 'Written', 'Italian', 'Circulate?']
			},
			name: {
				it: "Come è circolato l'italiano scritto?",
				en: 'How Did Written Italian Circulate?'
			},
			description: {
				it: 'Il viaggio dell’italiano scritto è degno di uno strepitoso romanzo d’avventura. All’inizio circola quasi segretamente sui muri, tra i margini di oscuri manoscritti, poi esce allo scoperto su libri, lettere, fogli di giornale, per arrivare infine sugli schermi di un computer o di uno smartphone, proprio come quelli da cui state leggendo ora. Ma qualcosa ci dice che il viaggio non finisce qui…',
				en: 'It first began to circulate almost secretly on walls, in the margins of obscure manuscripts, eventually coming out into the open in books, letters and on newspapers, and finally appearing on computer screens and smartphones, just like the ones you are reading from now.\n But something tells us that the journey is not over yet…',
			},
			introImages: [
				require('@assets/images/percorsi/italiano_scritto/intro/' + utils.manageImagesExtension('Olivetti.png')),
				require('@assets/images/percorsi/italiano_scritto/intro/' + utils.manageImagesExtension('Jean_Mielot.png')),
			],
			artifactsList: [
				{section: 's2', artifacts: [16]},
				{section: 's5', artifacts: [16]},
				{section: 's6', artifacts: [16]},
				{section: 's13', artifacts: [16]},
			],
			sections: [
				{
					id: 's1',
					name: 'introduzione',
					isFixed: true,
					stage: {
					...stagesData.stagesData.find(el => el.id == 8)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's2',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					caption: {
						it: "Nel corso dei secoli la **scrittura** e la **lingua** sono state coinvolte nell’**evoluzione tecnologica**.\n Se le prime testimonianze linguistiche sono apparse su muri, dipinti e documenti manoscritti, con l’invenzione della stampa, prima, e del digitale, poi, sono cambiati i supporti attraverso cui la lingua ha avuto modo di circolare.",
						en: "Over the centuries, writing and language have changed as technology has evolved.\n While the first written linguistic evidence appeared on walls, paintings and manuscripts, with the invention of printing, initially, and later of digital, the media through which language has been able to circulate have changed."
					},
					remarkablePoints: [
						{
							id: 'T1_S1_2', 
							textBefore: {
								it: "Una pratica molto comune in passato – proprio come oggi – era quella di affiggere manifesti, avvisi o cartelli sui muri degli spazi pubblici. Si trattava di scritture spesso illecite realizzate anche da incolti, come nel caso del cartello infamante qui proposto. Caratterizzato da un linguaggio molto colorito (_corne_, _bec[c]o fot[t]uto_), questo cartello era indirizzato al barbiere romano Giovanni Battista Fabrino, che lo aveva trovato fuori dalla sua bottega il 28 luglio del 1666. Eccone l’incipit: «Martino bec[c]o, sac[c]o de corna, lasseme sta’, perc[h]é te vo[g]lio mana’ in galera!».", 
								en: "A very common practice in the past – but also today – was to put up posters, notices or signs on public walls. These were often unlawful, and sometimes written by uneducated people, as in the case of the slanderous notice shown here. Characterised by very colourful language (_motherfucker_, _fuckin’ motherfucker_), this notice was aimed at the Roman barber, Giovanni Battista Fabrino, who found it outside his shop on 28 July 1666. Here are the opening words: «Martino bec\[c]o, sac\[c]o de corna, lasseme sta’, perc\[h]é io te vo\[g]lio mana’ in galera!» \[Martino motherfucker, fuckin’ motherfucker, let’s forget it, because I want to see you hang in jail]." 
							},
							caption: "descrizione", 
							posX: "65%", 
							posY: "60%", 
							position: "left", 
							customLabelStyle: "width: 20rem;", 
							wrap: true, 
							posXMobile: '15rem', 
							posYMobile: '17rem',
							customLabelStyle: 'width: 25rem;'
						}
					],
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: ''
				},
				{
					id: 's3',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					caption: '',
					template: 'left',
					caption: {
						it: '## Non si scrive sui muri! (O forse sì?)\n\n Le prime tracce dell’italiano, o meglio del volgare, si trovano in iscrizioni murarie che offrono informazioni sulla vita quotidiana e sugli usi linguistici del tempo. Il caso più famoso è quello del __graffito della catacomba di Commodilla a Roma (inizio del IX secolo)__.\n Del resto, la pratica di scrivere sui muri sopravvive ancora oggi.',
						en: '## Don\'t Write on Walls! (or Maybe Do?)\n\n The earliest traces of Italian, or rather of the vernacular, are to be found on wall inscriptions that provide information about daily life and linguistic practices of the time.\n The most famous case is the **graffito in the catacomb of Commodilla in Rome (early 9th century)**.\n Indeed, the practice of writing on walls still survives today.'
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T1_S2_1", 
							textBefore: {
								it: 'Il graffito si trova nel margine di un affresco della cappella dei due martiri e santi Felice e Adàutto, luogo utilizzato per il culto fino al IX secolo.\n Il suo significato è “non dire quei/i segreti a voce”. Secondo recenti studi, si ipotizza che l’autore del graffito possa essere un fedele. Nel dialogo fittizio creato a distanza di secoli con i personaggi dipinti nell’affresco, lo scrivente rimprovera il martire Adàutto per aver autodenunciato la sua fede cristiana una volta scoperta la condanna di Felice andando contro le pratiche di segretezza del culto cristiano e trovando, perciò, anche lui la morte.', 
								en: 'This graffito is located on the left-hand side of a fresco in the chapel of the two martyrs and saints, Felix and Adauctus, a place of worship until the 9th century.\n Its meaning is “Do not speak these/the secrets aloud”. Recent studies have suggested that the author of the graffito may have been a worshipper. In the fictitious dialogue created centuries later with the characters depicted in the fresco, the writer reproaches the martyr Adauctus for having confessed his Christian faith once he had found out about the condemnation of Felix, and, as a result, violating the practice of secrecy of Christian worship and therefore also meeting his own death.', 
							},
							posX: '60%', 
							posY: '50%',
							caption: 'trascrizione', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
						{
							id: "T1_S2_2", 
							textBefore: {
								it: 'non dire le cose segrete a voce', 
								en: 'Do not speak the secrets aloud', 
							},
							posX: '35%', 
							posY: '50%',
							caption: 'trascrizione', 
							position: 'bottom',
							customLabelStyle: 'width: 15rem;'
						}
					],
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's4',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					caption: {
						it: '## Il primo “fumetto” in volgare\n\n Ma la lingua circola anche negli affreschi, dialogando direttamente con le immagini, proprio come nei nostri moderni fumetti! Il primo esempio è l’iscrizione della __Basilica di san Clemente a Roma (fine XI sec.)__, in cui il modo di parlare dei personaggi rappresenta il loro valore morale. Ecco allora il santo esprimersi in latino, mentre Sisinnio e i servi usano un volgare rozzo e plebeo.',
						en: '## The First “Comic” in the Vernacular\n\n But language also circulated in frescoes, dialoguing directly with the images, just like in our modern comics!\n The first example is an inscription found in the **Basilica of Saint Clement in Rome (late 11th century)**, where the language used by the characters reflects their moral standing.\n Here Saint Clement speaks in Latin, while Sisinnius and his workmen use a crude, plebeian vernacular.'
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T1_S3_1", 
							textBefore: { 
								it: "L’affresco è una rappresentazione della leggenda relativa alla cattura di San Clemente, accusato dal pagano Sisinnio di aver cercato di convertire sua moglie al cristianesimo. Sisinnio ne ordina il rapimento. Durante la cattura, però, si compie un miracolo: i servi fedeli al pagano, incaricati di trasportare il santo, si trovano a trascinare una colonna!", 
								en: "The fresco depicts the legend concerning the capture of Saint Clement, who was accused by the pagan Sisinnius of trying to convert his wife to Christianity. Sisinnius orders his abduction. During the capture, however, a miracle occurs: the pagan's faithful workmen, charged with transporting the saint, find themselves dragging a column!"
							},
							posX: '70%', 
							posY: '50%',
							caption: 'didascalia', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 9),
					curiosity: {
						it: 'Sì, avete letto bene: in un affresco di una chiesa compare una parolaccia. Chi ha scritto ne era ben consapevole e lo ha fatto a fini espressivi: ha voluto rimarcare lo stacco netto tra la lingua nobile del santo e il registro basso di Sisinnio, che arriva fino al turpiloquio. ',
						en: 'Yes, you read that right: an obscenity appears in a church fresco: *fili de le pute*.\n Whoever wrote it was well aware of this and did so to be expressive: he wanted to distinguish clearly between the noble language of the saint and the low register of Sisinnius, who even uses an expletive.\n The language used therefore corresponds to a moral judgement.'
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's5',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'left',
					caption: {
						it: '## Tutta questione di praticità\n\n Fino all’invenzione della stampa, la lingua circola soprattutto nei manoscritti.\n Ma le materie prime sono costose, così si sfrutta ogni spazio bianco sulla carta.\n Negli atti ufficiali riempire gli spazi ha un fine pratico: evitare contraffazioni.',
						en: '## All a Matter of Practicality\n\n Until the invention of printing, language circulated mainly in manuscripts.\n But raw materials were expensive, so every blank space on the paper was exploited.\n Filling in the spaces in official documents had the practical purpose of preventing forgeries.'
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's6',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					caption: {
						it: '## Guarda chi si... legge\n\n Nei margini dei **_Memoriali bolognesi_** (1279-1325), oltre ad appunti vari, vengono scritti persino versi di poeti del tempo. Anche di Dante!',
						en: '## Reading… in the Margins…\n\n In the margins of the *Memoriali Bolognesi* (1279-1325), in addition to various notes, we often find verses by poets of the time were also written. Dante was also one of them!'
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 10),
					remarkablePoints: [
						{
							id: "T1_S5_1", 
							textBefore: {
								it: 'El duca lui Caron no_n_ ti crucciare\n vuolsi cosi cola dove si puote\n cio che si vuole _et_ piu no_n_ dima_n_dare<br/>\nIn passato era molto comune adoperare espedienti grafici per abbreviare alcune parole. Qui ad esempio sono riportate in corsivo le_n_di no_n_e di dima_n_dare, assenti nel manoscritto ma indicate da un segno grafico sopra le parole (detto _titulus_), e la congiunzione _et_ perché è lo scioglimento della cosiddetta nota tironiana (il simbolo simile a un 7).',
								en: 'El duca lui Caron no_n_ ti crucciare\n vuolsi cosi cola dove si puote\n cio che si vuole _et_ piu no_n_ dima_n_dare\n \[And my leader: “Charon, do not torment yourself.\n It is so willed where will and power are one,\n and ask no more”]\n\n In the past, it was very common to use graphic expedients to abbreviate certain words. Here, e.g., the _n_ of no_n_ and dima_n_dare is written in italic, but in the manuscript it is indicated by a graphic sign above the words (called _titulus_) and the conjunction _et_ is the dissolving of the so-called \'Tyronian note\''
							},
							posX: '85%', 
							posY: '60%', 
							caption: 'trascrizione', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						}
					],
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's7',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-gold-animated',
					caption: '',
					template: 'left',
					caption: {
						it: "## Una rivoluzione a caratteri mobili\n\n L'invenzione della **stampa** rivoluziona totalmente la modalità di trasmissione dei testi e della lingua.\n La possibilità di raggiungere un **pubblico** molto **più ampio** rende infatti necessario comunicare in una **lingua** il più possibile **condivisa**.\n La stampa dà quindi un grande impulso alla ricerca di una lingua scritta unitaria e ha un ruolo fondamentale per la diffusione delle idee di un protagonista di questo tempo, Pietro Bembo.",
						en: "## The Movable Type Revolution\n\n The invention of printing completely revolutionised the way texts and language were transmitted.\n The possibility of reaching a much wider audience made it necessary to communicate in a language that was as common as possible.\n Printing thus provided great impetus to the search for a unified written language and played a fundamental role in the dissemination of ideas of a protagonist of this time: Pietro Bembo."
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T1_S6_1", 
							textBefore: {
								it:"Le prime opere stampate in Italia sono le edizioni di due autori latini, Cicerone e Lattanzio, apparse nel 1465 a Subiaco, vicino a Roma.\n Di pochi anni dopo è la prima opera stampata in volgare: i _Fioretti di San Francesco_ (Roma, 1469).\n La capitale editoriale italiana sarà però Venezia. Capolavoro dell’editoria veneziana del tempo è l’edizione dell’_Hypnerotomachia Poliphili_, una curiosa opera scritta in una lingua mista tra latino e volgare, stampata da Aldo Manuzio nel 1499 e corredata da splendide xilografie.", 
								en:"The first works printed in Italy were editions of two Latin authors, Cicero and Lactantius, which appeared in 1465 in Subiaco, near Rome.\n The first work printed in the vernacular, the _Fioretti di San Francesco_ \[_The Little Flowers of St. Francis of Assisi_] (Rome, 1469) appeared a few years later.\n However, it was Venice that would become the Italian publishing capital. A masterpiece of Venetian publishing of the time is the edition of the _Hypnerotomachia Poliphili_ \[Poliphilo's Strife of Love in a Dream], a curious work written in a mix of Latin and the vernacular, printed by Aldo Manuzio in 1499 and accompanied by splendid woodcuts."
							},
							posX: '70%', 
							posY: '40%',
							caption: 'didascalia', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
					],
					curiosity: {
						it: 'La stampa a caratteri mobili viene inventata a metà del Quattrocento.\n\n I libri stampati in questo secolo prendono il nome di **incunaboli**, dal latino _cuna_ ‘culla’, denominazione che fa riferimento alla recente nascita della stampa.\n\n Le edizioni del secolo successivo si indicano invece con il nome di **cinquecentine**.',
						en: 'Movable type printing was invented in the mid-15th century.\n Books printed in this century are called **incunabula** from the Latin _cuna_ ‘cradle’, a term referring to the recent birth of printing.\n In contrast, the editions of the following century are referred to as **_Cinquecentine_** \[of sixteenth century].'
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's8',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: '',
					template: 'left',
					caption: {
						it: "## Un nuovo strumento\n\n Un’altra invenzione nella seconda metà dell’800 consente di scrivere molto rapidamente, andando via via a sostituire la scrittura a mano.\n È uno strumento formato da una tastiera collegata a vari dispositivi meccanici.\n È la **macchina da scrivere**.",
						en: "## A new Invention\n\n Another invention, this time from the second half of the 19th century, made it possible to write very quickly, gradually replacing handwriting.\n The invention consisted of a keyboard connected to various machines.\n It was the **typewriter**."
					},
					remarkablePoints: [
						{
							id: "T1_S7_1", 
							textBefore: {
								it: "La prima fabbrica italiana di macchine da scrivere è la Olivetti, fondata nel 1908 a Ivrea dal grande imprenditore Camillo Olivetti.\n La prima macchina da scrivere Olivetti in grado di competere sul mercato mondiale con produttori americani e tedeschi, che avevano avuto sino ad allora un primato, è la M1.\n Nel 1912, grazie a questa macchina da scrivere, la Olivetti diventa la fornitrice del Ministero della Regia Marina. Il prestigio acquisito le garantisce altri accordi con la pubblica amministrazione e numerose imprese.", 
								en: "The first Italian typewriter manufacturer was Olivetti, which was founded in Ivrea in 1908 by the great entrepreneur, Camillo Olivetti.\n The M1 was the first Olivetti typewriter capable of competing on the world market with American and German manufacturers, who had until then dominated the market.\n In 1912, thanks to this typewriter, Olivetti became supplier to the Italian Ministry of the Royal Navy. The prestige acquired led to other contracts with the public administration and numerous companies."
							},
							posX: '50%', 
							posY: '20%',
							caption: 'didascalia', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
					],
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's9',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-black',
					caption: '',
					template: 'left',
					caption: {
						it: "## Pro e contro\n\n Alla fine del ’900 il computer ha cambiato il modo di scrivere.\n Nessun costo della carta, nessun limite di spazio: un testo al computer potrebbe essere infinito. Si può scrivere una parola, cancellarla, cambiarle dimensione senza problemi.\n Ma ci sono anche i contro: i testi in rete sono spesso più semplici, e (purtroppo) troppe volte pieni di errori (nonostante i correttori automatici!).",
						en: "## Pros and cons\n\n At the end of the 20th century, the computer transformed the way we write.\n No need to buy paper, no restrictions of space: a computer text could be infinite. You could write a word, delete it and change its size to your heart’s content.\n But there are also cons: web texts are often simpler, and (unfortunately) all too often full of errors (despite the auto correct tool!)."
					},
					curiosity: {
						it: "Quello che noi oggi conosciamo come corsivo e troviamo anche su Word ha un’origine piuttosto antica.\n Sì, perché è stato inventato nel Cinquecento da Aldo Manuzio. Pare che sia ispirato alla scrittura cancelleresca (chiamata così perché usata nelle cancellerie) di tipo corsivo, l’_italica_, di Bartolomeo Sanvito.\n Il Corsivo (o Aldino) di Manuzio sarà chiamato _Italic_ dagli inglesi e _Italique_ dai francesi.",
						en: "What we now know as Italic and also find in Microsoft Word has a rather ancient origin.\n It was invented in the 16th century by Aldo Manuzio. Apparently, it was inspired by Bartolomeo Sanvito's chancery script _italica_ (so called because it was used in chancelleries).\n Manutius's Italic type (or Aldine) would be called _Italic_ in English and _Italique_ in French."
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's9-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 9)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's10',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 1)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: 'L\'italiano circola con la scrittura epistolare, un importante mezzo di comunicazione utile a rispondere a esigenze pratiche. Scrittura di getto, schiettezza e spontaneità sono tra le caratteristiche della scrittura delle lettere, che accolgono, perciò, anche diversi tratti linguistici tipici della lingua parlata.',
						en: 'Italian circulated through letter-writing, an important means of communication that suited practical needs.\n Spur-of-the-moment writing, frankness and spontaneity are among the characteristics of letter-writing, which therefore also includes various linguistic features typical of spoken language.'
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
					remarkablePoints: [
						{id: "T2_S1_1", textBefore: 'ch\'el', textAfter: 'ch\'l', posX: '50%', posY: '50%'}
					],
				},
				{
					id: 's11',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 1)
					},
					steps: [{}, {}],
					numSteps: 2,
					inDepthItem: inDepthItems.items.find(el => el.id == 1),
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: '## Caro mercante, ti scrivo\n\n Fra il XIII e il XIV secolo le potenzialità delle lettere sono sfruttate soprattutto dai mercanti per comunicare notizie sui prodotti, sulle guerre o sui giochi di potere, pettegolezzi e anche per inviarsi oggetti.  Così, iniziano a essere scritte ogni giorno lettere in volgare legate a un uso pratico e quotidiano.',
						en: '## Dear Merchant, I\'m Writing to You to…\n\n Between the 13th and 14th centuries, the potential of letter-writing was exploited mainly by merchants to communicate news about goods, wars or power games, gossip and even to send each other objects.\n Thus, many letters related to practical, everyday use began to be written.'
					},
					curiosity: {
						it: 'Ad oggi possediamo migliaia di lettere “mercantili”, molte delle quali scritte e ricevute dal mercante di Prato __Francesco Datini__ (1335ca.-1410).',
						en: 'Today, we possess thousands of \'mercantile\' letters, many of them written and received by **Francesco Datini** (approx. 1335-1410), a merchant from Prato. '
					},
					template: 'left',
					buttonPosition: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T2_S2_1", 
							textBefore: {
								it: "Si tratta di una ricordanza inviata da Giuliano di Giovanni Vernardo di Adoardo Portinari al mercante Francesco Datini nel 1394. La sua particolarità è la presenza del campione di stoffa spedito come “allegato”.", 
								en: "This is an account book sent by Giuliano di Giovanni Vernardo di Adoardo Portinari to the merchant Francesco Datini in 1394. What makes it original is the presence of a cloth sample sent as an “attachment”.", 
							},
							posX: '70%', 
							posY: '30%',
							caption: 'didascalia', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
					],
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's12',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 1)
					},
					steps: [{}],
					numSteps: 1,
					// inDepthItem: inDepthItems.items.find(el => el.id == 2),
					sectionStyle: 'bg-black',
					caption: {
						it: `## Grosse novità,<br> messer Pietro\nNel Cinquecento nascono le prime __raccolte di lettere in volgare__ concepite come prodotto letterario.\nQuindi, oltre a scrivere missive private, l’autore può decidere se e come pubblicarle e soprattutto in quale lingua (“spontanea”? letteraria?). Il merito dell’invenzione del “libro di lettere” in volgare va a __Pietro Aretino__ (1492-1556), che pubblica il primo volume nel 1538.`,
						en: '## Great News, Messer Pietro\n\n The 16th century saw the emergence of the first collections of letters in the vernacular conceived as a literary product.\n Thus, in addition to writing private letters, the author could decide whether and how to publish them and, above all, in what kind of language (“spontaneous”? literary?).\n The credit for the invention of the vernacular “book of letters” goes to **Pietro Aretino** (1492-1556), who published his first volume in 1538.'
					},
					template: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T2_S3_1", 
							textBefore: {
								it: "Chiamato «flagello dei principi» dal suo contemporaneo Ludovico Ariosto in un passo dell’_Orlando furioso_ (XLVI, 14, 3-4), Pietro Aretino è piuttosto famoso per i suoi _Sonetti lussuriosi_ (1526) e per la sua produzione dialogica e teatrale. Ma ha scritto anche numerosissime lettere pubblicate in sei volumi fra il 1538 e il 1557.", 
								en: "Called the «scourge of princes» by his contemporary Ludovico Ariosto in a passage from _Orlando furioso_ (XLVI, 14, 3-4), Pietro Aretino is famous for his _Sonetti lussuriosi_ \[_Lust_ _Sonnets_] (1526) as well as his dialogues and plays. But Aretino also wrote numerous letters published in six volumes between 1538 and 1557.", 
							},
							posX: '70%', 
							posY: '30%',
							caption: 'didascalia', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
					],
				},
				{
					id: 's13',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 1)
					},
					steps: [{}, {}],
					numSteps: 2,
					inDepthItem: inDepthItems.items.find(el => el.id == 2),
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: '## Commoditade? Meglio comodità!\nQuando Aretino decide di trasformare il suo carteggio in un vero e proprio libro, la struttura, i contenuti e la lingua delle lettere subiscono un cambiamento secondo un processo di **revisione** normale per l’epoca. Ad esempio, _commoditade_ diventa _comodità_, _benefitio_ si trasforma in _beneficio_ e si inserisce l’apostrofo in _laltezza_.',
						en: '## _Commoditade_? Better _Comodità_!\n\n When Aretino decided to turn his correspondence into a proper book, the structure, content and language of the letters underwent a **revision** process that was normal for the time.\n For example, _commoditade_ became _comodità_ \[comfort], _benefitio_ became _beneficio_ \[benefit] and the apostrophe was inserted in _laltezza_ (_l’altezza_, i.e. the height).'
					},
					curiosity: {
						it: 'Nel Cinquecento, prima di pubblicare un libro, era comune affidare il testo alle mani esperte di figure simili ai nostri __“redattori editoriali”__, che avevano il compito di adeguare la lingua alla norma linguistica di Pietro Bembo. Ad esempio, sui sei volumi dell’epistolario aretiniano (1538-1557) lavorano alcuni revisori, come Niccolò Franco e Lodovico Domenichi, e lo stesso autore è sempre molto presente in tipografia.',
						en: 'In the 16th century, before publishing a book, it was common to entrust the text to the expert hands of figures similar to our “**editors**”, whose task was to adapt the language to Pietro Bembo\'s linguistic standard.\n For example, proofreaders such as Niccolò Franco and Lodovico Domenichi worked on the six volumes of Aretino’s letters (1538-1557), while the author himself was always very present at the printing press.'
					},
					remarkablePoints: [
						{
							id: "t2_S4_1",
							textBefore: {
								it: "Questa breve lettera indirizzata al Duca di Firenze, vale a dire Cosimo I de’ Medici, è stata scritta da Aretino nel 1545 per ringraziarlo dei doni e del denaro ricevuti. I cambiamenti tra la versione manoscritta e quella a stampa riguardano tanto gli aspetti strutturali quanto quelli linguistici.",
								en: "This short letter addressed to the Duke of Florence, namely Cosimo I de' Medici, was written by Aretino in 1545 to thank him for gifts and money he was sent. The changes between the manuscript and printed version are both structural and linguistic."
							}
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					}
				},
				{
					id: 's14',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 1)
					},
					steps: [
						{}
					],
					numSteps: 1,
					// inDepthItem: inDepthItems.items.find(el => el.id == 3),
					sectionStyle: 'bg-red',
					caption: {
						it: "## Firmato La Befana\n\n Nell’800, con l’aumento dell’alfabetizzazione e il minor costo della carta, le lettere cambiano e hanno sempre più **tratti del parlato**, come **esclamativi** (_Ah!_), **forme dialettali** (_scarafone_) o **espressioni colorite** (_minchione_).\n Ne è un esempio la curiosa lettera che **Giacomo Leopardi** scrive nel 1810, a dodici anni, firmandosi, per scherzo, _La Befana_ e usando parole come _piscia_ e _merda_.",
						en: "## Signed _La Befana_\n\n In the 19th century, as literacy improved and the cost of paper fell, letters changed and had more and more features of speech, such as **exclamations** (_Ah!_), **dialectal forms** (_scarafone_ \[cockroach]) or **colourful expressions** (_minchione_ \[prick]). One example is the curious letter that Giacomo Leopardi wrote in 1810, at the age of twelve, jokingly signing himself _La Befana_ \[a kindly Christmas witch] and using words like _piscia_ \[piss] and _merda_ \[shit]."
					},
					template: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T2_S5_1",
							caption: 'trascrizione',
							textBefore: {
								it: 'È una delle prime missive leopardiane in cui traspare un linguaggio un po’ più colorito. Se in questa epistola del 1810 è, però, ravvisabile un chiaro intento scherzoso, meno giocose sono le lettere scambiate con i suoi fratelli o con altri corrispondenti, all’interno delle quali si trovano forme poco “educate” in linea con l’esigenza di poter «parlar liberamente» (di qualsiasi cosa e in tutti i modi possibili).', 
								en: 'It is one of the first of Leopardi\'s letters to contain more colourful language. While this letter of 1810 was clearly intended to be funny, the letters he exchanged with his brothers or with other correspondents are much less so. Here we can find some quite “impolite” forms which reflect the need to be able to “speak freely” (about anything and in any way possible).', 
							},
							posX: '80%', 
							posY: '35%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
				},
				{
					id: 's14-2',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 1)
					},
					steps: [{}, {}],
					numSteps: 2,
					// inDepthItem: inDepthItems.items.find(el => el.id == 3),
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Mandami una mail\n\n Le lettere continueranno a essere scritte e a circolare per tutto il ’900.\n E oggi?\n Oggi quasi più nessuno scrive una lettera. O meglio, se ne scrivono milioni ogni giorno, ma sotto forma del loro corrispondente digitale, la e-mail (alla lettera ‘posta elettronica’).",
						en: "## Send Me an E-mail\n\n Letters continued to be written and circulate throughout the 20th century.\n And today?\n Today, hardly anyone writes letters anymore. Or rather, they write millions of them every day, but in the form of their digital counterpart, the e-mail (literally ‘electronic mail’)."
					},
					template: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					curiosity: {
						it: "Un e-mail o un’e-mail? La distinzione sembra sottile, ma riguarda il genere del sostantivo. E-mail è maschile o femminile? E-mail è usato soprattutto come sostantivo femminile invariabile, perché è sentito come il corrispettivo italiano dell'inglese _mail_, 'posta' e 'corrispondenza', sostantivi appunto femminili.",
						en: "_Un e-mail_, or _un’e-mail_? The distinction may seem subtle, but it concerns the gender of the noun in Italian. Is e-mail masculine or feminine? E-mail is mostly used as invariable feminine noun, because the Italian equivalent of the English mail is “_posta_” and “_corrispondenza_”, both of which are feminine nouns."
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
				},
				{
					id: 's15',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 10)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: 'Tra i tanti punti di forza dell’italiano, c’è quello di saper approfondire interessi intellettuali nei vari campi del sapere e delle scienze. Il frutto di queste riflessioni ha prodotto i trattati. È con la trattatistica che l’italiano contribuisce a diffondere un lessico specifico e scientifico.\n Tra i dotti ma non solo.',
						en: 'One of the many strengths of the Italian language is its ability to investigate subjects in the various fields of knowledge and science.\n This has led to the production of treatises.\n It was through these treatises that the Italian language helped to spread specialist, scientific terminology, not only among the educated.'
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's16',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 10)
					},
					steps: [
						{}
					],
					numSteps: 1,
					// inDepthItem: inDepthItems.items.find(el => el.id == 3),
					sectionStyle: 'bg-red',
					caption: {
						it: '## Una lingua economica\n La lingua dell’economia inizia a circolare sin dal ’200 con i libri dei conti.\n Vengono introdotte parole che usiamo ancora oggi come _banca_ e _cambio_.\n Molto importante è la ***_Summa_*** **del matematico Luca Pacioli** stampata nel **1494** che pone le basi della moderna ragioneria e che accompagna le spiegazioni con tavole e disegni.',
						en: "## An “Economic” Language\n\n The language of economics began to circulate as early as the 13th century with account books.\n Words that we still use today such as _banca_ \[bank] and _cambio_ \[exchange] were introduced.\n Importantly, the **_Summa_** **by the mathematician Luca Pacioli**, which was printed in **1494**, laid the foundations of modern accountancy. His explanations were accompanied by tables and drawings."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T3_S2_1",
							caption: 'trascrizione',
							textBefore: {
								it: 'Questa pagina, estratta dalla _Summa de Arithmetica Geometria_\n\n _et Proportionalità_, illustra il sistema dell’indigitazione, che consentiva\n\n di contare e di indicare, con una sola mano, numeri molto alti.', 
								en: 'This page, taken from _the Summa de Arithmetica Geometria et Proportionalità_, illustrates the “indigitation” system, which allowed very large numbers to be counted and represented on one hand.', 
							},
							posX: '70%', 
							posY: '35%',
							position: 'top',
							customLabelStyle: 'width: 25rem;'
						}
					]
				},
				{
					id: 's17',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 10)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					// inDepthItem: inDepthItems.items.find(el => el.id == 3),
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "## Una lingua “artistica”\n\n Dal ’400 il volgare viene usato come lingua tecnica delle arti al posto del latino.\n Nel 1492 Francesco di Giorgio Martini traduce in volgare l’_Architettura_ di Vitruvio. I tecnicismi dell’opera latina sono tradotti con il corrispettivo volgare di più facile comprensione anche ai non addetti ai lavori. Ecco allora parole come _architrave_, _colonna_, _fregio_, ben presto diffuse anche all’estero.",
						en: "## An “Artistic” Language\n\n From the 15th century, “Italian vernacular”, rather than Latin, was used as the technical language of the arts.\n In 1492, Francesco di Giorgio Martini translated Vitruvius’ _Architettura_ into the vernacular. The technical expressions of the Latin work were translated into the corresponding vernacular, which was easier to understand even for the uninitiated. Thus, words such as _architrave_, _column_ and _frieze_ soon spread abroad."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					curiosity: {
						it: "Tra Quattro e Cinquecento, artisti come Leon Battista Alberti, Leonardo da Vinci, Sebastiano Serlio scrivono i loro trattati sull’arte in italiano contribuendo alla circolazione all’estero di moltissimi tecnicismi, come _facciata_, che dà _façade_ (ingl.), _fachada_ (spa.), _façade_ (fr.), _Fassade_ (ted.), o _stucco_, che dà _estuco_ (spa.), _stuc_ (fr.), _Stuck_ (ted.).",
						en: "Between the 15th and 16th centuries, artists such as Leon Battista Alberti, Leonardo da Vinci and Sebastiano Serlio wrote their treatises on art in Italian, contributing to the circulation abroad of many technical terms, such as _facciata_, which gives _façade_ (En.), _fachada_ (Sp.), _façade_ (Fr.), _Fassade_ (Ger.), or _stucco_, which gives _estuco_ (Sp.), _stuc_ (Fr.), _Stuck_ (Ger.)."
					},
					remarkablePoints: [
						{
							id: "T3_S3_1",
							caption: 'descrizione',
							textBefore: {
								it: 'Oltre a essere un pittore di spicco dell’epoca, Martini scrive anche il _Trattato di architettura civile e militare_, che ha una versione manoscritta arricchita da una traduzione di Vitruvio. Sceglie di affiancare ai tecnicismi latini le traduzioni in volgare, spesso introdotte da alcune glosse, accogliendo da un lato una lingua ormai universale nel settore e aprendosi dall’altro alla lingua delle botteghe.', 
								en: 'Francesco di Giorgio Martini was a Sienese artist who lived in the 15th century. In addition to being a prominent painter of the time, he also wrote the _Trattato di Architettura Civile e Militare_ \[Treatise on Civil and Military Architecture], which has a manuscript version supplemented with by a translation of Vitruvius. Martini chose to include vernacular translations for Latin technical terms, often introduced by a few glosses, on the one hand, accepting the fact that there was by then a universal language in the field and, on the other, taking on board the language of the _botteghe_ \[workshops].'
							},
							posX: '70%', 
							posY: '35%',
							position: 'bottom',
							customLabelStyle: "width: 20rem;"
						}
					]
				},
				{
					id: 's18',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 10)
					},
					steps: [{}, {}],
					numSteps: 2,
					// inDepthItem: inDepthItems.items.find(el => el.id == 3),
					sectionStyle: 'bg-black',
					caption: {
						it: "## La rivoluzione di Galileo\n\n La comunicazione scientifica cambia radicalmente con **Galileo Galilei** (1564-1642).\n Galileo abbandona il latino e usa un italiano più semplice e divulgativo, con parole comuni in accezione tecnica. L’obiettivo è raggiungere un pubblico sempre più ampio.",
						en: "## Galileo’s Revolution\n\n Scientific communication changed radically with **Galileo Galilei** (1564-1642).\n Galileo abandoned Latin and used a simpler and more widely accessible Italian, with common words used in a technical sense. His purpose was to reach an ever broader audience."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 11),
					remarkablePoints: [
						{
							id: "T3_S4_1",
							caption: 'descrizione',
							textBefore: {
								it: 'Questo è uno dei ritratti più famosi di Galileo, commissionato dal suo\n amico Elia Diodati e realizzato quando lo scienziato aveva ormai\n settant’anni anni e si trovava in esilio.', 
								en: 'This is one of the most famous portraits of Galileo, commissioned by his friend Elia Diodati and made when the scientist was in his seventies and in exile.', 
							},
							posX: '70%', 
							posY: '35%',
							position: 'top',
							customLabelStyle: 'width: 25rem;'
						}
					]
				},
				{
					id: 's19',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 10)
					},
					steps: [{}, {}],
					numSteps: 2,
					// inDepthItem: inDepthItems.items.find(el => el.id == 3),
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## Parola di Galileo\n\n Galileo arricchisce il vocabolario italiano di **parole nuove**, come _apogeo_ o _aurora boreale_, di **parole in disuso recuperate** e rilanciate (_infrangibile_) e di **parole correnti** con un nuovo significato (_nebulosa_ per «drappello di stelle»).\n Le sue opere sono ricche di **termini tecnici**, come _comete_, _disco_, _orbe lunare_, _pianeti_, _telescopio_.",
						en: "## The Words of Galileo\n\n Galileo Galilei enriched the Italian language with **new words**, such as  _apogeo_ \[apogee], _aurora boreale_ \[aurora borealis], **words recovered** from the past and given new life (_infrangibile_ \[unbreakable]) and **current words** with a new meaning (_nebulosa_ for «cluster of stars»).\n His works are full of **technical terms**, such as _comete_, _disco_, _orbe lunare_, _pianeti_, _telescopio_ \[comets, disc, lunar orb, planets, telescope]."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T3_S5_1",
							caption: 'descrizione',
							textBefore: {
								it: "In queste pagine del _Dialogo_, Salviati e Simplicio, due dei tre interlocutori dell’opera, si confrontano sul tema dell’osservazione dei corpi celesti (_stelle_, _comete_, _luna_) e di alcuni fenomeni astronomici (_macchie_ _solari_) rilevabili con il telescopio. Salviati, che si fa portatore delle tesi galileiane, domanda a Simplicio, sostenitore della teoria tolemaica, cosa direbbe Aristotele di fronte a queste manifestazioni.", 
								en: "In these pages from the _Dialogue_, Salviati and Simplicio, two of the work's three interlocutors, discuss the observation of celestial bodies (stars, comets, moon) and certain astronomical phenomena (sunspots) that can be identified with a telescope. Salviati, who is Galileo’s avatar, asks Simplicio, a supporter of Ptolemy’s theory, what Aristotle would say in the face of these observations.", 
							},
							posX: '70%', 
							posY: '35%',
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						}
					],
					curiosity: {
						it: 'Il _Dialogo sopra i due massimi sistemi del mondo_ (1632) è una delle opere più importanti di Galileo. Scritto in italiano, e perciò destinato a un vasto pubblico, il _Dialogo_ mette in contrapposizione le tesi cosmologiche tolemaica e copernicana. I suoi contenuti saranno condannati l’anno successivo dai Gesuiti e Galileo dovrà abiurare le proprie tesi di fronte al Tribunale dell’Inquisizione.',
						en: 'The _Dialogo sopra i due massimi sistemi del mondo_ `[Dialogue Concerning the Two Chief World Systems] (1632)` is one of Galileo\'s most important works. Written in Italian, and therefore intended for a wide audience, the _Dialogue_ contrasts the Ptolemaic and Copernican cosmological theories. Its contents were condemned the following year by the Jesuits and Galileo had to recant his views before the Inquisition Tribunal.'
					},
				},
				{
					id: 's20',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 10)
					},
					steps: [{}, {}],
					numSteps: 2,
					// inDepthItem: inDepthItems.items.find(el => el.id == 3),
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "## Tra _occhiali_ e _telescopi_\n\n Il termine tecnico **_telescopio_** è coniato nel 1611 dal matematico greco Giovanni Demisiani.\n Prima che fosse chiamato così, lo strumento aveva altri nomi, usati anche da Galileo. Quello latino era _perspicillum_ (da _perspicio_ ‘guardare attentamente’); in italiano i termini più comuni erano _occhiale_, _cannone_ o _cannocchiale_.",
						en: "## Eyeglasses and Telescopes\n\n The technical term **_telescopio_** \[telescope] was coined in 1611 by the Greek mathematician Giovanni Demisiani.\n Before it was called this, the instrument was also known by other names, which were also used by Galileo. The Latin one was _perspicillum_ \[from _perspicio_ ‘to look carefully’]; in Italian the most common terms were _occhiale_ \[eyeglasses], _cannone_ \[cannon] or _cannocchiale_ \[spyglass]."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					curiosity: {
						it: '_Telescopio_ viene dal greco τῆλε σκοπεῖν, ossia _téle_ ‘lontano’ e _skopéin_ ‘guardare’, dunque ‘guardare da lontano’.\n È tra le pochissime parole italiane ad avere una data di nascita precisa: la sera del 14 aprile 1611 durante un banchetto offerto a Roma da Federico Cesi per l’ingresso di Galileo nell’Accademia dei Lincei.\n Si diffonde ben presto nel mondo intero, diventando un italianismo molto fortunato: dal francese _télescope_ allo spagnolo _telescopio_, dall’inglese _telescope_ al tedesco _Teleskop_.',
						en: 'The word _telescopio_ \[telescope] comes from the Greek τῆλε σκοπεῖν, i.e. téle \'far\' and skopéin \'to look\', hence ‘to look from afar’.\n It is one of the very few Italian words to have a precise date of birth: it was on the evening of 14 April 1611 during a banquet offered in Rome by Federico Cesi for Galileo\'s admission to the _Accademia dei Lincei_.\n It soon spread throughout the world, becoming used in French _telescope_, Spanish _telescopio_, English _telescope_ and German _Teleskop_.'
					},
				},
				{
					id: 's20-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 10)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's21',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 11)
					},
					steps: [{}, {}, {}, {}, {}, {}],
					numSteps: 6,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
					inDepthItem: inDepthItems.items.find(el => el.id == 12),
					remarkablePoints: [
						{
							id: "T4_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "I quattro articoli hanno contenuti molto diversi tra loro: il primo fa parte delle notizie di cronaca; il secondo e il terzo rientrano nella categoria degli “annunci” o delle “curiosità”; l’ultimo è una recensione e un riassunto della tragedia _La morte di Adamo_ di Klopstock (1759).", 
								en: "The four articles are very different in content: the first contains local news items; the second and third come under the category of “classified ads” or “curiosities”; the last is a review and summary of the tragedy _The Death of Adam_ by Klopstock (1759).", 
							},
							posX: '35%', 
							posY: '35%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 20rem;'
						}
					]
				},
				{
					id: 's22',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 11)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Agli albori dello stile giornalistico\n\n Nell’800 si gettano le basi dello **_stile giornalistico_**. Ne è un esempio **_La Nazione_** **(1859)**.\n  Gli articoli sono meno complessi, pur conservando tracce di una lingua libresca, come il pronome alla fine del verbo (_trovavasi_).\n Ci sono anche forme vicine alla scrittura giornalistica di oggi, come il _noi_ inclusivo (_vogliamo_) e l’imperfetto cronistico (_si recava_).",
						en: "## The Dawn of _Journalistic Style_\n\n The foundations of _journalistic_ style were laid in the 19th century. An example of this is **_La Nazione_** (1859).\n The articles are less complex, while retaining traces of a bookish language, such as the pronoun at the end of the verb (_trovava-_**_si_**).\n There are also forms that are close to today's journalistic writing, such as the inclusive we (_vogliamo_, i.e. we want] and the reporting imperfect form (_si_ _recava_, i.e. he/she went)."
					},
					remarkablePoints: [
						{
							id: "T4_S1_1", 
							textBefore: {
								it: "Fondato a Firenze nel 1859 da Leopoldo Cempini, Alessandro d’Ancona, Carlo Fenzi e Piero Puccioni, il quotidiano *La Nazione* era dedito nei suoi primi anni alla causa nazionale e risorgimentale, al punto da destinare sempre la prima pagina alla politica. Negli anni Settanta dell’Ottocento viene diretto da Giuseppe Civinini, che cambia la formattazione (il cosiddetto _layout_) della pagina: distribuisce gli articoli su sei colonne e dedica ampio spazio alla cultura, pubblicando, ad esempio, racconti di Charles Dickens. Nell’ultimo ventennio del XIX secolo, sotto la direzione di Celestino Bianchi, si apre anche alla cronaca cittadina, alla moda, allo spettacolo e allo sport, e si inseriscono persino le rubriche settimanali.", 
								en: "Founded in Florence in 1859 by Leopoldo Cempini, Alessandro d'Ancona, Carlo Fenzi and Piero Puccioni, _La Nazione_ is a daily newspaper. In its early years, it was dedicated to the national and Risorgimento cause, to the point of always devoting the front page to politics. In the 1870s its editor was Giuseppe Civinini, who changed the layout of the page: he distributed articles across six columns and devoted ample space to culture, publishing, for example, stories by Charles Dickens. In the last two decades of the 19th century, under the editorship of Celestino Bianchi, it also carried news about the city, fashion, entertainment and sport, and even ran weekly features.", 
							},
							posX: '70%', 
							posY: '35%',
							caption: 'didascalia', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
					],
					curiosity: {
						it: "Già dalla seconda metà dell’Ottocento, gli articoli di cronaca e di politica ricorrono a un tono brillante con espressioni da romanzo (_furto audace_, _fuga precipitosa_). Si usano il condizionale di modestia (_si vorrebbe dire_), l’infinito iussivo (_Fare in fretta_). Si diffondono termini settoriali, come quelli medici (_aneurisma_) e burocratici (_causale_), parole straniere (_meeting_).",
						en: "As early as the second half of the 19th century, news and political articles used a lively style with novel-like expressions (_furto audace_, _fuga precipitosa_ \[daring theft, hasty escape]). The conditional of modesty (_si vorrebbe dire_ \[One would like to say]), the jussive infinitive (_Fare in fretta_ \[To make haste]) are used. Specialist medical (_aneurisma_ \[aneurysm]) and bureaucratic (_causale_ \[causal]) terms, as well as foreign words (meeting) were increasingly common."
					},
				},
				{
					id: 's23',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 11)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Tra guerra e fascismo\n\n Nel primo ’900 spicca il “**giornalismo d’assalto**” dei periodici di guerra (_L’Avanti!_, 1896), con una sintassi agile e uno stile vivace.\n Ci sono poi i giornali della propaganda fascista, dalla **retorica pomposa**, come **_Il Popolo d’Italia_**, fondato da Mussolini nel 1914, con scelte espressive che creano immagini e suoni per il lettore: _Tam!... Tam!_, _vomitando centinaia di proiettili dalle mitragliatrici sonore_.",
						en: "## From the First World War to Fascism\n\n In the early 20th century, the “assault journalism” of the war periodicals (_L'Avanti!_, 1896) stands out for its nimble syntax and lively style.\n There followed the journalism of fascist propaganda with its **pompous rhetoric**, such as **_Il Popolo d'Italia_**, founded by Mussolini in 1914, with expressions that create images and sounds for the reader: _Tam!... Tam!_, _vomitando centinaia di proiettili dalle mitragliatrici sonore_ \[Tam! Tam!, vomiting hundreds of bullets from sonorous machine guns]."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 13),
					remarkablePoints: [
						{
							id: "T4_S2_1", 
							textBefore: {
								it: "_Il Popolo d’Italia_ è stato fondato da Mussolini nel 1914. È passato poi sotto la direzione del fratello Arnaldo continuando a pubblicare fino al 1943.", 
								en: "_Il Popolo d'Italia_ was founded by Mussolini in 1914. It then passed under the editorship of his brother Arnaldo, and continued to be published until 1943.", 
							},
							posX: '70%', 
							posY: '35%',
							caption: 'didascalia', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
					],
				},
				{
					id: 's24',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 11)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Stile brillante\n\n Con il quotidiano **_La Repubblica_ (1976)** si afferma uno **stile brillante** nel linguaggio giornalistico.\n Il lessico è ricco di neologismi e metafore. Tendono a sparire i termini burocratici e letterari, relegati agli articoli di cronaca.\n L’uso del discorso diretto (attraverso le interviste) e delle citazioni porta a una semplificazione e frammentazione della sintassi.",
						en: "## A Dynamic Style\n\n The newspaper **_La Repubblica_** (1976) introduced a **dynamic style** in Italian journalism.\n The vocabulary is full of neologisms and metaphors. Bureaucratic and literary terms tend to disappear, relegated to news articles.\n The use of direct speech (through interviews) and quotations leads to a simplification and fragmentation of the syntax."
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "T4_S3_1", 
							textBefore: {
								it: "Fondato da Eugenio Scalfari, il primo numero del quotidiano _La Repubblica_ esce il 14 gennaio del 1976.", 
								en: "Founded by Eugenio Scalfari, the first issue of the newspaper _La Repubblica_ came out on 14 January 1976.", 
							},
							posX: '70%', 
							posY: '50%',
							caption: 'didascalia', 
							position: 'bottom',
							customLabelStyle: 'width: 25rem;'
						},
					],
				},
				{
					id: 's25',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 11)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## On-line vs Off-line\n\n I giornali on-line danno ampio spazio ai materiali **multimediali** (video, immagini) di supporto ai testi. Questi ultimi presentano una sintassi più snella, in cui dominano la **paratassi** e lo **stile nominale**.\n La necessità di essere sul pezzo comporta a volte una scarsa cura editoriale: da qui **sviste** ed **errori di battitura** in numero ben maggiore rispetto ai “parenti” cartacei, off-line.",
						en: "## Online vs Offline\n\n Online newspapers make use of multimedia (videos, images) to support the texts. These have a leaner syntax, in which **parataxis** and **nominal style** dominate.\n The need to be on the ball sometimes leads to a lack of editorial care. Hence, we can find misprints and typing errors in far greater numbers than in their offline, print editions."
					},
				},
				{
					id: 's26',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 12)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "La **telefonia** prima e, soprattutto, il **computer** e **internet** poi hanno contribuito a diffondere un nuovo tipo di comunicazione basata su una scrittura più semplice, rapida e informale.\n Parliamo della scrittura dei messaggini, delle chat e dei social network, che assumono il tono che si ha quando si chiacchiera con qualcuno.",
						en: "The telephone and, above all, the computer and the Internet have contributed to the spread of a new type of communication based on simpler, faster and more informal writing.\n We are talking about the language of text messages, chats and social networks, which take on a conversational tone, the tone we adopt when chatting with someone."
					},
					template: 'left',
					sizeContentText: 'normal',
				},
				{
					id: 's27',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 12)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: {
						it: "## 1 nuovo msg\n\n Il primo sms della storia risale al 1992.\n Con gli sms, che hanno un numero limitato di caratteri, si diffonde ben presto uno stile di scrittura fatto di **abbreviazioni**, come _cmq_, _ke_, _nn_, _risp_, che aiutano a risparmiare spazio e a essere molto rapidi nella digitazione, e di **_emoticon_** per esprimere i propri sentimenti.\n Questa scrittura continuerà anche nel web.",
						en: "## 1 New Message\n\n The first text message in history dates back to 1992.\n With text messages, which have a limited number of characters, a style of writing soon became widespread, consisting of abbreviations, such as _cmq_, _ke_, _nn_, _risp_ \[in any case, that, not, reply] which help to save space and are very quick to type, and of emoticons to express one's feelings. \n This writing style would later also continue on the web."
					},
					curiosity: {
						it: "Sms viene dall’inglese _short message service_ ‘servizio messaggi brevi’, mentre _emoticon_ (la cosiddetta ‘faccina’) unisce _icon_ (icona, immagine) ed _emotion_ (emozione). Agli inizi le emoticon (femminile, mi raccomando!) erano realizzate con i segni interpuntivi. Lo smile :), formato di due punti seguiti dalla parentesi tonda chiusa, è stato inventato nel 1963 da Harvey Ball. Non dobbiamo confondere le emoticon con le _emoji_, inventate negli anni Novanta in Giappone, che sostituiscono totalmente una parola («stasera 🍕?»).",
						en: "Sms comes from the English _Short Message Service_, while emoticon (‘smiley face’) combines an ideogram (picture) and emotion. In the early days, emoticons were created using punctuation marks. The smiley :), formed by the colon followed by the closed round bracket, was invented in 1963 by Harvey Ball. We should not confuse emoticons with emoji, invented in the 1990s in Japan, which totally replace a word («🍕 tonight?»)."
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					template: 'left',
				},
				{
					id: 's28',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 12)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## L'_e-taliano_\n\n Con internet la comunicazione è cambiata notevolmente: «ha fatto emergere il modo in cui gli italiani si esprimono» (Elena Pistolesi).\n Internet è diventato lo spazio dell’_e-taliano_, una varietà telematica dell’italiano scritto contemporaneo caratterizzata da discontinuità, velocità, tratti del parlato e molti anglicismi.",
						en: "## _E_-talian\n\n The Internet has changed communication considerably: «it has brought to the surface the way Italians express themselves» (Elena Pistolesi).\n The Internet has become the space of _e-talian_, an online variety of contemporary written Italian characterised by discontinuity, speed, conversational features and many English words."
					},
					curiosity: {
						it: "La parola _e-taliano_ è formata da _italiano_ e dal confisso _e_- (_electronic_). Con _e-taliano_ Giuseppe Antonelli (2012) indica una lingua telematica scritta, utilizzata da tutti: persone istruite e non, madrelingua italiani e non, ragazzi e adulti.\n Mentre per le persone più istruite è una scelta stilistica, per gli altri è spesso l’unico modo di esprimersi per via scritta. Fra le caratteristiche principali ci sono: uso di abbreviazioni, punteggiatura espressiva, icone, frammentarietà, paratassi, regionalismi, gergalismi e forestierismi.",
						en: "The word _e-taliano_ \[e-talian] is formed from Italian and the prefix _e-_ (electronic). Giuseppe Antonelli (2012) uses the expression _e-talian_ to describe a written language used online by everyone – educated and uneducated people, native Italians and non-native speakers, young people and adults.\n While for more educated people it is a stylistic choice, for others it is often the only way of expressing themselves in writing. Key features include: a rapid style, expressive punctuation, smileys, fragmentary phrases, parataxis, regionalisms, slang and foreign loan words."
					},
				},
				{
					id: 's29',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 12)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-rose',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Navigare (in internet)\n\n A partire dagli anni ’90 la rete ha contribuito a diffondere parole prese o prodotte dall’inglese: _chat_, _chattare_, _e-mail_, _googlare_, _social_…\n  Alcune sono persino preferite al corrispettivo italiano: _follower_ per _seguace_, _post_ per _contenuto_, _bannare_ per _espellere_.\n Ci sono anche espressioni di origine inglese tradotte in italiano. La più usata? _Navigare_.",
						en: "## Surf the Web\n\n Since the 1990s, the Net has helped to spread words taken or adapted from English: _chat_, _chattare_, _e-mail_, _googlare_, _social_… \[chat, to chat, e-mail, to google, social…]\n Some are even preferred to their Italian counterpart: follower for _seguace_, post for _contenuto_, _bannare_ for expel.\n There are also expressions of English origin translated into Italian. What is the one most commonly used? _Navigare_ \[to surf]."
					},
					curiosity: {
						it: "La metafora _navigare in internet_ è stata inventata da Jean Armor Dolly nel 1992 che, scrivendo del web, voleva trovare un’immagine rappresentativa del caos di internet.\n L’illuminazione è arrivata guardando il surfista sul tappetino del suo mouse: da qui _surf the internet_. In italiano _navigare_ traduce, perciò, il verbo _to surf_.",
						en: "The metaphor “surfing the internet” was invented by Jean Armor Dolly in 1992 who, while writing about the web, wanted to find an image that could describe the chaos of the internet.\n The metaphor came to him when he saw the surfer on his mouse pad: hence surf the internet. The Italian _navigare_ \[to navigate], therefore, translates the English verb _to surf_."
					},
				},
				{
					id: 's30',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 12)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Oggi posto e vi taggo\n\n _Msn_, _Facebook_ o _Twitter_ hanno avuto un ruolo fondamentale nel cambiamento linguistico della comunicazione.\n Non solo per i tempi comunicativi più immediati, ma anche per aver messo in circolo nuove parole, spesso derivate da verbi inglesi: ad esempio, _taggare_ e _postare_ da _to tag_ e _to post_.",
						en: "## Today I Post and today I Tag\n\n _Msn, Facebook_ and _Twitter_ have played a fundamental role in changing linguistic communication.\n Not only because communication is more rapid, but also because it has led to the spread of new words, often derived from English verbs: for example, _taggare_ and _postare_ from _to tag_ and _to post_."
					},
					curiosity: {
						it: "La scrittura di _Twitter_ è breve e semplificata, fatta di chiocciole (@) e hashtag (#), ricca di termini inglesi e di contrazioni che sono entrate a pieno titolo nel linguaggio della comunicazione digitale.\n Qualche esempio? _Lol_ (‘laughing out loud’), _omg_ (‘oh my God’), _POV_ (‘point of view’), _ship_, _blessare_.",
						en: "Writing on Twitter is short and simplified, made up of at (@) and hashtags (#), full of English terms and contractions that have become an integral part of the language of digital communication.\n Some examples are _Lol_ (‘laughing out loud’), omg (‘oh my God’), POV (‘point of view’), ship, _blessare_ \[bless] "
					},
				},
				{
					id: 's31',
					name: 'bibliography',
					stage: {
						...stagesData.stagesData.find(el => el.id == 13)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-extra-light-gold',
					template: 'left',
				}
			]
		},
		// NORMA
		{
			id: 3,
			name: {
				it: "Chi ha stabilito le regole dell'italiano?",
				en: 'Who Established the Rules of the Italian Language?'
			},
			nameCode: 'chi-stabilito-regole-italiano',
			nameArray: {
				it: ['chi ha', ' stabilito', 'le regole', 'dell\'italiano?'],
				en: ['Who Established', 'the Rules', 'of the Italian', 'Language?']
			},
			description: {
				it: 'All’inizio ci sono tre stelle luminosissime a indicare la strada. Le chiamano Corone, come fossero dei gioielli preziosi. Nel corso dei secoli la luce non diminuisce. Anzi. Vi aspetta un’avventura piena di sorprese. Incontrerete splendidi manoscritti e libri antichi, strane pale e bagni in Arno. E molto altro. Cominciamo?',
				en: 'At the beginning there were three very bright stars that showed the way. They were called crowns to symbolise how precious they were. Over the centuries their light has not faded. Quite the opposite.\n An adventure full of surprises awaits you. You will encounter splendid manuscripts, strange shovels and washing in the river Arno. And much more besides.\n Shall we begin?',
			},
			introImages: [
				require('@assets/images/percorsi/norma/Dante_High' + utils.manageImagesExtension('.png')),
				require('@assets/images/percorsi/norma/intro/' + utils.manageImagesExtension('Bembo_High.png')),
				require('@assets/images/percorsi/norma/intro/' + utils.manageImagesExtension('01_manzoni_High.png')),
			],
			artifactsList: [
				{section: 's2', artifacts: [16]},
				{section: 's4', artifacts: [16]},
				{section: 's5', artifacts: [16]},
				{section: 's6', artifacts: [16]},
				{section: 's7', artifacts: [16]},
				{section: 's20', artifacts: [16]},
				{section: 's27', artifacts: [16]}
			],
			sections: [
				{
					id: 's1',
					name: 'introduzione',
					isFixed: true,
					stage: {
					...stagesData.stagesData.find(el => el.id == 14)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's2',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: `Da subito i tre capolavori del Trecento fiorentino, la _Divina Commedia_ di Dante, il _Canzoniere_ di Petrarca, il _Decameron_ di Boccaccio, vengono letti in tutta l’Italia e diventano il punto di riferimento imprescindibile per la scrittura.  
Questo rende l’italiano unico in tutto il mondo.  
Sono le **Tre Corone** fiorentine.`,
						en: `From the very beginning the three masterpieces of 14th century Florence – Dante’s _Divine Comedy_, Petrarch’s _Canzoniere_ \[_Songbook_] and Boccaccio’s _Decameron_ – mounted the podium of the Italian language and became the gold standard for writing. This makes Italian unique in the whole world.
They are the Three Crowns of Florence.`,
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 14),
					curiosity: {
						it: "L’espressione _Tre Corone_ non è molto usata dagli scrittori del passato, che preferiscono parlare di “padri”, ad esempio. Le poche occorrenze (Vittoria Colonna, Vincenzo Monti) hanno tutt’altro significato, di argomento sacro. Infine, per estensione si usa anche applicata ad altri scrittori: le tre corone estensi (Boiardo, Ariosto, Tasso, indiscussi campioni del poema cavalleresco); oppure le tre corone della poesia moderna (Carducci, Pascoli, D’Annunzio).",
						en: "The expression _Three Crowns_ was not used much by writers of the past, who preferred to speak of “fathers”.\n The few occurrences that exist (Vittoria Colonna, Vincenzo Monti) have a very different, more sacred meaning.\n It was also used later for other writers: the three crowns of d’Este (Boiardo, Ariosto, Tasso, indisputable masters of chivalrous poetry); or the three crowns of modern poetry (Carducci, Pascoli and D’Annunzio).",
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's3',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Signore e signori, Dante Alighieri\n\nDante Alighieri nasce nel 1265 a Firenze, in un’epoca di violenti dissidi tra schieramenti avversari. Diviso fra letteratura e attività politica, nel 1302, di rientro da un'ambasceria a Roma, viene raggiunto dalla notizia della condanna a morte in seguito all’accusa di baratteria. Da quel giorno Dante non farà più ritorno a Firenze, vivendo gli ultimi vent’anni della sua vita in un drammatico esilio tra Italia del Nord, Appennino tosco-romagnolo e Toscana. Morirà a Ravenna nel 1321.",
						en: "## Ladies and Gentlemen, Dante Alighieri\n\n Dante Alighieri was born in 1265 in Florence, at a time of violent clashes between opposing sides. Divided between literature and political activity, in 1302 returning from an embassy in Rome he was reached by the news that he had been sentenced to death following a bartering charge. From that day Dante would never return to Florence, living the last twenty years of his life in a dramatic exile between northern Italy, the Tuscan-Romagna Apennines and Tuscany.\n He died in Ravenna in 1321."
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 14),
					curiosity: {
						it: "In realtà Dante si chiamava Durante.\n Il nome potrebbe risalire al presunto nonno materno Durante degli Abati.\n Il nome non compare mai nella _Commedia_, dove il poeta viene chiamato «Dante» (_Purg_. XXX, 55), ma solo due volte nel _Fiore_, opera attribuita al sommo poeta.",
						en: "Dante’s real name was Durante.\n The origin of the name may be from Dante’s probable maternal grandfather, Durante degli Abati.\n The name never appears in the _Divine_ _Comedy_ where he is called Dante, but appears only twice in _Fiore_ \[_Flower_], a work attributed to the supreme poet.",
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's4',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## Dal buio del peccato alla luce della salvezza\n\n La _Divina Commedia_, il poema di Dante che racconta un viaggio ultraterreno attraverso Inferno, Purgatorio e Paradiso, rivela tutte le potenzialità espressive di una lingua, il volgare fiorentino del Trecento, destinato a diventare l’italiano parlato ancora oggi.",
						en: "## From the Darkness of Sin to the Light of Salvation\n\n Dante Alighieri’s poem _Divina Commedia_ \[_The Divine Comedy_] which recounts an afterlife journey through Inferno \[Hell], Purgatorio \[Purgatory] and Paradiso \[Paradise], reveals all the expressive potential of the Florentine vernacular of the _Trecento_ \[14 century], which would later become the Italian spoken even today."
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 15),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's5',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-black',
					caption: {
						it: "## Il padre della lingua italiana\n\n Dante può dire e scrivere tutto.\n Dalle “maleparole” (_sterco_, _puttaneggiare_, _fica_) alle parole paradisiache (_incielare_, _fulgore_, _teodia_), passando per espressioni che sono diventate parte integrante del nostro patrimonio linguistico (_senza infamia e senza lode_; _le dolenti note_; _il bel Paese_).\n Per questo Dante è considerato il “padre” della lingua italiana.",
						en: "## The Father of the Italian Language\n\n Dante can say and write anything he wants.\n From obscenities (_sterco_ \[_shit_], _puttaneggiare_ \[_act like a whore_], _fica_ \[_cunt_]) to heavenly words (_incielare_ \[_enheaven_], _fulgore_ \[_brightness_], _teodia_ \[_hymn_]), as well as expressions that have become an integral part of Italy’s linguistic heritage (_senza infamia e_ _senza lode_ \[_without praise nor blame_]; _le dolenti note_ \[_sore point_]; _il bel Paese_ \[_the beautiful country_]).\n This is why Dante is the “father” of the Italian language."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's6',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "## Una lingua ancora in uso\n\n La _Commedia_ di Dante ha contribuito a integrare e a trasmettere fino a noi, attraverso i secoli, il cosiddetto vocabolario fondamentale della lingua italiana, che già alla fine del Trecento risultava in buona parte completo.\n Dunque, «non è enfasi retorica dire che parliamo la lingua di Dante» (Tullio De Mauro).",
						en: "## A Language That Is Still in Use\n\n Dante’s _Divine Comedy_ has helped to enrich and pass down through the centuries to us the so-called fundamental vocabulary of the Italian language, which was already largely complete by the end of the 14th century.\n «It is no mere rhetoric to say that we speak the language of Dante» (Tullio De Mauro)."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's7',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Il \"brand\" Dante\n\n Oggi la _Divina Commedia_ è una narrazione scolpita nell’immaginario collettivo.\n Se _Inferno_ e _Purgatorio_ godettero di grande fortuna popolare fin dai tempi di Dante, è a partire soprattutto dal Romanticismo e poi nel Novecento che l'opera può vantare un’eccezionale serie di adattamenti, omaggi, citazioni: dalle arti figurative al cinema, dalla radio ai fumetti, dalla pubblicità ai videogiochi.",
						en: "## Dante as a Brand\n\n Today, we can say the _Divine Comedy_ is etched in the collective imagination of the entire world.\n While the cantos of _Inferno_ and _Purgatorio_ must have enjoyed considerable popular success from the moment they were first published in Dante’s lifetime, starting at least from the 19th century – and then more  intensely so in the following century – the work has seen many an exceptional number of adaptations, transpositions, homages, and quotations. They are to be found in everything from the figurative arts to cinema, from the theatre to radio, from comics to adverts and video games."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 16),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's8',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "##Dante da leggere\n\n Con l’espressione latina _Lectura Dantis_ si intende la lettura ad alta voce di interi canti o episodi provenienti dalle tre cantiche della _Divina Commedia_.\n La lettura è spesso accompagnata da un commento che introduce la vicenda o affronta determinate questioni di tipo culturale, dottrinale, linguistico, eccetera.",
						en: "##Reading Dante\n\n The Latin expression _Lectura Dantis_ refers to the reading aloud of entire cantos or episodes from the three canticles of the _Divine Comedy_.\n The reading is often accompanied by a commentary that introduces the episode or addresses certain questions of a cultural, theological, linguistic etc. nature."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 17),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's9',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-extra-light-gold',
					caption: {
						it: "## Il primo moderno linguista\n\n Dante è il “padre” non solo della lingua italiana, ma anche della linguistica, la moderna scienza del linguaggio.\n Tra il 1304 e il 1305 inizia, senza mai portarlo a termine, il _De vulgari eloquentia_, un originale trattato in latino (perché rivolto a un pubblico specialistico) di taglio enciclopedico sulla lingua, dall’origine biblica al panorama dell’Europa e dell’Italia.",
						en: "## The First Modern Linguist\n\n Dante is the “father” not only of the Italian language, but also of linguistics, the modern science of language.\n Between 1304 and 1305 he drafted _De vulgari eloquentia_ \[_Concerning Vernacular Eloquence_], an original, encyclopaedic treatise in Latin (because it was intended for a specialist audience) on language from its Biblical origins to the nature of poetry in the vernaculars of Italy and Europe."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					inDepthItem: inDepthItems.items.find(el => el.id == 111),
				},
				{
					id: 's9-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 15)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's10',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 16)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "Francesco Petrarca (1304-1374) fu uno degli uomini più importanti del suo tempo, e forse uno dei più importanti di tutti i tempi.\n Primo vero intellettuale moderno, ha scritto moltissimo in latino. Si è innamorato di una donna, Laura, vista una sola volta. Per lei ha scritto in volgare. Ha cambiato per sempre la poesia italiana, e non solo.",
						en: "Francesco Petrarch (1304-1374) was one of the most important men of his time, and perhaps one of the most important men of all time. The first true modern intellectual, he wrote extensively in Latin. He fell in love with a woman, who he saw only once. For her he wrote in the vernacular. He changed poetry in Italy, and elsewhere, forever."
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: 'color: var(--color-black-text)',
				},
				{
					id: 's11',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 16)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "## Il libro di una vita\n\n Petrarca lo chiama _nugellae _(cose di poco conto) o _Rerum vulgarium fragmenta_ (Frammenti di cose in volgare), ma dedica **tutta la vita **a scriverlo.\n Conosciuto anche come _Canzoniere_, è tra i libri di poesia più importanti e influenti al mondo.",
						en: "## A Lifetime's Work\n\n Petrarch called it _nugellae_ \[Latin word, i.e. trifles] or _Rerum vulgarium fragmenta_ \[_Fragments of Vulgar Things_], but he dedicated his entire life to writing it.\n Also known as the _Canzoniere_ \[_Songbook_], it is among the most important and influential books of poetry in the world.",
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "norma_T2_S2_1",
							caption: 'didascalia',
							textBefore: {
								it: "Del _Canzoniere_ esiste qualcosa di eccezionale per l’epoca, una **versione d’autore**: il manoscritto **Vaticano Latino 3195**, in parte autografo (cioè scritto dallo stesso Petrarca) e in parte trascritto dal copista di fiducia di Petrarca, Giovanni Malpaghini.\n Particolare anche la storia del manoscritto. A un certo punto questo venne usato da Pietro Bembo per una sua copia personale, l’attuale Vat. lat. 3197 del 1502. Nel 1544 Bembo acquistò il manoscritto che passò poi in eredità al figlio Tommaso e successivamente a Fulvio Orsini e quindi, dopo il 1600, entrò nelle collezioni della Biblioteca Vaticana.", 
								en: `Of the _Canzoniere_, there exists something exceptional for its time: ms **Vat. Lat. 3195** is a partial autograph (i.e. written by Petrarch himself) and a partial transcription by a Petrarch’s trusted copyist, usually identified in Giovanni Malpaghini.	n The history of the manuscript is also unusual. At one point it was used by Pietro Bembo to prepare an autograph copy, now Vat. lat. 3197 of 1502. Bembo acquired the manuscript in 1544, which was then bequeathed to his son, Tommaso, and later to Fulvio Orsini, and after 1600 it entered the collections of the Vatican Library.`
							},
							posX: '75%', 
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 30rem;'
						},
						{
							id: "norma_T2_S2_2",
							caption: 'didascalia',
							textBefore: {
								it: "Il processo per raggiungere la forma definitiva quale risulta nel Vat. Lat. 3195 è lungo: tra le molte fasi intermedie di elaborazione (testimoniate, ad es., dal manoscritto Chigiano L.V. 176, o dal Laurenziano XLI.17), la più interessante è offerta da alcune carte, tormentatissime fino al limite della leggibilità: è il cosiddetto **codice degli abbozzi**, Vat. Lat. 3196. Queste carte sono molto importanti perché testimoniano la continua ricerca di Petrarca, tra correzioni, spostamenti e ripensamenti: insomma, è come vedere in presa diretta il lavoro del poeta nel corso degli anni.", 
								en: `The process to reach the final form as it appears in Vat. Lat. 3195 is very long: in addition to a number of intermediate configurations, which are very useful for understanding the development of the book (Chigiano manuscript L.V. 176, for example, or the Laurenziano XLI.17), there are a number of precious draft notebooks, which reveal Petrarch’s torment to the point of being difficult to read, known as the **codice degli abbozzi**, (Vat. Lat. 3196) \[code of sketches]. These papers are very important because they bear witness to Petrarch's continuous research, amidst corrections, shifts and reconsiderations: in short, it is like seeing the poet's work over the years.`
							},
							posX: '35%', 
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 30rem; gap: var(--s0);'
						}
					],
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItems: [
						inDepthItems.items.find(el => el.id == 18),
						inDepthItems.items.find(el => el.id == 112),
					],
					additionalStyleSectionContent: 'color: var(--color-black-text)',
				},
				{
					id: 's12',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 16)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: {
						it: "##Chi è davvero Laura?\n\n Nel cantare Laura Petrarca allude spesso al **lauro**, l’alloro, simbolo di gloria poetica.\n L’evocazione del mito di Apollo e Dafne (trasformata in lauro) e l’**incoronazione** in Campidoglio del poeta (con il lauro!) hanno fatto dubitare già i contemporanei di Petrarca dell’esistenza di Laura.\n In realtà Laura è esistita.\n Si chiamava Laura de Noves (o de Novalis o de Noyes): di Avignone, era sposata al nobile Ugo de Sade, antenato del famoso marchese.",
						en: "##Who Is Really Laura?	\n\n In singing Laura, Petrarch often alludes to the laurel, the laurel, the symbol of poetic glory.\n The evocation of the myth of Apollo and Daphne (transformed into the laurel) and the poet's coronation on the Capitol (with the laurel!) already led his contemporaries to doubt her existence.\n Laura did actually exist.\n Her name was Laura de Noves, or de Novalis or de Noyes, she was from Avignon and was married to the nobleman Hugh de Sade, an ancestor of the famous Marquis de Sade."
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "norma_T2_S3_1",
							caption: 'didascalia',
							textBefore: {
								it: "Erano i capei d’oro a l’aura sparsi / che ’n mille dolci nodi gli avolgea\n (*RVF* 90)", 
								en: "She let her gold hair scatter in the breeze\n that twined it in a thousand sweet knots"
							},
							posX: '75%', 
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 20rem;'
						},
						{
							id: "norma_T2_S3_2",
							caption: 'didascalia',
							textBefore: {
								it: "I begli occhi ond’i’ fui percosso in guisa / ch’e’ medesmi porian saldar la piaga, / et non già vertù d’erbe, o d’arte maga, / o di pietra dal mar nostro divisa\n (*RVF* 75)", 
								en: "Those lovely eyes, that struck me in such guise\n that only they themselves could heal the wound,\n and not the power of herbs, nor magic art,\n nor some lodestone from far beyond our seas"
							},
							posX: '35%', 
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 20rem;'
						}
					],
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItem: inDepthItems.items.find(el => el.id == 19),
					additionalStyleSectionContent: '',
				},
				{
					id: 's13',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 16)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "##Il primo influencer della storia\n\n Con Petrarca nasce una vera e propria \"scuola\" poetica, il **petrarchismo**, che influenzerà il modo di fare poesia in Italia e in Europa.\n Per secoli le donne avranno capelli d’oro, forme angeliche, dolci sorrisi, begli occhi, bianche mani...",
						en: "##The First Influencer in History\n\n Thanks to Petrarch, a veritable poetic \"school\" was born known as Petrarchism, which was to influence the way poetry was produced in Italy and Europe.\n For centuries, women would have golden hair, angelic forms, sweet smiles, beautiful eyes, white hands..."
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItem: inDepthItems.items.find(el => el.id == 20),
					additionalStyleSectionContent: '',
				},
				{
					id: 's14',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 17)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-rose',
					caption: {
						it: "Con il **_Decameron_**, composto tra il 1349 e il 1353, l’italiano in prosa raggiunge **risultati altissimi**.\n I punti di forza sono una narrazione avvincente, che parla della società del tempo rivolgendosi a un nuovo pubblico di donne, mercanti, studenti; e una lingua che unisce **alto e basso**, **voci popolari e colte**.",
						en: "Composed between 1349 and 1353, the _Decameron_ marks a high point in Italian prose.\n Its strengths are: a compelling narrative, which describes the society of the time addressing a new audience of women, merchants and students; a language that combines high and low register, popular and cultured figures."
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'normal',
					inDepthItem: inDepthItems.items.find(el => el.id == 21),
					additionalStyleSectionContent: '',
				},
				{
					id: 's15',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 17)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: {
						it: "##Un'opera e un manoscritto eccezionali\n\n A differenza della _Commedia_ di Dante (di cui non si è conservato neppure un frammento autografo), e in maniera simile a Petrarca con il suo _Canzoniere_, del _Decameron_ di Boccaccio esiste un intero manoscritto ricopiato dall’autore **in persona**.\n Lo ha fatto verso il 1370, vent’anni dopo la composizione dell’opera.",
						en: "##An Exceptional Manuscript and Work\n\n There exists an entire manuscript of the _Decameron_ that was copied by Boccaccio **himself**!\n He copied it around 1370, twenty years after the work was composed.\n While nothing has been found written in Dante's hand so far (but let us not despair), and of Petrarch there remain a number of extracts, we can admire Boccaccio's masterpiece written in his own hand."
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					curiosity: {
						it: "Come già i capolavori di Dante (_Comedìa_, _Divina Commedia_) e Petrarca (_Rerum vulgarium fragmenta_, _Canzoniere_), anche il capolavoro di Boccaccio presenta più titoli: _Decameron_ (dal greco _deka heméron_, ‘di dieci giorni’, da pronunciare sia tronco sia sdrucciolo), la forma italianizzata _Decamerone_, oppure anche _Cento novelle_. Boccaccio lo chiama pure «prencipe Galeotto», in omaggio al verso di Dante «Galeotto fu ’l libro e chi lo scrisse» (_Inf._ V, 137).",
						en: "As with Dante’s masterpiece (_Commedia_, _Divina Commedia_) and that of Petrarch (_Rerurm vulgarium fragmenta_, _Canzoniere_), Boccaccio's masterpiece also has several titles. _Decameron_ (from the Greek _deka heméron,_ ‘of ten days’, accent on either the final syllable or on the third last syllable), the “Italianised” form _Decamerone_, or also _Cento Novelle_ \[Hundred Novellas].\n Boccaccio also called it «prince Galeotto», in homage to Dante’s verse «Galeotto fu il libro e chi lo scrisse» (_Inf_. V, 137) \[A Galeotto was the book and he that wrote it; Galeotto is a go-between].",
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItem: inDepthItems.items.find(el => el.id == 22),
					additionalStyleSectionContent: '',
				},
				{
					id: 's16',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 17)
					},
					steps: [{}, {}, {}, {}],
					numSteps: 4,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "##Un successo strepitoso\n\n Tra manoscritti e stampe, il _Decameron_ è un vero e proprio _bestseller_.\n **Merito anche della lingua, già all’epoca modernissima:** infatti, più dell’80% delle parole utilizzate nell’opera è riconoscibile e in uso nell’italiano contemporaneo.\n Cosa che non si può dire per l’inglese o il francese medievale, ostici per un inglese o francese di oggi.",
						en: "##A Resounding Success\n\n Counting manuscripts and printed editions, the _Decameron_ is a true bestseller.\n **The merit also goes to the Italian language, which was already very modern at the time.**\n Even today more than 80% of the words are recognisable and used in contemporary Italian.\n The same cannot be said for medieval English or French, which are not easily comprehensible to an English or French native speaker today."
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					curiosity: {
						it: "Il sostantivo più usato da Boccaccio? _Donna_/_donne_ con 1626 occorrenze.\n La stoffa del narratore emerge dalle forme verbali più usate: _era_ (1352 occorrenze) e _disse_ (1275; il lemma _dire_ ne ha 2931).",
						en: "Which noun is most frequently used by Boccaccio? _Woman_/_women_, with 1626 occurrences.\n The narrator emerges in the most frequently used verb forms: _era_ \[was] (1352 occurrences) and _disse_ \[said] (1275 occurrences; the lemma _dire_ \[to say] has 2931 occurrences).",
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's17',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 17)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: {
						it: "##Un segno inconfondibile\n\n I grandi scrittori lasciano tracce di sé anche dando origine ad aggettivi ispirati ai loro nomi.\n Pensa a **machiavellico**, riferito a un comportamento privo di scrupoli (_quell’uomo è machiavellico_). O, guardando all’estero, a **shakespeariano**, per una trama molto coinvolgente (_un film shakespeariano_).\n È successo anche a Boccaccio.",
						en: "##An Unforgetable Mark\n\n Great writers also leave their mark through adjectives formed from their names.\n One example is the word **Machiavellian**, referring to unscrupulous behaviour (_this guy is Machiavellian_). Another is the word **Shakespearean**, used to describe a very intriguing plot (_a Shakespearean film_).\n This also happened to Boccaccio.",
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItem: inDepthItems.items.find(el => el.id == 23),
					additionalStyleSectionContent: '',
				},
				{
					id: 's18',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 17)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "##Boccaccio quotidiano\n\n Non solo un autore e un’opera che parlano del quotidiano: il _Decameron_ di Boccaccio entra ben presto nel **quotidiano** di molte persone.\n Lo dimostra la tradizione dei **cassoni rinascimentali**, mobili decorati con scene dall’opera, di solito commissionati per il matrimonio di una giovane coppia di sposi.",
						en: "##An Everyday Boccaccio…\n\n Not only an author and a work that describe everyday life.\n The _Decameron_ soon entered the everyday lives of many people.\n We can find evidence of this in the traditional Renaissance _cassoni_ \[bridal chests], which were decorated with scenes from the _Decameron_, usually commissioned for the wedding of young betrothed couples.",
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's19',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 18)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "Il Rinascimento è un’epoca di scoperte e innovazioni: Colombo sbarca in America, Leonardo progetta macchine avveniristiche, Michelangelo rivoluziona la scultura, Raffaello la pittura.\n Anche **la lingua italiana ha il suo Leonardo** o Raffaello.\n Grande innovatore ma anche, secondo lo spirito del Rinascimento, grande conoscitore del passato.\n Il suo nome è Pietro Bembo.",
						en: "The Renaissance was an era of discoveries and innovations: Columbus landed in America, Leonardo designed futuristic machines, Michelangelo revolutionised sculpture, Raphael painting.\n The Italian language too has its Leonardo or Raphael.\n He was a great innovator but also, in the spirit of the Renaissance, had a great knowledge of the past.\n His name is Pietro Bembo.",
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'normal',
					inDepthItem: inDepthItems.items.find(el => el.id == 24),
					additionalStyleSectionContent: '',
				},
				{
					id: 's20',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 18)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItem: inDepthItems.items.find(el => el.id == 25),
					additionalStyleSectionContent: '',
				},
				{
					id: 's20b',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 18)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					template: 'left',
					caption: {
						it: `La proposta di Bembo risulterà quella vincente, ma questo non impedisce a diversi letterati, toscani e non, di elaborare **altre proposte** di modello: chi la lingua della corte papale, chi il fiorentino contemporaneo…
È il dibattito noto come “**Questione della lingua**”: e a un certo punto spunta pure il fantasma di Dante…`,
						en: 'Bembo\'s proposal would be the winning one, but this did not prevent various literati, Tuscan and otherwise, from proposing other languages as a model: some suggested the language of the papal court, others contemporary Florentine…\n It is the debate known as the **“Language Question”** and at a certain point even Dante\'s ghost appears…'
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItem: inDepthItems.items.find(el => el.id == 27),
				},
				{
					id: 's21',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 18)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					caption: {
						it: "##Bembo inventore\n\n Da degno contemporaneo di Leonardo, Bembo è anche un inventore. Con lo stampatore Aldo Manuzio crea il punto e virgola: il segno compare nel dialogo in latino _De Aetna_ (1496) dello stesso Bembo. Per il punto e virgola, oggi usato in moltissime lingue del mondo, è l’inizio di una storia plurisecolare.",
						en: "##Bembo The Inventor\n\n As a worthy contemporary of Leonardo, Bembo was also an inventor.\n With the printer Aldus Manutius he created the semicolon.\n The sign appears in Bembo’s Latin dialogue _De Aetna_ (1496).\n For the semicolon this is the beginning of a story that continues today; this punctuation mark is now used in many languages of the world."
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItem: inDepthItems.items.find(el => el.id == 26),
					additionalStyleSectionContent: '',
				},
				{
					id: 's22',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 18)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-paper',
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					caption: {
						it: "##Un piccolo mistero\n\n Bembo ebbe tra i suoi libri quella che è considerata la prima grammatica italiana a tutti gli effetti: la cosiddetta **_Grammatichetta vaticana_** dell’umanista Leon Battista Alberti.\n Ma Bembo non ne parlò mai, e così per secoli nessuno ne accennò fino a che se ne persero le tracce.",
						en: "##A Minor Mistery\n\n Among the books in Bembo’s possession was what is to all intents and purposes the first Italian grammar: the **_Grammatichetta vaticana_** \[Little Vatican Grammar] by the humanist Leon Battista Alberti.\n But Bembo never spoke of it, and so for centuries no one mentioned it and for a long time all trace of it was lost.",
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					inDepthItem: inDepthItems.items.find(el => el.id == 28),
					additionalStyleSectionContent: '',
				},
				{
					id: 's23',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 18)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					template: 'right',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					caption: {
						it: "##La prima “risciacquatura” della storia\n\n Tra i primi grandi scrittori a seguire la proposta di Bembo c’è **Ludovico Ariosto**.\n Ancora prima della celebre “risciacquatura dei panni in Arno” di Manzoni, Ariosto **“risciacqua”** le prime due edizioni dell’_Orlando Furioso_ (1516, 1521), ossia **ripulisce e corregge** certe forme avvertite come troppo settentrionali.",
						en: "##The First “Washing” in History\n\n One of the first great writers to follow Bembo's proposal was **Ludovico Ariosto**.\n Even before Manzoni's famous “washing of sheets in the Arno”, Ariosto “washed” the first two editions of _Orlando Furioso_ (1516, 1521), i.e. he polished up and corrected certain forms that were too northern.",
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					remarkablePoints: [
						{
							id: "norma_T4_S6_1",
							caption: 'didascalia',
							textBefore: "I quattro articoli hanno contenuti molto diversi tra loro: il primo fa parte delle notizie di cronaca; il secondo e il terzo rientrano nella categoria degli “annunci” o delle “curiosità”; l’ultimo è una recensione e un riassunto della tragedia _La morte di Adamo_ di Klopstock (1759).", 
							posX: '0%', 
							posY: '0%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 20rem;'
						}
					],
					additionalStyleSectionContent: '',
				},
				{
					id: 's24',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 18)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					caption: {
						it: "Ariosto sottopone la prima edizione dell’_Orlando Furioso_ (1516) e, in particolare, la seconda edizione (1521) a una **lunga e scrupolosa revisione linguistica**: per l’esattezza passa dal cosiddetto **“padano illustre”** della prima edizione **al fiorentino** della terza e definitiva stampa del 1532, seguendo le indicazioni grammaticali delle _Prose della volgar lingua_.",
						en: "Ariosto **scrupulously revised** at length the language of the first edition of _Orlando Furioso_ (1516) and, in particular, the second edition (1521): to be exact, he transformed from the so-called “**Padano illustre**” \[illustrious Po vernacular] of the first edition to the Florentine of the third and final printing of 1532, following the grammatical suggestions of the _Prose della volgar lingua_."
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					remarkablePoints: [
						{
							id: "norma_T4_S6_1",
							caption: '',
							textBefore: "ch'el", 
							textAfter: "che'l", 
							posX: '0%', 
							posY: '0%',
							position: 'top',
						},
						{
							id: "norma_T4_S6_2",
							caption: '',
							textBefore: "serà", 
							textAfter: "sarà", 
							posX: '0%', 
							posY: '0%',
							position: 'left',
						},
						{
							id: "norma_T4_S6_3",
							caption: '',
							textBefore: "debbio", 
							textAfter: "debbo", 
							posX: '0%', 
							posY: '0%',
							position: 'bottom',
						},
						{
							id: "norma_T4_S6_4",
							caption: '',
							textBefore: "cedeno", 
							textAfter: "cedino", 
							posX: '0%', 
							posY: '0%',
							position: 'top',
						},
						{
							id: "norma_T4_S6_5",
							caption: '',
							textBefore: "luoco", 
							textAfter: "loco", 
							posX: '0%', 
							posY: '0%',
							position: 'top',
						}
					],
					additionalStyleSectionContent: '',
				},
				{
					id: 's25',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 19)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "A Firenze tra il 1570 e il 1580 un gruppo di amici si riunisce per parlare di “cruscate”, cioè di cose di poco conto.\n Il termine, legato al mondo della farina, è scherzoso. Ben altro è l’obiettivo: “passare al setaccio” la lingua per ricavarne “il fiore”, cioè la parte migliore.\n Nasce così la più antica accademia linguistica del mondo.",
						en: "Between 1570 and 1580, a group of friends got together in Florence to talk about _cruscate,_ i.e. things of little importance.\n The term, linked to the world of flour, is joking. Their aim was quite different. It was to “sift” the language to extract “the flower”, i.e. the best part.\n This is how the oldest literary academy in the world was born.",
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 29),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's26',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 19)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: {
						it: "##Brigata dei Crusconi\n\n Così si chiamava il gruppo di accademici originario. Giovan Battista Deti, il Sollo (cioè soffice); Anton Francesco Grazzini, il Lasca (pesce simile alla trota); Bernardo Canigiani, il Gramolato (pieno di grumi); Bernardo Zanchini, il Macerato (lasciato a macerare); Bastiano de’ Rossi, l’Inferigno (mischiato a crusca, impuro). Nel 1582 si unirà a loro Lionardo Salviati: con lui arrivano la costituzione dell’Accademia e i lavori di spoglio per il Vocabolario.",
						en: "##_Brigata dei Crusconi_\n\n Brigade of coarse bran. That is the name of the first original group (the year is 1570), formed by Giovan Battista Deti, known as the Sollo (i.e. soft); Anton Francesco Grazzini, the Lasca (a fish similar to trout); Bernardo Canigiani, the Gramolato (full of lumps); Bernardo Zanchini, the Macerato (left to soak); Bastiano de' Rossi, the Inferigno (mixed with bran, therefore impure). He was joined in 1582 by Lionardo Salviati, the man who was actually responsible for establishing the prestigious Academy and selecting the entries for the Dictionary."
					},
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's27',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 19)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-rose',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 30),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's28',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 19)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					remarkablePoints: [
						{
							id: "norma_T5_S3_1",
							caption: 'descrizione',
							textBefore: {
								it: "L’immagine rappresenta un riccio che grufola nella farina, simbolo umoristico dell’attività di selezione lessicale e di difesa della “buona lingua” con cui la Crusca si sarebbe identificata nei secoli.", 
								en: "The image depicts a hedgehog rooting in flour, a humorous symbol for the lexical selection and defence of the “good language” with which the Crusca would be identified over the centuries."
							},
							textAfter: "ch'l", 
							posX: '75%', 
							posY: '50%',
							position: 'bottom',
							customLabelStyle: 'width: 30rem;'
						},
					],
					caption: {
						it: "##Non solo parole, ma anche... pale\n\n Sulle tradizionali pale da farina, gli Accademici (soprattutto tra Cinque e Settecento, ma non solo) lasciano la loro “firma”, fatta di tre elementi: un motto, un’immagine e un soprannome.",
						en: "##Not Only Words but Also… Shovels\n\n The Academicians leave their “signature” on flour shovels – a tradition that was especially strong between the 16th and 18th centuries – consisting of three elements: a motto, an image and a nickname.",
					},
					curiosity: {
						it: "Questa tradizione, dopo essere stata abbandonata per lungo tempo, ha ritrovato slancio negli ultimi decenni con la presentazione di nuovi stemmi di Accademici contemporanei, fra cui Paolo D'Achille (Integrale), Rita Librandi (Tenace), Paola Manni (Chiara), Nicoletta Maraschio (Leggera), Claudio Marazzini (Boreale), Silvia Morgana (Ariosa), Francesco Sabatini (Ventilato), Angelo Stella (Abscondito).",
						en: "The tradition of the shovels, which was in danger of being lost after being abandoned for a long time, has been successfully revived in recent decades with the presentation of new coats-of-arms by distinguished contemporary academics, including Paolo D'Achille (Integral), Rita Librandi (Tenacious), Paola Manni (Clear), Nicoletta Maraschio (Light), Claudio Marazzini (Boreal), Silvia Morgana (Airy), Francesco Sabatini (Ventilated), Angelo Stella (Absconded).",
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 31),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's29',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 19)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					caption: {
						it: "##Una Crusca “vissuta”\n\n Il Vocabolario della Crusca rappresenta uno strumento prezioso per gli scrittori desiderosi di adeguare la propria lingua al modello fiorentino dell’Accademia. In particolare l’edizione curata dall’abate Antonio Cesari (la “Crusca veronese”) del 1806-1811 venne letta e annotata da molti scrittori, tra cui Vincenzo Monti e Niccolò Tommaseo, ma soprattutto da Alessandro Manzoni.",
						en: "##A “Dog-Eared” Crusca \n\n The _Vocabolario della Crusca_ was a valuable tool for writers wishing to adapt their language to the Crusca Academy’s Florentine model.\n In particular, the edition prepared by Abbot Antonio Cesari (known as the _Crusca veronese_) of 1806-1811 was read and annotated by many writers, including Vincenzo Monti and Niccolò Tommaseo, but especially by Alessandro Manzoni."
					},
					curiosity: {
						it: "La “Crusca veronese” suscitò vivacissime discussioni: Cesari infatti, in polemica con la «Firenze d’oggidì», linguisticamente decaduta rispetto a quella di un tempo, vi inserì molte voci trecentesche scartate dalla precedenti edizioni. Così lui, “lombardo”, rivendicava il diritto di fare ciò che i fiorentini non facevano più. Del resto, scriveva Cesari nella prefazione, quasi tutti i più grandi scrittori latini non erano nati a Roma!",
						en: "The _Crusca veronese_ provoked lively discussions.\n Cesari inserted many 14th-century entries, in polemic with the «Florence of today», linguistically decadent compared to that of the past, according to Cesari. Thus he, a “Lombard”, claimed the right to do what the Florentines no longer did. \n After all, Cesari wrote in the preface, almost all the greatest Latin writers were not born in Rome!",
					},
					remarkablePoints: [
						{
							id: "norma_T5_S4_1",
							caption: 'didascalia',
							textBefore: {
								it: "Le _Postille al Vocabolario della Crusca nell’edizione veronese_ sono state curate e pubblicate da Dante Isella nel 1964 e ristampate nel 2005, e permettono di entrare nel laboratorio di uno dei principali protagonisti della letteratura e della lingua italiana.",
								en: "The _Postille al Vocabolario della Crusca nell’edizione veronese_ \[Notes to Veronese edition of the _Crusca_ Dictionary] were edited and published by Dante Isella in 1964 and reprinted in 2005, and allow us to enter the workshop of one of the key figures in Italian literature and language."
							},
							posX: '75%', 
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 30rem;'
						},
						{
							id: "norma_T5_S4_2",
							caption: 'didascalia',
							textBefore: {
								it: "«Oh che goffo e sgrammaticato assempro!», scrive Manzoni nel margine inferiore del tomo VII, pag. 80 a proposito del participio _vinciuto_ e del relativo «glorioso assempro», manifestando con sarcasmo la sua distanza dalle posizioni arcaizzanti dei compilatori veronesi.\n La postilla sarà cancellata in un secondo momento dallo stesso autore, come molte altre, perché avvertita come troppo irrispettosa.",							
								en: "«Oh what a clumsy and ungrammatical example!» writes Manzoni in the lower margin of page 80 of volume VII about the participle _vinciuto_ \[i.e. won] and the relative «glorioso assempro» \[glorious example], sarcastically manifesting his distance from the archaising positions of the Veronese compilers. The note was later deleted by the author himself, like many others, because it was perceived as too disrespectful."
							},
							posX: '35%', 
							posY: '50%',
							position: 'top',
							wrap: true,
							customLabelStyle: 'width: 30rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 32),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's29-2',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 19)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-paper',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					caption: {
						it: "## Si usa... non si usa...\n\n E noi possiamo inventare parole sperando che finiscano nel vocabolario?\n Nel 2016 **_petaloso_**, parola creata da un bambino di terza elementare, viene portata all’attenzione della Crusca dalla sua maestra. \n La Crusca riconosce che la parola è interessante, formata da un suffisso ancora molto produttivo. Ma, aggiunge, non basta: per entrare in un vocabolario «**bisogna che la usino tante persone**».",
						en: "## It Is Used… It Is Not Used… \n\n Can we invent words hoping they will end up in the dictionary?\n In 2016, **_petaloso_** [i.e. full of petals], a word created by a third-grader, was brought to the attention of the Crusca Academy by his teacher.\n The Crusca Academy acknowledged that the word was interesting, formed using a suffix that continues to be productive.\n But, it added, this was not enough. To make it into a dictionary a word «has to be **used by a lot of people**»."
					},
					curiosity: {
						it: "La “Crusca veronese” suscitò vivacissime discussioni: Cesari infatti, in polemica con la «Firenze d’oggidì», linguisticamente decaduta rispetto a quella di un tempo, vi inserì molte voci trecentesche scartate dalla precedenti edizioni. Così lui, “lombardo”, rivendicava il diritto di fare ciò che i fiorentini non facevano più. Del resto, scriveva Cesari nella prefazione, quasi tutti i più grandi scrittori latini non erano nati a Roma!",
						en: "La “Crusca veronese” suscitò vivacissime discussioni: Cesari infatti, in polemica con la «Firenze d’oggidì», linguisticamente decaduta rispetto a quella di un tempo, vi inserì molte voci trecentesche scartate dalla precedenti edizioni. Così lui, “lombardo”, rivendicava il diritto di fare ciò che i fiorentini non facevano più. Del resto, scriveva Cesari nella prefazione, quasi tutti i più grandi scrittori latini non erano nati a Roma!",
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 75),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's2-game',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 19)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-paper',
				},
				{
					id: 's30',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 20)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: {
						it: "Monumento della letteratura italiana e dell’età risorgimentale, poeta, drammaturgo e prosatore, il milanese Alessandro Manzoni (1785-1873) è uno dei “padri” della lingua italiana, o meglio: di quella comunemente parlata, oggi, da un capo all’altro della Penisola. Una lunga e faticosa conquista ottenuta con il romanzo più importante e noto dell’Ottocento italiano: _I promessi sposi_.",
						en: "A towering figure of Italian literature and of the Risorgimento, the writer Alessandro Manzoni (1785-1873) is also one of the “fathers” of the Italian language, or, to be more precise, the language that is commonly spoken today from one end of the peninsula to the other.\n The painstaking search for a language for the novel was to coincide with the proposal of the most important and famous Italian novel: _I promessi sposi_ \[_The Betrothed_].",
					},
					template: 'left',
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'normal',
					inDepthItem: inDepthItems.items.find(el => el.id == 33),
					additionalStyleSectionContent: '',
				},
				{
					id: 's31',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 20)
					},
					steps: [{},
						{
							curiosity: {
								it: "Per un certo periodo Manzoni ebbe un dubbio e _I promessi sposi_ si chiamarono _Gli sposi promessi_.\n Successe nella cosiddetta “seconda minuta”, una fase intermedia della revisione del romanzo che porterà poi alla prima edizione del 1827.",
								en: "For a while, Manzoni was unsure about the novel’s title and _I promessi sposi_ was called _Gli sposi promessi_.\n It happened in the _seconda minuta_, an intermediate stage of the revision that would later lead to the first edition of 1827.",
							},
						}, {}, {}, {
						curiosity: {
							it: "L’espressione esatta usata da Manzoni non è “risciacquare i panni”, ma “i cenci”. Proprio così si esprime in una dedica a Emilia Luti: «questi cenci […] risciacquati in Arno». Non solo. La “risciacquatura” non è l’unica immagine usata da Manzoni. In una lettera a Tommaso Grossi Manzoni scrive: «Un’acqua come Arno, e lavandaie come Cioni e Niccolini, fuori di qui non le trovo in nessun luogo».",
							en: "The exact expression used by Manzoni is not “risciacquare i panni” \[to wash the sheets], but “i cenci”, i.e Tuscan form for “panni”.\n This is exactly how he expressed himself in a dedication to Emilia Luti: «questi cenci \[...] risciacquati in Arno».\n The image of “washing” is not the only one Manzoni uses.\n In a letter to Tommaso Grossi, Manzoni writes: «A river like the Arno, and **laundrymen** like Cioni and Niccolini, outside of here I cannot find them anywhere»."
						},
					}],
					numSteps: 5,
					sectionStyle: 'bg-blue-paper',
					template: 'left',
					remarkablePoints: [
						{
							id: "norma_T5_S2_1",
							caption: 'Descrizione',
							textBefore: {
								it: 'Una pagina della Crusca veronese di proprietà di Manzoni: nell’angolo in basso a destra, in relazione al lemma _agucchiatore_, si legge la postilla «Da verificarsi in Firenze. Risp.: non è più in uso».',
								en: "A page from the Crusca veronese owned by Manzoni. In the bottom right-hand corner, alongside the headword agucchiatore [worker sewing with a needle], there is a note «To check in Florence. Answer.: it is no longer in use»."
							},
							posX: '78%', 
							posY: '65%',
							position: 'bottom',
							customLabelStyle: 'width: 25rem;',
						}
					],
					artifact: {
						id: 16,
						pos: {
							x: 'calc(100vw - var(--menu-size) - var(--section-right-margin) - 100%)',
							y: 'calc(100vh - var(--section-bottom-margin) - 100%)'
						}
					},
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's31-2',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 20)
					},
					steps: [{}, {}, {}, {}],
					numSteps: 4,
					sectionStyle: 'bg-gold-animated',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					frames: [
						{
							title: "Fermo e Lucia",
							subtitle: {it: "1821 - 1823", en: "1821 - 1823"},
							text: "Quel ramo del lago di Como d'onde esce l'Adda e che giace fra due catene non interrotte di monti da settentrione a mezzogiorno, dopo aver formati varj seni e per così dire piccioli golfi d'ineguale grandezza, si viene tutto ad un tratto a ristringere; ivi il fluttuamento delle onde si cangia in un corso diretto e continuato di modo che dalla riva si può per dir così segnare il punto dove il lago divien fiume",
							customStyle: "color: var(--color-white); background-color: var(--color-brand-blue);",
							customStyleMobile: "color: var(--color-white); background-color: var(--color-brand-blue); --target-rot: 6deg;"
						},
						{
							title: "1827",
							subtitle: {it: "Capitolo I", en: "First Chapter"},
							text: "Quel ramo del lago di Como che volge a mezzogiorno tra due catene non interrotte di monti, tutto a seni e a golfi, a seconda dello sporgere e del rientrare di quelli, **viene** quasi a un tratto a ristringersi e a prender corso e figura di fiume, tra un promontorio a destra, e un'ampia **riviera di rincontro**; e il ponte, che ivi congiunge le due rive, par che renda ancor più sensibile all'occhio questa trasformazione, e segni il punto in cui il lago cessa, e l'Adda **ricomincia**, per ripigliar poi nome di lago dove le rive, allontanandosi di nuovo, **lasciano** l'acqua distendersi e **allentarsi** in nuovi golfi e in nuovi seni.",
							customStyle: "color: var(--color-black-text); background: var(--color-red);",
							customStyleMobile: "color: var(--color-black-text); background: var(--color-red); --target-rot: -6deg;",
						},
						{
							title: "1840",
							subtitle: {it: "Capitolo I", en: "First Chapter"},
							text: "Quel ramo del lago di Como,  che volge a mezzogiorno, tra due catene non interrotte di monti, tutto a seni e a golfi, a seconda dello sporgere e del rientrare di quelli, **vien** quasi a un tratto, a ristringersi, e a prender corso e figura di fiume, tra un promontorio a destra, e un'ampia **costiera dall'altra parte**; e il ponte, che ivi congiunge le due rive, par che renda ancor più sensibile all'occhio questa trasformazione, e segni il punto in cui il lago cessa, e l'Adda **rincomincia**, per ripigliar poi nome di lago dove le rive, allontanandosi di nuovo, **lascian** l'acqua distendersi e **rallentarsi** in nuovi golfi e in nuovi seni.",
							customStyle: "color: var(--color-black-text); background: var(--color-gold);",
							customStyleMobile: "color: var(--color-black-text); background: var(--color-gold); --target-rot: 6deg;"
						}
					],
					remarkablePoints: [
						{
							id: "norma_T5_S3_1",
							caption: ' ',
							textBefore: {
								it: `Nel passaggio dal _Fermo e Lucia_ alla Ventisettana, e da questa alla Quarantana: qualche parola perde la vocale finale (apocope), es. _vien_;  
c’è una semplificazione nel lessico, es. _riviera di rincontro_ > _costiera dall’altra parte_;  
c’è una maggiore ricerca di sintesi, es. i_vi il fluttuamento delle onde si cangia in un corso diretto e continuato di modo che dalla riva si può per dir così segnare il punto dove il lago divien fiume_ > _vien \[...] a prender corso e figura di fiume_.`,
								en: `In the transition from _Fermo e Lucia_ to _Ventisettana_, and from this to _Quarantana_ some words lose their final vowel > vien \[instead of _viene_]
the text undergoes lexical simplification > _riviera di rincontro_ > _costiera dall’altra parte_ \[shore on the other]
there is a greater search for synthesis, e.g.
_ivi il fluttuamento delle onde si cangia in un corso diretto e continuato di modo che dalla riva si può per dir così segnare il punto dove il lago divien fiume_ > _vien \[...] a prender corso e figura di fiume_ \[narrows …. into the form of a river]`
							},
							posX: '12%', 
							posY: '25%',
							position: 'right',
							customLabelStyle: 'width: 28rem;',
						}
					],
				},
				{
					id: 's32',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 20)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## «la vanguardia d’un esercito già formato»\n\n Gli scritti linguistici di Manzoni costituiscono il risvolto teorico del lungo e complesso lavoro di revisione sui _Promessi sposi_.\n Nell’insieme, ipotizzano una rifondazione radicale della lingua italiana basata sull’adozione del fiorentino contemporaneo come lingua nazionale per la comunicazione.",
						en: "## «the vanguard of an already well trained army»\n\n Manzoni's linguistic writings form the theoretical side of the long and complex revision work on _The Betrothed_.\n As a whole, they look to a radical refoundation of the Italian language based on the adoption of contemporary Florentine as the national language of communication."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 66),
				},
				{
					id: 's33',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 20)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Strepitoso successo in Europa\n\n Fin dalla Ventisettana, numerose furono le **traduzioni** dei _Promessi sposi_ in tutto il continente.\n In Francia, Germania, Spagna, Inghilterra escono trasposizioni più o meno fedeli, più o meno tagliate o riadattate.\n In ogni caso, un chiaro segno della grande importanza di Manzoni anche a livello europeo.",
						en: "## A Huge Success in Europe\n\n From the _Ventisettana_ edition onwards, there have been numerous translations of _The Betrothed_ throughout Europe.\n Translations that were more or less faithful, more or less cut or readjusted were published in France, Germany, Spain and England.\n In any case, it was a clear sign of Manzoni's great importance also at a European level."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 67),
					curiosity: {
						it: "Meno positivo il fenomeno delle **edizioni pirata**.\n In una lettera al cugino Giacomo Beccaria, Manzoni stesso fa una stima del **danno**: «Della prima edizione posso credere siano state fatte quaranta edizioni, delle quali una da me, di mille esemplari; le altre posso credere che abbiano sommato a 59000; il che vuol dire ch’io non ho avuto che la sessantesima parte dei compratori».",
						en: "Less positive is the phenomenon of **pirate editions**.\n In a letter, Manzoni himself makes an estimate of the **damage**: «Of the first edition I have reason to believe that forty editions were made, of which one was made by me, of one thousand copies; the others I may believe added up to 59,000; which means that I had only a sixtieth part of the buyers»."
					}
				},
				{
					id: 's34',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 20)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## «di Manzoni ce ne sono molti»\n\n La fortuna di Manzoni è dimostrata dall’immediata diffusione del romanzo e dalla sua “consacrazione”, scolastica e non solo.\n Ma proprio il suo essere patrimonio comune nell’immaginario di moltissimi lettori ha fatto sì che si potesse tentare di sottrarlo a quell’atmosfera «di rispetto e noia» (Natalia Ginzburg), parodiandolo e, perché no?, riscoprendolo.",
						en: "## «There are many Manzonis»\n\n Manzoni's fortune can be seen in the novel's immediate circulation and its importance, both in schools and elsewhere.\n But it is precisely because he has become part of the collective imagination of so many readers that has led to attempts to remove him from that atmosphere «of respect and boredom» (Natalia Ginzburg), by parodying him and, indeed, rediscovering him."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 68),
				},
				{
					id: 's35',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 29)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-heading',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "La norma scolastica è una norma «sommersa», come dice lo studioso Luca Serianni.\n Non appare in superficie, come accade per l'uso reale della lingua, parlata o scritta.\n Eppure il suo impatto è altrettanto importante e decisivo.\n Per moltissime persone ha assicurato un contatto con una certa forma di lingua scritta.",
						en: "The school norm is a “submerged” norm, as scholar Luca Serianni puts it.\n It does not appear on the surface, as happens with the language that is actually used, be it spoken or written.\n Yet its impact is just as important and decisive.\n For many people, it has provided contact with a certain form of written language."
					},
				},
				{
					id: 's36',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 29)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-paper',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Si scrive... non si scrive?\n\n Un maestro di scuola, forse del V secolo d.C., elenca alcune forme latine “errate” riportando a fianco quelle “corrette”.\n Dunque: _aqua_ non _acqua_, _calida_ non _calda_, _columna_ non _colomna_...\n Le parole che usiamo oggi derivano da quelle ritenute sbagliate: perché più della grammatica vale l'uso.",
						en: "## Can I Write It?… Or Not?\n\n A teacher, possibly from the 5th century AD, lists some “wrong” Latin forms with the “correct” ones alongside.\n Thus _aqua_ not _acqua_, _calida_ not _calda_, _columna_ not _colomna_....\n The words we use today derive from those that are considered wrong.\n Because usage matters more than grammar."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 69),
				},
				{
					id: 's37',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 29)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-rose',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Quale italiano?\n\n Con la nascita del Regno d’Italia (1861) emerge una vera e propria “Questione della lingua a scuola”.\n Al momento dell’Unità solo una piccola percentuale di italiani parla e scrive correttamente l’italiano. Nella vita di tutti i giorni si comunica in dialetto.\n Dunque, **quale tipo di italiano insegnare a scuola?**",
						en: "## What Kind of Italian?\n\n Following the birth of the Kingdom of Italy (1861), a real “language issue at school” emerged.\n At the time of Italy’s unification, only a small percentage of Italians spoke and wrote Italian correctly. People communicated in dialect in everyday life.\n So **what kind of Italian was to be taught at school?**"
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 70),
					curiosity: {
						it: "Noto per _Cuore_ (1886), forse il romanzo più famoso sulla scuola italiana, Edmondo De Amicis scrisse anche quello che si può considerare il primo manuale sull’uso dell’italiano: _L’idioma gentile_ (1905).\n Per molte generazioni un vero punto di riferimento per l’uso corretto della lingua, l’origine delle parole e dei modi di dire, gli errori da evitare.",
						en: "Known for _Cuore_ \[Heart] (1886), perhaps the most famous novel on schooling in Italy, Edmondo De Amicis also wrote what can be considered the first manual on Italian usage, _L'idioma gentile_ \[The gentle idiom] (1905).\n It was for many generations a reference work for the correct usage of Italian, on the origin of words and idioms, and on the mistakes to avoid."
					}
				},
				{
					id: 's38',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 29)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## A scuola con Checchina\n\n Nel 1876 a Sulmona, nella profonda provincia dell’Italia appena unita, una bambina abruzzese frequenta la scuola elementare.\n I suoi quaderni sono preziosissime testimonianze degli usi scolastici di quel tempo lontano.\n Checchina scrive _aio_, non “maestro”, _angue_, non “serpente”.\n Insomma, un italiano che non parla.",
						en: "## In Class with Checchina\n\n In 1876 in Sulmona, in the provincial heartland of newly united Italy, a little girl from Abruzzo attended primary school.\n Her notebooks are precious testimonies of schooling traditions from that distant time.\n Checchina writes _aio_, not _maestro_ \[master], _angue_, not _serpente_ \[snake].\n That is to say an Italian that she does not speak."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 71),
					curiosity: {
						it: "Non sono eufemismi, non devono rendere accettabile (_eu_ ‘bene’ in greco) un concetto sgradevole.\n Devono renderlo più “bello” (_kalòs_ ‘bello’ in greco), secondo un’idea un po’ distorta di “bello scrivere“ spesso presente nella scuola: sono i “calofemismi”.\n\n _Sono andato_ > _andai_ \[uso del passato remoto al posto del passato prossimo]\n\n _Sento un rumore_ > _odo un rumore_\n\n _Vado_ > _mi reco_\n\n _È passato_ > _è trascorso_\n\n _Fare i compiti_ > _svolgere i compiti_",
						en: "They are not euphemisms, they are not meant to make an unpleasant concept or idea acceptable (_eu_ ‘good’ in Greek).\n They are calophemisms: they must make it more “beautiful” (_kalòs_ ‘beautiful’ in Greek) according to a somewhat distorted idea of ‘beautiful writing’, which often exists in schools.\n\n _Sono andato_ > _andai_ \[use of _passato remoto_ verb form instead of _passato prossimo_ verb form]\n\n _Sento un rumore_ > _odo un rumore_ \[I hear a noise]\n\n _Vado_ > _mi reco_ \[I go].\n\n _È passato_ > _è trascorso_ \[it passed]\n\n _Fare i compiti_ > _svolgere i compiti_ \[to do homework]"
					}
				},
				{
					id: 's39',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 29)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-extra-light-gold',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Il lungo viaggio verso l'alfabetizzazione\n\n Grazie alla scuola si è passati dal 78% di analfabeti del 1861, anno di nascita del Regno d’Italia, al 56% del 1901, al 13% dell’immediato dopoguerra, fino ad arrivare ai livelli minimi dei nostri giorni.",
						en: "## The Long Journey towards Literacy\n\n Illiteracy levels in Italy have fallen from 78% in 1861, the year of the birth of the Kingdom of Italy, to 56% in 1901, to 13% in the immediate post-war period, to a record-low today.\n All this thanks to schools, of course, but not just ..."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 72),
				},
				{
					id: 's40',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 29)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Pierini vs Gianni\n\n A Barbiana, un minuscolo borgo contadino vicino a Firenze, don Lorenzo Milani e i ragazzi della sua scuola scrivono _Lettera a una professoressa_ (1967).\n Si tratta di un radicale atto d’accusa contro la scuola tradizionale pensata per i ricchi Pierini, e non per i tanti Gianni, scolari poveri e meno attrezzati.",
						en: "## Pierini vs Gianni\n\n In Barbiana, a tiny farming village near Florence, “Don” Lorenzo Milani and the boys in his school wrote _Lettera a una professoressa_ \[Letter to a Teacher] (1967).\n It is a radical indictment of the traditional school designed for the rich Pierini, and not for the many Gianni, the poor and less well-equipped schoolchildren."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 73),
				},
				{
					id: 's41',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 29)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
					caption: {
						it: "## Nuovi italiani per un nuovo italiano\n\n Da paese di emigranti l’Italia è diventata meta di emigranti.\n Da sempre la lingua è requisito e strumento di **cittadinanza**.\n L'insegnamento dell'italiano come lingua seconda, ora così diffuso, è un’occasione per felici incontri e contaminazioni di lingue e civiltà di tutto il mondo.",
						en: "## New Italians for a New Italian Language\n\n From being a country of emigrants, Italy has become a destination for emigrants.\n Language has always been a requirement and a means of acquiring **citizenship**.\n The teaching of Italian as a second language, now so widespread, is an opportunity for happy encounters and the mixing of languages and civilisations from all over the world."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 74),
				},
				{
					id: 's4-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 29)
					},
					steps: [{}],
					numSteps: 1,
				},
				{
					id: 's42',
					name: 'bibliography',
					stage: {
						...stagesData.stagesData.find(el => el.id == 21)
					},
					sectionStyle: 'bg-extra-light-gold',
				}
			],
		},
		// DIALETTI
		{
			id: 4,
			nameCode: 'quali-lingue-parlano-italia',
			name: {
				it: "Quali lingue si parlano in Italia?",
				en: 'Which Languages Are Spoken in Italy?'
			},
			nameArray: {
				it: ['quali', 'lingue', 'si parlano', 'in Italia?'],
				en: ["Which", "Languages", "Are Spoken", "in Italy?"],
			},
			description: {
				it: `In Italia convivono molte lingue. Ancora oggi, nel nuovo Millennio, in Italia si parlano (si scrivono, si ascoltano) anche i dialetti e le lingue di minoranza. Ognuno di questi idiomi ha una propria grammatica e un proprio lessico, vanta una tradizione millenaria e ha un’eccezionale ricchezza storica, culturale ed espressiva.`,
				en: 'Many languages live together in Italy. Even today, in the 21st century, dialects and other languages are also spoken, written and heard in Italy.\n Each of these idioms has its own grammar and lexicon. Each can boast a thousand-year-old tradition and an exceptional historical, cultural and expressive richness.',
			},
			introImages: [
				require('@assets/images/percorsi/dialetti/s0_zerocalcare' + utils.manageImagesExtension('.png')),
				require('@assets/images/percorsi/dialetti/s0_postino' + utils.manageImagesExtension('.png')),
				require('@assets/images/percorsi/dialetti/s1_09_low' + utils.manageImagesExtension('.png')),
			],
			sections: [
				{
					id: 's1',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 8)
					},
					isFixed: true,
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-intro',
					
					template: 'left',
				},
				{
					id: 's1-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `Una grande varietà di popoli abitò l’Italia antica, prima dell’espansione di Roma del III sec. a.C.
						Questi popoli parlavano le cosiddette “lingue preromane”, che anche sotto Roma non sparirono del tutto: nel latino usato soprattutto nell’oralità nelle varie zone d’Italia sopravvissero alcuni elementi delle antiche lingue locali.`,
						en: `A great variety of peoples inhabited ancient Italy before the expansion of Rome in the 3rd century BC.
						These peoples spoke what are known as “pre-Roman languages”, which even under Rome did not completely disappear. Some traces of these ancient local languages survived mainly in the Latin spoken in the various parts of Italy.`,
					},
					steps: [{}, {}, {
						quote: {
							it: `«Furono il prestigio della città e la superiorità della lingua che da essa si diffondeva a indurre sempre più numerose fasce della popolazione \[…] ad assimilarsi ai Romani anche linguisticamente»`,
							en: `It was the prestige of the urban centre and the superiority of the language that spread from it that led more and more sections of the population […] to assimilate with the Romans also linguistically`
						},
						author: { it: 'Alberto Varvaro\n_Il latino e la formazione delle lingue romanze_\n2014', en: 'Alberto Varvaro\n_Il latino e la formazione delle lingue romanze_\n2014' },
					}],
					numSteps: 3,
					sizeContentText: 'normal',
					sectionStyle: 'bg-blue-heading',
					template: 'left',
				},
				{
					id: 's1-01',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `## I popoli dell’Italia antica
Alcuni sono noti come “popoli italici”, perché le loro lingue appartenevano alla grande famiglia indoeuropea: i Latino-Falisci, attestati già dal VII secolo a.C., e gli Osco-Umbri, presenti nel centro Italia dal VI-V secolo a.C. 
Altri (Veneti, Celti, Greci delle colonie) parlavano lingue indoeuropee non italiche, mentre Etruschi, Liguri, Sicani e Sardi parlavano lingue non indoeuropee.`,
						en: `##The Peoples of Ancient Italy
Some are known as “Italic peoples”, because their languages belonged to the large Indo-European family: the Latino-Falisci, attested as early as the 7th century BC, and the Osco-Umbrians, who inhabited central Italy from the 6th-5th centuries BC. 
Others (Veneti, Celts, Greeks from the colonies) spoke non-Italic Indo-European languages, while Etruscans, Ligures, Sicans and Sardinians spoke non-Indo-European languages.
`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-map',

					sizeContentText: 'normal',
					remarkablePoints: [
						{ id: 'Sicani', caption: {it: 'Sicani', en: 'Sicans' }},
						{ id: 'Siculi', caption: {it: 'Siculi', en: 'Sicels' }},
						{ id: 'Sardi', caption: {it: 'Sardi', en: 'Sardinians' }},
						{ id: 'Lucani', caption: {it: 'Lucani', en: 'Lucans' }},
						{ id: 'Bruzi', caption: {it: 'Bruzi', en: 'Bruzi' }},
						{ id: 'Osci', caption: {it: 'Osci', en: 'Oscans' }},
						{ id: 'Apuli', caption: {it: 'Apuli', en: 'Apuli' }},
						{ id: 'Sabini', caption: {it: 'Sabini', en: 'Sabini' }},
						{ id: 'Sanniti', caption: {it: 'Sanniti', en: 'Samnites' }},
						{ id: 'Equi', caption: {it: 'Equi', en: 'Equi' }},
						{ id: 'Umbri', caption: {it: 'Umbri', en: 'Umbrians' }},
						{ id: 'Latini', caption: {it: 'Latini', en: 'Latins' }},
						{ id: 'Piceni', caption: {it: 'Piceni', en: 'Piceni' }},
						{ id: 'Etruschi', caption: {it: 'Etruschi', en: 'Etruscans' }},
						{ id: 'Veneti', caption: {it: 'Veneti', en: 'Veneti' }},
						{ id: 'Camuni', caption: {it: 'Camuni', en: 'Camuni' }},
						{ id: 'Liguri', caption: {it: 'Liguri', en: 'Ligures'}, posY: '29.3%' },
						{ id: 'Celti', caption: {it: 'Celti', en: 'Celts'} },
						{
							id: 'Coloni_greci_e_fenici',
							posX: '93%', posY: '62%',
							caption: {it: 'Coloni greci e fenici', en: 'Greek and Phoenician colonists'},
							customLabelStyle: 'min-width: 7em; text-align: center',
						},
					],

					template: 'left',
				},
				{
					id: 's1-03',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `## Una lingua misteriosa
Gli Etruschi hanno abitato, fra il IX e il I secolo a.C., la zona chiamata Etruria, che comprendeva la Toscana, l’Umbria occidentale, parte del Lazio e della Pianura Padana. 
Esiste un’ampia documentazione della lingua etrusca, che tuttavia resta ancora intraducibile per la maggior parte.`,
						en: `## A Mysterius languages
The Etruscans inhabited the area called Etruria, which included Tuscany, western Umbria, parts of Latium and the Po Valley, between the 9th and 1st centuries BC. 
There is ample documentation of the Etruscan language, most of which, however, is still untranslatable.`
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',

					template: 'left',
					remarkablePoints: [
						{
							posX: '55%',
							posY: '30%',
							caption: 'Descrizione',
							textBefore: {
								it: 'Il più importante testo in lingua etrusca venne ritrovato intorno a una mummia egiziana. Si tratta di un lungo testo etrusco scritto tra il III e il II secolo a.C. su un tessuto di lino portato in Egitto da alcuni etruschi che lasciarono l’Etruria in seguito all’espansione di Roma. Questo “libro” di lino che contiene 1300 parole fu tagliato e usato per fasciare la mummia di una donna egiziana, oggi conservata nel Museo Archeologico di Zagabria. Le ricerche fatte nel corso del Novecento hanno dimostrato che il testo è un calendario di riti e sacrifici per le divinità.',
								en: `The most important text in the Etruscan language was found wrapped around an Egyptian mummy. It is a long Etruscan text written between the 3rd and 2nd century BC on a linen cloth brought to Egypt by some Etruscans who left Etruria following the expansion of Rome. This linen “book” containing 1300 words was cut up into strips and used to wrap the mummy of an Egyptian woman, now preserved in the Archaeological Museum in Zagreb. Research during the 20th century has shown that the text is a ritual calendar relating to sacrifices to deities.`
							},
							customLabelStyle: 'width: 30.6em',
						},
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 401),
					buttonPosition: 'left',
				},
				{
					id: 's1-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `## Sulle sponde dei grandi laghi alpini
Dal VI secolo a.C. gruppi parlanti una lingua celtica, il cosiddetto leponzio, si stabilirono tra Lugano, il lago Maggiore e il lago di Como.
Oggi il leponzio è attestato da circa 140 iscrizioni.
Si tratta di iscrizioni in un alfabeto con caratteristiche fortemente celtiche, il cosiddetto “alfabeto di Lugano”.`,
						en: `## On the Banks of the Great Alpine Lakes
From the 6th century BC, groups speaking a Celtic language known as Lepontic settled between Lugano, Lake Maggiore and Lake Como. 
Today, Lepontic is attested by around 140 inscriptions. 
These inscriptions are in an alphabet with strongly Celtic characteristics, the so-called “Lugano alphabet”.
`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-cement',
					curiosity: {
						it: 'Le prime attestazioni risalgono al VI secolo, quindi a un’epoca precedente l’invasione dei Galli. A partire dal IV secolo, la presenza di iscrizioni galliche in alfabeto leponzio mostra la graduale fusione della cultura più antica con quella dei nuovi arrivati. Dopo la conquista dei territori gallici da parte dei Romani, le tracce del leponzio si faranno sempre più rare, fino a sparire del tutto.',
						en: 'The first attestations date back to the 6th century, thus to a time before the invasion of the Gauls. From the 4th century onwards, the presence of Gallic inscriptions in the Lepontic alphabet shows the gradual merging of the older culture with that of the newcomers. After the conquest of the Gallic territories by the Romans, traces of Lepontic would become increasingly rare, until they disappeared altogether.',
					},
				},
				{
					id: 's1-05',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `## A est il venetico…
Fin dal X secolo a.C. nell’Italia del Nord si formarono insediamenti nella regione veneta, in particolare a Este e Padova.
Queste popolazioni parlavano il venetico, testimoniato da eccezionali ritrovamenti che hanno permesso di ricostruire la cultura materiale dei Veneti.
Dopo la seconda metà del IV secolo il territorio fu invaso dai Celti, che occuparono gran parte della Pianura Padana.`,
						en: `## Venetic on the East...
As early as the 10th century BC, settlements were formed in northern Italy in the Veneto region, particularly in Este and Padua.
These peoples spoke Venetic, as attested by exceptional finds that have allowed the material culture of the Veneti to be reconstructed. 
In the second half of the 4th century, the territory was invaded by the Celts, who occupied a large part of the Po Valley.
`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					remarkablePoints: [
						{
							posX: '76%',
							posY: '28%',
							caption: 'Descrizione',
							textBefore: {
								it: 'La tavoletta appartiene a una serie di ex voto recuperati all’interno del santuario della dea Reitia a Este. Si tratta di una tavoletta alfabetica di bronzo realizzata sul modello di un prontuario per l’apprendimento della scrittura, come mostra l’esercizio di riproduzione delle lettere (vocali, consonanti e gruppi di consonanti).\n\nIl suo curioso impiego come ex voto è dovuto al fatto che proprio i santuari erano sedi di scuole scrittorie, guidate da membri della classe sacerdotale.',
								en: 'This tablet belongs to a series of ex-votos found in a sanctuary devoted to the goddess Reitia at Este. It is a bronze alphabetical tablet modelled on a shrine for learning to write, as shown by the exercise related to reproducing letters (vowels, consonants and groups of consonants).\n Its curious use as an ex-voto is due to the fact that sanctuaries were seats of writing schools, run by members of the priestly class.'
							},
							customLabelStyle: 'width: 30.6em',
						},
					]
				},
				{
					id: 's1-06',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `## Tra le Alpi Marittime e l’Appennino Ligure
I Liguri sono la più antica popolazione presente nella penisola italiana (fin dal V millennio a.C.).
Non hanno lasciato testimonianze dirette, ma vengono citati da molte fonti indirette, romane e greche.
Gli studiosi ipotizzano che questo popolo non abbia sviluppato una vera civiltà urbana, ma che abbia fondato la propria economia su attività primitive come la caccia e la raccolta.`,
						en: `## Between the Maritime Alps and the Ligurian Apennines
The Ligures are the oldest people known to have inhabited the Italian peninsula (since the 5th millennium BC). 
They left no direct evidence, but are mentioned in many indirect Roman and Greek sources. 
Scholars assume that this people did not develop a true urban civilisation, but based their society on primitive activities such as hunting and gathering.
`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					remarkablePoints: [
						{
							posX: '74%',
							posY: '36%',
							caption: 'Descrizione',
							textBefore: {
								it: 'La “tavola di Polcevera”, ritrovata nel greto del torrente Pernecco, sembra risalire alla fine del II secolo, ma mostra indizi che rimandano a un’epoca precedente.\n\nIl testo è un documento giuridico in latino (la _Sententia Minuciorum_) che riporta la sentenza pronunciata da due magistrati romani in merito alla disputa fra due tribù liguri su una questione di confini. È un documento molto prezioso, perché contiene vari riferimenti alle pratiche sociali ed economiche del popolo ligure.',
								en: `The “Polcevera Tablet”, found in the bed of the Pernecco river, seems to date back to the end of the 2nd century, but evidence suggests it belongs to an earlier period.\n The text is a legal document in Latin (the _Sententia Minuciorum_) that contains a judgment pronounced by two Roman magistrates regarding a dispute between two Ligurian tribes over a border issue. It is a very valuable document because it contains various references to the social and economic practices of the Ligures.`
							},
							customLabelStyle: 'width: 30.6em',
						},
					]
				},
				{
					id: 's1-07',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `## Verso il centro-sud: l’umbro
In età preromana l’Italia centro-meridionale era occupata da popolazioni italiche che parlavano lingue dei gruppi latino-falisco e osco-umbro.
Il primo gruppo comprendeva il latino e il falisco (una lingua simile al latino).
L’umbro era parlato dagli Umbri, in un’area corrispondente a parte delle attuali Marche, Umbria e Romagna.`,
						en: `## Umbrian towards Central-South Italy 
In pre-Roman times, central-southern Italy was occupied by Italic peoples who spoke languages belonging to the Latin-Faliscan and Oscan-Umbrian groups.
The first group included Latin and Faliscan (a language similar to Latin).
Umbrian was spoken by the Umbrians in an area corresponding to part of today's Marche, Umbria and Romagna.
`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-paper',
					remarkablePoints: [
						{
							posX: '88%',
							posY: '35%',
							caption: 'Descrizione',
							textBefore: {
								it: 'Le Tavole Eugubine (o Iguvine, dall’antico nome di Gubbio, _Iguvium_) sono state trovate a Gubbio verso la metà del Quattrocento e costituiscono un reperto di inestimabile valore. Si tratta di sette lastre di bronzo scritte in lingua umbra tra il III e il I secolo a.C. sia in alfabeto etrusco sia in quello latino. Questo reperto costituisce una testimonianza preziosissima della vita sociale e religiosa del popolo umbro.',
								en: 'The Eugubian Tablets (or Iguvine, from the ancient name of Gubbio, _Iguvium_) were found in Gubbio in the mid-15th century and are a priceless find. They consist of seven bronze tablets written in the Umbrian language between the 3rd and 1st century BC in both the Etruscan and Latin alphabets. This find constitutes an invaluable testimony to the social and religious life of the Umbrian people.'
							},
							customLabelStyle: 'width: 30.6em',
						},
					]
				},
				{
					id: 's1-08',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `## Più a sud: l’osco
Sempre del ramo italico della famiglia indoeuropea, nell’Italia meridionale l’osco era parlato dal popolo degli Oschi (assorbito dal V sec. dai Sanniti) nei territori corrispondenti alle attuali Campania, Basilicata e Calabria.
L’osco presenta declinazioni e modi verbali affini al latino.`,
						en: `## Further South: Oscan
Also belonging to the Italic branch of the Indo-European family, in southern Italy, Oscan was spoken by the Oscan people (absorbed by the Samnites from the 5th century BC) in the territories corresponding to present-day Campania, Basilicata and Calabria.
Oscan has declensions and verbal modes similar to Latin.`
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					remarkablePoints: [
						{
							posX: '88%',
							posY: '35%',
							caption: 'Descrizione',
							textBefore: {
								it: 'Quest’iscrizione rupestre si trova su una parete di roccia a picco sul mare, all’estremità della penisola sorrentina. Qui, nell’antichità, si trovava un santuario della dea Atena.\n\nIl testo fa riferimento a tre “magistrati di Minerva” che avevano fatto realizzare una scala di accesso al tempio. Dato che Minerva è la divinità italica corrispondente ad Atena, il testo conferma che, col passaggio dalla dominazione greca a quella osca, il tempio aveva mantenuto la consacrazione alla stessa divinità.',
								en: 'This rock inscription is located on a cliff face overlooking the sea at the tip of the Sorrento peninsula. Here, in antiquity, stood a sanctuary devoted to the goddess Athena.\n The text refers to three “magistrates of Minerva” who had a staircase built to access the temple. Since Minerva is the Italic deity corresponding to Athena, the text confirms that, with the transition from Greek to Oscan domination, the temple continued to be consecrated to the same deity.'
							},
							customLabelStyle: 'width: 30.6em',
						},
					]
				},
				{
					id: 's1-09',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 401)
					},
					caption: {
						it: `## Per finire in Sicilia
Nell’isola, oltre al greco delle colonie, c’erano due lingue locali. 
Nella parte occidentale il sicano, di probabile origine non indoeuropea, e nella parte orientale, di sicura origine indoeuropea, il siculo.
Queste due lingue, soprattutto il siculo, hanno avuto grande influenza sui moderni dialetti siciliani.`,
						en: `## Ending in Sicily
On the island of Sicily, in addition to the Greek of the colonies, there were two local languages. 
In the western part, Sican, of probable non-Indo-European origin, and in the eastern part, of certain Indo-European origin, Sicel.
These two languages, especially Sicel, have greatly influenced modern Sicilian dialects.
`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					remarkablePoints: [
						{
							posX: '80%',
							posY: '28%',
							caption: 'Descrizione',
							textBefore: {
								it: 'L’iscrizione di Centuripe è la più lunga attestazione della [lingua sicula](https://it.wikipedia.org/wiki/Lingua_sicula). È un’incisione in alfabeto greco su un _askòs_ (un vaso che conteneva bevande), messo nella tomba di un defunto dai suoi parenti a Centuripe, città di origine sicula. Il testo dell’iscrizione, composto tra la fine del V secolo a.C. e gli inizi del [IV secolo a.C.](https://it.wikipedia.org/wiki/IV_secolo_a.C.), è compilato secondo il consueto formulario con cui si augura al defunto di poter continuare a bere un ottimo vino.',
								en: 'The Centuripe inscription is the longest surviving attestation of the Sicel language. It is an inscription in the Greek alphabet on an _askòs_ (a wine-jug), placed in the tomb of a deceased person by his relatives in Centuripe, a town of Sicel origin. The text of the inscription, composed between the end of the 5th century B.C. and the beginning of the 4th century B.C., is compiled according to the customary formula in which the deceased is wished to continue drinking excellent wine.'
							},
							customLabelStyle: 'width: 30.6em',
						},
					],
					curiosity: {
						it: 'Probabilmente la prima volta che nel mondo si scrisse la parola _vino_ la si scrisse a Centuripe, nella celebre iscrizione in siculo. Ma anche altre parole sono facilmente riconoscibili: il vocativo latino in -e (*Nane*), *durom* (*donum*, dono), *eredes* (*heredes*, eredi).',
						en: 'Probably the first time the word _wine_ was written in the world was in Centuripe, in the famous inscription in Sicel. But other words are also easily recognisable: the Latin vocative in -_e_ (Nane), _durom (donum_, gift), _eredes (heredes_, heirs).',
					}
				},
				{
					id: 's1-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 401)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's2-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 402)
					},
					caption: {
						it: `Tutti i dialetti italiani sono lingue con una propria grammatica e un proprio lessico. Non sono forme di italiano parlato o scritto male.
Come l’italiano, quelli che oggi chiamiamo dialetti sono lingue romanze o neolatine, cioè derivate dal latino parlato nelle diverse aree dell’Impero Romano.`,
						en: `All Italian dialects are languages in their own right, with their own grammar and vocabulary. They are not misspoken or misspelled forms of Italian.
Like Italian, what we now call dialects are Romance or Neo-Latin languages, i.e. derived from the Latin spoken in the different areas of the Roman Empire.
`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 407),
					buttonPosition: 'left'
				},
				{
					id: 's2-02',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 402)
					},
					caption: {
						it: `## Non solo dialetti…

Oltre all'italiano e ai dialetti, in Italia si parlano anche altre lingue.
La legge 482 del 15 dicembre 1999 (Norme di tutela delle minoranze linguistiche storiche) riconosce e tutela la lingua e la cultura di dodici «minoranze linguistiche storiche»: francoprovenzale, occitana, francese, ladina, friulana, tedesca, slovena, croata, catalana, sarda, albanese, greca.`,
						en: `## Not only Dialects...
In addition to Italian and dialects, other languages are also spoken in Italy. 
Law 482 of 15 December 1999 (Rules for the protection of historical linguistic minorities) recognises and protects the language and culture of twelve «historical linguistic minorities»: Franco-Provençal, Occitan, French, Ladin, Friulian, German, Slovenian, Croatian, Catalan, Sardinian, Albanian and Greek.
`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-map',

					template: 'left',
					curiosity: {
						it: 'C’è una parola romagnola ormai entrata anche nel dizionario italiano e molto nota: _amarcord_.\nVuol dire ‘mi ricordo’ e indica una rievocazione del passato un po’ nostalgica.\nIl romagnolo ha ispirato grandi poeti come Tonino Guerra e grandi registi come Federico Fellini – l’autore del film _Amarcord_ appunto.',
						en: '',
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 408),
					buttonPosition: 'left',
					remarkablePoints: [
						{ map: 'map-a', caption: {it: 'Area meridionale estrema', en: 'Extreme Southernn Area'}, id: 'meri_estr', posX: '81%', posY: '82%' },
						{ map: 'map-a', caption: {it: 'Dialetti sassaresi e galluresi', en: 'Sassari and Galluria dialects'}, id: 'Sassaresi_e_Galluresi' },
						{ map: 'map-a', caption: {it: 'Dialetti logudoresi-campidanesi', en: 'Logudorese-Campidanese dialects'}, id: 'Sarde' },
						{ map: 'map-a', caption: {it: 'Area alto meridionale', en: 'Upper Southern Area'}, id: 'Area_alto_meridionale'},
						{ map: 'map-a', caption: {it: 'Area mediana', en: 'Middle Area'}, id: 'Area_mediana'},
						{ map: 'map-a', caption: {it: 'Area centrale', en: 'Central Area'}, id: 'Area_centrale'},
						{ map: 'map-a', caption: {it: 'Dialetti romagnoli gallo-piceni', en: 'Gallo-Picene dialects of Romagna'}, id: 'Romagnole_gallo-picene', posX: '74.5%', posY: '40%' },
						{ map: 'map-a', caption: {it: 'Coesistenza di dialetti emiliani e liguri', en: 'Coexistence of Emilian and Ligurian dialects'}, id: 'Coesistenza_di_Emiliane_e_Liguri' },
						{ map: 'map-a', caption: {it: 'Dialetti liguri', en: 'Ligurian dialects'}, id: 'Liguri' },
						{ map: 'map-a', caption: {it: 'Dialetti emiliano-romagnoli', en: 'Emilia-Romagna dialects'}, id: 'Emiliano_-_romagnole' },
						{ map: 'map-a', caption: {it: 'Dialetti emiliani dell\'Oltrepò', en: 'Oltrepò Emilian dialects'}, id: 'oltrepo' },
						{ map: 'map-a', caption: {it: 'Dialetti piemontesi', en: 'Piedmont dialects'}, id: 'Piemontesi' },
						{ map: 'map-a', caption: {it: 'Dialetti veneti', en: 'Veneto dialects'}, id: 'Venete' },
						{ map: 'map-a', caption: {it: 'Dialetti lombardi', en: 'Lombard dialects'}, id: 'Lombarde' },
						{ map: 'map-a', caption: {it: 'Dialetti trentini (veneti e lombardi)', en: 'Trentino dialects (Veneto and Lombardy)'}, id: 'Trentine' },
						{ map: 'map-a', caption: {it: 'Area Friulana', en: 'Friulan Area' }, id: 'Area_Friulana'},
						{ map: 'map-a', caption: {it: 'Coesistenza di dialetti veneti e ladini', en: 'Coexistence of Veneto and Ladin dialects'}, id: 'Coesistenza_di_Venete_e_Ladine' },
						{ map: 'map-a', caption: {it: 'Coesistenza di dialetti lombardi e ladini', en: 'Coexistence of Lombard and Ladin dialects'}, id: 'Coesistenza_di_Lombarde_e_Ladine' },

						{ map: 'map-b', caption: {it: 'Minoranze linguistiche gallo-italiche di Basilicata', en: 'Gallo-Italic linguistic minorities of Basilicata'}, id: 'Gallo-italiche_di_basilicata' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche albanesi', en: 'Albanian linguistic minorities'}, id: 'Albanesi', posX: '75.5%', posY: '79.3%' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche grike e greche di Calabria', en: 'Grike and Greek linguistic minorities of Calabria'}, id: 'Grike_e_Greche_di_Calabria', posX: '83.5%', posY: '77.7%' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche gallo-italiche di Sicilia', en: 'Gallo-Italic linguistic minorities of Sicily'}, id: 'Gallo-italiche_di_sicilia' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche Walser', en: 'Minority linguistic communities of Walser'}, id: 'Walser' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche occitane', en: 'Occitan linguistic minorities'}, id: 'Occitane', posX: '57.6%', posY: '34%' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche croate', en: 'Croatian linguistic minorities'}, id: 'Croate' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche gallo-italiche tabarchine', en: 'Gallo-Italic linguistic minorities of Tabarch'}, id: 'Gallo-italiche_tabarchine_' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche catalane', en: 'Catalan linguistic minorities'}, id: 'Catalane' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche franco provenzali', en: 'Franco-Provençal linguistic minorities'}, id: 'Franco_provenzali' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche cimbre', en: 'Cimbrian linguistic minorities'}, id: 'Cimbre' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche mochéne', en: 'Mochene linguistic minorities'}, id: 'mochene' },
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche ladine', en: 'Ladin linguistic minorities'}, id: 'Ladine' },
						{ map: 'map-b', caption: {it: 'Varietà di sloveno', en: 'Slovenian varieties'}, id: 'sloveno' },
						{ map: 'map-b', caption: {it: 'Dialetti germanici di origine carinziana', en: 'Germanic dialects of Carinthian origin'}, id: 'Dialetti_germanici_di_origine_carinziana' },
						{ map: 'map-b', caption: {it: 'Dialetti germanici di origine bavarese', en: 'Germanic dialects of Bavarian origin'}, id: 'Dialetti_germanici_di_origine_bavarese'},
						{ map: 'map-b', caption: {it: 'Minoranze linguistiche sud-tirolesi', en: 'South Tyrolean linguistic minorities'}, id: 'Sud-Tirolesi' },
					],
				},
				{
					id: 's2-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 402)
					},
					caption: {
						it: `## Di che isoglossa sei?

Il linguista tedesco Gerard Rohlfs (1892-1986) amò molto l’Italia e fu ricercatore per l’AIS nell’Italia meridionale.
Scrisse una celebre _Grammatica storica_ ancora oggi fondamentale e studiò le isoglosse, veri e propri confini linguistici che servono a indicare il limite di diffusione di una forma.
Molto importanti quelle che vanno da La Spezia a Rimini e da Roma ad Ancona.`,
						en: `## What’s Your Isogloss?
The German linguist Gerard Rohlfs (1892-1986) loved Italy very dearly and was a researcher for AIS in southern Italy.
He wrote the seminal _Grammatica storica_ \[Historical Grammar], which is still a fundamental work today, and studied isoglosses, the geographic boundaries of a certain linguistic feature.
Of particular importance are those stretching from La Spezia to Rimini and from Rome to Ancona.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-map',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 409),
					buttonPosition: 'left',

					remarkablePoints: [
            { stroke: true, caption: {it: 'Limite meridionale', en: 'Southern board'}, text: {it: '*ortiga* – ortica\n("c" > "g")', en: '*ortiga* – ortica\n("c" > "g")'}, id: 'iso1' },
            { stroke: true, caption: {it: 'Limite meridionale', en: 'Southern board'}, text: {it: '*sal* – sale\n(cadute di "-e")', en: '*sal* – sale\n(drop of "-e")'}, id: 'iso2' },
            { stroke: true, caption: {it: 'Limite meridionale', en: 'Southern board'}, text: {it: '*cavei* – capelli\n("p" > "v")', en: '*cavei* – capelli\n("p" > "v")'}, id: 'iso3' },
            { stroke: true, caption: {it: 'Limite meridionale', en: 'Southern board'}, text: {it: '*spala* – spalla\n("ll" > "l")', en: '*spala* – spalla\n("ll" > "l")'}, id: 'iso4' },
            { stroke: true, caption: {it: 'Limite meridionale', en: 'Southern board'}, text: {it: '*slèr, slar* – sellaio\n(caduta della vocale protonica)', en: '*slèr, slar* – saddler\n(protonic vowel drop)'}, id: 'iso5' },
            { stroke: true, caption: {it: 'Limite meridionale', en: 'Southern board'}, text: {it: '*pà* – pane', en: '*pà* – pane'}, id: 'iso6' },
            { stroke: true, caption: {it: 'Limite meridionale', en: 'Southern board'}, text: {it: '*incö, incū* – oggi', en: '*incö, incū* – today'}, id: 'iso7' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*ferraru* – fabbro', en: '*ferraru* – blacksmith'}, id: 'iso8' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*frate* – fratello', en: '*frate* – brother'}, id: 'iso9' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*femmina* – donna', en: '*femmina* – woman'}, id: 'iso10' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*figliomo* – mio figlio', en: '*figliomo* – my son'}, id: 'iso11' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*tene le spalle larghe* – ha le spalle larghe', en: '*tene le spalle larghe* – ha le spalle larghe'}, id: 'iso12' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*cossa* – coscia', en: '*cossa* – coscia'}, id: 'iso13' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*lu cimice* – la cimice', en: '*lu cimice* – la cimice'}, id: 'iso14' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*fagu* – faggio', en: '*fagu* – faggio'}, id: 'iso15' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*mondone* – montone\n("nt" > "nd")', en: '*mondone* – montone\n("nt" > "nd")'}, id: 'iso16' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*dienti* – denti\n(metafonesi)', en: '*dienti* – denti\n(metaphony)'}, id: 'iso17' },
            { stroke: true, caption: {it: 'Limite settentrionale', en: 'Nothern border'}, text: {it: '*acitu* – aceto\n(metafonesi)', en: '*acitu* – aceto\n(metaphony)'}, id: 'iso18' },
					],
				},
				{
					id: 's3-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 403)
					},
					caption: {
						it: `I dialetti italiani possono essere divisi in cinque grandi sistemi:

- dialetti settentrionali: area gallo-italica e area veneta;
- dialetto friulano;
- dialetto toscano;
- dialetti centro-meridionali;
- dialetto sardo.

Che ne dite di iniziare il nostro viaggio?`,
						en: `Italian dialects can be divided into five major systems:

- Northern dialects, subdivided into Gallo-Italic dialects and Veneto dialects;
- Friulian dialects;
- Tuscan dialects;
- Central-Southern dialects;
- Sardinian dialects.

Shall we start exploring them?`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					sizeContentText: 'normal',

					template: 'left',
				},
				{
					id: 's3-02',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 403)
					},
					caption: {
						it: `## Si comincia: Italia settentrionale 
I dialetti lombardi e romagnoli (insieme a quelli piemontesi, liguri ed emiliani) appartengono ai dialetti “gallo-italici”, chiamati così perché, prima della conquista romana, quest’area fu invasa da popolazioni galliche di lingua celtica, mentre dopo il crollo dell’Impero Romano fu conquistata prima dagli Ostrogoti, poi dai Longobardi.
I dialetti veneti, invece, conservano le tracce del venetico, la lingua dei Veneti che abitavano questo territorio prima della romanizzazione.`,
						en: `## Here We Go: Northern Italian
The dialects of Lombardy and Romagna (with those of Piemonte, Liguria and Emilia) belong to the “Gallic-Italic” dialects, so called because, before the Roman conquest, this area was invaded by Celtic-speaking Gallic peoples, while after the collapse of the Roman Empire it was first conquered by the Ostrogoths, then by the Lombards. 
Venetian dialects, on the other hand, preserve traces of Venetic, the language of the Veneti who inhabited this territory before Romanisation.`,
					},
					steps: [{}, {}, {}, {}],
					numSteps: 4,
					sectionStyle: 'bg-rose',

					template: 'left',
					curiosity: {
						it: 'C’è una parola romagnola ormai entrata anche nel dizionario italiano e molto nota: _amarcord_.\nVuol dire ‘mi ricordo’ e indica una rievocazione del passato un po’ nostalgica.\nIl romagnolo ha ispirato grandi poeti come Tonino Guerra e grandi registi come Federico Fellini – l’autore del film _Amarcord_ appunto.',
						en: `The Romagnolo word _amarcord,_ is very well known, and has even entered the Italian dictionary.
It means ‘I remember’ and indicates a somewhat nostalgic evocation of the past.
Romagnolo has being the source of inspiration of the great poet Tonino Guerra and the great film director Federico Fellini – the author of a film likewise entitled _Amarcord_.`,
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 402),
					buttonPosition: 'left',
				},
				{
					id: 's3-03',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 403)
					},
					caption: {
						it: `## Marilenghe
Così i friulani chiamano il loro dialetto: madre lingua.
Tra i tratti caratteristici, la quantità vocalica (breve e lunga) ha valore fonologico, dunque _lăt_ (‘latte’), _lāt_ (‘andato’), _mĭl_ (‘mille’), _mīl_ (‘miele’).
Un altro elemento tipico è la formazione del plurale. In friulano il plurale ha, come in latino (e in sardo!), una -_s_ finale, e quindi _poesiis_ ‘poesie’, _mans_ ‘mani’ e così via.`,
						en: `## Marilenghe
“Mother tongue” – this is what Friulians call their dialect. 
One characteristic feature is vowel length (short and long), which gives rise to words with different meanings, thus lăt (‘milk’), lāt ('gone'), mĭl (‘thousand’), mīl (‘honey’).
Another typical feature is the formation of the plural. In Friulian the plural has a final -s, as in Latin (and in Sardinian!), and thus poesiis ‘poems’, mans ‘hands’ and so on.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 403),
					buttonPosition: 'left',
					remarkablePoints: [
						{
							posX: '75%', posY: '17%',
							caption: 'Descrizione',
							textBefore: {
								it: 'Il cartello stradale con le indicazioni in lingua e in dialetto ci mostra un tratto tipico del friulano nella voce _cjistiel_ (‘castello’): infatti, ca- e ga- iniziali di parola diventano in friulano cia- e gia- (ad esempio, dal latino casa(m) e gallina(m) abbiamo il friulano _ciasa_ e _gialina_).',
								en: 'Road signs with indications in Italian and Friulian dialect shows us a typical Friulian trait in the word _cjistiel_ (‘castle’). Indeed in Friulian, initial _ca_- and _ga_- become _cia_- and _gia_- (for example, from the Latin _casa_(m) and _gallina_(m) we have the Friulian _ciasa_ \[home] and _gialina_ \[chicken]).'
							},
							customLabelStyle: 'width: 30.6em',
						},
					],
				},
				{
					id: 's3-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 403)
					},
					caption: {
						it: `## «La porti un bacione a Firenze…»
Un tratto molto noto è la “gorgia toscana”, la particolare pronuncia aspirata che riguarda le consonanti _c_ ‘dura’, _p_ e _t_ (e in modo meno forte e regolare anche _b_, _d_ e _g_ ‘dura’).
Caratterizza soprattutto l’area fiorentina, mentre negli altri dialetti della regione è presente, in modo più o meno marcato, l’aspirazione della sola _c_ ‘dura’.`,
						en: `## «La porti un bacione a Firenze…» \[Give my love to Florence]
A well-known trait is the _gorgia toscana_ \[Tuscan throat], which involves aspirating the consonants ‘hard’ _c_, _p_ and _t_ (and in a weaker and regular manner also _b_, _d_ and ‘hard’ _g_).
This is characteristic above all of the Florentine area, while in the other dialects of the region the aspiration of the ‘hard’ _c_ alone is present to varying degrees.
`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-paper',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 404),
					buttonPosition: 'left',
				},
				{
					id: 's3-05',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 403)
					},
					caption: {
						it: `## Al centro-sud
L’area dei dialetti centro-meridionali è così ripartita: area mediana (Marche e Umbria centro-meridionali, Lazio centrale), area meridionale (Marche meridionali, Abruzzo, Lazio a est e sud di Frosinone, Molise, Campania, Puglia – a eccezione del Salento –, Calabria, Basilicata), area meridionale estrema (Salento, Calabria meridionale, Sicilia).`,
						en: `## Central-South Italy
The area of central-southern dialects is divided as follows: central area (central-southern Marches and Umbria, central Latium), southern area (southern Marches, Abruzzo, Latium east and south of Frosinone, Molise, Campania, Apulia – with the exception of Salento –, Calabria, Basilicata), extreme southern area (Salento, southern Calabria, Sicily).
`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 405),
					buttonPosition: 'left',
					remarkablePoints: [
						{
							posX: '82%', posY: '24%',
							caption: 'Descrizione',
							textBefore: 'I. Buttitta, _Lingua e dialettu_: scritta su una saracinesca di Palermo.\n\n‘Un popolo, mettetelo in catene, spogliatelo, tappategli la bocca: è ancora libero. Levategli il lavoro, il passaporto, la tavola su cui mangia, il letto dove dorme: è ancora ricco. Un popolo diventa povero e servo quando gli rubano la lingua ricevuta dai padri: è perso per sempre. Diventa povero e servo quando le parole non generano parole e si mangiano tra loro’.',
							customLabelStyle: 'width: 31.2em',
						},
					],
				},
				{
					id: 's3-06',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 403)
					},
					caption: {
						it: `## Sa limba sarda
Sapete cosa vuol dire? Vuol dire: ‘la lingua sarda’.
Da notare l’articolo _sa_: gli articoli determinativi non derivano, come in italiano, da <tt>illu(m)</tt>, <tt>illa(m)</tt>, ma da <tt>ipsu(m)</tt> e <tt>ipsa(m)</tt>, abbiamo così _su_ (‘il’ e ‘lo’), _sa_ (‘la’), _sos_ (‘i’, ‘gli’), e _sas_ (‘le’).`,
						en: `## Sa limba sarda
Do you know what it mean? It means: ‘the Sardinian language’.
Note the article _sa_: the definite articles do not derive, like Italian, from illu(m), illa(m),but from ipsu(m) and ipsa(m),  so we have _su_ ( masculine ‘the’), _sa_ (feminine ‘the’), _sos_ (masculine plural ‘the’), and _sas_ (feminine plural ‘the’).`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-paper',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 406),
					buttonPosition: 'left',
					/*
					remarkablePoints: [
						{
							posX: '82%', posY: '24%',
							caption: 'Descrizione',
							textBefore: 'I. Buttitta, _Lingua e dialettu_: scritta su una saracinesca di Palermo.\n\n‘Un popolo, mettetelo in catene, spogliatelo, tappategli la bocca: è ancora libero. Levategli il lavoro, il passaporto, la tavola su cui mangia, il letto dove dorme: è ancora ricco. Un popolo diventa povero e servo quando gli rubano la lingua ricevuta dai padri: è perso per sempre. Diventa povero e servo quando le parole non generano parole e si mangiano tra loro’.',
							customLabelStyle: 'width: 31.2em',
						},
					],
					*/
				},
				{
					id: 's4-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 404)
					},
					caption: {
						it: `I dialetti italiani hanno dato vita a un patrimonio artistico di incredibile varietà e bellezza. 
Gli spazi creativi preferiti dagli autori dialettali sono stati storicamente la poesia (con la variante musicata della _canzone_) e il teatro, in quanto generi recitati. 
In tempi più recenti il dialetto ha raggiunto anche il cinema, in particolare a partire dal secondo dopoguerra.`,
						en: `Italian dialects have given rise to an artistic heritage of incredible variety and beauty. 
The preferred creative forms of dialect authors have historically been the recited genres of poetry (with the musical variant of _canzone_ i.e. song) and theatre. 
In more recent times, dialect has also entered the cinema, particularly after World War II.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					sizeContentText: 'normal',
					template: 'left',
				},
				{
					id: 's4-02',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 404)
					},
					caption: {
						it: `## Tra alterità e grande poesia
All’inizio del Cinquecento la letteratura in dialetto si afferma come alternativa all’uso letterario del toscano, riconosciuto ufficialmente come “italiano”.
Nel Novecento il dialetto diventa un codice poetico raffinato: è la grande stagione “neodialettale”, che vedrà l’opera di autori straordinari come Pier Paolo Pasolini, Tonino Guerra, Virgilio Giotti, Ignazio Buttitta e molti altri.`,
						en: `## Otherness and Great Poetry
From the beginning of the 16th century, literature in dialect established itself as an alternative to the literary use of Tuscan, officially recognised as “Italian”.
In the 20th century, dialect became a sophisticated poetic code: this was the great “neo-dialectal” period, which saw the work of extraordinary writers such as Pier Paolo Pasolini, Tonino Guerra, Virgilio Giotti, Ignazio Buttitta and many others.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-rose',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 410),
					buttonPosition: 'left',
				},
				{
					id: 's4-03',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 404)
					},
					caption: {
						it: `## In scena!

Sulla scena europea solo il teatro italiano vanta una lunga tradizione sia colta che popolare in dialetto: il teatro è, dopo la poesia, lo spazio artistico preferito dagli autori dialettali, grazie alla sua natura dialogica e interattiva.`,
						en: `## On Stage!
Across Europe, only Italian theatre boasts long literary and dialect traditions. After poetry, theatre is the preferred artistic form for dialect authors, due to its dialogic and interactive nature.`,
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-gold-animated',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 411),
					buttonPosition: 'left',
				},
				{
					id: 's4-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 404)
					},
					caption: {
						it: `## Tra compromesso e realtà
Di rado al cinema si fa un uso mimetico del dialetto: per esigenze di comprensibilità si utilizza l’italiano regionale o una lingua mista, con solo alcune note di colore dialettale. 
Alcuni registi però tentano, anche attraverso attori non professionisti, di restituire la vera parlata di alcune aree del Paese: da Luchino Visconti (con il siciliano della _Terra trema_), a Pier Paolo Pasolini (con il romanesco di _Mamma Roma_ e il napoletano del _Decameron_), da Ermanno Olmi (con il bergamasco dell’_Albero degli zoccoli_), a Giuseppe Tornatore (con il siciliano di _Baarìa_).`,
						en: `## Compromise and reality
Dialect is rarely used in cinema mimetically. Rather, regional Italian or a mixed language is used for the sake of comprehensibility, with only a few notes of dialectal colour. 
Some directors, however, have attempted to render the dialects spoken in certain parts of the country, often using non-professional actors: they include Luchino Visconti (Sicilian in Terra trema), Pier Paolo Pasolini (Roman dialect in Mamma Roma and Neapolitan in Decameron), Ermanno Olmi (Bergamesque dialect in Albero degli zoccoli) and Giuseppe Tornatore (Sicilian in Baarìa).`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-cement',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 412),
					buttonPosition: 'left',
				},
				{
					id: 's4-05',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 404)
					},
					caption: {
						it: `## Un grande connubio
Fin dalle sue origini, nell’Ottocento, la canzone italiana ha una forte impronta dialettale, sia dal punto di vista dei contenuti, che rispecchiano la cultura popolare dei vari territori, sia rispetto all’impianto musicale. 
Nel Novecento l’uso del dialetto nella canzone assume sempre più un valore identitario, e rispecchia la volontà di difendere e promuovere le differenti tradizioni locali.`,
						en: `## A Great Combination
Ever since its origins in the 19th century, Italian song has had a strong dialectal imprint, both in terms of content, reflecting the popular culture of the various parts of Italy, and in terms of musical structure. `,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 413),
					buttonPosition: 'left',

					curiosity: {
  					it: 'Lo stretto legame della canzone in lingua con quella dialettale trova conferma nella storia di quella che viene generalmente considerata la “prima” canzone italiana, _Santa Lucia_, composta nel 1848 da due autori napoletani. La versione originale del brano, scritto da Enrico Crossovich e musicato da Teodoro Cottrau, era in dialetto napoletano: fu lo stesso Crossovich, in un secondo momento, a “tradurla” in italiano.',
  					en: `Reflecting the close association between Italian song and dialect song is the history of what is generally considered the “first” Italian song, _Santa Lucia_, composed in 1848 by two Neapolitans. The original version of the song, written by Enrico Crossovich and set to music by Teodoro Cottrau, was in Neapolitan dialect. It was Crossovich himself who later “translated” it into Italian.`,
					},
				},
				{
					id: 's4-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 404)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's5-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 405)
					},
					caption: {
						it: `L’italiano regionale è l’italiano che parlano tutti i giorni gli italiani, soprattutto nelle situazioni comunicative informali: è un italiano caratterizzato da tratti locali, che cambia a seconda dell’area geografica di provenienza dei parlanti. 
Da non confondere dunque con i vari dialetti, vere e proprie lingue a sé.`,
						en: `Regional Italian is the Italian that Italians speak every day, especially in informal communicative situations. It is an Italian characterised by local features, and changes according to the geographical area of origin of the speakers. 
It should therefore not be confused with the various dialects, which are languages in their own right.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					sizeContentText: 'normal',

					inDepthItem: inDepthItems.items.find(el => el.id == 414),
					buttonPosition: 'left',
					template: 'left',
				},
				{
					id: 's5-02',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 405)
					},
					caption: {
						it: `## Da voi come si dice?
I geosinonimi sono sinonimi regionali, cioè parole italiane differenti usate nelle varie regioni per riferirsi allo stesso oggetto o concetto.
Per esempio, da voi si dice gomma, cicca o ciunga?`,
						en: `##How Do You Say… in Your Part of the Italy?
Geosynonyms are regional synonyms, i.e. different Italian words used in the various regions to refer to the same object or concept.
For example, do you say _gomma_, _cicca_ or _ciunga_ \[chewing gum]?`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-map',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 415),
					buttonPosition: 'left',

					curiosity: {
  					it: 'Esiste una regione in Italia in cui si parla “meglio” l’italiano?\nNo, quando parliamo usiamo tutti, nella comunicazione quotidiana, l’italiano regionale: non esiste una varietà regionale “migliore” o “peggiore” delle altre, perché ogni forma regionale della nostra lingua porta con sé gli elementi caratteristici di un certo territorio.',
  					en: `Is there a region in Italy where Italian is spoken “better”?\n No, when we speak we all use a regional Italian in everyday communication. No regional variety is “better” or “worse” than the others, because every regional form of Italian bears the characteristic features of a given area.`,
					},
					remarkablePoints: [
						{ text: 'ciunga, masticone', caption: {it: 'Sicilia',en: "Sicily"}, id: 'Sicilia' },
						{ text: 'gingomma, cincingomma', caption: {it: 'Calabria',en: "Calabria"}, id: 'Calabria' },
						{ text: 'cingomma, gingomma', caption: {it: 'Basilicata',en: "Basilicata"}, id: 'Basilicata' },
						{ text: 'gomma, gomma da masticare', caption: {it: 'Campania',en: "Campania"}, id: 'Campania' },
						{ text: 'gingomma, cicca', caption:  {it:'Puglia',en: "Apulia"}, id: 'Puglia' },
						{ text: 'cingomma', caption: {it: 'Sardegna',en: "Sardinia"}, id: 'Sardegna' },
						{ text: 'gomma, cicca', caption: {it: 'Molise',en: "Molise"}, id: 'Molise' },
						{ text: 'gomma, gomma da masticare', caption: {it: 'Lazio',en: "Lazio"}, id: 'Lazio' },
						{ text: 'gomma, gingomma', caption: {it: 'Abruzzo',en: "Abruzzo"}, id: 'Abruzzo' },
						{ text: 'gomma', caption: {it: 'Umbria',en: "Umbria"}, id: 'Umbria' },
						{ text: 'cicca, cingomma', caption: {it: 'Marche',en: "Marche"}, id: 'Marche' },
						{ text: 'gomma, cingomma', caption: {it: 'Toscana',en: "Tuscany"}, id: 'Toscana' },
						{ text: 'cicingomma, cicca', caption: {it: 'Liguria',en: "Liguria"}, id: 'Liguria' },
						{ text: 'cicca, cicles', caption: {it: 'Emilia-Romagna',en: "Emilia-Romagna"}, id: 'Emilia-Romagna' },
						{ text: 'cicles, chewing gum', caption: {it: 'Piemonte',en: "Piedmont"}, id: 'Piemonte' },
						{ text: 'cicca', caption: {it: 'Lombardia', en: 'Lombardy'}, id: 'Lombardia' },
						{ text: 'cicca, cauciu, ciunga', caption: {it: 'Veneto', en: "Veneto"}, id: 'Veneto' },
						{ text: 'ciunga, gomma', caption: {it: 'Friuli-Venezia Giulia',en: "Friuli-Venezia Giulia"}, id: 'Friuli' },
						{ text: 'gomma', caption: {it: 'Valle d\'Aosta',en: "Aosta Valley"}, id: 'Valle_d\'aosta' },
						{ text: 'ciunga', caption: {it: 'Trentino-Alto Adige/Südtirol',en: "Trentino-Alto Adige/South Tyrol"}, id: 'Trentino' },
					],
				},
				{
					id: 's5-03',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 405)
					},
					caption: {
						it: `## Un uso diversificato
A partire dal Neorealismo nel secondo dopoguerra, il cinema rappresenta la realtà viva della nazione anche con il recupero delle lingue parlate, e in particolare dei dialetti, nella recitazione.
Con la diffusione sempre maggiore dell’italiano come lingua parlata, il cinema usa le varietà regionali per caratterizzare in modo più realistico ed espressivo i personaggi. È il caso dell'italiano regionale lombardo nel film _Romanzo popolare_.`,
						en: `## Differentiated Use
Starting with Neo-realism after World War II, cinema also represents Italy’s linguistic variety through the use of spoken languages, and, in particular, dialects.
With the increasing dissemination of Italian as a spoken language, the cinema uses regional varieties for more realistic and expressive characterisation. This is true of Milanese regional Italian in the film _Romanzo popolare_ \[Come Home and Meet My Wife].`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 416),
					buttonPosition: 'left',
				},
				{
					id: 's5-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 405)
					},
					caption: {
						it: `## Italiano regionale o dialetto?
La situazione linguistica della Toscana (soprattutto dell’area fiorentina) e di Roma è molto particolare, perché in queste aree non c’è una distinzione netta fra dialetto e italiano e le due varietà sembrano quasi sovrapporsi. 
Di conseguenza, nella resa cinematografica si rischia di appiattire il parlato in lingua su un’unica varietà dialettale oppure di costruire una lingua finta.`,
						en: `## Regional Italian or Dialect
The linguistic situation in Tuscany (especially in the Florentine area) and Rome is very peculiar, because in these areas there is no clear distinction between dialect and Italian, and the two varieties almost seem to overlap. 
Consequently, on the big screen there is the danger of reducing the spoken Italian to a single dialect variety or of constructing a fake language.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-cement',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 417),
					buttonPosition: 'left',
				},
				{
					id: 's5-05',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 405)
					},
					caption: {
						it: `## Un’attenta ricostruzione
La varietà regionale parlata nell’area napoletana resta una delle più note al pubblico italiano, anche se è stata spesso semplificata o ridotta a formule convenzionali.
In anni più recenti, tuttavia, si è cercato di ricostruire un parlato più vicino alla comunicazione spontanea, intrecciando e sovrapponendo italiano regionale e dialetto, registri colloquiali, trascurati o formali.`,
						en: `## An accurate Reconstruction
The regional variety spoken in the Neapolitan area remains one of the best known to the Italian public, although it has often been simplified or reduced to conventional expressions.
In more recent years, however, attempts have been made to reconstruct speech that is closer to spontaneous communication, interweaving and overlapping regional Italian and dialect as well as colloquial, neglected or formal registers.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 418),
					buttonPosition: 'left',
				},
				{
					id: 's5-06',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 405)
					},
					caption: {
						it: `## Il caso Camilleri
La scrittura di Camilleri, nella fortunata serie del commissario Montalbano, mostra caratteristiche particolari: Camilleri costruisce un tessuto linguistico che non corrisponde con precisione né al dialetto siciliano né all’italiano regionale di Sicilia, ma si compone di diverse varietà del siciliano e dell’italiano, in un continuo affastellarsi di lingue.`,
						en: `## The Case of Andrea Camilleri
Andrea Camilleri's writing in the successful “commissario” Montalbano detective mysteries displays some original characteristics. Camilleri weaves a linguistic fabric that does not correspond exactly to Sicilian dialect nor to the regional Italian of Sicily, but is composed of different varieties of Sicilian and Italian, in a continuous jumbling of languages.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					sizeContentText: 'normal',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 419),
					buttonPosition: 'left',
				},
				{
					id: 's5-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 405)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's6',
					name: 'bibliography',
					stage: {
						...stagesData.stagesData.find(el => el.id == 13)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-extra-light-gold',
					template: 'left',
				}
			],
		},
		// NEL MONDO
		{
			id: 5,
			nameCode: 'lingua-italiana-nel-mondo',
			nameArray: {
				it: ['Come si','è diffusa', 'la lingua italiana', 'nel mondo?'],
				en: ["How Did", "Italian Spread", "Throughout", "the World?"]
			},
			name: {
				it: "come si è diffusa la lingua italiana nel mondo?",
				en: 'How Did Italian Spread Throughout the World?'
			},
			description: {
				it: 'L’italiano e i dialetti italiani non si parlano solo in Italia. Grazie ai viaggi, agli spostamenti (anche dolorosi come l’emigrazione), all’arte e alle risorse del nostro eccezionale patrimonio, l’italiano circola in tutto il mondo da secoli. E oggi per merito dell’incontro con altre culture (anche grazie all’immigrazione) non smette di arricchirsi di nuove parole, nuove sfumature, nuove suggestioni. Con l’italiano si è cittadini e cittadine del mondo.',
				en: 'Italian and Italian dialects are not only spoken in Italy.\n As a result of travel, movement (even painful forms such as emigration), art and the resources of Italy’s exceptional heritage, Italian has been circulating throughout the world for centuries.\n And today, as a result of contact with other cultures (due also to immigration), it is continuously enriched with new words, new nuances, new sensations.\n With Italian, all of us are citizens of the world.',
			},
			introImages: [
				require('@assets/images/percorsi/mondo/Mastroianni-ed-Ekberg_High'+utils.manageImagesExtension(".png")),
				require('@assets/images/percorsi/mondo/That\'s_Amore_High'+utils.manageImagesExtension(".jpg")),
				require('@assets/images/percorsi/mondo/intro-mondo-1'+utils.manageImagesExtension(".png"))
			],
			sections: [
				{
					id: 's1',
					name: 'introduzione',
					isFixed: true,
					stage: {
					...stagesData.stagesData.find(el => el.id == 22)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's2',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "La storia della diffusione dell’italiano nel mondo e la storia dell’italiano in Italia viaggiano parallele.\n Dal Medioevo i volgari italiani, soprattutto quelli dei centri politici e culturali più importanti, lasciano la loro traccia nel mondo con il commercio, la letteratura, le arti figurative, la musica e il teatro.\n Solo gradualmente il fiorentino letterario del Trecento diventerà il modello linguistico più prestigioso.",
						en: "The history of the spread of Italian around the world and the history of Italian in Italy run in parallel.\n Since the Middle Ages, Italian vernaculars, especially those of the most important political and cultural centres, have left their mark on the world through trade, literature, the figurative arts, music and theatre.\n Only gradually did 14th century literary Florentine become the most prestigious linguistic model."
					},
					artifact: {
						id: 16
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 34),
					curiosity: {
						it: "La prima grammatica italiana stampata all’estero fu scritta da Jean Pierre de Mesmes.\n Nato intorno al 1516, de Mesmes studiò probabilmente in Italia.  Esperto di geometria, contribuì a delimitare i confini tra Francia e Inghilterra dopo la pace di Cateau-Cambrésis (1559). Fu anche un poeta, sia in francese sia in italiano, e un traduttore dal francese all’italiano e viceversa.",
						en: "The first Italian grammar printed abroad was written by Jean Pierre de Mesmes.\n Born around 1516, de Mesmes probably studied in Italy.  An expert in geometry, he helped to demarcate the borders between France and England after the Peace of Cateau-Cambrésis (1559). He was also a poet, both in French and Italian, and a translator from French into Italian and vice versa."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's3',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Dal Mediterraneo al Medio Oriente\n\n Nel Medioevo i modelli linguistici italo-romanzi lasciarono tracce in Europa, nel Mediterraneo e nel Medio Oriente.\n I più importanti canali di diffusione furono i mercanti e i banchieri dalla «Lombardia» (si indicava così l’intera l’Italia centro-settentrionale) che viaggiavano in tutta Europa; l’espansione nei Balcani e nel Levante di Venezia; lo sviluppo economico legato all’espansione commerciale di Genova.",
						en: "## From the Mediterranean to the Middle East\n\n In the Middle Ages, Italo-Romance linguistic models left traces in Europe, the Mediterranean and the Middle East.\n The most important vehicles for its spread were firstly the merchants and bankers from «Lombardy» (this was the name given to the whole of central and northern Italy) who travelled throughout Europe; secondly Venice’s expansion into the Balkans and the Levant; thirdly Genoa’s economic development linked to its commercial expansion."
					},
					artifact: {
						id: 16
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 35),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's4',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Italiano o arabo?\n\n L’anonima traduzione italiana di un documento arabo inviato il 10 giugno 1366 dall’emiro di Bona e Bugia (nell’attuale Algeria) al doge di Pisa Giovanni dell’Agnello presenta una peculiarità linguistica importante: l’italiano (in questo caso da intendere come pisano municipale del periodo) è scritto in caratteri arabi.",
						en: "## Italian or Arabic?\n\n The anonymous Italian translation of an Arabic document sent on 10 June 1366 by the emir of Bona e Bugia (in present-day Algeria) to the doge of Pisa Giovanni dell'Agnello contains an important linguistic peculiarity: the Italian (in this case the Pisan vernacular of the period) is written in Arabic script."
					},
					artifact: {
						id: 16
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 36),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's5',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## Un successo europeo\n\n Dal Cinquecento il fiorentino letterario di Dante, Petrarca e Boccaccio si diffuse in Italia e nel mondo.\n In particolare, la poetica di Francesco Petrarca divenne il modello per la lingua della poesia in Italia e un modello culturale per tutta l’Europa. \n Venezia, con la grande innovazione della stampa, giocò un ruolo nella diffusione del modello linguistico-letterario della Toscana.\n Così, nel Cinquecento e nel Seicento, si affermò in Europa una vera “italomania”, in particolare in Francia.",
						en: "## A European Success\n\n From the 16th century onwards, the literary Florentine of Dante, Petrarch and Boccaccio spread throughout Italy and the world. The poetics of Francesco Petrarch became the model for the language of poetry in Italy and a cultural model for the whole of Europe.\n Venice, following the revolutionary invention of the printing press, played a role in the spread of the Tuscan linguistic-literary model. \n As a result, in the 16th and 17th centuries, “Italomania” grew in Europe, particularly in France.",
					},
					artifact: {
						id: 16
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 37),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's6',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: {
						it: "## Alla conquista dei teatri\n\n L’italiano ebbe una grande fortuna come lingua del teatro, grazie al successo della Commedia dell’arte.\n Arlecchino, Colombina, Pantalone e le altre maschere conquistarono presto i teatri di tutto il mondo.",
						en: "## Success in Theatre's\n\n Italian had great fortune as the language of theatre, as a result of the success of the _commedia dell'arte_.\n Harlequin, Columbine, Pantalone and the other masks soon conquered theatres all over the world.",
					},
					artifact: {
						id: 16
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 38),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's7',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## Musica, maestro\n\n Già nel Cinquecento, ma in particolar modo nel Seicento e nel Settecento, l’italiano ebbe una grande fortuna nel mondo come lingua del teatro di musica, sia per il successo del genere del melodramma (i cui testi erano scritti in italiano) sia per la diffusione della terminologia musicale",
						en: "## Music, _Maestro_!\n\n As long ago as the 16th century, but especially in the 17th and 18th centuries, Italian became the language of music in the world, both because of the success of opera (whose texts were in Italian) and because of the spread of musical terminology."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 39),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's8',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Destinazione Italia\n\n Tra Settecento e inizio Ottocento, con un’Italia frazionata e caratterizzata da continue tensioni politiche, l’italiano soffrì il confronto con le altre lingue europee ormai espressioni di realtà politiche consolidate.\n Tuttavia, continuò ad avere prestigio come lingua delle arti, e inoltre l’Italia divenne meta privilegiata del Grand Tour dei giovani delle famiglie ricche d’Europa e molte personalità di spicco della cultura del tempo (Voltaire, Mozart, Haydn e Byron) parlavano e scrivevano in italiano.",
						en: "## Destination Italy\n\n Between the 18th and early 19th century, in a fragmented Italy characterised by continuous political tensions, Italian suffered in comparison with other European languages that were by then expressions of established nations.\n Nevertheless, it continued to be prestigious as the language of the arts, and moreover, Italy became a privileged destination for the Grand Tour of young scions from Europe's wealthy families, while many leading cultural figures of the time (Voltaire, Mozart, Haydn and Byron) spoke and wrote in Italian.",
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 40),
					remarkablePoints: [
						{
							id: "T1_S7_1",
							caption: 'didascalia',
							textBefore: {
								it: 'Il 12 giugno 1746 Voltaire invia agli Accademici della Crusca una lettera di ringraziamento con la quale esprime gratitudine per essere stato accolto nell’Accademia. L’intellettuale francese, dopo aver ricordato che è da tempo che un francese non viene onorato con questo riconoscimento, nella parte finale elogia la lingua toscana e sostiene che lui stesso avrebbe preferito formarsi prima sui poeti toscani e poi su quelli francesi.', 
								en: 'On 12 June 1746, Voltaire sent the Academicians of the Crusca a letter of thanks in which he expressed his gratitude for being accepted into the Academy. The French intellectual, after recalling that it had been a long time since a Frenchman had received such an honour, in the final part praises the Tuscan language and states that he himself would have preferred to have been educated first on Tuscan poets and then on French ones.'
							},
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's9',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Italiano lingua d'amore!\n\n Nel 1809 anche Lord George Gordon Byron si imbarcò per il tour all’estero, viaggiando in Portogallo, in Spagna, nei Balcani, in Albania e in Grecia. Tornò in Inghilterra ma fu costretto a ripartire nel 1812 per gli attacchi politici e letterari e le maldicenze sulla sregolatezza della sua vita privata. Byron si stabilì a Venezia dove frequentò la società italiana, di cui amò profondamente la cultura e la lingua.",
						en: "## Italian, the Language of Love!\n\n In 1809, Lord George Gordon Byron also embarked on a foreign tour, travelling to Portugal, Spain, the Balkans, Albania and Greece. He returned to England but was forced to leave again in 1812 due to political and literary attacks and slander over his unruly private life. Byron settled in Venice where he frequented Italian society, whose culture and language he loved deeply. ",
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 41),
					remarkablePoints: [
						{
							id: "T1_S8_1",
							caption: 'didascalia',
							textBefore: {
								it: 'Signora Contessina Pregiatissima,\n Appena ricevuta la obbligantissima di lei lettera, Sig.a Contessina, mi fo un dovere di rispondere riscontrarvela collo stesso mezzo, e di ringraziarla per i generosi sentimenti ch’Ella nutre a mio riguardo, nonché pure ringrazio il Sig. Cavaliere di Lei Marito, assicurando entrambi, che mi vedrei onorato se potessi offrirle i libri ch’Ella, Signora Contessina, con tanta buona grazia mi chiede. Debbo assicurarla non tener io polso di me cosa veniva di mia penna, né a Venezia pure si trovano le mie opere, perché in tal caso mi farei un pregio di scriverla, acciò anch’Ella ed il Sig. Cav. una prova della mia obbedienza.\n Non è fuor di proposito che dovendo io recarmi fra pochi giorni a Bologna non passi anche a vedere la bella Romagna ed in ispecie le celebri antichità di Ravenna, ed allora avrei il doppio piacere di baciarle rispettosamente la mano e riverire il Sig. Cavaliere a cui la prego far aggradire gl’ingenui sentimenti della mia stima ed amicizia.\n Intanto ho l’onore di essere di Lei Signora Contessina\n obb.mo (obbligatissimo) ed umil.mo (umilissimo) servo\n Venezia, Addì 15 Maggio 1819', 
								en: 'Most Noble Lady Contessina,\n I have just received your most obliging letter, Signora Contessina, I make it my duty to reply to you by the same means, and to thank you for the generous sentiments that you have for me, as well as thanking your husband, Signor Cavaliere, assuring both of you that I would be honoured if I could offer you the books that you, Signora Contessina, so graciously ask of me. I must assure you that I do not control what came from my pen, nor are my works to be found in Venice, because in that case I would be honoured to write to you, so that you and Signor Cavaliere might have proof of my obedience.\n Since I have to go to Bologna in a few days’ time I may well visit the beautiful Romagna and in particular the celebrated antiquities of Ravenna, and therefore I would have the double pleasure of respectfully kissing your hand and revering Signor Cavaliere to whom I beg you to convey the naive sentiments of my esteem and friendship.\n In the meantime, I have the honour, Signora Contessina, of being your most obliging and most humble servant\n Venice, 15 May 1819'
							},
							posX: '10%',
							posy: '10%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's10',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 23)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: {
						it: "## Italiano lingua franca\n\n Dal Cinquecento nel Mediterraneo centro-orientale e nel Levante l’italiano svolge una funzione di lingua franca, ovvero lingua di comunicazione orale sovranazionale.\n Tuttavia l’italiano ha un suo ruolo anche nella comunicazione ufficiale, in particolare nel Settecento per la stesura di trattati internazionali.\n Nell’Ottocento, con la migrazione di numerosi professionisti, l’italiano continuò a essere usato nel Levante (da Istanbul a Salonicco, da Smirne al Cairo) e nel Maghreb (Tunisia e Algeria).",
						en: "## Italian as _lingua franca_\n\n Since the 16th century in the central and eastern Mediterranean and the Levant, Italian has been a _lingua franca_, i.e. a language of supranational oral communication.\n However, Italian has also played a role in official communication, particularly in the 18th century in the drafting of international treaties.\n In the 19th century, with the migration of numerous professionals, Italian continued to be used in the Levant (from Istanbul to Thessaloniki, from Izmir to Cairo) and in the Maghreb (Tunisia and Algeria).",
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 42),
					remarkablePoints: [
						{
							id: "T1_S9_1",
							caption: 'didascalia',
							textBefore: {
								it: 'I dragomanni dell’Impero ottomano erano interpreti ufficiali, mediatori linguistici tra gli europei e i turchi, arruolati anche dai consolati occidentali. Erano perlopiù greci, ma non mancavano membri delle famiglie di antica origine veneziana e genovese. In molti casi, erano europei convertiti all’Islam. Non erano soltanto dei traduttori, a volte avevano anche il ruolo di ambasciatore o persino di spia.', 
								en: 'The dragomans of the Ottoman Empire were official interpreters and translators between Europeans and Turks, who were also enlisted by western consulates. They were mostly Greeks, but they also included members of families of Venetian and Genoese origin. In many cases, they were Europeans who had converted to Islam. They were not only translators, some were also ambassadors or even spies.'
							},
							posX: '10%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's11',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-extra-light-gold',
					caption: {
						it: "In questa tappa seguiremo le impronte della lingua italiana nelle ex colonie, dall'Italia liberale postunitaria alla dittatura fascista, in particolare nel sistema scolastico, nei fenomeni di contatto e interferenza linguistica e nella toponomastica, ma parleremo anche di decolonizzazione e letteratura postcoloniale.",
						en: "In this section, we will follow the footprint of the Italian language in the former colonies, from post-unification liberal Italy to the Fascist dictatorship, particularly in the school education system, in the phenomena of linguistic contact and interference, and in toponymy, but we will also talk about decolonisation and post-colonial literature.",
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 43),
					remarkablePoints: [
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's12',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}, {}, {}, {}, {}],
					numSteps: 5,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## L'italiano nelle scuole delle ex colonie\n\n Il contributo delle scuole alla diffusione della lingua italiana nelle ex colonie è parziale e al di sotto delle aspettative.\n Varie le cause: investimenti inadeguati di governi e istituzioni; mancanza di competenze del corpo insegnante (spesso non adeguatamente formato all’insegnamento della lingua italiana); programmi e manuali non adatti alla platea scolastica di riferimento; infine, una ghettizzazione sempre più marcata delle scuole locali rispetto a quelle destinate agli italiani.",
						en: "## Italian in the Schools of the Former Colonies\n\n The contribution of schools to the dissemination of the Italian language in the former colonies is patchy and much less than might be expected.\n There were various reasons for this: inadequate investment by governments and institutions; lack of skills among the teaching staff (often inadequately trained to teach Italian); curricula and textbooks ill-suited to the target school audience; and finally, an increasing ghettoisation of local schools compared to those attended by Italians."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 44),
					curiosity: {
						it: 'Il sistema scolastico nelle colonie era fortemente differenziato tra scuole riservate a italiani e assimilati (meticci, figli di famiglie miste e indigeni che avevano diritto a un’istruzione in italiano) e scuole destinate agli indigeni. A differenza delle prime, che presentavano la stessa offerta formativa delle scuole italiane, le seconde non erano obbligatorie e offrivano tuttalpiù le basi per apprendere semplici lavori manuali.',
						en: 'The school system in the colonies was strongly differentiated between schools reserved for Italians and local assimilated children (mixed-race children and natives who were entitled to an education in Italian) and schools for natives. Whereas the former offered the same education as schools in Italy, the latter were not compulsory and at most offered the basics for learning simple manual jobs.',
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's13',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Dentro l'aula di una scuola coloniale\n\n I manuali, le grammatiche e i dizionari per la Libia e il Corno d’Africa del periodo coloniale non mettono al centro le caratteristiche e le esigenze del pubblico e dell’ambiente locale, soprattutto i manuali di insegnamento della lingua italiana per le popolazioni autoctone.\n Inoltre la propaganda sempre più pressante sottolinea le differenze razziali tra colonizzatori e colonizzati, e i vantaggi che questi ultimi trarrebbero in termini di civilizzazione dalla dominazione italiana.",
						en: "## Inside a Colonial Class \n\n The textbooks, grammars and dictionaries for Libya and the Horn of Africa of the colonial period do not focus on the characteristics or the needs of the local public and environment, especially the textbooks teaching the Italian language to the local populations.\n Moreover, the ever intense propaganda emphasises the racial differences between colonisers and colonised, and the benefits the latter would gain from civilisation under Italian rule."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 45),
					remarkablePoints: [
						{
							id: "T2_S3_1",
							caption: 'didascalia',
							textBefore: {
								it: 'Il poster testimonia una delle proposte di ortografia della lingua somala a base latina prima del 1973, data in cui fu scelta la nuova ortografia che è in vigore ancora oggi dopo più di mezzo secolo. La datazione è incerta, tuttavia la presenza dei colori e della bandiera della Somalia indipendente fanno pensare a una datazione recente, posteriore al 1960.', 
								en: 'The poster is evidence of one of the proposed Roman script spellings of the Somali language before 1973, the date when the new spelling system was adopted. It is still in force today after more than half a century. While the date is uncertain, the colours and the flag of independent Somalia suggest it could be after 1960.'
							},
							posX: '80%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					curiosity: {
						it: 'Nonostante il ruolo normativo della scuola e il divieto di usare i dialetti e l’italiano coloniale semplificato, è importante ricordare che la maggior parte degli italiani nelle colonie era dialettofona e non sempre aveva dimestichezza con l’italiano standard. Il repertorio linguistico prevalentemente dialettale giocò un ruolo importante nelle interferenze linguistiche con le lingue parlate nelle ex colonie.',
						en: 'Despite the normative role of the school and a ban on the use of dialects as well as a simplified colonial Italian, it is important to remember that most Italians in the colonies spoke dialect and were not always familiar with standard Italian. The predominantly dialectal linguistic repertoire played an important role in linguistic interference with the languages spoken in the former colonies.'
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's14',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Rivoluzioni toponomastiche\n\n L’italiano nelle colonie si diffonde anche tramite la ridenominazione dei luoghi. I toponimi italiani aumentano dalla fase liberale a quella fascista, seppure in prevalenza solo nelle fonti ufficiali dei colonizzatori.\n La ridenominazione dei luoghi varia da colonia a colonia: emblematico è il caso della città libica di Tripoli che dal 1914 al 1934 subisce una vera e propria rivoluzione toponomastica.",
						en: "## A Revolution in Place Names\n\n Italian in the colonies also spread through the renaming of places. The number of Italian place names increased from the liberal phase to the Fascist one, although mainly only in the colonisers’ official sources.\n The degree of renaming varied from colony to colony:  a good example is the case of the Libyan city of Tripoli, which, between 1914 and 1934, underwent a toponymic revolution."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 46),
					curiosity: {
						it: "I **toponimi** sono i nomi dei luoghi. Nell’ambito della toponomastica coloniale si dividono in tre categorie in base alla lingua di provenienza: **esonimi**, **endonimi** e **ibridi**. Gli **esonimi** sono i toponimi nella lingua dei colonizzatori, gli **endonimi** i toponimi nella lingua della popolazione locale, infine gli **ibridi** sono i toponimi che presentano sia elementi della lingua dei colonizzatori sia della lingua dei popoli autoctoni.",
						en: "**Toponyms** are the names of places. In colonial toponymy, they can be divided into three categories according to the language of origin: **exonyms**, **endonyms** and **hybrids**. **Exonyms** are toponyms in the language of the colonisers, **endonyms** are toponyms in the local language, and **hybrids** are toponyms with elements of both the language of the colonisers and the language of the indigenous people."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's15',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Un pidgin italiano in Etiopia e Eritrea?\n\n Secondo alcuni studiosi l’Asmara Pidgin Italian o Italiano semplificato di Etiopia (ISE) è un pidgin a base italiana appreso dai giovani dopo l’occupazione militare (1882-1943) e usato soprattutto tra etiopi/eritrei e italiani e in parte anche tra etiopi/eritrei di lingue diverse.",
						en: "## A Pidgin Italian in Ethiopia and Eritrea?\n\n According to some scholars, Asmara Pidgin Italian or Simplified Italian of Ethiopia (SIE) is an Italian-based pidgin learnt by young people after military occupation (1882-1943) and was used mainly between Ethiopians/Eritreans and Italians and partly also between Ethiopians/Eritreans who spoke different languages."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 47),
					remarkablePoints: [
						{
							id: "T2_S5_1",
							caption: 'didascalia',
							textBefore: {
								it: "Le interviste danno un’idea delle diverse competenze linguistiche tra gli italiani d'Eritrea, i meticci italo-eritrei e gli indigeni eritrei. Nell’ultima intervista ritroviamo alcuni tratti dell’ISE, come la riduzione dei dittonghi (_scuola_ → _skola_).", 
								en: 'The three interviews with three Asmaris (an Italian woman, an Italo-Eritrean man, and an Eritrean man who attended an Italian primary school) give an insight into the differing linguistic skills of Eritrean Italians, mixed-race Eritrean-Italians and Eritrean natives. In the last interview, we find some features of SIE, such as the shortening of diphthongs (_scuola_ → _skola_ \[school] → skola).'
							},
							posX: '80%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					curiosity: {
						it: "Il **pidgin** è una lingua semplificata che non ha parlanti nativi perché nasce dall’incontro di lingue diverse in determinati contesti storici. Un pidgin si evolve in **creolo** quando diventa la lingua materna di una comunità.",
						en: "A **pidgin** is a simplified language that has no native speakers because it is born out of the contact between speakers of different languages in certain historical contexts. A pidgin evolves into a **creole** when it becomes the mother tongue of a community.",
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's16',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: {
						it: "## Sapete come si dice falegname in somalo?\n\n Nelle lingue delle ex colonie, molti italianismi non appartengono ad ambiti di prestigio, ma a campi semantici legati alla vita di tutti i giorni e ad attività manuali: _faryaame_ ‘falegname’, _gomistā_ ‘gommista’, _shamiinto_ ‘cemento’, _fārmāčā_ ‘farmacia’ e così via.",
						en: "## Do You Know the Somali Word for Carpenter?\n\n Many Italian words not belonging to the domain of the arts, but to semantic fields related to everyday life and manual activities have entered the languages of the former colonies: _faryaame_ ‘falegname’ \[carpenter], _gomistā_ ‘gommista’ \[tyre-repairer], _shamiinto_ ‘cemento’ \[cement], _fārmāčā_ ‘farmacia’ \[pharmacy] and so on.",
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 48),
					remarkablePoints: [
						{
							id: "T2_S6_1",
							caption: 'didascalia',
							textBefore: {
								it: "Nell’immagine un sarto eritreo nel periodo coloniale italiano. Nei campi dell’industria tessile e della moda numerosi italianismi sono entrati nelle lingue africane delle ex colonie. Tra questi _giacca_ e _gonna_ in tigrino (_ğākā_ e _gonā_); _cappotto_ e _cravatta_ in amarico come prestiti di necessità (_kāpoto_ e _kerāvātā_); _sciarpa_ e _pantaloni_ in saho (_sharba_ e _banthallooni_).", 
								en: 'Pictured is an Eritrean carpenter during the Italian colonial period. The Italian word _falegname_ entered several African languages of the former colonies, among them Tigrinya (_fālaňama_, _fālaňāma_) and Somali (_faryaame_).'
							},
							posX: '80%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's17',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Un lungo processo di rimozione\n\n A differenza di altre potenze coloniali, l’Italia ha perso tutte le sue colonie con la Seconda guerra mondiale. Ma il  processo di decolonizzazione storica è iniziato solo negli anni Settanta con le ricerche di Angelo Del Boca.\n La decolonizzazione culturale (che riguarda la politica, la società e, in generale, la coscienza collettiva degli italiani) è a tutt’oggi incompiuta: la maggior parte degli italiani ha una conoscenza inadeguata di questa fase storica del Paese.",
						en: "## A Long Period of Amnesia\n\n Unlike other colonial powers, Italy lost all its colonies during the Second World War. But the process of historical decolonisation only began in the 1970s with the research undertaken by Angelo Del Boca.\n Cultural decolonisation (which concerns politics, society and, in general, the collective consciousness of Italians) is still unfinished: most Italians have an inadequate knowledge of this period in the country’s history."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 49),
					remarkablePoints: [
						{
							id: "T2_S7_1",
							caption: 'didascalia',
							textBefore: {
								it: "Il tema della cittadinanza mai riconosciuta ai ‘meticci’ italo-eritrei e alla loro discendenza è emblema della rimozione storica e culturale del periodo coloniale. I protagonisti del video rivendicano il diritto alla cittadinanza denunciando l’abbandono da parte dell’Italia. La questione è stata sollevata recentemente dal giornalista Vittorio Longhi, a sua volta nipote di ‘meticci’ italo-eritrei, nel libro _Il colore del nome. Storia della mia famiglia. Cent’anni di razzismo coloniale e identità negata_ (2021).", 
								en: 'The issue of citizenship which was never granted to mixed-race Italo-Eritreans and their descendants is symbolic of the historical and cultural amnesia of the colonial period. The protagonists of the video claim the right to citizenship, denouncing their abandonment by Italy. The issue was recently raised by journalist Vittorio Longhi, himself the grandson of mixed-race Italo-Eritreans, in his book _Il colore del nome. Storia della mia famiglia._ _Cent\'anni di razzismo coloniale e identità negata_ \[The colour of the name. History of my family. One hundred years of colonial racism and denied identity] (2021).'
							},
							posX: '80%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's18',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Letteratura postcoloniale in Italia\n\n A raccontare però la decolonizzazione e le sfide dell’integrazione ci pensa la letteratura postcoloniale italiana, scrittrici e scrittori provenienti dalle ex colonie italiane o nati in Italia da famiglie che provenivano dai possedimenti coloniali.\n Il repertorio linguistico varia a seconda dei casi, ma è sempre molto interessante e innovativo perché nasce dall’incontro tra il repertorio linguistico endogeno (italiano e dialetti) e quello esogeno (lingue parlate nelle ex colonie).",
						en: "## Post-colonial Literature in Italy\n\n However, decolonisation and the challenges of integration have been narrated in Italian post-colonial literature by writers from former Italian colonies or born in Italy to families that came from colonial possessions.\n The linguistic repertoire varies from author to author, but it is always very interesting and innovative because it stems from the contact between the endogenous linguistic repertoire (Italian and dialects) and the exogenous one (languages spoken in the former colonies)."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 50),
					remarkablePoints: [
						{
							id: "T2_S8_1",
							caption: 'didascalia',
							textBefore: {
								it: "Shirin Ramzanali Fazel, scrittrice italiana di origine somalo-pakistana, racconta il plurilinguismo nella sua produzione letteraria ed esprime le sue considerazioni sulla letteratura postcoloniale italiana.", 
								en: 'Shirin Ramzanali Fazel, an Italian writer of Somali-Pakistani origin, talks about multilingualism in her literary work and expresses her thoughts on Italian post-colonial literature.'
							},
							posX: '65%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's18-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 24)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's19',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 25)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "Prima di diventare paese di immigrazione, l’Italia è stato un paese di emigranti. In un secolo di storia, dal 1870 al 1970, tra i 20 e i 25 milioni di italiani sono espatriati all’estero.\n All’emigrazione italiana sono legate le varietà a base italoromanza nate presso le comunità di emigranti all’estero, come l’**italiano dei lavoratori stranieri in Svizzera** (_Fremdarbeiteritalienisch_), il **siculo-tunisino** in Tunisia, il **cocoliche** e il **lunfardo** in Argentina, e l’**italiese** negli Stati Uniti, Canada e Australia.",
						en: "Before becoming a country of immigration, Italy was a country of emigrants. In the space of a century, from 1870 to 1970, between 20 and 25 million Italians emigrated abroad.\n Linked to Italian emigration are the Italo-Romance varieties that sprung up in emigrant communities abroad, such as the Italian of foreign workers in Switzerland (_Fremdarbeiteritalienisch_), Sicilian-Tunisian in Tunisia, Cocoliche and Lunfardo in Argentina, and Italese in the United States, Canada and Australia."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 51),
					remarkablePoints: [
						{
							id: "T3_S1_1",
							caption: 'didascalia',
							textBefore: "_Nuovomondo_ è un film del 2006 del regista italiano Emanuele Crialese Narra la storia di una famiglia siciliana che emigra negli Stati Uniti all’inizio del XX secolo. Nella scena, la ripresa dall’alto della nave che si allontana lentamente dalla banchina del porto rappresenta, con realismo e drammaticità, l’allontanamento degli emigrati dalla terra di origine e dai loro familiari.", 
							posX: '80%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's20',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 25)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## Emigrare e apprendere l'italiano\n\n Molti italiani partirono dialettofoni e analfabeti e in molti casi furono costretti a imparare le basi dell’italiano, scritto e parlato, per comunicare con i connazionali all’estero e con la famiglia in patria.\n Da questo punto di vista la grande emigrazione italiana all’estero ha contribuito al processo di diffusione dell’italiano, anche nella Penisola.\n In una commissione parlamentare d’inchiesta sul Mezzogiorno si legge che gli emigrati «vanno via bruti e tornano uomini civili».",
						en: "## Emigrating and Learning Italian\n\n Many Italians left as dialect speakers and illiterate and, in many cases, were forced to learn the basics of Italian, both written and spoken, in order to communicate with their compatriots abroad and with their families at home.\n In this respect, the great Italian emigration contributed to the dissemination of Italian, even in Italy.\n A parliamentary committee of enquiry on the _Mezzogiorno_ \[South] stated that emigrants «vanno via bruti e tornano uomini civili» \[leave brutish and return civil]."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 52),
					remarkablePoints: [
						{
							id: "T3_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "La sezione _Literacy Act_ del primo _Immigration Act_, emanato dal Congresso statunitense nel 1917, stabiliva che non sarebbero stati accettati sul territorio statunitense tutti gli stranieri che, al di sopra dei sedici anni, non dimostravano di saper leggere e scrivere in inglese o in altra lingua o dialetto. In seguito a questo provvedimento la migrazione italiana verso gli Stati Uniti crollò e si diresse verso altre mete (Canada, Sud America e Oceania).", 
								en: "The Literacy Act section of the first Immigration Act, enacted by the US Congress in 1917, stated that all «aliens over sixteen years of age, physically capable of reading, who cannot read the English language, or some other language or dialect» would not be accepted on US soil. As a result of this measure, Italian migration to the United States collapsed and headed for other destinations (Canada, South America and Oceania)."
							},
							posX: '60%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					curiosity: {
						it: "Nell’epoca postrisorgimentale nacquero da iniziative private le società di mutuo soccorso, con l’obiettivo di tutelare dal punto di vista sanitario e sociale gli emigranti e le loro famiglie. In alcuni casi si occupavano anche di dare assistenza per fornire le basi dell’alfabetizzazione.\n Nel 1889 il poeta Giosuè Carducci fondò con altri intellettuali la Società Dante Alighieri, con lo scopo di mantenere vivo il legame linguistico tra gli emigrati italiani e l’Italia",
						en: "In the post-Risorgimento era, mutual aid societies emerged through private initiatives with the aim of providing health and social protection for emigrants and their families. In some cases they also assisted with providing the basic literacy skills.\n In 1889 the poet Giosuè Carducci founded the Dante Alighieri Society with other intellectuals, with the aim of maintaining linguistic ties between Italian emigrants and the homeland alive."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's21',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 25)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Un rapporto complesso\n\n Il rapporto tra gli emigrati italiani e la lingua d’origine muta nel corso del tempo.\n La conoscenza del dialetto, dell’italiano e della lingua del Paese di residenza cambia di generazione in generazione, e gli usi linguistici presentano interessanti fenomeni di interferenza.",
						en: "## A Complex Relationship\n\n The relationship between Italian emigrants and their language of origin has changed over time.\n Knowledge of dialect, Italian and the language of the country of residence changes from generation to generation, and linguistic usage reveals interesting phenomena of interference"
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 53),
					remarkablePoints: [
						{
							id: "T3_S2_1",
							caption: 'didascalia',
							textBefore: {
								it: "Nel video La lingua del Museo dell’emigrazione italiana online sono riportate le interviste a migranti italiani che parlano delle loro esperienze di contatto e apprendimento della lingua del Paese di destinazione.", 
								en: "The video entitled _La lingua_ \[Language], produced by the Paolo Cresci Museum for the History of Italian Emigration, contains interviews with Italian migrants talking about their experiences of contact with and learning the language of their destination country."
							},
							posX: '60%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's22',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 25)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Da Zurigo a Tunisi\n\n Il _Fremdarbeiteritalienisch_ (FAI) ‘italiano dei lavoratori’ e il siculo-tunisino sono varietà legate a periodi diversi della storia dell’emigrazione italiana. Sono presentate insieme per una ragione geografica: la vicinanza all’Italia dei paesi in cui si sono diffuse, la Svizzera e la Tunisia.",
						en: "## From Zurich to Tunis\n\n _Fremdarbeiteritalienisch_ (FAI) \[Italian of foreign workers] and Sicilian-Tunisian are varieties linked to different periods in the history of Italian emigration. They are presented together because of the  geographic proximity to Italy of the countries in which they spread, Switzerland and Tunisia."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 54),
					remarkablePoints: [
						{
							id: "T3_S2_1",
							caption: 'didascalia',
							textBefore: {
								it: "Il documentario, girato nel 1969, mostra la vita di tutti i giorni degli emigrati italiani in Svizzera. Gli operai italiani forniscono un contributo importante allo sviluppo del paese, ma vivono perlopiù isolati dal resto della società. Proprio in questi contesti lavorativi interetnici della Svizzera degli anni Ottanta si parla il Fremdarbeiteritalienisch ‘italiano dei lavoratori stranieri’.", 
								en: "The documentary, which was made in 1969, shows the everyday life of Italian emigrants in Switzerland. Italian workers contributed greatly to the development of the country, but they lived largely isolated from the rest of society. It is in these interethnic working environments in 1980s Switzerland that _Fremdarbeiteritalienisch_ \[Italian of foreign workers] is spoken."
							},
							posX: '60%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's23',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 25)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: {
						it: "## Fino al continente americano\n\n L’italiese (o itangliano), il cocoliche e il lunfardo sono varietà a base italiana nate in situazione di contatto, perlopiù nel continente americano: la prima negli Stati Uniti, in Canada e (unica eccezione fuori dall’America) in Australia; la seconda e la terza si sono diffuse in Argentina.",
						en: "## The American Continent\n\n Italiese \[Italish] (or Itangliano), Cocoliche and Lunfardo are Italian-based varieties that originated in contact situations, mostly on the American continent: the first in the United States, Canada and (the only exception outside America) Australia; the second and the third spread in Argentina."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 55),
					remarkablePoints: [
						{
							id: "T3_S3_1",
							caption: 'didascalia',
							textBefore: {
								it: "_That’s amore_ è una canzone del 1953, scritta da Harry Warren e Jack Brooks e interpretata dal cantante americano di origini italiane Dean Martin (pseudonimo di Dino Paolo Martino Crocetti). Il brano è diventato un simbolo dell’italianità nel mondo, in particolare negli Stati Uniti. Il testo della canzone è una dichiarazione di amore per la città di Napoli e presenta molte parole in italiano (_pizza_, _amore_, _vita bella_, _tarantella_, _pasta_, _signore_, _scusami_) e una in napoletano (_fasule_).", 
								en: "_That’s amore_ is a 1953 song written by Harry Warren and Jack Brooks and performed by American singer, of Italian descent, Dean Martin (a.k.a Dino Paolo Martino Crocetti). The song has become a symbol of Italianness around the world, particularly in the United States. The lyrics are a declaration of love to the city of Naples and contains many words in Italian (_pizza, amore, vita bella, tarantella, pasta, signore, scusami_) and one in Neapolitan (_fasule_)."
							},
							posX: '60%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's24',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 25)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## L'emigrazione nella letteratura italiana\n\n La grande emigrazione italiana è stata raccontata nella letteratura e nel cinema, sia in Italia sia nei Paesi di destinazione dei flussi migratori (si pensi alla fortuna che ha avuto, per esempio, nel cinema americano).\n Qui ci soffermeremo sul poemetto _Italy_ di Giovanni Pascoli (1904). ",
						en: "## Emigration in Italian Literature\n\n The great Italian emigration has been recounted in literature and cinema, both in Italy and in the destination countries of migration flows (we need only think of the fortune it has had, for example, in American cinema).\n Here we will focus on the poem _Italy_ by Giovanni Pascoli (1904)."
					},
					remarkablePoints: [
						{
							id: "T3_S4_1",
							caption: 'didascalia',
							textBefore: {
								it: "Il poemetto, ispirato a un episodio realmente accaduto, narra del ritorno in Italia (precisamente in Lucchesia) di una famiglia di emigrati negli Stati Uniti, composta dai genitori Ghita e Beppe e dalla figlioletta Maria, malata di tisi. La bimba parla solo inglese e non riesce a comunicare con la nonna mentre il padre Beppe parla un italiese simile a quello della comunità italoamericana con tanti adattamenti di termini inglesi, come «pai con fleva» ossia, torta [_pie_] con aromi [_flavour_].", 
								en: "The poem, inspired by a real-life episode, tells of the return to Italy (specifically to the Lucchesia) of a family of emigrants to the United States, consisting of the parents Ghita and Beppe and their little daughter Maria, who is sick with consumption. The child only speaks English and is unable to communicate with her grandmother, while her father Beppe speaks an Italian dialect similar to that of the Italo-American community with many adaptations of English terms (_pai con fleva_ \[pie with flavour])."
							},
							posX: '60%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's25',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 26)
					},
					steps: [{}, {}, {}, {}],
					numSteps: 4,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "Gli italianismi sono prestiti dall’italiano a un’altra lingua e sono un vero e proprio termometro del successo dell’italianità nel mondo e dell’influenza dell’italiano nelle altre lingue.",
						en: "Italianisms are words borrowed from Italian by another language. They are a good gauge of the success of Italianness in the world and the influence of Italian on other languages."
					},
					remarkablePoints: [
						{
							id: "T4_S1_1",
							caption: 'didascalia',
							textBefore: {
								it:"Gli italianismi _dolce vita_, _paparazzo_ e _spaghetti western_ si sono diffusi grazie al cinema di Federico Fellini e Sergio Leone.\n _Dolce vita_ è entrato come prestito non adattato in molte lingue straniere con il significato di ‘tendenza a vivere seguendo solo l’istinto e il piacere'. L’italianismo _paparazzo_ in alcune lingue (francese, portoghese e spagnolo) è presente nella forma al plurale _paparazzi_.", 
								en: "The Italianisms _dolce vita, paparazzo_ and _spaghetti western_ spread thanks to the cinema of Federico Fellini and Sergio Leone.\n _Dolce vita_ has entered as an unadapted loanword in many foreign languages with the meaning of ‘tendency to live following only instinct and pleasure’. The Italianism _paparazzo_ in some languages (French, Portuguese and Spanish) occurs in the plural form _paparazzi_."
							},
							posX: '60%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 56),
					curiosity: {
						it: "I **prestiti** sono parole o espressioni di una lingua che entrano nel vocabolario di un’altra lingua. I prestiti che mantengono la struttura linguistica della lingua di partenza si definiscono **non adattati** (il francese _camion_ in italiano), i prestiti che si adeguano totalmente o parzialmente alla struttura della lingua di arrivo si chiamano **adattati** (l’italiano _bistecca_ dall’inglese _beef steak_).",
						en: "**Loanwords** are words or expressions from one language that enter the vocabulary of another language. Loanwords that retain the linguistic structure of the source language are called **unadapted** (e.g. French word _camion_ \[lorry] in Italian), loans that adapt totally or partially to the structure of the target language are called **adapted** (Italian _bistecca_ from English _beef steak_)."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's26',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 26)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-red',
					caption: {
						it: "## Sembrava italiano e invece...\n\n Gli pseudoitalianismi sono falsi italianismi, cioè parole che suonano italiane ma che in realtà non lo sono.\n Anche gli pseudoitalianismi sono indicatori dell’influenza culturale dell’Italia nel mondo. ",
						en: "## It Sounds Italian but in Reality…\n\n Pseudoitalianisms are false Italianisms, i.e. words that sound Italian but in reality are not.\n Pseudoitalianisms, too, are indicators of Italy’s cultural influence in the world."
					},
					remarkablePoints: [
						{
							id: "T4_S2_1",
							caption: 'didascalia',
							textBefore: {
								it: "Lo pseudoitalianismo _tutti frutti_, con l’accezione di “profumato o composto da più frutti” ha dato il nome a una marca di gomme da masticare, venduta in molti paesi all’estero.", 
								en: "The pseudoitalianism _tutti frutti_, meaning “many fruit flavours or consisting of many fruits”, was the name of a chewing gum brand sold in many countries."
							},
							posX: '60%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					curiosity: {
						it: "Il **calco** è un particolare tipo di prestito. È **strutturale** quando la struttura della parola della lingua di partenza e la struttura della parola della lingua di arrivo coincidono (l’italiano _luna di miele_ dall’inglese _honeymoon_); **semantico** quando una parola già esistente in una lingua acquista un ulteriore significato per l’influenza di un’altra (l’italiano _angolo_ con significato di ‘calcio d’angolo’ dall’inglese _corner_).",
						en: "A **calque** is a type of loanword. It is **structural** when the word structure of the source language and the word structure of the target language are the same (the Italian _luna di miele_ from the English _honeymoon_); it is **semantic** when a word that already exists in one language acquires a further meaning through the influence of another (the Italian _angolo_ meaning ‘corner kick’ from the English _corner_)."
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 57),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's27',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 26)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Marineria, navigazione, arte militare, commercio e finanza...\n\n Nel Medioevo la maggior parte delle parole italiane che entrano nel lessico di molte lingue straniere appartiene alla marineria, alla navigazione, all’arte militare (grazie all’influenza delle repubbliche marinare italiane) o al commercio e alla finanza (per l’attività di commercianti e banchieri italiani provenienti soprattutto dal Centro-Nord).\n Tra queste: _gondola_, _bussola_, _soldato_, _fiorino_ e molte altre.",
						en: "## Seafaring, Navigation, Military Art, Commerce and Finance...\n\n In the Middle Ages, most of the Italian words that entered the lexicon of many foreign languages belonged to seafaring, navigation, military art (thanks to the influence of the Italian maritime republics) or to trade and finance (due to the activities of merchants and bankers mainly from central and northern Italy).\n These include: _gondola_, _bussola_ \[compass] _soldato_ \[soldier], _fiorino_ \[florin] and many others."
					},
					remarkablePoints: [
						{
							id: "T4_S3_1",
							caption: 'didascalia',
							textBefore: {
								it: "L’italianismo _gondola_ è entrato a far parte del vocabolario di moltissime lingue: tra queste albanese (_gondolȅ_), ceco (_gondola_), danese (_gongol_), francese (_gondole), greco moderno (_gòndola_), inglese (_gondola_), olandese (_gondel_), polacco (_gondola_), romeno (_gondolă), serbocrato (_gondola_), spagnolo (_gòndola_), tedesco (_Gondel_), turco (_gondol_), ungherese (_gondola_).", 
								en: "The Italianism _gondola_ has become part of the vocabulary of many languages: among them Albanian (_gondolȅ_), Czech (_gondola_), Danish (_gongol_), French (_gondole_), Modern Greek (_gòndola_), English (_gondola_), Dutch (_gondel_), Polish (_gondola_), Romanian (_gondolă_), Serbo-Croatian (_gondola_), Spanish (_gòndola_), German (_Gondel_), Turkish (_gondol_), Hungarian (_gondola_)."
							},
							posX: '60%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 58),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's28',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 26)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Architettura, arte, teatro, musica...\n\n Dal Rinascimento al Settecento sono numerosi gli italianismi che si diffondono in tutto il mondo; arte, teatro e musica sono sicuramente i principali ambiti di trasmissione.\n _Loggia_, _affresco_, _piano_, _mandolino_, _sonetto_ sono alcune di queste parole.",
						en: "## Architecture, Art, Theatre, Music... \n\n From the Renaissance to the 18th century, numerous Italianisms spread throughout the world; art, theatre and music are undoubtedly the main areas of dissemination.	n _Loggia_, _affresco_ \[fresco], _piano_, _mandolino_ \[mandolin] and _sonetto_ \[sonnet] are some of these words."
					},
					remarkablePoints: [
						{
							id: "T4_S4_1",
							caption: 'didascalia',
							textBefore: {
								it: "L'italianismo _fresco_ è entrato nel lessico inglese nella forma non adattata; in tedesco, polacco e ungherese invece la _c _è stata sostituita con una _k_. In francese si è trasformato in _fresque_. In inglese _fresco_ ha gli stessi significati che ha in italiano _affresco_.", 
								en: "The Italian word _fresco_ entered the English lexicon in the unadapted form; in German, Polish and Hungarian the _c_ was replaced with a _k_. In French the Italian word has been changed to _fresque_. In English _fresco_ has the same meanings as the Italian _affresco_."
							},
							posX: '60%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 59),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's29',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 26)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: {
						it: "## A tavola\n\n Il grande successo della cucina italiana nel mondo ha determinato la fortuna di tante parole italiane che sono entrate nel lessico di molte lingue, persino quelle dei paesi più lontani.\n Il fenomeno è perlopiù recente, ma non mancano italianismi gastronomici molto antichi.",
						en: "## At Table\n\n The great success of Italian cuisine in the world has determined the fortune of many Italian words that have entered the lexicon of many languages, even those of the most distant countries.\n While the phenomenon is mostly recent, there is no shortage of very old gastronomic Italianisms."
					},
					remarkablePoints: [
						{
							id: "T4_S5_1",
							caption: 'didascalia',
							textBefore: {
								it: "_The Paintress of Maccaroni's_ è un dipinto del 1770 del pittore britannico Robert Dighton. L’opera testimonia che l’italianismo _maccarone_, entrato nel lessico inglese con la forma adattata _maccaroni_, assunse in quella lingua anche un altro significato; veniva usato infatti per fare riferimento, ironicamente, alle persone di bassa estrazione sociale che imitavano l’abbigliamento e le maniere dei ricchi aristocratici che partivano per il Grand Tour.", 
								en: "The _Paintress of Maccaroni’s_ is a 1770 painting by the British painter Robert Dighton. The work bears witness to the fact that the Italianism _maccarone_, which entered the English lexicon with the adapted form _maccaroni_, also took on another meaning; it was in fact used to refer, ironically, to people of low social standing who imitated the dress and manners of the rich aristocrats who went on the Grand Tour."
							},
							posX: '10%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 60),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's30',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 26)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Per finire: dalla Chiesa alla scienza, dal cinema al calcio.\n\n Oltre agli ambiti già menzionati, che hanno giocato un ruolo da protagonisti nella diffusione di numerosi italianismi, ci sono tanti altri campi semantici che meritano attenzione, soprattutto per alcuni casi specifici.\n Dalla Chiesa cattolica (soprattutto dalla Controriforma in poi) ai grandi nomi italiani della scienza. Dal cinema al calcio.",
						en: "## To Conclude: from the Church to Science, from Cinema to Football.\n\n In addition to the areas already mentioned, which have played a leading role in the spread of numerous Italianisms, there are many other semantic fields that deserve attention, especially due to certain specific cases.\n They range from the Catholic Church (especially from the Counter-Reformation onwards) to the great Italian names in science, and from cinema to football."
					},
					remarkablePoints: [
						{
							id: "T4_S6_1",
							caption: 'didascalia',
							textBefore: {
								it: "L’illustrazione realizzata per il giornale francese _Le Petit journal_ il 9 agosto 1903 in occasione del conclave che elesse papa Leone XIII presenta nella didascalia l’italianismo non adattato nel francese.  La parola _conclave_ è stata presa in prestito da altre lingue, tra queste l’inglese (anche qui con la forma non adattata _conclave_) e il polacco (adattata in _konklawe_).",
								en: "The illustration published by the French newspaper _Le Petit journal_ on 9 August 1903 on the occasion of the conclave that elected Pope Leo XIII has the unadapted Italianism in the caption. The word _conclave_ was borrowed by other languages, including English (again unadapted _conclave_) and Polish (adapted to _konklawe_)."
							},
							posX: '10%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 61),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's31',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 27)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "Il quadro linguistico dell’Italia, già storicamente complesso per la presenza di minoranze linguistiche e dialetti (plurilinguismo endogeno), si arricchisce ulteriormente dagli anni Ottanta e Novanta del XX secolo con l’arrivo in Italia delle comunità immigrate.\n Proprio negli anni in cui la maggior parte degli italiani parla ormai italiano, la realtà linguistica della Penisola entra in contatto con altre lingue (plurilinguismo esogeno), parlate dagli immigrati di prima e seconda generazione.",
						en: "Italy's linguistic situation, already historically complex due to the presence of linguistic minorities and dialects (endogenous plurilingualism), has been further enriched since the 1980s and 1990s with the arrival of immigrant communities in Italy.\n In the very years in which most Italians now spoke Italian, the Italian peninsula came into contact with other languages (exogenous plurilingualism), spoken by first and second generation immigrants."
					},
					remarkablePoints: [
						{
							id: "T5_S1_1",
							caption: 'didascalia',
							textBefore: "_Terraferma_ (2011) del regista italiano Emanuele Crialese racconta un incontro tra una famiglia di pescatori di un’isola siciliana e un gruppo di migranti africani sbarcati clandestinamente sull’isola. Nella scena il pescatore Ernesto e il nipote Filippo, al largo per pescare, si imbattono in un gommone con a bordo dei migranti africani. Ernesto, nel rispetto della legge del mare e dell’umanità, salva alcuni migranti che si erano tuffati in acqua per raggiungere il peschereccio.",
							posX: '70%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 62),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's32',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 27)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## «_So_ italiano, diciamo più _na via de mezzo_»\n\n Un film come _Bangla_ (2019) riproduce il repertorio linguistico delle seconde generazioni (cioè dei bambini stranieri nati in Italia o arrivati nei primi anni di vita); il protagonista capisce la lingua bengali, parlata in famiglia e nella comunità bengalese, ma parla anche l’italiano (spesso nella varietà dell’italiano regionale di Roma) e il dialetto romanesco (_so_ per “sono” e _na via de mezzo_ per “una via di mezzo”).",
						en: "## «_So_ italiano, diciamo più _na via de mezzo_» [I’m Italian, actually half and half]\n\n The film _Bangla_ (2019) reproduces the linguistic repertoire of second generations (i.e. foreign children who were either born in Italy or arrived in their early years); the protagonist understands Bengali, spoken in the family and in the Bengali community, but he also speaks Italian (often in the variety of regional Italian of Rome) and the Roman dialect (_so_ stands for “I am” and _na via de mezzo_ for “half and half”).",
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 63),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's33',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 27)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Tracce di lingue immigrate nell'italiano\n\n Come si è visto nella tappa precedente, la lingua italiana si è diffusa nel mondo tramite italianismi lessicali e semiotici. Lo stesso fenomeno riguarda anche la diffusione delle lingue immigrate nel panorama linguistico (lessicale e semiotico) italiano.",
						en: "## Traces of Immigrant Languages in Italian\n\n As we have seen in the previous section, Italian has spread throughout the world through lexical and semiotic Italianisms. The same phenomenon is occurring with the spread of immigrant languages in the (lexical and semiotic) Italian linguistic landscape.",
					},
					remarkablePoints: [
						{
							id: "T5_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "_Il tempio delle culture migranti_ è un murale di oltre 50 metri di Carlos Atoche e si trova a Torpignattara, quartiere multietnico di Roma. L’artista di strada di origini latinoamericane spiega il significato dell’opera definendola “un posto, un tempio immaginario all'aperto, dove possono convivere culture differenti, nella geografia e nel tempo, inesorabilmente legate nell'aspetto rituale, spirituale e simbolico”. Una iconica rappresentazione dell’incontro e della coesistenza di culture e lingue diverse.",
								en: "_Il tempio delle culture migranti_ \[Temple of migrant cultures] is a mural over 50 metres long by Carlos Atoche and is located in Torpignattara, a multi-ethnic neighbourhood in Rome. The street artist, of Latin American origin, explains the meaning of the work, defining it as «a place, an imaginary open-air temple, where different cultures can coexist, in geography and time, inexorably linked through ritual, the spiritual and the symbolic». It is a symbolic representation of the contact between and the coexistence of different cultures and languages."
							},
							posX: '70%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 64),
					curiosity: {
						it: "Un’ulteriore suddivisione dei prestiti è quella tra prestiti di necessità e prestiti di lusso e riguarda perlopiù i prestiti lessicali. Si definiscono **di necessità** i prestiti a una lingua in cui non c’è un termine corrispettivo per definire lo stesso oggetto o concetto.\n Al contrario si chiamano **di lusso** i prestiti che avvengono anche quando nella lingua di arrivo c’è già una parola o un’espressione per denotare un oggetto o un concetto.",
						en: "A further subdivision of loanwords is that between necessary loans and luxury loans and mostly concerns lexical loans. **Necessary loans** are defined as loans to a language in which there is no corresponding term to define the same object or concept.\n Conversely, loans that occur even when there is already a word or expression to denote an object or concept in the target language are called **luxury loans**.",
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's34',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 27)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## _Soldi_, ma non solo\n\n Recentemente le lingue immigrate sono arrivate anche nel panorama musicale italiano, portando un forte segnale di originalità e novità.\n La vittoria di Sanremo 2019 del cantante italiano di origini egiziane Mahmood (immigrato di seconda generazione) con la canzone _Soldi_, per quanto rappresentativo, è solo un caso tra i tanti.",
						en: "## Not just _Soldi_\n\n Immigrant languages have also recently arrived on the Italian music scene, bringing originality and novelty. \n The victory of the Italian singer, of Egyptian origin, Mahmood (second-generation immigrant) in the 2019 Sanremo Song Festival with the song _Soldi_ (Money), is just one of many examples."
					},
					remarkablePoints: [
						{
							id: "T5_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "Il cantante italiano di origini egiziane Mahmood vince l’edizione 2019 del Festival di Sanremo (il più noto Festival della canzone italiana) con il singolo _Soldi_. Nel testo della canzone ci sono prestiti di necessità dall’arabo come _Ramadan_ e un'intera frase in arabo «Walad-i walad-i ḥabīb-i taʕāla hina», che in italiano vuol dire “Figlio mio, figlio mio, amore vieni qua”.",
								en: "Egyptian-born Italian singer Mahmood won the 2019 edition of the Sanremo Festival (the best-known Italian song festival) with the single _Soldi_ \[Money]. In the lyrics of the song there are necessary borrowings from Arabic such as Ramadan and a whole phrase in Arabic 'Walad-i walad-i ḥabīb-i taʕāla hina', which in Italian means “Son of mine, son of mine, love come here”."
							},
							posX: '70%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's35',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 27)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## La grande sfida della letteratura\n\n La letteratura della migrazione in Italia comprende la produzione letteraria di scrittrici e scrittori immigrati in lingua italiana che hanno vissuto direttamente l’esperienza migratoria o che sono immigrati di seconda generazione.\n Inizia convenzionalmente nel 1989 con il romanzo _Io, venditore di elefanti_ di Pap Khouma e la prima fase è contraddistinta da una “collaborazione” tra autori stranieri e italiani.\n Il repertorio linguistico si presenta ricco e vario perché nasce dall’incontro tra il plurilinguismo esogeno (lingue di origine dei migranti) e il plurilinguismo endogeno (italiano e dialetti).",
						en: "## The Great Literary Challenge\n\n Migrant literature in Italy comprises the literary work in Italian of immigrant writers who have directly experienced migration or who are second-generation immigrants.\n It conventionally begins in 1989 with the novel _Io, venditore di elefanti_ \[I, an elephant seller] by Pap Khouma. The first phase was characterised by a “collaboration” between foreign and Italian authors.\n The linguistic repertoire is rich and varied because it stems from the contact between exogenous plurilingualism (migrants' languages of origin) and endogenous plurilingualism (Italian and dialects)."
					},
					remarkablePoints: [
						{
							id: "T5_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "Sabrina Efionayi, scrittrice italiana afrodiscendente, racconta il plurilinguismo nella sua produzione letteraria ed esprime le sue considerazioni sulla letteratura della migrazione in Italia.",
								en: "Sabrina Efionayi, an Italian writer of African descent, talks about multilingualism in her literary work and expresses her thoughts on migrant literature in Italy."
							},
							posX: '70%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 65),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's35-game',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 27)
					},
					steps: [{}],
					numSteps: 1,
				},
				{
					id: 's36',
					name: 'bibliography',
					stage: {
						...stagesData.stagesData.find(el => el.id == 28)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-extra-light-gold',
					caption: {
						it: 'L\'italiano circola con la scrittura epistolare, un importante mezzo di comunicazione utile a rispondere a esigenze pratiche. Scrittura di getto, schiettezza e spontaneità sono tra le caratteristiche della scrittura delle lettere, che accolgono, perciò, anche diversi tratti linguistici tipici della lingua parlata.',
						en: 'Italian circulates with epistolary writing, an important means of communication serving practical needs. Spur-of-the-moment writing, directness and spontaneity are among the characteristics of letter-writing, which therefore also includes various linguistic traits typical of the spoken language.'
					},
					template: 'left',
				}
			]
		},
		// VOCE
		{
			id: 5,
			nameCode: 'come-diffuso-italiano-parlato',
			nameArray: {
				it: ["come si", "è diffuso", "l'italiano",  "parlato?"],
				en: ["How Did", "Spoken", "Italian", "Circulate?"],
			},
			name: {
				it: "Come si è diffuso l'italiano parlato?",
				en: "How Did Spoken Italian Circulate?"
			},
			description: {
				it: 'L’italiano parlato è una conquista recente. Per secoli in Italia si sono parlati i dialetti (e anticamente i volgari). Il viaggio che ha portato alla lingua comune, segnato in particolare dall’Unità d’Italia e dai grandi cambiamenti che da allora a oggi sono conseguiti, è stato lungo e avvincente. Ha attraversato piazze e palcoscenici, sale da concerto e studi di registrazione. E non è ancora finito. Perché a voce la lingua viaggia veloce. Di bocca in bocca. Di persona in persona. Cambia, si adatta, si rinnova. E con lei un’intera comunità.',
				en: 'Spoken Italian is a recent conquest. For centuries, dialects (and formerly vernaculars) were spoken in Italy.\n The journey that led to the common language, marked in particular by the Unification of Italy and the great changes that have taken place since then, has been long and exciting: it has crossed squares and stages, concert halls and recording studios.\n And it is not over yet.\n Because spoken language travels fast.\n From mouth to mouth. From person to person.\n It changes, it adapts, it renews itself. And with it a whole community.'
			},
			introImages: [
				require('@assets/images/percorsi/voce/intro/' + utils.manageImagesExtension('Verdi_High.png')),
				require('@assets/images/percorsi/voce/intro/' + utils.manageImagesExtension('Predicatore_High.png')),
				require('@assets/images/percorsi/voce/intro/' + utils.manageImagesExtension('Radio_High.png')),
				require('@assets/images/percorsi/voce/intro/' + utils.manageImagesExtension('Credente_High.png'))
			],
			artifactFromScrolliesFile: false,
			artifactFromScrolliesExtra: {
				's7-1': 16,
				's7-2': 16,
			},
			sections: [
				{
					id: 's1',
					name: 'introduzione',
					isFixed: true,
					stage: {
					...stagesData.stagesData.find(el => el.id == 30)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: '',
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: ''
				},
				{
					id: 's2',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 31)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "La predicazione è il primo fenomeno di **comunicazione di massa** con cui, per secoli, si sono trasmessi saperi, storie, nozioni religiose.\n Ma è anche una delle rare occasioni di contatto tra il popolo, abituato a parlare nella propria varietà locale e ignaro di latino, e la cultura alta.",
						en: "Preaching is the first mass communication phenomenon through which, for centuries, knowledge, stories and religious concepts were conveyed.\n But it is also one of the rare occasions of contact between the common people, who spoke in their own local variety and were unaware of Latin, and high culture."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T1_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "San Bernardino tenne un ciclo di prediche presso piazza del Campo, a Siena, nel 1427. Per 45 giorni, all’alba, il santo salì sul pulpito di legno realizzato per l’occasione per celebrare la messa e per rivolgere agli astanti, che seguivano rapiti, una delle sue famose orazioni sacre, con cui istruiva su temi religiosi e no, raccontava episodi delle storie sacre, ammoniva i fedeli, li intratteneva e, indirettamente, insegnava loro la lingua.",
								en: "Saint Bernardine gave a series of sermons in Piazza del Campo, Siena, in 1427. For 45 days, at dawn, he climbed up onto the wooden pulpit prepared for the occasion to celebrate mass and to address those listening, who followed with rapt attention, one of his famous holy sermons, in which he instructed them on religious and non-religious topics, recounted episodes from sacred stories, admonished the faithful, entertained them and, indirectly, taught them language."
							},
							posX: '50%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],	
					curiosity: {
						it: "Veramente già nel lontano 813 (!) il Concilio di Tours disponeva che nella predicazione si usasse la lingua del popolo e non il latino.\n Disposizione che, purtroppo, in molti casi è rimasta inascoltata per secoli.",
						en: "Actually, as far back as 813 AD (!), the Council of Tours had decreed that the language of the people, and not Latin, should be used when preaching.\n A decree that, unfortunately, in many cases went unheeded for centuries."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'normal',
					additionalStyleSectionContent: '',
				},
				{
					id: 's3',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 31)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Chiarozzo, chiarozzo...\n\n Tra i predicatori più noti del Quattrocento c’è san Bernardino da Siena (1380-1444).\n Sceglie un linguaggio semplice, «chiarozzo chiarozzo», per dirla con le sue parole, in modo che tutti possano capirlo.",
						en: "## Plainly Plainly...\n\n Saint Bernardine of Siena (1380-1444) was one of the best known preachers of the 15th century.\n He chose a plain, direct language, «chiarozzo chiarozzo» [plainly plainly] in his words, so that everyone could understand him."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T1_S2_1",
							caption: 'didascalia',
							textBefore: {
								it: "Il trigramma ideato da San Bernardino è uno stemma che rappresenta Gesù: le tre lettere (_I_, _H_, _S_) stanno per _Iesus hominum salvator_ (Gesù salvatore degli uomini). Il simbolo diventa una costante della rappresentazione del santo, come accade nel dipinto di Giovanni di ser Giovanni Guidi, in cui Bernardino, durante una sua predicazione, tiene nella mano sinistra proprio il trigramma.",
								en: "The Christogram devised by Saint Bernardine is a monogram representing Jesus: the three letters (I, H, S) stand for _Iesus hominum salvator_ (“Jesus saviour of men”). The symbol became associated with the depiction of the saint, as in the painting by Giovanni di ser Giovanni Guidi, in which Bernardine, during one of his sermons, holds the Christogram in his left hand."
							},
							posX: '76%',
							posY: '28%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's4',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 31)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## A Siena tra esclamazioni e interiezioni\n\n Le 45 prediche tenute nel 1427 in piazza del Campo a Siena sono state trascritte da uno dei fedeli, Benedetto di Bartolomeo.\n Colpisce la cura nella registrazione delle parole del santo, in particolare delle espressioni tipiche del discorso orale come le interiezioni e le esclamazioni _Doh!_ (tipicamente senese), _Oh, oh, oh!_, _ooh!_, _uuuh!_, o anche incertezze e pause (_Non più di questo… Oh, oh, oh! aspetta, aspetta_).",
						en: "## Exclamations and Interjections in Siena\n\n The 45 sermons delivered in 1427 in Siena's Piazza del Campo were transcribed by one of the faithful, Benedetto di Messer di Bartolomeo.\n The care taken in recording the saint's words is striking, especially the expressions typical of oral speech such as the interjections and exclamations _Doh!_ (typically Sienese), _Oh, oh!_, _ooh!_, _uuuh!_"
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T1_S3_1",
							caption: 'didascalia',
							textBefore: {
								it: "Il ciclo di prediche è tramandato, in modo completo o parziale, da ben 21 codici, quasi tutti risalenti al XV secolo. ",
								en: "The cycle of sermons is handed down, in full or in part, through no less than 21 manuscripts, almost all dating back to the 15th century.",
							},
							posX: '70%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 76),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's5',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 31)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: {
						it: "## «Dissi a te popolo fiorentino…»\n\n Il frate domenicano Girolamo Savonarola (1452-1498) scrisse delle infuocate prediche rivolte ai fiorentini contro la corruzione della Chiesa e i politici del tempo.\n Prediche che rappresentarono una «straordinaria impresa di educazione linguistica popolare» (Vittorio Coletti).",
						en: "## «I Said to You, People of Florence…»\n\n The Dominican friar Girolamo Savonarola (1452-1498) wrote fiery sermons addressed to the Florentines against the corruption in the Church and the leaders of the time.\n These sermons were an «extraordinary feat of popular language education» (Vittorio Coletti)."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T1_S4_1",
							caption: 'didascalia',
							textBefore: {
								it: "Nato a Ferrara nel 1452, Savonarola è passato alla storia soprattutto per aver instaurato la Repubblica a Firenze dopo la cacciata di Piero II de’ Medici, tra il 1494 e il 1498. Proprio nel 1498, la mattina del 23 maggio, fu impiccato e bruciato sul rogo in piazza della Signoria insieme ad altri due religiosi.",
								en: "Born in Ferrara in 1452, Savonarola went down in history above all for having established a republic in Florence after the ousting of Piero II de' Medici, between 1494 and 1498. It was on the morning of 23 May 1498 that he was hanged and burned at the stake in Piazza della Signoria, together with two other friars."
							},
							posX: '70%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					curiosity: {
						it: "Savonarola è ancora oggi ben presente nell’immaginario comune. A lui, nel film _Non ci resta che piangere_ (1984), Roberto Benigni e Massimo Troisi indirizzano una famosa lettera che inizia così: «Santissimo Savonarola, quanto ci piaci a noi due!».\n Ma compare anche in produzioni più recenti, sia televisive (nella serie _I Medici_, interpretato da Francesco Montanari), sia d’intrattenimento (nel videogioco _Assassin’s Creed II_), sia musicali (_Sono il tuo sogno eretico_ di Caparezza).",
						en: "Savonarola is still very present in the popular imagination today. In the film _Non ci resta che piangere_ \[_Nothing Left to Do But Cry_] (1984), Roberto Benigni and Massimo Troisi address a famous letter to him that begins: «Most Holy Savonarola, how we two adore you!».\n But he has also appeared more recently on television (in the series _Medici_, played by Francesco Montanari), in entertainment (in the video game _Assassin's Creed II_), and in music (Caparezza's _Sono il tuo sogno eretico_ \[_I'm your Heretic Dream_])."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's6',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 31)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "## Tra il dire e il... predicare\n\n Se ancora Savonarola mostrava vicinanza alla lingua del popolo, i predicatori del Cinque-Seicento adottano un tono più alto e letterario, lontano dal parlato.\n È il caso del milanese Francesco Panigarola (1548-1594) che, pur professando idee di semplicità nel suo manuale _Sul modo di comporre una predica_ (1591), usa nelle prediche uno stile ormai barocco.",
						en: "## To Say or… to Preach, that is the Question…\n\n While Savonarola’s language was still close to that of the people, the preachers of the 16th-17th century adopted a higher and more literary tone, which was very distant from the spoken word.\n This is true of the preacher Francesco Panigarola from Milan (1548-1594), who, while professing ideas plain language in his manual _Sul modo di comporre una predica_ \[_How to Prepare a Sermon_] (1591), uses what was then a Baroque style in his sermons."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T1_S5_1",
							caption: 'didascalia',
							textBefore: {
								it: "In questo passo, tratto da una sua predica, Panigarola fa uso della strategia retorica dell’accumulazione (una delle preferite dai predicatori barocchi), che consiste nell’allineare una grande quantità di parole o espressioni al fine di stupire il lettore con la propria ricchezza lessicale.",
								en: "In this passage, taken from one of his sermons, Panigarola makes use of the rhetorical device of accumulation (a favourite of Baroque preachers), which consists of putting together a large number of words or expressions in order to astonish the reader with his lexical richness."
							},
							posX: '70%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's7',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 31)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## «Missa est» o «La messa è finita»?\n\n Nei secoli successivi il tono delle prediche torna a semplificarsi e avvicinarsi al popolo.\n Al contrario, la lingua della liturgia rimane ancora a lungo il latino, inarrivabile per moltissimi fedeli.\n Pensate che **la messa**, la più comune funzione religiosa, ha smesso di essere detta in latino solo il 7 marzo 1965, quando papa Paolo VI celebrò la prima messa in italiano.",
						en: "## «Missa est» or « The Mass is Over»?\n\n In the following centuries, the tone of sermons once again became simpler and closer to the people.\n In contrast, Latin, incomprehensible to many of the faithful, remained the language of the liturgy for a long time.\n We need only think that the Mass, the most common religious service, only stopped being said in Latin on 7 March 1965, when Pope Paul VI celebrated the first Mass in Italian."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T1_S6_1",
							caption: 'didascalia',
							textBefore: {
								it: "La messa celebrata da Paolo VI la prima domenica di Quaresima del 1965 nella parrocchia di Ognissanti sull’Appia Nuova rappresenta un momento storico per la Chiesa. Il passaggio dal latino all’italiano, che permette finalmente a tutti i fedeli di seguire la liturgia a prescindere dal loro livello di istruzione, è una conseguenza di ciò che fu stabilito qualche anno prima all’interno del Concilio Vaticano II (1962-1965).",
								en: "The Mass celebrated by Paul VI on the first Sunday of Lent 1965 in the parish of All Saints on the Appia Nuova was a historic moment for the Church. The change from Latin to Italian, which finally allowed all the faithful to follow the liturgy regardless of their level of education, was a consequence of what had been decreed a few years earlier by the Second Vatican Council (1962-1965)."
							},
							posX: '70%',
							posY: '40%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					curiosity: {
						it: "Se ancora oggi il latino è la lingua “ufficiale” del Vaticano (con tanto di Ufficio delle Lettere Latine della Segreteria di Stato vaticana preposto alla stesura dei testi in latino, anche i tweet!), la lingua “universale” è diventata però l’italiano.\n Celebre il discorso di Benedetto XVI al campo di Auschwitz-Birkenau del 28 maggio 2006 in italiano, e non in tedesco.\n Fateci caso: nei viaggi all’estero il Papa parla quasi sempre in italiano. Insomma, una bella rivincita!",
						en: "While Latin is still the “official” language of the Vatican (with the Office of Latin Letters of the Vatican Secretariat of State responsible for Latin texts, even tweets!), the “universal” language has become Italian.\n Pope Benedict XVI's speech at the Auschwitz-Birkenau camp on 28 May 2006 in Italian, not German, is famous.\n Listen and you will observe how on trips abroad, the Pope almost always speaks in Italian. Revenge is sweet!"
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's8',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 31)
					},
					steps: [{}, {}, {}, {}],
					numSteps: 4,
					sectionStyle: 'bg-gold-animated',
					caption: {
						it: "##Conoscete donna Bissodia?\n\n «Che vuol ch'io faccia del suo latinorum?» dice Renzo a Don Abbondio, confuso dalle citazioni latine che non capisce.\n Per secoli il popolo non istruito ha faticato a seguire la liturgia in latino: da qui una serie di incomprensioni che hanno dato vita a personaggi come donna Bissodia, nata dall’errata interpretazione della locuzione latina _da nobis hodie_ (“dai oggi a noi”) del _Pater noster_.",
						en: "##Have You Ever Heard of Donna Bissodia?\n\n «What good is your _Latinorum_ to me?» says Renzo to Don Abbondio in Manzoni’s _The_ _Betrothed_, confused by the Latin quotations he does not understand.\n For centuries, the uneducated populace struggled to follow the liturgy in Latin: hence a series of misunderstandings that gave rise to characters like _donna Bissodia_, born from the misinterpretation of the Latin expression _da nobis hodie_ \[give us today] of the _Pater Noster_ \[_Our Father_]."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T1_S7_1",
							caption: 'didascalia',
							textBefore: {
								it: "L’origine del personaggio è illustrata da Antonio Gramsci in una delle sue lettere. Nella missiva del 16 novembre 1931, indirizzata alla sorella Teresa, Gramsci ci dà infatti una testimonianza e insieme una spiegazione sull’origine di donna Bissodia. Ecco le sue parole:\n«ti ricordi che zia Grazia credeva fosse esistita una “donna Bisodia” molto pia, tanto che il suo nome veniva sempre ripetuto nel Pater noster? Era il “dona nobis hodie” che lei, come molte altre, leggeva “donna Bisodia” e impersonava in una dama del tempo passato, quando tutti andavano in Chiesa e c’era ancora un po’ di religione in questo mondo».",
								en: "Antonio Gramsci describes the origin of the character in one of his letters. In his letter of 16 November 1931 to his sister Teresa, Gramsci gives us an account and at the same time an explanation of the origin of _donna Bissodia_. This is what he says:\n «Do you remember that aunt Grazia believed there was a very pious “donna Bisodia”, so much so that her name was always repeated in the Our Father? It was the expression “dona nobis hodie” that she, like many others, turned into “donna Bisodia” a lady of the past, when everyone went to church and there was still some religion in this world»."
							},
							posX: '70%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					curiosity: {
						it: "Donna Bissodia non soffre certo di solitudine. A farle compagnia ci sono altre figure inventate dal popolo secondo gli stessi meccanismi.\n Tra queste c’è Tenenosse, nato dall’errata riformulazione del passo latino _et ne nos inducas_ (“e non ci indurre”) come _Tenenosse in du’ casse_.\n Questo personaggio, citato nei sonetti romaneschi di Giuseppe Gioachino Belli, è visto come simbolo del lusso proprio perché avrebbe chiesto di farsi seppellire in «du’ casse»! Belli ci dà notizia anche di un certo er Nocchilìa, nato dalla fusione dei nomi dei profeti Enoc ed Elia; sarà lui (secondo le credenze) che scaccerà l’Anticristo nel Giorno del giudizio.",
						en: "Donna Bissodia was not a solitary figure. Keeping her company are other characters invented by the people in similar ways.\n They include Tenenosse, born from the incorrect rephrasing of the Latin words _et_ _ne nos inducas_ \[and do not lead us] as _Tenenosse in du' casse_ \[Hold us in two coffins].\n This character, quoted in Giuseppe Gioachino Belli's Romanesque sonnets, was seen as a symbol of luxury precisely because he allegedly asked to be buried in «du' casse» \[two coffins]! Belli also gives us news of a certain _er Nocchilìa_, born from the fusion of the names of the prophets Enoch and Elijah; it will be he (according to popular belief) who will drive out the Antichrist on Judgement Day."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's9',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 32)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "«Se c’è in Europa una lingua adatta alla musica, è certamente la lingua italiana, poiché è una lingua dolce, sonora, armoniosa».\n Così scrive il filosofo francese Jean-Jacques Rousseau, riconoscendo nella nostra lingua i tratti dell’**armonia** e della **dolcezza** che le hanno permesso di conquistare un ruolo egemone nel campo della **musica**.",
						en: "«If there is a language suitable for music in Europe, it is certainly the Italian language, for it is a sweet, sonorous, harmonious language».\n So wrote the French philosopher Jean-Jacques Rousseau, acknowledging Italian’s traits of harmony and sweetness which have allowed it to play a dominant role in the field of music."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T2_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "Il Teatro alla Scala di Milano è uno dei punti di riferimento culturali per l’Italia e per l’Europa. È stato progettato dall’architetto Giuseppe Piermarini e costruito fra il 1776 e il 1778 sulle ceneri del Teatro Regio Ducale. Danneggiato dai bombardamenti durante la Seconda Guerra Mondiale, sarà ricostruito nel 1946 e infine restaurato e rinnovato da Mario Botta fra il 2002 e il 2004.",
								en: "The La Scala Theatre in Milan is one of the cultural landmarks in Italy and Europe. It was designed by architect Giuseppe Piermarini and built between 1776 and 1778 after a fire destroyed the previous theatre, the Teatro Regio Ducale. Damaged by bombing during World War II, it was rebuilt in 1946, and restored and renovated by Mario Botta between 2002 and 2004."
							},
							posX: '70%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's10',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 32)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Un genere di grande successo\n\n Il trionfo dell’italiano all’estero si deve in particolare alla diffusione del **melodramma**, genere in cui il teatro incontra la musica.\n Fu così che la conoscenza dell’**italiano come lingua di cultura** diventò un fatto comune in tutta Europa, in particolare alla corte di Vienna, dove operarono grandi librettisti come Apostolo Zeno e soprattutto **Pietro Metastasio**.",
						en: "## A very Popular Genre\n\n The triumph of Italian abroad was due in particular to the spread of **melodrama**, a genre in which theatre meets music.\n Hence, knowledge of Italian as **a language of culture** became commonplace throughout Europe, particularly at the court of Vienna, where great librettists such as Apostolo Zeno and, above all, **Pietro Metastasio** worked."
					},
					artifact: {
						id: 16
					},
					// remarkablePoints: [
					// 	{
					// 		id: "voce_T2_S2_1",
					// 		caption: 'didascalia',
					// 		textBefore: {
					// 			it: "Rosalba Carriera, _Ritratto di Metastasio_, 1730, pastello su carta, Gemäldegalerie Alte Meister , Dresden.",
					// 			en: ""
					// 		},
					// 		posX: '70%',
					// 		posY: '50%',
					// 		position: 'bottom',
					// 		wrap: true,
					// 		customLabelStyle: 'width: 25rem;'
					// 	}
					// ],
					inDepthItem: inDepthItems.items.find(el => el.id == 77),
					curiosity: {
						it: "In realtà Pietro Metastasio (1698-1782) si chiamava Pietro Trapassi. Metastasio è la forma classicheggiante “grecizzata”.\n Le sue opere si caratterizzano per la costante ricerca di chiarezza, ordine e musicalità. La lingua è quella della tradizione poetica, ma senza eccesso di arcaismi lessicali o sintattici: un tono medio ancora oggi facilmente comprensibile.",
						en: "Pietro Metastasio (1698-1782) was actually called Pietro Trapassi. Metastasio is the “Grecianised” form.\n His works are characterised by a constant search for clarity, order and musicality. The language is that of the poetic tradition, but without an overuse of lexical or syntactic archaisms – a middle path that is still easily comprehensible today."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's11',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 32)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-red',
					caption: {
						it: "## Una lingua naturalmente musicale\n\n Metastasio si rifà al codice poetico della tradizione, in particolare a Petrarca, riuscendo allo stesso tempo a creare «una meravigliosa e naturale musicalità» (Ilaria Bonomi).\n In questa scena dell’_Olimpiade_, in cui compare la famosa aria _Se cerca, se dice_, si notano forme poetiche come _prence_ ‘principe’ e diverse inversioni sintattiche («intese tutto Astrea?»).",
						en: "## A Naturally Musical Language\n\n «Metastasio drew on the traditional poetic code, in particular Petrarch, while managing to create «a wonderful and natural musicality» (I. Bonomi).\n In this scene from _Olimpiade_, in which the famous aria _Se cerca, se dice_ \[_If she searches, if she asks_] appears, we note poetic forms such as _prence_ \[prince] and several syntactic inversions («intese tutto Astrea?» \[does Astrea understand everything?])."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T2_S3_1",
							caption: 'didascalia',
							textBefore: "L’_Olimpiade_ è tra le opere più famose di Metastasio. Fu messa in scena per la prima volta a Vienna il 28 agosto 1733, con musica di Antonio Caldara.\n Come prassi dell’epoca, il libretto venne musicato da molti altri compositori oltre a Caldara (più di cinquanta nel solo Settecento), tra cui Antonio Vivaldi e Giovanni Battista Pergolesi.",
							posX: '70%',
							posY: '50%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 78),
					curiosity: {
						it: "L’opera ebbe sin da subito un grandissimo successo, non solo tra il pubblico più colto e raffinato, ma anche tra la gente comune.\n Agli inizi dell’Ottocento lo scrittore francese Stendhal ci dice infatti che l’aria _Se cerca, se dice_ era conosciuta «a memoria in tutta Italia».",
						en: "The opera was an immediate success, not only with the more cultured and refined audiences, but also with ordinary people.\n Indeed, at the beginning of the 19th century, the French writer Stendhal tells us that the aria _Se cerca, se dice_ \[_If she searches, if she asks_] was known «by heart throughout Italy»."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's12',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 32)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## In tutto il mondo\n\n Grazie al melodramma, tra Sei e Settecento moltissime parole italiane legate alla musica si sono diffuse in tutto il mondo.\n Da _opera_ a _maestro_, passando per le diverse tonalità di voce (_baritono_, _soprano_), i nomi di strumenti (_pianoforte_, _clavicembalo_), l’andamento della musica (_adagio_, _allegro_), fino alle parti principali del melodramma (_aria_, _recitativo_).",
						en: "## Italian throughout the World\n\n Through melodrama, many Italian words related to music spread throughout the world between the 17th and 18th century.\n They range from _opera_ to _maestro_ to the different voice types (_baritone_, _soprano_), from the names of instruments (_pianoforte_, _clavicembalo_ \[harpsichord]) to the speed of the music (_adagio_, _allegro_), and the main parts of the melodrama (_aria_, _recitative_)."
					},
					artifact: {
						id: 16
					},
					curiosity: {
						it: "La parola _aria_ indica un brano melodico con schema fisso, di solito cantato da una voce sola: un’espansione lirica perlopiù statica e meditativa, spesso occupata dai monologhi.\n Vi si contrappone il _recitativo_, sezione predisposta ai dialoghi e all’avanzamento dell’azione drammatica.",
						en: "The word _aria_ indicates a lyrical melody, with musical repetition, usually sung by one voice: a mostly static and meditative piece, often made up of a monologue.\n In contrast, the _recitativo_ \[_recitative_] is a section consisting of dialogues that advances the plot."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's13',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 32)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Un'eccezionale fortuna\n\n Ma non finisce qui, anzi. L’Ottocento è stato il secolo dei grandi compositori: da Bellini a Rossini, da Donizetti a Puccini.\n Nessuno, però, è noto in patria e all’estero quanto **Giuseppe Verdi** (1813-1901).\n Musicò ventotto opere scritte per lui da diversi librettisti, tra cui Francesco Maria Piave, dalla cui penna nacquero capolavori come _Rigoletto_ e _La Traviata_.",
						en: "## An Exceptional Fortune\n\n But this is not the end of the story. The 19th century was the century of great composers: from Bellini to Rossini, from Donizetti to Puccini.\n No one, however, was as well-known both at home and abroad as **Giuseppe Verdi** (1813-1901).\n He set to music twenty-eight operas which were written for him by various librettists, including Francesco Maria Piave, from whose pen masterpieces such as _Rigoletto_ and _La Traviata_ \[_The Fallen_ _Woman_] were born."
					},
					artifact: {
						id: 16
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's14',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 32)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Va, pensiero, sull’ali dorate\n\n Inizia così una delle arie più famose della lirica italiana, contenuta nel Nabucco.\n Il legame con la lingua della tradizione poetica è ancora molto forte. Troviamo parole auliche (_concento_ ‘armonia’, _crudo_ ‘crudele’), espressioni petrarchesche (_aure dolci_), forme arcaizzanti (_traggi_ ‘trai’) e troncamenti in rima (_natal_ : _fatal_).",
						en: "## Va, pensiero, sull’ali dorate\n\n _Va, pensiero, sull'ali dorate_ \[_Fly, thought, on wings of gold_]: so begins one of the most famous arias of Italian opera, contained in Nabucco.\n The link with the language of poetic tradition is still very strong. We find formal words (_concento_ \[harmony], _crudo_ \[cruel]), Petrarchan expressions (_aure dolci_ \[sweet breezes]), archaic forms (_traggi_ 'trai' i.e. make) and truncated rhymes (_natal_ : _fatal_)."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T2_S6_1",
							caption: 'didascalia',
							textBefore: {
								it: "**Il _Nabucco_** è un’opera scritta per Verdi da Temistocle Solera e rappresentata per la prima volta il 9 marzo 1842 al Teatro alla Scala di Milano.\n Protagonista del libretto è il re babilonese Nabucodonosor (o Nabucco) che riduce in schiavitù gli Ebrei, colpevoli di aver rapito sua figlia Fenena (nel frattempo convertitasi all’ebraismo). Progressivamente, anche il re si converte e insieme a Fenena libera gli Ebrei dal giogo di Abigaille, altra sua figlia che ne aveva intanto usurpato il trono.",
								en: "_Nabucco_ was an opera written for Verdi by Temistocle Solera and first performed on 9 March 1842 at La Scala in Milan.\n The main character of the libretto is the Babylonian king Nebuchadnezzar (or Nabucco) who enslaves the Jews, who are guilty of having kidnapped his daughter Fenena, who has in the meantime converted to Judaism. Gradually, the king also converts and together with Fenena frees the Hebrews from the yoke of Abigaille, his other daughter, who had in the meantime usurped his throne."
							},
							posX: '80%',
							posY: '20%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 79),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's15',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 32)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Volare... oh oh\n\n Il successo mondiale dell’italiano in musica prosegue nel Novecento con la canzone italiana.\n La più famosa? Vi dice niente la parola _volare_?\n Il brano di Modugno e Migliacci, oltre a essere un vero e proprio inno italiano nel mondo, contribuì al rinnovamento del linguaggio della canzone grazie (anche) al suo semplice, e proprio per questo rivoluzionario, ritornello.",
						en: "## Volare… oh oh! [Fly… oh oh!]\n\n The worldwide success of Italian in music continued into the 20th century with the Italian song.\n Which is the most famous? Does the word _Volare_ \[_Fly_] ring a bell?\n The song by Modugno and Migliacci, besides being a true Italian anthem in the world, contributed to the renewal of the language of song thanks (also) to its simple, and for this very reason revolutionary, refrain."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T2_S7_1",
							caption: 'didascalia',
							textBefore: {
								it: "La canzone di **Domenico Modugno** e **Franco Migliacci** trionfò al Festival di Sanremo del 1958, conquistando pubblico e critica.",
								en: "Domenico Modugno and Franco Migliacci's song triumphed at the 1958 Sanremo Music Festival, winning over audience and critics alike."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 80),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's15-game',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 32)
					},
					steps: [{}],
					numSteps: 1,
				},
				{
					id: 's16',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 33)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "Da sempre il teatro ha attirato moltissimi spettatori, essendo una forma di contatto tra pubblico e lingua.\n Ma che lingua si parla in scena? Dipende da tanti fattori: il periodo storico, il genere (commedia, tragedia)...\n Un aspetto comune, però, c’è: la ricerca di una lingua verosimile (che spesso è stato il dialetto).",
						en: "Theatre has always attracted very many spectators, since it is a form of contact between audience and language.\n But what language is spoken on stage? This depends on many factors such as the historical period, the genre (comedy, tragedy) etc.\n There is, however, one common aspect - the search for a _verisimilar_ language (which has often been dialect)."
					},
					artifact: {
						id: 16
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's17',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 33)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-extra-light-gold',
					caption: {
						it: "## Canovacci e maschere\n\n Il teatro come lo conosciamo oggi nasce nel Cinquecento, anche se già prima c’erano spettacoli come le sacre rappresentazioni.\n Nel Cinquecento si sviluppa la **commedia dell’arte**: gli attori non seguono un copione ma si basano su un semplice **canovaccio**, per il resto improvvisano.\n In scena regna un grande plurilinguismo: ogni maschera è collegata a una città e ne rappresenta la lingua.",
						en: "## Plots and Masks\n\n Theatre as we know it today was born in the 16th century, although works such as sacred plays were performed before then.\n The **commedia dell'arte** \[Comedy of the profession] developed in the 16th century: the actors did not follow a script but relied on a simple **plot**, for the rest they would improvise.\n Many languages could be heard on stage: each mask was linked to a city and represented its language."
					},
					artifact: {
						id: 16
					},
					curiosity: {
						it: "Un momento chiave per la nascita del genere teatrale in Italia è il carnevale del 1508, quando viene rappresentata la prima commedia di Ludovico Ariosto, _La_ _cassaria_. Da qui in poi si susseguono grandi capolavori, tra cui le opere di autori toscani come Bernardo Dovizi da Bibbiena (_Calandra_) e Niccolò Machiavelli (_Mandragola_).\n Alla prima della _Calandra_, Baldassar Castiglione rimase estasiato dalla scenografia allestita per l’occasione: «La scena poi era finta una città bellissima, con le strade, palazzi, chiese, torri, strade vere». Effetti speciali d’altri tempi!",
						en: "A key moment for the birth of early modern theatre in Italy was the carnival of 1508, when Ludovico Ariosto's first comedy, La cassaria, was performed. From then on, great masterpieces followed, including works by Tuscan authors such as Bernardo Dovizi da Bibbiena (La Calandria) and Niccolò Machiavelli (La Mandragola [The Mandrake]).\n At the premiere of La Calandria, Baldassar Castiglione was enraptured by the stage setting for the occasion: «Moreover the scene gave the illusion of a beautiful city, with real streets, palaces, churches, towers». Special effects from another era!"
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 81),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's18',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 33)
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Una lingua finalmente credibile\n\n È Carlo Goldoni (1707-1793) ad abbandonare le maschere e creare un linguaggio credibile per la commedia.\n Quando non scrive in veneziano, riesce comunque a usare una lingua media, che si nutre di forme veneziane, lombarde, di francesismi e di modi di dire toscani e che, soprattutto, ricalca le movenze del parlato.",
						en: "## Finally a Realistic Language\n\n It was **Carlo Goldoni** (1707-1793) who abandoned masks and developed a realistic language for comedy.\n When he did not write in Venetian, he still managed to use a comprehensible language, based on Venetian and Lombard forms, Frenchisms and Tuscan idioms and, above all, imitated the spoken word."
					},
					artifact: {
						id: 16
					},
					curiosity: {
						it: "Mentre Goldoni riempiva i teatri (non solo italiani) con le sue commedie, ai tragediografi andava decisamente peggio. Anche ai più grandi, come Vittorio Alfieri (autore di tragedie come _Antigone_, _Mirra_, _Saul_ e tante altre). Secondo un aneddoto, per sottolineare il fiasco di una sua tragedia, uno spettatore avrebbe composto un crudele endecasillabo imitando scherzosamente lo stile della poesia tragica (dove era frequente un ordine innaturale delle parole): «Oh quanto poca nel teatro gente!».",
						en: "While Goldoni filled the theatres (not only in Italy) with his comedies, tragedians were not nearly as successful. Even the greatest, such as Vittorio Alfieri (author of tragedies like _Antigone_, _Myrrha_, _Saul_ and many others). According to an anecdote, to underscore the fiasco of one of his tragedies, a spectator composed a cruel hendecasyllable mockingly imitating the style of tragic poetry (where an unnatural order of words was common): «Oh quanto poca nel teatro gente!» \[Oh how few in the theatre people!]."
					},
					// remarkablePoints: [
					// 	{
					// 		id: "voce_T3_S3_1",
					// 		caption: 'didascalia',
					// 		textBefore: "Le _Commedie_ di Carlo Goldoni furono pubblicate a Venezia da Giambattista Pasquali fra il 1760 e il 1764, in 17 volumi, con 89 tavole disegnate da Pietro Antonio Novelli e incise da Antonio Baratti.",
					// 		posX: '75%',
					// 		posY: '25%',
					// 		position: 'bottom',
					// 		wrap: true,
					// 		customLabelStyle: 'width: 25rem;'
					// 	}
					// ],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's19',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 33)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## _La locandiera_\n\n La lingua di Goldoni è stata definita un «fantasma scenico» (G. Folena), perché non esisteva niente del genere nell’Italia del Settecento.\n Lo si vede benissimo nella commedia più famosa, _La_ _locandiera_.\n La conversazione non è troppo distante dal parlato medio di oggi, ricca di frasi idiomatiche («Il diavolo mi ci ha strascinato») e segnali discorsivi (_Eh sì_, _Ehi_).",
						en: "## _The Mistress of the Inn_\n\n Goldoni's language has been called a «scenic ghost» (Gianfranco Folena), because there was nothing like it in 18th century Italy.\n This can be clearly seen in his most famous comedy, La locandiera [The Mistress of the Inn].\n The conversation is not too far from the speech of today, full of idiomatic phrases («The devil dragged me here!») and interjections (Oh yes, Oh)"
					},
					artifact: {
						id: 16
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 82),
					curiosity: {
						it: `I primi caffè italiani nascono a Venezia verso la fine del Seicento, in concorrenza con le tradizionali _malvasie_ (gli spacci di vino). A metà tra il pubblico e il privato, il caffè è il luogo perfetto per la lettura dei moderni giornali e, soprattutto, per la conversazione. La conversazione ‘da caffè’ non prevede l’eloquenza e la retorica propria del cortigiano delle elitarie corti rinascimentali ma uno stile moderno, sobrio, semplice e brillante. Il caffè diventa presto anche un luogo dove ambientare commedie, come _Le cafè_ di Jean-Baptiste Rousseau, _L’ecoissaise ou le cafè_ di Voltaire e _La bottega del caffè_ di Carlo Goldoni (1736).`,
						en: `The first Italian cafés were born in Venice towards the end of the 17th century, in competition with the traditional _malvasie_ (wine shops). Somewhere between public and private, the café is the perfect place for reading modern newspapers and, above all, for conversation. The 'café' conversation does not involve the eloquence and rhetoric of the courtier of the elitist Renaissance courts, but a modern, sober, simple and brilliant style. The café soon also became a setting for comedies, such as Jean-Baptiste Rousseau's _Le café_, Voltaire's _L'ecoissaise ou le café_, and Carlo Goldoni's _La bottega del caffè_ (1736).`,
						// it: "Nel Settecento era ancora forte il modello di lingua proposto dal Vocabolario della Crusca. Goldoni venne più volte accusato di non rispettare quel modello.\n Ecco come il commediografo si difese in una lettera: «io non sono accademico della Crusca, ma un poeta che ha scritto per essere inteso in Toscana, in Lombardia, in Venezia principalmente, e che tutto il mondo può capire quell’italiano stile di cui mi ho servito».",
						// en: "Nel Settecento era ancora forte il modello di lingua proposto dal Vocabolario della Crusca. Goldoni venne più volte accusato di non rispettare quel modello.\n Ecco come il commediografo si difese in una lettera: «io non sono accademico della Crusca, ma un poeta che ha scritto per essere inteso in Toscana, in Lombardia, in Venezia principalmente, e che tutto il mondo può capire quell’italiano stile di cui mi ho servito»."
					},
					// remarkablePoints: [
					// 	{
					// 		id: "voce_T3_S4_1",
					// 		caption: 'didascalia',
					// 		textBefore: "La Locandiera: la trama\n Mirandolina è una locandiera fiorentina di cui tutti i clienti si innamorano. Non fanno eccezione il Marchese di Forlipopoli (un aristocratico impoverito) e il Conte di Albafiorita (un ricco mercante), mentre il Cavaliere di Ripafratta si dimostra insensibile alla sua bellezza. Mirandolina inizia a corteggiarlo per gioco e in poco tempo gli fa cambiare idea: il Cavaliere si dichiara alla donna che però, raggiunto lo scopo, lo rifiuta con ironico disprezzo. Alla fine, tutti e tre i potenti spasimanti rimangono beffati, perché Mirandolina decide di sposare Fabrizio, il garzone della locanda.",
					// 		posX: '75%',
					// 		posY: '25%',
					// 		position: 'bottom',
					// 		wrap: true,
					// 		customLabelStyle: 'width: 25rem;'
					// 	}
					// ],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's20',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 33)
					},
					steps: [{}, {}, {}, {}],
					numSteps: 4,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Un teatro da Nobel (Atto primo)\n\n Un’altra svolta nella lingua del teatro è rappresentata dall’opera di Luigi Pirandello, premio Nobel per la Letteratura nel 1934.\n Caratteristica della lingua delle sue commedie è il saper ricreare dialoghi perfettamente credibili giocando in particolare sugli «aspetti prosodici e fonetici (espressi a livello scritto dall’interpunzione, dai vocativi e dalle interiezioni)» (Luca Serianni).",
						en: "## Nobel Prize Winning Theatre (Act One)\n\n The work of Luigi Pirandello, who won the Nobel Prize for Literature in 1934, was another turning point in the language of theatre.\n Characteristic of the language of his plays is his ability to recreate perfectly realistic dialogues by playing in particular on «prosodic and phonetic aspects (expressed at the written level through punctuation, vocatives and interjections)» (Luca Serianni)."
					},
					artifact: {
						id: 16
					},
					curiosity: {
						it: "«L’uso vivo e costante della lingua italiana non esiste nelle varie regioni della penisola»: così scrive Pirandello nel 1908.\n All’interno delle commedie risolve questo problema impiegando una lingua meno formale rispetto alle sue novelle, per riprodurre in maniera verosimile il parlato. ",
						en: "«The living and constant use of the Italian language does not exist in the various regions of the peninsula»: so wrote Pirandello in 1908.\n In his plays, he solves this problem by using a less formal language than in his novellas, in order to reproduce speech in a verisimilar manner."
					},
					remarkablePoints: [
						{
							id: "voce_T3_S5_1",
							caption: 'didascalia',
							textBefore: {
								it: "Simbolo di questa svolta è la commedia _Sei personaggi in cerca d’autore_ (1921), nella quale dei personaggi prendono vita e chiedono a un capocomico – che aveva appena iniziato le prove con i suoi attori – di rappresentare la loro storia, tra l’incredulità generale. ",
								en: "The play which marks this turning point is the drama _Sei personaggi in cerca d'autore_ \[_Six Characters in Search of an Author_] (1921), in which characters come to life and ask a director - who had just started rehearsing with his actors - to perform their story, to the general disbelief of the audience."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's21',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 33)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Un teatro da Nobel (Atto secondo)\n\n Un altro premio Nobel (1997), Dario Fo, rielabora il teatro popolare, usando dialetti, lingue straniere, linguaggi specialistici.\n La sua “firma” più famosa è il _grammelot_: una sequenza di suoni, perlopiù senza un vero significato, che ricalca le sonorità di una serie di lingue.\n Insomma, uscendo da teatro si ha l’impressione di aver capito tutto, anche se l’attore non ha detto nemmeno una parola sensata!",
						en: "## Nobel Prize Winning Theatre (Act Two)\n\n Another Nobel Prize winner (1997), Dario Fo, reworked popular theatre, using dialects, foreign languages and specialised languages.\n His most famous “trademark” was **_grammelot_****:** a sequence of sounds, mostly gibberish, that re-creates the sounds of various languages.\n In short, on leaving the theatre, one has the impression of having understood everything, even if the actor has not uttered a single sensible word!"
					},
					artifact: {
						id: 16
					},
					curiosity: {
						it: "La parola _grammelot_ (o _gramelot_) sembra essere di origine onomatopeica, forse deriva dal francese _grommeler_ (borbottare), attraverso il veneziano _gramlotto_.",
						en: "The word _grammelot_ (or _gramelot_) appears to be onomatopoeic in origin, possibly derived from the French _grommeler_ (i.e. muttering), via the Venetian _gramlotto_."
					},
					remarkablePoints: [
						{
							id: "voce_T3_S6_1",
							caption: 'didascalia',
							textBefore: "Un esempio di grammelot si ha nella scena La fame dello Zanni (che apre Mistero buffo), incentrata su una delle più antiche maschere della tradizione, antenato dei vari Arlecchino e Brighella.",
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's21-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 33)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's22',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 34)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "1924: una voce nuova, lontana ma che diventerà ben presto familiare, entra nelle case degli italiani, raggiungendo milioni di persone. Anche chi non sa leggere. Anche i tanti che si esprimono solo in dialetto.\n La radio, insieme alla televisione, avrà un ruolo fondamentale nella diffusione e conoscenza dell’italiano comune.",
						en: "1924: a new voice, distant but soon to become familiar, entered the homes of Italians, reaching millions. Even those who could not read. Even the many who still only spoke in dialect.\n Radio, together with television, would play a fundamental role in the spread and knowledge of everyday Italian."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T4_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "Il 5 marzo 1896, anticipando di pochi giorni il russo  Aleksandr Stepanovič Popov, Guglielmo Marconi (1874-1937) presenta la prima richiesta provvisoria di brevetto per la radio. Qualche anno dopo, il 12 dicembre 1901, avviene il primo segnale radio transoceanico, che collega la Cornovaglia e il Canada.\n In Italia, le trasmissioni radiofoniche prendono il via il 6 ottobre 1924, quando la radio era gestita dall’URI, l’Unione Radiofonica Italiana.",
								en: "On 5 March 1896, just a few days before the Russian Aleksandr Stepanovič Popov, Guglielmo Marconi (1874-1937) submitted the first provisional patent application for the radio. A few years later, on 12 December 1901, the first transAtlantic radio transmission between Cornwall and Canada, took place.\nRadio broadcasting in Italy started on 6 October 1924, when radio was managed by the URI, Unione Radiofonica Italiana [Italian Radio Union]."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's23',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 34)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',
					caption: {
						it: "## Mezzo privilegiato\n\n Il fascismo usò moltissimo la radio per la propaganda.\n I discorsi di Mussolini venivano trasmessi in tutta Italia, affiancati, dal 1933, dalla rubrica _Cronache del regime_.\n Il linguaggio è di tono aulico, ricco di formule stereotipate, ridondanze, frasi brevi volte a coinvolgere emotivamente gli ascoltatori.",
						en: "## Preferred medium\n\n Fascism used the radio extensively for propaganda.\n Mussolini's speeches were broadcast throughout Italy, followed, from 1933, by the programme _Cronache del regime_ \[News of the regime].\n The language is formal in tone, full of stereotypical expressions, repetitions and short sentences, all intended to emotionally arouse listeners."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T4_S2_1",
							caption: 'didascalia',
							textBefore: {
								it: "18 settembre 1943: dai microfoni di Radio Monaco, Mussolini annuncia la fondazione della Repubblica Sociale Italiana.\n Nelle prime battute del suo discorso, fa spesso riferimento alla sua voce, che proprio grazie alla radio aveva raggiunto milioni di italiani. ",
								en: "On 18 September 1943, from the microphones of Radio Monaco, Mussolini proclaimed the founding of the Italian Social Republic.\n In the first lines of his speech, he often refers to his voice, which had reached millions of Italians through radio."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 83),
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's24',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 34)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## Il teatro da casa\n\n Radiodramma è quella cosa\n in cui tutto ben si mischia\n pure il vento c’è che fischia\n perché il pubblico non può.\n\nCosì nel 1927 è descritto il radiodramma: una forma di spettacolo che permetteva alle famiglie di “andare” a teatro direttamente da casa (ma non di fischiare, ovviamente).",
						en: "## Theatre from the Home\n\n _Radio drama is that thing_\n _in which everything is well mixed_\n _even the wind is whistling_\n _because the audience cannot._\n\n This is how radio drama was described in 1927: a form of entertainment that allowed families to “go” to the theatre from their own homes (but not, of course, to whistle i.e. boo).",
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T4_S3_1",
							caption: 'didascalia',
							textBefore: {
								it: "I radiodrammi presentavano di norma una lingua letteraria e arcaizzante, ancora lontana dal parlato spontaneo. Ne è un esempio l’opera di ambientazione giapponese _Otohimè, principessa del mare_, di cui è conservato anche il copione dattiloscritto.\n Di tono più colloquiale è _L’anello di Teodosio_ del commediografo Luigi Chiarelli. Si tratta della prima radiocommedia trasmessa in Italia e segue le vicende di tre maldestri detective. Il testo è stato pubblicato sul numero di novembre 1929 del mensile La lettura del Corriere della Sera.",
								en: "Usually the radio plays contain archaic and literary language, and were still distant from spontaneous speech. One of the many examples is the opera _Otohimè, principessa del mare_ \[_Otohimè, Princess of the_ _Sea_]. The typescript of this radio drama is also preserved.\n Of a more colloquial tone is _L'anello di Teodosio_ \[The Ring of Theodosius] by playwright Luigi Chiarelli. It is the first radio drama in Italy and follows the adventures of three clumsy detectives. The text was published in the November 1929 issue of the monthly magazine “La lettura” of the “Corriere della Sera”."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's25',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 34)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Chiamate Roma 3131\n\n Il parlato della gente comune irrompe in radio a partire dal 1969, quando iniziano le trasmissioni di _Chiamate Roma 3131_.\n È il primo programma in cui compaiono le telefonate da casa.\n Finalmente il parlato della radio si allontana dai modelli scritti e dà spazio a un italiano spontaneo e regionale.",
						en: "## _Call Rome 3131_\n\n The speech of ordinary people burst onto the radio in 1969, when _Call Rome 3131_ began broadcasting.\n This was the first programme to host phone-ins.\n Spoken language on the radio finally moved away from written models and gave voice to spontaneous and regional Italian."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T4_S4_1",
							caption: 'didascalia',
							textBefore: {
								it: "_Chiamate Roma 3131_ è stato un programma radiofonico di Rai Radio 1, poi spostato sul secondo canale RAI.  In onda ogni giorno per tre ore, ha iniziato le trasmissioni il 7 gennaio 1969 per terminarle nel 1995. I primi conduttori sono stati Gianni Boncompagni, Franco Moccagatta e Federica Taddei.",
								en: "_Call Rome 3131_ was a radio programme broadcast on Rai Radio 1, later moved to RAI's second channel. On air every day for three hours, it began broadcasting on 7 January 1969 and ended in 1995. The first presenters were Gianni Boncompagni, Franco Moccagatta and Federica Taddei."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's26',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 35)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "Dalla metà del ’900 la **televisione** ha svolto – e svolge ancora – un ruolo importantissimo nella **diffusione della lingua italiana**.\n Sin dalla sua comparsa in Italia nel **1954**, è entrata nelle case di buona parte degli italiani aprendosi sempre di più a un pubblico ampio, senza distinzioni di provenienza geografica o condizione sociale.",
						en: "Since the mid-20th century, **television** has played – and still plays continues to play – a very important role in the **dissemination of the Italian language**.\n Since its appearance in Italy in **1954**, it has entered the homes of most Italians, becoming ever more accessible to a wide audience, regardless of geographical origin or social condition."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T5_S1_1",
							caption: 'didascalia',
							textBefore: {
								it: "La televisione nasce il 26 gennaio del 1926: il primo prototipo è realizzato dall’inglese John Logie Baird che riesce a trasformare le immagini in impulsi elettrici attraverso dei dischi rotanti e delle lampade al neon. Nel 1937 viene sostituito dal sistema Marconi-EMI.",
								en: "Television was born on 26 January 1926: the first prototype was made by Scotsman John Logie Baird, who succeeded in transforming images into electrical impulses by means of rotating discs and neon lamps. In 1937 it was replaced by the Marconi-EMI system."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's27',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 35)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## A scuola dal maestro Manzi\n\n Fra gli anni ’50 e ’60 la televisione è un potente **strumento di alfabetizzazione**.\n Nel palinsesto settimanale della RAI viene trasmessa[ **_Non è mai troppo tardi_**](https://www.raicultura.it/raicultura/articoli/2020/11/15-novembre-1960-Nasce-Non-e-mai-troppo-tardi-e4ec75ad-178f-4f90-a454-4fafaedf10d7.html), una serie di lezioni tenute dal maestro[ **Alberto Manzi**](https://www.raicultura.it/letteratura/articoli/2018/12/Alberto-Manzi-storia-di-un-maestro-07cb3aa5-efea-4240-a036-ce1349246f0e.html).\n Pensate: grazie a questo programma, un milione e mezzo di italiani è riuscito a prendere la licenza media!",
						en: "## At school with Mr Alberto Manzi\n\n Between the 1950s and 1960s, television was a powerful tool for literacy.\n **_Non è mai troppo tardi_** \[_It’s never too late_] – a series of lessons given by teacher and educator **Alberto Manzi** – was broadcast weekly on RAI, the Italian state television.\n It's incredible to think that, as a result of this programme, one and a half million Italians managed to get their secondary school leaving certificate!"
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T5_S2_1",
							caption: 'didascalia',
							textBefore: {
								it: "_Non è mai troppo tardi_ è una trasmissione curata da Oreste Gasperini, Alberto Manzi e Carlo Piantoni. Apparsa per la prima volta sul piccolo schermo italiano il 15 settembre del 1960, è andata in onda per 8 anni. Grazie al suo straordinario successo, è stata trasmessa anche all’estero in ben 72 paesi.",
								en: "Non è mai troppo tardi [It’s never too late] was a programme by Oreste Gasperini, Alberto Manzi and Carlo Piantoni. It first appeared on Italian TV screens on 15 September 1960 was broadcast for eight years. Thanks to its extraordinary success, it was also shown abroad in no less than 72 countries."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 84),
					curiosity: {
						it: "_Palinsesto_ viene dal latino PALIMPSĒSTUM. Anticamente era un manoscritto su pergamena nel quale si poteva osservare una scrittura sovrapposta a un’altra precedente raschiata via. \n Nel mondo della televisione (e anche della radio) il palinsesto è un testo in cui si organizza la programmazione della messa in onda, e che per sua natura è soggetto a cancellature, modifiche e inserimenti successivi.",
						en: "_Palimpsest_ comes from the Latin PALIMPSĒSTU(M). In ancient times, it was a manuscript on parchment in which one handwriting was superimposed on another previous one that had been scraped off.\n In the world of Italian television (and radio), a palimpsest is a document containing broadcast schedules, which by their very nature are subject to cancellations and changes."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's28',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 35)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## L'ora di scienze\n\n Alfabetizzazione, ma anche **divulgazione**.\n Con i suoi documentari come **_Quark_** e **_Superquark_**, la televisione mette in circolo un linguaggio più tecnico (_ruolo_ _patogeno_), sempre accompagnato però da esempi tratti dal quotidiano per rendere più chiari gli argomenti affrontati e da una comunicazione efficace, come il _noi_ “inclusivo” (_vediamo_, _nostro corpo_), che avvicina il conduttore ai propri ascoltatori.",
						en: "## The Science Lesson\n\n Literacy, but also popularisation.\n Through documentaries such as **_Quark_** and **_Superquark_**, television disseminates more technical concepts, always accompanied, however, by examples taken from everyday life to make the topics discussed clearer. Moreover, it uses effective communication strategies, such as the use of the inclusive ‘we’ form (we see, our body), which brings the presenter closer to his audience."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T5_S3_1",
							caption: 'didascalia',
							textBefore: {
								it: "In onda dal 1995, _Superquark_ è un programma di divulgazione culturale e scientifica molto apprezzato. Ideato e condotto dal giornalista Piero Angela, affronta diversi argomenti (dalla biologia alla chimica, dalla storia alla tecnologia) con una precisione e una semplicità che lo contraddistinguono.\n La puntata qui proposta affronta il tema dei batteri e delle loro funzioni.",
								en: "On air since 1995, _Superquark_ is a much-loved programme popularising culture and science. Conceived and hosted by journalist Piero Angela, it deals with various topics (from biology to chemistry, from history to technology) in a precise and plain manner that makes it unique.\n This episode deals with the subject of bacteria and their functions."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 85),
					curiosity: {
						it: "Nel 1987 in Italia arriva _Siamo fatti così_ (o _Esplorando il corpo umano_), un cartone animato che ha accompagnato grandi e piccoli nell’apprendimento dell’anatomia umana.\n L’animazione ha la funzione alleggerire i contenuti del cartone, che per illustrare il corpo umano si serve di un lessico molto specifico (_enzimi disintossicanti_) il più delle volte affiancato da spiegazioni (_piccole squadre prodotte da tutte le nostre cellule_).",
						en: "1987 saw the arrival in Italy of _Siamo fatti così_ \[_Once Upon a Time – Life_], a cartoon that helped children and adults to learn about human anatomy.\n The purpose of the cartoon animation style was to make the content more entertaining while using technical terms (e.g. _detoxifying enzymes_) to talk about the human body, very often accompanied by explanations (_small teams produced by all our cells_)."
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's29',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 35)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-heading',
					caption: {
						it: "## Allegria! Scavicchi, ma non apra!\n\n Oltre a educare il pubblico, dagli anni ’70 la televisione **intrattiene**.\n Conduttori e attori hanno lasciato traccia nella nostra lingua del loro passaggio: da «**Che barba, che noia**» di Sandra Mondaini ad «**Allegria!**» di Mike Bongiorno, da «**Mooseca**» di Enrico Papi a «**Scavicchi, ma non apra**» di Paolo Bonolis.",
						en: "## _Allegria!_ \[Joy!] _Scavicchi, ma non apra!_ \[Break the seal, but don’t see!]\n\n In addition to educating the public, television has been entertaining since the 1970s.\n Presenters and actors, with their catchphrases, have left their mark on our language: from Sandra Mondaini's «**Che barba, che noia» \[Boring! Hyper-boring!]** to Mike Bongiorno's «**Allegria!»** \[Joy!], from Enrico Papi's «**Mooseca» \[Moosic]** to Paolo Bonolis' «**Scavicchi, ma non apra»** **\[Break the seal, but don’t see!]** ."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T5_S4_1",
							caption: 'didascalia',
							textBefore: {
								it: "Mike Bongiorno con Sabina Ciuffini alla conduzione di _Rischiatutto_",
								en: "Mike Bongiorno with Sabina Ciuffini hosting _Rischiatutto_ \[_Risk everything_]"
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's30',
					name: 'section',
					isStageStart: true,
					stage: {
						...stagesData.stagesData.find(el => el.id == 35)
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-rose',
					caption: {
						it: "## La _real-tv_\n\n La **lingua spontanea,** “reale”, fa irruzione nei talent o nei reality come il **_Grande Fratello_**.\n Si assiste a dialoghi tra il conduttore e i concorrenti, o tra i concorrenti stessi.\n Vengono così fuori alcuni tratti che sono lo specchio della realtà linguistica degli italiani, come i comunissimi _cioè_ e _diciamo_.",
						en: "## Real TV\n\n **Spontaneous, ‘real’ language** invades talent or reality shows such as **_Big Brother_**.\n There are dialogues between the host and the contestants, or between the contestants themselves.\n Certain features surface reflecting the actual expressions Italians use, such as the very common interjections _cioè_ \[_that is_] and _diciamo_ \[_let’s say_]."
					},
					artifact: {
						id: 16
					},
					remarkablePoints: [
						{
							id: "voce_T5_S4_1",
							caption: 'didascalia',
							textBefore: {
								it: "Entrato nelle case degli italiani dal 2000, il _Grande Fratello_ è un _reality-show_ in cui i concorrenti sono seguiti dalle telecamere in tutte le loro attività quotidiane: le prove per la salvezza, i momenti di svago, i litigi, le effusioni amorose e gli sfoghi dentro il cosiddetto confessionale.\n Allo spettatore è dato il potere di eliminare i vari concorrenti (di solito uno per settimana) durante gli appuntamenti serali, fino a decretare un unico vincitore.",
								en: "_Big Brother_, which entered the homes of Italians in 2000, is a reality show in which contestants are followed by cameras in all their daily activities: tasks to save themselves from eviction, moments of leisure, quarrels, love affairs and outbursts inside the so-called Diary Room.\n Viewers can vote to evict the various contestants (usually one per week) during the evening shows, until a winner is declared."
							},
							posX: '75%',
							posY: '25%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
					inDepthItem: inDepthItems.items.find(el => el.id == 86),
					curiosity: {
						it: "Sapevate che il format del _Grande Fratello_ si ispira al romanzo _1984_ di George Orwell? Composto nel 1948 (e l’inversione delle ultime due cifre dà proprio _1984_!), racconta di un futuro distopico in cui le persone sono spiate e tenute sotto controllo dal Grande Fratello, il dittatore dello stato di Oceania. Nella trasmissione televisiva è lo spettatore, cioè tutti noi, che possiamo spiare in diretta, in qualsiasi momento della giornata, e senza essere visti, le conversazioni tra i partecipanti al reality.",
						en: "Did you know that the _Big Brother_ format was inspired by George Orwell's novel 1984? Written in 1948 (and the inversion of the last two figures gives just 1984!), it tells of a dystopian future in which people are spied on and kept under control by Big Brother, the dictator of the state of Oceania. Through this brilliant satire, Orwell condemns all totalitarian regimes. And if you want to know more, race to read it!"
					},
					template: 'left',
					buttonPosition: 'left',
					sizeContentText: 'small',
					additionalStyleSectionContent: '',
				},
				{
					id: 's30-game',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 35)
					},
					steps: [{}],
					numSteps: 1,
				},
				{
					id: 's31',
					name: 'bibliography',
					stage: {
						...stagesData.stagesData.find(el => el.id == 36)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-extra-light-gold',
					caption: {
						it: 'L\'italiano circola con la scrittura epistolare, un importante mezzo di comunicazione utile a rispondere a esigenze pratiche. Scrittura di getto, schiettezza e spontaneità sono tra le caratteristiche della scrittura delle lettere, che accolgono, perciò, anche diversi tratti linguistici tipici della lingua parlata.',
						en: 'Italian circulates with epistolary writing, an important means of communication serving practical needs. Spur-of-the-moment writing, directness and spontaneity are among the characteristics of letter-writing, which therefore also includes various linguistic traits typical of the spoken language.'
					},
					template: 'left',
				}
			]
		},
		// POPOLARE
		{
			id: 6,
			nameCode: 'come-suona-italiano-popolare',
			name: {
				it: "Come suona l’italiano di chi non sa l’italiano?",
				en: 'What Italian Do Illiterates Speak?'
			},
			nameArray: {
				it: ["Come suona", "l’italiano di", "chi non sa", "l’italiano?"],
				en: ["What", "Italian Do", "Illiterates", "Speak?"],
			},
			description: {
				it: `Per secoli le persone scarsamente alfabetizzate hanno cercato di appropriarsi dell’italiano per le proprie esigenze pratiche e comunicative.
Memorie, lettere, diari, spezzoni di film ci raccontano di questa intensa lotta con la scrittura, alla ricerca di un compromesso tra la spontaneità della propria parlata dialettale e le regole della lingua "ufficiale".
Che ne dite di andare a conoscere alcune delle storie più interessanti?`,
				en: `For centuries, poorly literate people have tried to appropriate Italian for their own practical and communicative needs.
Memoirs, letters, diaries and film clips tell us about their intense struggle with writing, searching for a compromise between the spontaneity of their own dialect and the rules of the “official” language.
Let’s discover some of the most interesting stories.
`,
			},
			introImages: [
				require('@assets/images/percorsi/popolare/soldato' + utils.manageImagesExtension('.png')),
				require('@assets/images/percorsi/popolare/s1/bellezze_high' + utils.manageImagesExtension('.png')),
			],
			sections: [
				{
					id: 's1',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 8)
					},
					isFixed: true,
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-intro',
					
					template: 'left',
				},
				{
					id: 's1-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 601)
					},
					caption: {
						it: `Tra i due estremi (da una parte la lingua scritta, alta e dotta; dall'altra i dialetti parlati, spontanei e bassi) esiste un italiano malcerto ma vitale, parlato e scritto – spesso per necessità – da uomini e donne del popolo, non particolarmente istruiti, ma nemmeno completamente analfabeti.

Sono i cosiddetti "semicolti".`,
						en: `Between the two extremes (on the one hand, the high, learned register of the written language; on the other, the spontaneous, low register of spoken dialects), there exists a poorly written but vital Italian, spoken and written – often out of necessity – by ordinary men and women who, while not well-educated, are not completely illiterate either.
They are known as the “semi-educated”.`,
					},
					steps: [{}, {}, {
						quote: {
							it: '«Nella storia della lingua, come nella storia _tout court_, non si hanno contrapposizioni assolute, polarità stabili, ma piuttosto gradazioni e convivenze»',
							en: '«In the history of language, as in history itself, there are no absolute opposites, stable polarities, but rather gradations and coexistences»'
						},
						author: { it: 'Maurizio Dardano', en: 'Maurizio Dardano' },
					}],
					numSteps: 3,
					sizeContentText: 'normal',
					sectionStyle: 'bg-blue-heading',
					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 611),
					buttonPosition: 'left',
				},
				{
					id: 's1-01',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 601)
					},
					caption: {
						it: `## «Me, Belleze de Agnelo Ursini»
Non sappiamo quante donne furono processate (e spesso condannate) per stregoneria. 
Alcune stime dicono siano state, nei secoli, quasi centomila in Europa. 
Quasi mai si conservano le parole delle “streghe”, perché venivano bruciate con loro sul rogo. 
Le otto paginette ricoperte dalla fitta scrittura di Bellezze sono dunque una testimonianza rarissima e preziosa. 
Siamo a Roma, anno del Signore 1528, poco dopo il famigerato sacco della città.`,
						en: `We do not know how many women were tried (and often convicted) for witchcraft.
Some estimates suggest there were, over the centuries, as many as a hundred thousand in Europe.
The words of the “witches” have almost never been conserved, because they were burned at the stake with them.
The eight densely written pages by Belleze de Agnelo Ursini are therefore a very rare and precious testimony.
We are in Rome, in the year of our Lord 1528, shortly after the infamous sack of the city.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-rose',

					inDepthItem: inDepthItems.items.find(el => el.id == 612),
					buttonPosition: 'left',
				},
				{
					id: 's1-02',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 601)
					},
					caption: {
						it: `Nelle parole di Bellezze il sapere della «strearia» viene descritto come un processo di conoscenza senza fine, sempre frustrato: «quante più cose cierchi de inparare tante più sonno quelle che trovi da ’nparare, che prima nemanco ne tenevi sentimento, e più vai inanti più vo’ ire e non te ne cuntenti. Cusì è la strearia».`,
						en: `Bellezze describes the knowledge of «_strearia_» (i.e. witchcraft) as an endless, frustrating process: «the more things thou try to learn, the more thou find to learn, which thou didn't even imagine before, and the more thou go on, the more thou want to go on and are never content. That’s witchcraft».`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-red',

					template: 'right',
					remarkablePoints: [
						{
							caption: 'x',
							textBefore: {
								it: "Como che chi impara la lettera se dà el principio delo leiere e delo scrivere, e po’ se sequita secunno la ’ncrinazione de onnechivelli, chi a uno modo chi a un altro, chi de piune e chi de mino, ma non se ne vede mai l’anbene, per dicere, la concrusione, lu fonno: quante più cose cierchi de inparare tante più sonno quelle che trovi da ‘nparare, che prima nemanco ne tenevi sentimento, e più vai inanti più vo’ ire e non te ne cuntenti. Cusì è la strearia.",
								en: "Just as one who learns the alphabet acquires the ability to read and write and then continues depending on one's inclination, some in one way and some in another, some more and some less, but one never sees the Amen, i.e. the end, the bottom: the more things thou try to learn, the more thou find to learn, which thou were not even aware of before, and the more thou go on, the more thou want to go on and are never content. That’s witchcraft."
							},
							posX: '15%',
							posY: '22%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
				},
				{
					id: 's1-03',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 601)
					},
					caption: {
						it: `## Nel lontano Friuli…
«Io ho questa opinione, che il parlar latin sia un tradimento de’ poveri».
Così la pensava un mugnaio friulano del XVI secolo, Domenico Scandella, detto Menocchio, e non aveva paura di dirlo.
E tante altre cose pensava, poetiche e affascinanti, che gli costarono la vita.`,
						en: `## In Faraway Friuli…

«Me have this opinion, that to speak Latin is a betrayal of the poor».  
This is what a 16th century Friulian miller, Domenico Scandella, known as Menocchio, thought, and he was not afraid to say so.  
And he thought many other poetic and fascinating things, which cost him his life.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-blue-cement',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 613),
					buttonPosition: 'left',
				},
				{
					id: 's1-03b',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 601)
					},
					caption: {
						it: `Ad esempio, pensava che «tutto era caos, cioè terra, aere, acqua et foco insieme; et quel volume andando così fece una massa, aponto come si fa il formazo nel latte, et in quel deventorno vermi, et quelli furno li angeli; et la santissima maestà volse che quel fosse Dio et li angeli».
Idee fantastiche, ma troppo pericolose per la Chiesa di allora.`,
						en: `He thought, for example, that «all was chaos, that is, earth, air, water, and fire together; and out of that bulk a mass was formed, just as cheese is formed in milk, and worms were created in it, and these were the angels; and the most holy majesty willed it to be God and the angels».  
Fantastic ideas, but they were too dangerous for the Church of the time.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-cement',

					template: 'right',
					remarkablePoints: [
						{
							caption: 'x',
							textBefore: {
								it: "«Io ho detto che, quanto al mio pensier et creder, tutto era caos, cioè terra, aere, acqua et foco insieme; et quel volume andando così fece una massa, aponto come si fa il formazo nel latte, et in quel deventorno vermi, et quelli furno li angeli; et la santissima maestà volse che quel fosse Dio et li angeli; et tra quel numero de angeli ve era ancho Dio creato anchora lui da quella massa in quel medesmo tempo, et fu fatto signor con quattro capitani, Lucivello, Michael, Gabriel et Rafael».",
								en: '«My opinion, all was chaos, that is, earth, air, water, and fire together; and out of that bulk a mass was formed, just as cheese is formed in milk, and worms were created in it, and these were the angels; and the most holy majesty willed it to be God and the angels; and among that number of angels there was also God, he too having been created from that mass at the same time, and he was made lord with four captains, Lucifer, Michael, Gabriel and Raphael»'
							},
							posX: '26%',
							posY: '20%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
				},
				{
					id: 's1-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 601)
					},
					caption: {
						it: `## Venti di guerra
Settembre 1746: Genova si arrende agli austriaci. 
Dicembre 1746: in città scoppia una rivolta.
14 gennaio 1747: nuova rivolta. Giovanni Garbino, mercante di pesce, viene arrestato. 
Dal carcere scrive una memoria difensiva per convincere i giudici della sua innocenza: «Me ne andai subitamente».
Il tentativo di difesa fallisce e Garbino viene impiccato il 10 giugno 1747.`,
						en: `## Winds of War
September 1746: Genoa surrenders to the Austrians.  
December 1746: a revolt breaks out in the city.  
14 January 1747: another revolt. Fish merchant Giovanni Garbino is arrested.  
From prison he writes a defence statement to prove to the judges he is innocent.  
«I left immediately».  
His defence was rejected and he was hanged on 10 June 1747.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-blue-cement',

					curiosity: {
						it: `A dare inizio alla rivolta del dicembre 1746 è un ragazzino di appena 11 anni, Giovan Battista Perasso, che lancia una pietra contro le truppe austriache, diventando subito un simbolo della resistenza contro l'invasore straniero. 

Il suo soprannome, Balilla, probabile vezzeggiativo di Battista, avrà però un altro destino, diventando una delle parole identitarie del fascismo.`,
						en: `Behind the revolt of December 1746 was a young boy of just 11 years, Giovan Battista Perasso, who threw a stone at the Austrian troops and immediately became a symbol of resistance against the foreign invader. 
His nickname was Balilla, probably a term of endearment for Battista which, however, would one day become associated with Fascism.`,
					},

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 614),
					buttonPosition: 'left',
				},
				{
					id: 's1-05',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 601)
					},
					caption: {
						it: `## Il “fidato” Elia
Francesco Elia, di Asti, è a servizio di Vittorio Alfieri dal 1766 al 1781. 
Lo segue nei suoi viaggi per l’Europa, da Vienna a Madrid, da Parigi a Stoccolma, e tra i molti compiti ha anche quello di riferire alla sorella di Alfieri, contessa di Cumiana, le sregolatezze del padrone. 
Il suo essere “servo di due padroni” ci dà la possibilità di leggere dei gustosissimi resoconti, esilarante cartina tornasole della _Vita_ alfieriana.`,
						en: `## “Trusted” Elia
Francesco Elia, from Asti, was in the service of Vittorio Alfieri from 1766 to 1781.
He accompanied him on his travels around Europe, from Vienna to Madrid, from Paris to Stockholm. One of his many tasks was to report to Alfieri’s sister, the Countess of Cumiana, on his master's turbulent life. 
His being “servant of two masters” gives us the opportunity to read some very spicy stories, which reveal some comic details about Alfieri’s autobiography _Life_.`,
					},
					steps: [{}, {
						quote: {
							it: '«Questo ometto d’Asti è uno che la sa lunga, sa che il mondo è il luogo di una svariata commedia, dove gli uomini giocano per destino o la parte del signore o quella del servo»',
							en: '«This little man from Asti knows what’s what, he knows that the world is a stage, where fate leads men to play either the part of the master or of the servant»'
						},
						author: { it: 'Maria Corti', en: 'Maria Corti' },
					}, {
						bg: 'bg-blue-heading',
						style: 'opacity: 0; transform: translate(calc(var(--scrolly-w)), 100%) rotate(12deg);',
						title: { it: 'Lettera di Elia', en: 'Elia’s Letter sent from St. Petersburg' },
						date: { it: '31 maggio del 1770', en: '31 May 1770' },
						letter: {
							it: '«abiamo trovato il pasagio serratto da grandi pezi di giacio, non si vedeva che giacio a vista d’ochio e siamo tornati a dietro a l’osteria: e di più ci facevo delle minestre di ortiche che cominciavano a pena a spontare, dove il mio padrone le trovò così buone che tutto il viagio, dove si fermavano, bisognava sùbitto còrere cercarne. Si è però sogornatto che un giorno e meso, e duopo mi fece montare io con luj con il violino, e lui remava e io sonavo; ed abiamo fatto più miglia per indare in una picola isola deserta, dove mi fece ancora suonare molto il violino, e faceva belissimo tempo».',
							en: '«We found the passage closed by great chunks of ice, because the North wind comes that way, and there was nothing but ice to be seen \[...] and in addition I made soup from nettles, which were just beginning to sprout, and I found them so good that for the whole journey, wherever we stopped, we had to immediately run and look for them. We stayed only a day and a half, \[...] and afterwards he made me ride with him with a violin, and he rowed and I played; and then I took an oar too, and we went several miles to a small deserted island, where he made me play the violin a lot again, and it was beautiful weather»',
						},
					}, {
						style: 'background: var(--color-red); opacity: 0; transform: translate(calc(var(--scrolly-w) / 2 - 50%), 100%) rotate(-15deg);',
						mobileStyle: 'background: var(--color-red)',
						title: { it: 'Vittorio Alfieri, _Vita_', en: 'Vittorio Alfieri, _Vita_ [Life]' },
						date: { it: 'Epoca III, Capitolo IX', en: 'Epoch III, Chapter IX' },
						letter: {
							it: 'Era gelato gran parte di mare, e il tragitto dal continente nella prima isoletta riusciva per allora impossibile ad ogni specie di barca. Mi convenne dunque aspettare in quel tristo luogo tre giorni, finché spirando altri venti cominciò quella densissima crostona a screpolarsi qua e là, e far crich, come dice il poeta nostro, quindi a poco a poco a disgiungersi in tavoloni galleggianti, che alcuna viuzza pure dischiudevano a chi si fosse arrischiato d\'intromettervi una barcuccia. Ed in fatti io subito volli tentare...',
							en: 'A large part of the sea was frozen over, and the journey from the mainland to the first islet (which is crossed by five islets at the entrance to the aforesaid gulf) was impossible for any kind of boat, due to the fact that the water was completely immobile. So I had to wait in that sad place for three days, until, as more winds blew in, the dense crust began to crack here and there, and make _crich_, as our poet says, then little by little to break up into floating planks, which also opened up some lanes to those who ventured to put a small boat in them. And indeed \[...] I immediately wanted to try…',
						},
					}],
					numSteps: 4,
					sectionStyle: 'bg-rose',

					template: 'left',
				},
				{
					id: 's1-06',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 601)
					},
					caption: {
						it: `## «Stimatissima Signora»
Un umile amministratore di una cascina a Cortemilia in Val Bormida, Domenico Mezzano, ha una corrispondenza con la proprietaria, Giuseppina Viola, residente a Cairo. 
Tra il 1858 e il 1860 la tiene al corrente delle migliorie e della manutenzione che il fondo richiede. 
Ma, come si sente dalle sue parole, ha più confidenza con le cose pratiche che con la scrittura.`,
						en: `##«Most Estimed Ladi…»
Domenico Mezzano, a humble administrator of a farmstead in Cortemilia in the Bormida Valley, corresponded with the owner, Giuseppina Viola, who lived in Cairo.  
Between 1858 and 1860 he kept her informed of the improvements and maintenance that the estate required.  
But he is more familiar with practical things than with writing!`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-red',

					template: 'left',
				},
				{
					id: 's1-game',
					name: 'section',
					stage: {
					...stagesData.stagesData.find(el => el.id == 601)
					},
					steps: [{}],
					numSteps: 1
				},
				{
					id: 's2-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `Le due guerre mondiali hanno prodotto un’enorme quantità di testi, anche e soprattutto da parte di persone fino a quel momento poco abituate a scrivere. 

Dalle lettere dei prigionieri della Grande Guerra a quelle dei condannati a morte della Resistenza, l’urgenza di creare un contatto con chi leggerà fa della scrittura un prezioso mezzo di rivendicazione della propria umanità ferita.`,
						en: `The two World Wars produced an enormous quantity of texts, also and above all by people who were unaccustomed to writing.
From the letters of the prisoners of the Great War to those sentenced to death in the Resistance, the urge to establish contact with the reader makes writing a precious means of reclaiming one's wounded humanity.`,
					},
					steps: [{}, {}, {
						quote: {
							it: '«Carisima Moglie \[…] Ora non poi gredere quanto ligreza \[…] di sentire la tua voce in un pezo di carta»',
							en: '«Dearest Wife... You won\'t believe how happy I am ... to hear your\n voice in a piece of paper»'
						},
						author: { it: '', en: '' },
					}],
					numSteps: 3,
					sizeContentText: 'normal',
					sectionStyle: 'bg-blue-heading',
					template: 'left',
				},
				{
					id: 's2-02',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `## Un esperimento di scrittura di massa
1915: l’Italia entra ufficialmente in guerra.
Le durissime condizioni della vita di trincea spingono migliaia di soldati parlanti dialetto, di estrazione perlopiù contadina, a prendere in mano la penna (spesso per la prima volta) e mandare notizie di sé ai propri cari. 
La Grande Guerra diventa così uno snodo cruciale nel processo di unificazione linguistica.`,
						en: `## An Experiment in Crowd-Writing
1915: Italy officially enters the war.
The harsh conditions in the trenches drive thousands of dialect-speaking soldiers, mostly of peasant origin, to pick up a pen (often for the first time) and send news of themselves to their loved ones. 
The Great War thus became a crucial turning point in the process of linguistic unification.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-red',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 602),
					buttonPosition: 'left',

					remarkablePoints: [
						{
							caption: 'x',
							textBefore: {
								it: "Nell'audio è possibile ascoltare una lettera inviata dal campo di internamento austriaco di Katzenau. Il ricordo di un bacio sotto la pioggia annulla la lontananza imposta dalla guerra e, insieme, le difficoltà testuali dello scrivente, che stende l’intera lettera di getto, senza neppure una virgola, in un unico appassionato periodo.",
								en: 'In this letter sent from the Austrian internment camp of Katzenau, the memory of a kiss in the rain cancels the distance imposed by the war and, at the same time, the textual difficulties of the writer, who writes the entire letter, without even a comma, in a single passionate sentence.'
							},
							posX: '75%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
				},
				{
					id: 's2-03',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `## Senza filtri

Durante i sanguinosi anni della Prima guerra mondiale, un gran numero di lettere anonime contenenti proteste, minacce, suppliche e richieste di pace arriva sulle scrivanie del re d’Italia Vittorio Emanuele III e delle altre autorità di governo. 
Sono testi scritti da civili, familiari o soldati in licenza: privi di filtri censori, testimoniano il grande dolore provocato dal conflitto.`,
						en: `## Uncensored
During the bloody years of the First World War, a large number of anonymous letters containing protests, threats, pleas and requests for peace arrived on the desks of the King Vittorio Emanuele III and other government authorities. 
These letters were written by civilians, family members or soldiers on leave: uncensored, they testify to the great pain caused by the conflict.`
					},
					steps: [{}, {
						quote: {
							it: '«Nel 18 vò fate l’accordo vo pure. noi faremo la la disfatta. faremo la Rivoluzione e torneremo. dalle trincere con le armi verremo Arroma ed allora vedremo se e dà farla finita che sono 3 Anni di Sanque e Voi vi divertite negli Otelli?»',
							en: '«In 18 u go ahead, make the agriment. we will make the rout. wi will make the Rivolution and wi will return. from the trench with weapon wi will come to Rom and then wi will see if it’s necessary to end what have been 3 Year of Blud and u enjoy yourself in the hotel?»'
						},
						author: { it: '', en: '' },
					}],
					numSteps: 2,
					sectionStyle: 'bg-rose',

					template: 'left',

					remarkablePoints: [
						{
							caption: 'citazione',
							textBefore: {
								it: "In questo frammento di lettera indirizzata da un soldato anconetano al Presidente del Consiglio Orlando (18/12/1917), le minacce di «disfatta» e «Rivoluzione» reagiscono a «3 Anni di Sanque» e lutti. D’altra parte, la «disfatta del Monto» di cui parla il soldato ricorda la tristemente celebre “disfatta di Caporetto” del novembre 1917, entrata nel linguaggio comune a indicare una pesante sconfitta.",
								en: "This excerpt is from a letter addressed by a soldier from Ancona to Prime Minister Orlando (18/12/1917). He threatens «rout» and «Rivolution \[sic]» in response to «3 Year \[sic] of Blud \[sic]» and mourning. The «defeat of the World» of which the soldier speaks recalls the infamous “rout of Caporetto” of November 1917, which entered common parlance to indicate a heavy defeat."
							},
							posX: '80%',
							posY: '30%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
				},
				{
					id: 's2-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `## È ora di finirla!
Vero capolavoro di contestazione è una lunga lettera del 21 ottobre 1918 proveniente dalla Calabria, di cui possiamo ascoltare qui un estratto. Indirizzata a Orlando e agli altri «responsabbili» della guerra, esprime la più genuina rabbia popolare per il massacro in corso, attraverso una dura invettiva che insiste in continuazione sullo stesso concetto: «è ora di finirla!... e non intendiamo più soffrire!... per la vostra causa!...».`,
						en: `##It Is Time to End It!
A true masterpiece of protest is a long letter dated 21 October 1918 from Calabria, part of which we can hear  here. Addressed to Orlando and the others «responsible» for the war, it expresses the genuine popular anger at the ongoing massacre, through a harsh polemic that continually insists on the same concept: «it is time to end it!... and we do not intend to suffer any more!... for your cause!...».`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-blue-cement',
					remarkablePoints: [
						{
							caption: 'citazione',
							textBefore: {
								it: `**Armando Diaz**

Capo di stato maggiore generale del Regio Esercito 1917-1919`,
								en: `**Armando Diaz**

Chief of General Staff of the Royal Army 1917-1919`
							},
							posX: '60%',
							posY: '80%',
							position: 'top',
							wrap: true,
							customLabelStyle: 'width: 20rem; text-align: center;'
						},
						{
							caption: 'citazione',
							textBefore: {
								it: `**Luigi Cadorna**

Capo di stato maggiore generale del Regio Esercito 1914-1917`,
								en: `**Luigi Cadorna**

Chief of General Staff of the Royal Army 1914-1917`
							},
							posX: '15%',
							posY: '80%',
							position: 'top',
							wrap: true,
							customLabelStyle: 'width: 20rem; text-align: center;'
						},
						{
							caption: 'citazione',
							textBefore: {
								it: `**Sua Maestà il Re Vittorio Emanuele III di Savoia**

Re d’Italia (1900-1946)`,
								en: `**His Majesty King Vittorio Emanuele III of Savoy**

King of Italy (1900-1946)`
							},
							posX: '35%',
							posY: '80%',
							position: 'top',
							wrap: true,
							customLabelStyle: 'width: 20rem; text-align: center;'
						},
						{
							caption: 'citazione',
							textBefore: {
								it: `**Vittorio Emanuele Orlando**

Presidente del Consiglio dei ministri 1917-1919`,
								en: `**Vittorio Emanuele Orlando**

President of the Council of Ministers 1917-1919`
							},
							posX: '80%',
							posY: '80%',
							position: 'top',
							wrap: true,
							customLabelStyle: 'width: 20rem; text-align: center;'
						},
					],

					template: 'left',
				},
				{
					id: 's2-05',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `## Tapum tapum tapum…
Il rombo del cannone, le granate, gli spari dei fucili accompagnano incessantemente la vita dei soldati al fronte: risuonano dalle trincee alle retrovie e non smettono di turbare i sogni dei reduci tornati a casa.
_Tapum_ (termine tipico del gergo militare) è un celeberrimo canto alpino della Grande Guerra, testimonianza delle sofferenze e delle malinconie dei «soldà».`,
						en: `## Tapum Tapum Tapum
The continuous roar of cannons, grenades and rifles firing accompany the soldiers at the front: they echo from the trenches to the rear and never cease to disturb the dreams of those who come back home.
_Tapum_ (typical word of military slang) is a very famous Alpine song from the Great War, a testimony to the suffering and melancholy of the «soldà» \[soldiers].`,
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-rose',

					template: 'left',
					curiosity: {
						it: `_Tapum_ è un’onomatopea, ovvero una parola che imita un suono naturale o un rumore artificiale: in questo caso, il terribile scoppio emesso dalla fucileria dei cecchini austriaci. 

In breve tempo la parola, scritta anche _ta-pum_, diventa un nome per indicare il suono di un qualunque sparo (“il tapum di un fucile”) ed entra così nel _gergo_ militare.`,
						en: `_Tapum_ is an onomatopoeic word, i.e. a word that imitates a natural sound or an artificial noise. In this case, it is the terrible bang from the rifles of Austrian snipers.

Soon the word, also spelled _ta-pum_, came to mean the sound of any gunshot (“the tapum of a rifle”) and thus entered military slang.`,
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 603),
					buttonPosition: 'left',
				},
				{
					id: 's2-06',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `## Le ultime lettere…
Dopo poco più di vent’anni di pace, ecco la tragedia di un’altra guerra mondiale. 
La Seconda guerra mondiale ci ha lasciato centinaia di lettere scritte dai partigiani italiani catturati dai nazifascisti: le loro ultime lettere prima dell’esecuzione della condanna a morte. 
Come per i prigionieri italiani del ’15-’18, sono testi spesso scritti da persone scarsamente alfabetizzate, mosse dal bisogno di comunicare con i familiari e di affidare loro la propria eredità di idee e sentimenti.`,
						en: `## The Last Letters...
After a little over twenty years of peace, there followed another world war.
The Second World War has left us hundreds of letters written by Italian partisans who were captured by Nazi-Fascists. They were their _last_ letters before being sentenced to death. 
As with the Italian prisoners of 1915-1918, these letters are often written by people who were not well-educated. They were moved by the need to communicate with family and to entrust them with their legacy of ideas and feelings.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-blue-cement',

					template: 'left',
				},
				{
					id: 's2-07',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `## Ciau Luigi
Luigi Rasario era un giovane partigiano di Novara attivo nella 82<sup>a</sup> Brigata Garibaldi “Osella”.
Catturato dai fascisti, farà appena in tempo a scrivere la sua ultima lettera ai genitori prima di finire giustiziato a soli diciannove anni, il 26 aprile 1944.`,
						en: `## Ciau Luigi [Bye Luigi]
Luigi Rasario was a young partisan from Novara in the 82nd Garibaldi “Osella” Brigade.  
Captured by the Fascists, he managed just in time to write his last letter to his parents before being executed at only nineteen years of age, on 26 April 1944.`,
					},
					steps: [{}, {}, {}],
					numSteps: 3,
					sectionStyle: 'bg-red',

					template: 'left',
					curiosity: {
						it: `La parola **_partigiano_** compare nel XIV secolo con un significato negativo: chi sostiene una parte. Con il significato di ‘soldato mercenario’ si diffonde in Europa, ad es. nel francese _partesan_. Ma è grazie allo slavo _partizan_ che assume l’accezione di ‘combattente che resiste all’invasore’, ed è in questo senso che la parola tornerà a circolare nel Risorgimento italiano. Giuseppe Mazzini, infatti, parla proprio di «guerra partigiana».`,
						en: 'The word **_partisan_** appears in the 14th century with the pejorative meaning of defender of a faction. It later spread throughout Europe with the meaning of ‘mercenary soldier’, e.g. in French _partisan_ . However, it was through the Slavic _partizan_ that it would take on the meaning of ‘a guerilla fighter’, and it is with this meaning that the word would circulate again in the Italian Risorgimento. Indeed, Giuseppe Mazzini spoke of «a partisan war».',
					},
					inDepthItem: inDepthItems.items.find(el => el.id == 604),
					buttonPosition: 'left',
				},
				{
					id: 's2-08',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `## «ke fine a fata kuesta signora»
Dal 1943 la Risiera di San Sabba a Trieste divenne un campo di concentramento nazista destinato soprattutto a prigionieri politici ed ebrei. Migliaia furono le persone che trovarono la morte in questo luogo di dolore: tra queste, la veneziana Giovanna Bordignon Sereni.`,
						en: `## «ke fine a fata kuesta signora» [what happen’d dis lady]
From 1943 onwards, the Ricemill of San Sabba in Trieste was used as a Nazi concentration camp, mainly for political prisoners and Jews. Thousands of people met their death in this place of suffering, including Venetian-born Giovanna Bordignon Sereni.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-rose',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 605),
					buttonPosition: 'left',
				},
				{
					id: 's2-09',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 602)
					},
					caption: {
						it: `## Voliamo… la pace
“NEL 1917 / VOLIAMO / LA PACE”: le parole di questa iscrizione, graffita da un ignoto soldato italiano nelle trincee del monte Brestovec, a poca distanza dal San Michele, nel Carso, giungono a noi contemporanei come un pensiero di pace portato dal vento della Storia, in ricordo delle tante vite spazzate via dalle terribili carneficine del secolo scorso. Per non dimenticare.`,
						en: `## Voliamo… la pace [We want… peace]
«IN 1917 / WE WANT / PEACE»: these words, graffitied by an unknown Italian soldier in the trenches of Mount Brestovec, not far from San Michele on the Carso plateau, are to us a thought of peace carried by the wind of History, in memory of the many lives lost in the terrible carnage of the last century. Lest we forget.
`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-cement',

					template: 'left',
				},
				{
					id: 's3-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 603)
					},
					caption: {
						it: `Nel Novecento molte scritture "semicolte" sono legate a esigenze private. 
L’Archivio Diaristico Nazionale di Pieve Santo Stefano (Arezzo), fondato da Saverio Tutino nel 1984, raccoglie più di 6000 manoscritti di donne e uomini che hanno sentito la necessità di raccontare di sé, di «opporre resistenza alla dimenticanza», come ha scritto Mario Perrotta.`,
						en: `In the 20th century, much “semi-educated” writing was linked to private needs. 
The Archivio Diaristico Nazionale [National Diary Archive] in Pieve Santo Stefano (Arezzo), founded by Saverio Tutino in 1984, possesses more than 6.000 documents of women and men who felt the need to talk about themselves, to «resist oblivion», as Mario Perrotta wrote.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sizeContentText: 'normal',
					sectionStyle: 'bg-blue-heading',
					template: 'left',
				},
				{
					id: 's3-02',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 603)
					},
					caption: {
						it: `## Un fiume di parole
_Terra matta_ (2007) è un memoriale di più di 1000 pagine steso da Vincenzo Rabito, siciliano semianalfabeta (anzi, «inafabeto»).
Pubblicato solo dopo la morte dell'autore, _Terra matta_ attraversa rocambolescamente una buona parte del ’900 italiano, dalla Grande Guerra agli anni del _boom_ economico, in un aspro impasto italiano-siciliano di straordinaria forza espressiva.`,
						en: `## A Stream Of Words
_Terra Matta_ \[Mad Earth] (2007) is a memoir of more than 1,000 pages written by Vincenzo Rabito, a semi-literate (or rather «semi-lirate») Sicilian.
Published only after the author's death, _Terra Matta_ traverses a large part of Italy's 20th century, from the Great War to the years of the economic boom, in a harsh Italian-Sicilian blend of extraordinary expressive power.`,
					},
					steps: [{}, {}, {
						quote: {
							it: '«Questa è la bella vita che ho fatto il sotto scritto Rabito Vincenzo \[...]. La sua vita fu molta maletratata e molto travagliata e molto desprezata»',
							en: '«Dis is good live of one Rabito Vincenzo [...] His live was very bed and much despised»'
						},
						author: { it: 'Vincenzo Rabito', en: 'Vincenzo Rabito' },
					}],
					numSteps: 3,
					sectionStyle: 'bg-gold-animated',

					curiosity: {
						it: `Scritture come quella di Rabito possono anche influenzare la letteratura, come dimostra il caso recente del romanzo _Vita, morte e miracoli di Bonfiglio Liborio_ di Remo Rapino, premio Campiello 2020, storia di un «_cocciamatte_» (una ‘testa matta’) che con voce «sgarbugliata» racconta il suo personale Novecento.`,
						en: `Writing such as Rabito's can also influence literature, as demonstrated by the recent case of Remo Rapino's novel _Vita, morte e miracoli di Bonfiglio Liborio_ (The Life, Death and Miracles of Bonfiglio Liborio), Campiello Prize 2020, the story of a «_cocciamatte_» (a crazy head) who recounts his personal twentieth century in a «confus’d» voice.`,
					},

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 606),
					buttonPosition: 'left',
				},
				{
					id: 's3-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 603)
					},
					steps: [{
						caption: {
							it: `## Il lenzuolo-libro-vita
«Care Persone Fatene Tesoro Di Questo Lenzuolo Chè C’è Un Po’ della Vita Mia; è Mio Marito; Clelia Marchi (72) anni hà scritto la storia della gente della sua terra, riempiendo un lenzuolo di scritte».
Questa è la storia di un lenzuolo che diventa libro e parla di una vita intera di sacrifici e sofferenze.`,
							en: `## The Bed-Sheet Autobiography
«Dear People Treasure This Sheet Because There Is A Bit Of My Life; It Is My Husband; Clelia Marchi (aged 72) wrote the story of the people of her land, filling a bedsheet with words».
This is the story of a sheet turned into a book and it is about a lifetime of sacrifice and suffering.`,
						},
					}, {
						quote: {
							it: '«La mia maestra Angiolina Martini mi aveva spiegato che i Truschi avevano avvolto un morto in un pezzo di stoffa scritto. Ho pensato, se l’hanno fatto loro, posso farlo anch’io»',
							en: '«My primary schoolteacher Angiolina Martini explained me the Truschi [Etruscans] had wrapped a dead in a piece of write cloth. Thought: if they did it, me can do it»'
						},
						author: { it: 'Clelia Marchi', en: 'Clelia Marchi' },
					}, {
						caption: {
							it: `Nel 1986 Clelia Marchi scende da una corriera a Pieve Santo Stefano e consegna al Piccolo museo del diario (dove è tuttora conservato) il suo lenzuolo-libro, perché il suo racconto non vada perduto. 
Titolo del lenzuolo-libro: _Gnanca na busia_. Nemmeno una bugia.`,
							en: `In 1986 Clelia Marchi got off a coach in Pieve Santo Stefano and handed over her sheet-book to the museum (where it is still kept), so that her story would not be lost. 
The sheet-book is entitled with dialect expression: _Gnanca na busia_ \[Not even one lie].`,
						},
					}, {}],
					numSteps: 4,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-blue-cement',

					curiosity: {
						it: `Poggio Rusco è anche il paese natale di Arnoldo Mondadori. È stato Luca Formenton, erede della famiglia, durante una visita nella località mantovana a decidere di pubblicare il racconto di Clelia in un libro dal titolo _Il tuo nome sulla neve. Gnanca na busia_ (1992), che ebbe grande risonanza.`,
						en: `Poggio Rusco is also the birthplace of Arnoldo Mondadori. It was Luca Formenton, heir to the family's publishing business, on one of his visits to the Mantuan town that decided to publish Clelia's story in a book entitled _Il tuo nome sulla neve. Gnanca na busia_ \[Your name in the snow. Not even one lie], which was well received.`,
					},

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 607),
					buttonPosition: 'left',
				},
				{
					id: 's3-05',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 603)
					},
					steps: [{
						caption: {
							it: `## Quaderno delle mie confidenze
«Sono nata il 1946-9-11 Torrice P. \[provincia] Frosinone sono alta cm 150 Peso medio kg 53 Residenza Cisterna P. \[provincia] Latina. Quaderno delle mie confidenze e segreti quinti sarà il mio amico della vita di tutti i giorni». 
Così inizia il quaderno-diario di Luisa, unico confidente dei suoi pensieri e, in qualche modo, specchio per riconoscersi e cercare di capirsi.`,
							en: `## Notebook of My Confidences
«Was born 1946-9-11 Torrice P. [province] Frosinone I are 150 cm tall Average weight kg 53 Residence Cisterna P. [province] Latina. Notebook of my confidences and secrets quint [so] it will be my friend in everyday life». 
So begins Luisa's notebook-diary, the only confidant of her thoughts and, to some extent, a mirror to recognise and try to understand herself.`,
						},
					}, {
						caption: {
							it: `In una vita di routine domestica, gli anni passano tra giornate buone e altre meno.
Finché una sera, Luisa reagisce ai gesti violenti del marito Nando e scappa con i figli.
Finalmente libera, si mantiene col suo lavoro e può anche ritirare un premio del Museo del Diario di Pieve Santo Stefano, che le era stato assegnato proprio per il suo quaderno-confidente.`,
							en: `In a life of domestic routine, years go by, with some good days and some bad.
Until one evening, Luisa reacts to her husband Nando's violent behaviour and runs away with her children.
Free at last, she supports herself with her work and is also able to collect a prize from the National Diary Archive in Pieve Santo Stefano, which she was awarded for her notebook.`,
						},
					}, {
						quote: {
							it: '«Hò messo nella facciata la foto con tutti i miei dati per sconfiggere ogni tentazione di bruciarti, perché mi guarderò e capirò che tu quaderno sei la vera Luisa nel bene e nel male e rinnegarti sarebbe un suicidio»',
							en: '«Put the photo with all my details on the front cover to defeat any temptation to burn you, bicause I will look at myself and understand you notebook are the real Luisa for better or for worse and to deny you would be suicide»'
						},
						author: { it: 'Luisa T.', en: 'Luisa T.' },
					}],
					numSteps: 3,
					sectionStyle: 'bg-rose',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 608),
					buttonPosition: 'left',
				},
				{
					id: 's3-06',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 603)
					},
					caption: {
						it: `## Una nuova narrazione
L’Archivio Diaristico Nazionale ha allestito un fondo speciale per i diari di persone migranti: _DiMMi – Diari Multimediali Migranti_ raccoglie al momento più di 500 testimonianze da oltre 50 Paesi, e costituisce uno strumento prezioso per favorire il dialogo interculturale e la conoscenza di nuove forme di scrittura in lingua italiana.`,
						en: `## A New Form of Writing
The National Diary Archive has set up a special fund for the diaries of migrants. The _DiMMi-Diari Multimediali Migranti_ \[Migrant multimedia Diaries] currently possesses more than 500 testimonies from more than 50 countries, and is a valuable tool for fostering intercultural dialogue and knowledge of new forms of writing in Italian.`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-red',

					template: 'left',
					remarkablePoints: [
						{
							caption: ' ',
							textBefore: {
								it: `Tra le realtà del progetto _DiMMi_, l’Archivio Memorie Migranti si occupa di raccogliere, produrre e conservare materiali in formato audio/video sulle esperienze di vita delle persone migranti. 
Nel 2019 ha prodotto il video di animazione _Oltre i muri_/_Beyond walls_ dell’uruguaiano Juan Pablo Etcheverry.`,
								en: `The _DiMMi_-_Archivio Memorie Migranti_ \[Migrant Memories Archive] will be responsible for collecting, producing and preserving audio/video materials on the life experiences of migrants. 

In 2019, it produced the animated video _Oltre i muri_/_Beyond walls_ by Uruguayan Juan Pablo Etcheverry.`
							},
							posX: '65%',
							posY: '45%',
							position: 'bottom',
							wrap: true,
							customLabelStyle: 'width: 25rem;'
						}
					],
				},
				{
					id: 's4-00',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 604)
					},
					caption: {
						it: `Nella storia del cinema italiano, grande spazio è riservato a personaggi poco istruiti, che spiccano per simpatia e comicità.
Li vediamo affrontare varie peripezie, tra cui la scrittura di testi con risultati esilaranti rimasti indelebili nell’immaginario collettivo.`,
						en: `The history of Italian cinema has given us a number of characters who, although uneducated, stand out for their likeability and comic spirit.
We see them face various difficult situations, including writing texts, with hilarious results that remain etched in the collective imagination.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sizeContentText: 'normal',
					sectionStyle: 'bg-blue-heading',
					template: 'left',
				},
				{
					id: 's4-02',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 604)
					},
					caption: {
						it: `## «Punto, due punti…»
È forse la scena sulla scrittura più famosa del cinema italiano.
In _Totò, Peppino e la… malafemmina_ (1956) Totò detta e Peppino scrive una lettera a una «signorina» (la «malafemmina») colpevole di aver distolto il nipote dagli studi.
La lettera è ricchissima di esilaranti strafalcioni: «questa moneta servono», «dai dispiacere che avreta», «salutandovi indistintamente»…`,
						en: `## «Punto, due punti…» [Dot… colon…]
This is perhaps the most famous scene about writing in Italian cinema. 
In _Totò_, _Peppino e la... malafemmina_ [Toto, Peppino, and the… Hussy] (1956) Totò dictates and Peppino writes a letter to a «young lady» (the ‘Hussy’), who is guilty of having distracted their nephew from his studies. 
The letter is full of hilarious malapropisms: «questa moneta servono» \[this coin are needed], «dai dispiacere che avreta» \[the impleasure you will got], «salutandovi indistintamente» \[greeting you indistinctly]…`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-rose',

					curiosity: {
						it: `In linguistica i cosiddetti “strafalcioni” (da _strafalciare_, ‘falciare male’, dunque ‘lavorare male’) si chiamano anche **malapropismi** (da Mrs. Malaprop, personaggio della commedia _The Rivals_ di R.B. Sheridan del 1775), e per la precisione indicano la sostituzione di una parola con un'altra più familiare, dal suono simile ma dal significato completamente diverso: come _parente_ per _parentesi_.`,
						en: `In linguistics, the Italian “strafalcioni” (from _strafalciare_ , ‘to mow badly’, hence ‘to work badly’) are known in English as **malapropisms** (from Mrs. Malaprop, a character in R.B. Sheridan's 1775 play _The Rivals_ ). They indicate the replacement of one word with another with a similar sound but a completely different meaning, like _bracket_ with _racket_ .`,
					},

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 609),
					buttonPosition: 'left',
				},
				{
					id: 's4-03',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 604)
					},
					caption: {
						it: `## «Savonarola!»
In _Non ci resta che piangere_ (1984) anche Roberto Benigni e Massimo Troisi scrivono una lettera, in un’altra famosa scena che è un vero e proprio omaggio a Totò e Peppino. 
Se qua la grammatica è sostanzialmente corretta, sono il susseguirsi delle frasi e il registro (colloquiale con inserti dialettali) a risultare comicamente sconclusionati: «miette», «Savonarola, e che è?! oh! diamoci una calmata eh, oh…».`,
						en: `## «Savonarola!»
In _Non ci resta che piangere_ \[Nothing Left to Do But Cry] (1984), Roberto Benigni and Massimo Troisi also write a letter, in another famous scene that is a true homage to Totò and Peppino.
While the grammar here is essentially correct, the succession of rambling sentences and the register (colloquial combined with dialect expressions) are pure comedy: «miette» \[Neapolitan for «write»], «Savonarola, e che è, oh, diamoci una calmata eh oh» \[Savonarola, come on eh, calm down eh oh].`,
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-blue-cement',

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 615),
					buttonPosition: 'left',
				},
				{
					id: 's4-04',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 604)
					},
					caption: {
						it: `## Totò colpisce ancora
In _Miseria e nobiltà_ (1954) Totò interpreta lo scrivano Felice Sciosciammocca, alle prese, in una famosa scena, con un contadino analfabeta.
La scena è tutta giocata sull’equivoco tra il dialetto napoletano del contadino e i tentativi di resa in italiano di Totò: _stoce_ (corretto da Totò in _sto_), _vago_ (_vado_), _chiote_ (_chiude_)…`,
						en: `## Totò Strikes Again!
In _Miseria e nobiltà_ (1954) \[Poverty and Nobility] Totò plays the scribe Felice Sciosciammocca who, in another famous scene, encounters an illiterate peasant. 
The scene is built around misunderstandings resulting from the peasant’s Neapolitan dialect and Totò's attempts to render it into Italian: _stoce_ (corrected by Totò to _sto_ [I am]), vago (_vado_ \[I go]), _chiote_ (_chiude_ \[it closes])…`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-gold-animated',

					curiosity: {
						it: `_Sciosciammocca_ è un cosiddetto nome parlante: dal napoletano _scioscia_, 'soffia' e _mmocca_, 'in bocca': quindi "che respira a bocca aperta", che resta a bocca aperta per la meraviglia. Nasce come maschera del teatro popolare napoletano, passando da contadino a cittadino, per poi trovare in Totò un'ultima incarnazione, diversissima dal personaggio originario.`,
						en: ' The name _Sciosciammocca_ is a play on words from the Neapolitan _scioscia_ meaning ‘blow’ and _mmocca_ ‘in the mouth’, hence, “breathe with open mouth”. It indicates a person with a gaping mouth, and therefore someone who marvels at everything. The character began life as a mask of popular Neapolitan theatre, was transformed from peasant to citizen, eventually being embodied in the character played by Totò, who is markedly different from the original.',
					},
					remarkablePoints: [
						{
							posX: '75%',
							posY: '35%',
							caption: 'Descrizione',
							textBefore: {
								it: `Contadino: Caro Giuseppe cumpare e nipote, a Napoli stoce facendo la vita de lu signure
Totò: Io stocio… tu stoci… non esiste
C: Non ti piace stoce?
T: Ah sto! Io sto!<br/>
C: Alla sera me ne vago a lu tapparene…
T: … me ne vago… me ne vado
C.:... e me ne esco quande chiote
T: Quando chioto? Ah quando chiude! Ah, dice chiodo…`,
								en: `Peasant: Dear Giuseppe companion and nephew, I be leading the life of a lord here in Naples
Totò: I be … you be … doesn’t exist
P: Don’t you like be?
T: Ah I am! I am!
P: In the evening I get to the tabarin \[= a nightclub]
T: … I get … I go
P.:... and I leave when it clauses
T: When it clauses? Ah when it closes! Ah, he says _chiodo_ \[nail in Italian]…`
						},
							customLabelStyle: 'width: 30.6em',
						},
					],

					template: 'left',
				},
				{
					id: 's4-05',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 604)
					},
					caption: {
						it: `## Errori da innamorati
A un certo punto del celebre film _C’eravamo tanto amati_ (1974) Elide (l’attrice Giovanna Ralli) confessa al suo diario di essersi innamorata di Gianni (Vittorio Gassman).
Fin qui niente di strano. Ma è il modo a essere singolare. Lo fa sotto la data di «Sabbato 12», con grafia incerta e ancora più incerta grammatica: «Oggi a conoscuto», che da ultimo corregge ulteriormente – sbagliando in modo rovinoso –  «ah conoscuto»!`,
						en: `## A Lover's Mistake
At a certain point in the famous film _C’eravamo tanto amati_ \[We All Loved Each Other So Much] (1974) Elide (played by Giovanna Ralli) confesses in her diary that she has fallen in love with Gianni (Vittorio Gassman).
Nothing strange about that. But it is how she does it that is interesting. She does so under the date of «Sabbato 12» [Satturday the 12th], 
making spelling and grammatical errors: «Oggi a conoscuto» \[Today I mit], which she corrects again – and hopelessly – to «ah conoscuto» \[Today hi mit]!`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-red',

					/*
					curiosity: {
						it: `La correzione finale di Elide si chiama tecnicamente **ipercorrettismo** o **ipercorrezione**, cioè la correzione di una forma linguistica corretta ma ritenuta errata. Insomma, quando il rammendo è peggio del buco, direbbe la saggezza popolare.`,
						en: '',
					},
					*/

					template: 'left',
				},
				{
					id: 's4-06',
					name: 'section',
					stage: {
						...stagesData.stagesData.find(el => el.id == 604)
					},
					caption: {
						it: `## «Dicevo: batti lei?»
Nel mezzo di una mitica partita di tennis avvolta dalla nebbia, questa volta tocca a un’altra spinosissima questione: l’uso del congiuntivo.
Il ragionier Ugo Fantozzi e il fido collega ragionier Filini lo affrontano a loro modo, naturalmente.`,
						en: `## «Dicevo: _batti lei_?» [I was saying… would you like to serve?]
In the midst of a legendary tennis match shrouded in fog, the topic this time is another thorny issue: the use of the… Italian subjunctive!
Ragionier \[accountant] Ugo Fantozzi and his trusted colleague Ragionier Filini tackle it in their own way, of course.`,
					},
					steps: [{}, {}],
					numSteps: 2,
					sectionStyle: 'bg-fullscreen-dark',
					sectionStyleMobile: 'bg-rose',

					curiosity: {
						it: `Il personaggio di Fantozzi e i relativi libri (pubblicati a partire dal 1971) hanno suscitato l’ammirazione del grande poeta russo Evgenij Evtušenko, che durante un incontro fra scrittori italiani e russi spese parole di grande ammirazione per l’opera letteraria di Paolo Villaggio, arrivando addirittura a citare Gogol’. Insomma, avevamo un capolavoro e non ce n’eravamo accorti!`,
						en: `The books featuring the character of Fantozzi (published since 1971) aroused the admiration of the great Russian poet Evgenij Evtušenko, who during a meeting of Italian and Russian writers had words of great admiration for Paolo Villaggio's literary work, even going so far as to quote Gogol. And we didn't even realise we had a masterpiece!`,
					},

					template: 'left',
					inDepthItem: inDepthItems.items.find(el => el.id == 610),
					buttonPosition: 'left',
				},
				{
					id: 's5',
					name: 'bibliography',
					stage: {
						...stagesData.stagesData.find(el => el.id == 13)
					},
					steps: [{}],
					numSteps: 1,
					sectionStyle: 'bg-extra-light-gold',
					template: 'left',
				}
			],
		},
	]
}
