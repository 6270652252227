/*
	DROPDOWN MIXIN
	handle common dropdown interactions and data
*/
import button from "@mixins/button.js";

export default {
	mixins: [ button ],
	props: {
		dropdownList: {
			type: Array,
			required: false,
			default: () => []
		},
		forceDropdown: {
			type: Boolean,
			default: false
		}
	},
  computed: {
    dropdownActive() {
      return this.isDropdown && this.active;
    },
    isDropdown() {
    	return this.dropdownList.length || this.forceDropdown;
    }
  },
  methods: {
  	selectOption(option) {
  		this.$emit("option-selected", option);
  		this.toggleActive();
  	},
  	closeDropdown() {
  		if(this.clickOutsideDeactivated) return
  		
  		if (this.active) this.toggleActive();
  	}
  }
}