import globals from '@js/globals.js';
import cloneDeep from 'lodash.clonedeep';
import utils from '@js/utils.js';
import i18n from '@js/i18n.js';

let headers = new Headers()
headers.set('Authorization', 'bearer '+globals.STRAPI_TOKEN)
headers.append('Content-Type', 'application/json')

let headersMS = new Headers()

headersMS.append('Content-Type', 'application/json')
headersMS.append('Authorization', `Bearer ${globals.MEILISEARCH_MASTER_KEY}`)

let parseArgument = (obj) => {
	let { 
		fields, 
		populate, 
		pagination, 
		search, 
		filters,
		sort,
		locale,
	} = obj;
	// console.log(cloneDeep(obj))
	let fieldsString = fields && `fields=${fields.join(',')}`;
	let populateString = populate ? `populate=${populate.join(',')}` : '';
	let paginationString = pagination ? `pagination[page]=${pagination.page}&pagination[pageSize]=${pagination.pageSize}` : ''
	let searchString = '';
	let filterString = [];
	let sortString = sort ? `sort=${encodeURIComponent(sort.value.param + ':' + sort.value.sortType)}` : '';
	let localeString = `locale=${locale ? locale : i18n.global.locale}`;

	if(search && search.value) {
		searchString = `filters[${search.field}][$containsi]=${search.value}`
	}

	if(filters) {
	
		let filterTypeGroups = {}
		filters.forEach(f => {
			if(!filterTypeGroups[f.type]) {
				filterTypeGroups[f.type] = [f.value]
			}else {
				filterTypeGroups[f.type].push(f.value)
			}
		})

		Object.keys(filterTypeGroups).forEach(k => {
			switch (k) {
				case 'secolo':
					filterString.push(...filterTypeGroups[k].map((f, i) => `filters[$or][${i}][anno][$between]=${parseInt(f)}&filters[$or][${i}][anno][$between]=${parseInt(f)+100}`))
					break;
				case 'highlighted':
					filterString.push(`filters[${k}][${filterTypeGroups[k] ? '$ne' : '$eq'}]=0`);
					break;
				default:
					const values = filterTypeGroups[k];
					const op = values.length > 1 ? '$in' : '$eq';
					for (const value of values) {
						filterString.push(`filters[${k}][${op}]=${filterTypeGroups[k][0]}`);
					}
					break;
			}
		})
	}

	filterString = filterString.join('&');
	return [fieldsString, searchString, filterString, populateString, paginationString, sortString, localeString].filter(i => i).join('&');
}

let parseArgumentMS = obj => {
	// console.log('obj', cloneDeep(obj))
	let { 
		fields,
		pagination, 
		search, 
		filters,
		sort,
		tagsSerianni
	} = obj;

	let jsonObj = {}

	jsonObj.attributesToRetrieve = fields

	if(search && search.value) {
		jsonObj.q = search.value
	}

	if(pagination) {
		jsonObj.limit = pagination.pageSize;
		jsonObj.offset = (pagination.page-1) * pagination.pageSize
	}

	if(filters && filters.length) {

		jsonObj.filter = []
		let filterTypeGroups = {}
		filters.forEach(f => {
			// console.log(f.type, filterTypeGroups[f.type])
			if(!filterTypeGroups[f.type]) {
				filterTypeGroups[f.type] = [f.value]
			}else {
				filterTypeGroups[f.type].push(f.value)
			}
		})
		Object.keys(filterTypeGroups).forEach(k => {
			if(k == 'secolo') {
				if(filterTypeGroups[k].length > 1) {
					let temp = []
					filterTypeGroups[k].forEach(el => {
						temp.push(`${k} >= ${(Math.ceil(el/100))} AND ${k} < ${(Math.ceil(el/100))+1}`)
					})
					jsonObj.filter.push(temp)
				}else {
					jsonObj.filter.push(`${k} >= ${(Math.ceil(filterTypeGroups[k][0]/100))}`)
					jsonObj.filter.push(`${k} < ${(Math.ceil(filterTypeGroups[k][0]/100))+1}`);
					// jsonObj.filter.push(`${k}=${filterTypeGroups[k][0]}`);
				}
			}else if(k == 'show_all_serianni') {
				// console.log('inside all serianni', tagsSerianni)
				jsonObj.filter.push(tagsSerianni.map(t => `tag_serianni = \"${t}\" `).join(' OR '))
			}else {
				if(filterTypeGroups[k].length > 1) {
					jsonObj.filter.push(filterTypeGroups[k].map(el => `${utils.fieldToFilterName(k)}=${k == 'tag_serianni' ? `\"${el}\"` : el}`));
				}else {
					jsonObj.filter.push(`${utils.fieldToFilterName(k)}=${k == 'tag_serianni' ? `\"${filterTypeGroups[k][0]}\"` : filterTypeGroups[k][0]}`);
				}	
			}
		})
	}

	if(sort) jsonObj.sort = [`${sort.value.param}:${sort.value.sortType}`]

	// console.log('jsonObj', cloneDeep(jsonObj))
	return jsonObj;
}

export default {
	getArtifacts(obj) {
		let parsedArguments = parseArgument(obj)
		return fetch(globals.STRAPI_URL + '/api/reperti/?'+parsedArguments, {headers: headers})
	},
	getArtifactsMS(obj) {
		return fetch(globals.MEILISEARCH_URL + '/indexes/reperti/search', {method: 'POST', body: JSON.stringify(parseArgumentMS(obj)), headers: headersMS})
	},
	getArtifact(obj) {
		let parsedArguments = parseArgument(obj)
		return fetch(globals.STRAPI_URL + '/api/reperti/'+obj.id+'?'+parsedArguments, {headers: headers})
	},
	getKeyMoments(obj) {
		let parsedArguments = parseArgument(obj)
		return fetch(globals.STRAPI_URL + '/api/key-moments/?'+parsedArguments, {headers: headers})
	},
	getScrollytelling(obj) {
		let parsedArguments = parseArgument(obj)
		return fetch(globals.STRAPI_URL + '/api/scrollytellings/'+obj.id+'?'+parsedArguments, {headers: headers})
	},
	async getScrollytellings() {
		// always italian
		const res = await fetch(globals.STRAPI_URL + '/api/scrollytellings?populate=reperto.reperto&locale=it', {headers: headers})
		return await res.json();
	},
	getArticles(obj) {
		let parsedArguments = parseArgument(obj)
		return fetch(globals.STRAPI_URL + '/api/articoli/?'+parsedArguments, {headers: headers})
	},
	getFacetedMS(obj, params) {
		let parsedObj = parseArgumentMS(obj);
		delete obj.limit;
		return fetch(globals.MEILISEARCH_URL + '/indexes/reperti/search', {
			headers: headersMS,
			method: 'POST',
			body: JSON.stringify(
				{
					...parsedObj,
					"facets": params,
					"limit": 10000,
					"attributesToRetrieve": ["facetDistribution"]
				})
		})
	},
	getMediaByName(obj) {
		let parsedArguments = parseArgument(obj)
		return fetch(globals.STRAPI_URL + '/api/upload/files/?'+parsedArguments, {headers: headers})
	},
	getVocabulary() {
		return fetch(globals.STRAPI_URL + '/api/vocabulary/', {headers: headers})
	}
}
