<template>
	<main class="no-mobile-cont abs-fill-cover stack-vertical" data-align="center" data-justify="center">
		<div class="no-mobile-text-cont stack-vertical" data-align="center" data-justify="center">
			<div class="no-mobile-logo-cont" v-html="logo"></div>
			<h3 class="no-mobile-text font-regular">Il sito è attualmente ottimizzato per una fruizione da desktop. Nei prossimi giorni verrà rilasciata la sua versione mobile.</h3>
		</div>
	</main>
</template>

<script>

export default {
	name: 'no-mobile',
	computed: {
		logo() {
			return require('@assets/icons/multi_logotipo_negativo_1.svg');
		},
	}
}

</script>

<style lang="scss">

	@import '@css/variables.scss';
	
	.no-mobile-cont {
		position: fixed;
		background-color: var(--color-brand-blue);
		color: var(--color-white);
		width: 100vw;
		height: 100vh;
		z-index: 1000;
	}

	.no-mobile-text-cont {
		width: 70vw;
		height: 70vh;
		gap: 3rem;
	}

	.no-mobile-text {
		text-align: center;
	}

	.no-mobile-logo-cont {
		width: 60%;
	}

	@media (max-width: $breakpoint-sm) {
		.no-mobile-text-cont {
			width: 90vw;
			height: 70vh;
		}
	}

</style>