import m from '@/static/Multi_contenuti_statici.json';
import cloneDeep from 'lodash.clonedeep';

function merge_from_json(src, dest, lang) {
  let rtn = {};
  src.forEach(el => {
    rtn[el["Slug"]] = el[`Contenuto ${lang}`];
  })
  return {...dest, ...rtn};
}

let en = {
	collection: 'collection',
	serianni_hall: 'Luca Serianni Hall',
	paths: 'paths',
	articles: 'articles',
	about: 'the museum',
	search_artefacts: 'search through Multi artefacts',
	artefacts: 'artefacts',
	time_line: 'time line',
	refine_search: 'refine your search',
	search_serianni_hall: 'Search among the artifacts in the Luca Serianni Hall...',
	sort: 'sort',
	alphabet_sort: 'name',
	date_sort: 'date',
	type_artifact: 'type',
	century_artifact: 'century',
	path_artifact: 'path',
	rights_artifact: 'rights',
	show_more: 'show more',
	show_less: 'show less',
	clear_all: 'clear all',
	artifact_description: 'description',
	related_artifacts: 'related artifacts',
	where_to_find: 'where to find it',
	timeline_title: 'Timeline',
}

let it = {
	collection: 'collezione',
	serianni_hall: 'Sala Luca Serianni',
	paths: 'percorsi',
	articles: 'articoli',
	about: 'il museo',
	search_artefacts: 'cerca fra i reperti del Multi',
	search_serianni_hall: 'Cerca fra i reperti della Sala Luca Serianni…',
	artefacts: 'reperti',
	time_line: 'linea del tempo',
	refine_search: 'raffina la tua ricerca',
	sort: 'ordina',
	alphabet_sort: 'nome',
	date_sort: 'data',
	type_artifact: 'tipologia',
	century_artifact: 'secolo',
	path_artifact: 'percorso',
	rights_artifact: 'diritti',
	show_more: 'mostra di più',
	show_less: 'mostra meno',
	clear_all: 'cancella tutti',
	artifact_description: 'descrizione',
	related_artifacts: 'reperti correlati',
	where_to_find: 'dove si trova',
	timeline_title: 'Linea del tempo',
}

en = merge_from_json(m, en,"ENG")
it = merge_from_json(m, it,"ITA")

export default {en, it}
