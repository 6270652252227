<template>
	<router-view></router-view> 
</template>

<script>
export default {
  name: 'LocaleContainer',
  beforeMount() {
	  const locale = this.$route.params.lang;
	  this.$root.$i18n.locale = locale;
  },
  beforeUpdate() {
	  const locale = this.$route.params.lang;
	  this.$root.$i18n.locale = locale;
  },
}
</script>
