<template>
	<interface-menu/>
	<router-view></router-view> 
</template>

<script>
import InterfaceMenu from '@components/InterfaceMenu'
export default {
  name: 'PageMain',
  components: { InterfaceMenu },
  computed: {

  }
}
</script>
