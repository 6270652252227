<template>
	<div class="base-button-rounded pointer shadow-small stack-horizontal" :class="{inactive: inactive}" data-align="center" data-justify="center" :style="'background-color: '+color" @click="buttonClicked">
		<base-icon :name="icon" :style="'--svg-icon-color: '+iconColor"/>
	</div>
</template>

<script type="text/javascript">

export default {
	name: 'BaseButtonRoundend',
	props: {
		icon: {
			type: String,
		},
		iconColor: {
			type: String,
			default: 'currentColor',
		},
		color: {
			type: String
		},
		inactive: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		buttonClicked() {
			this.$emit('button-clicked')
		}
	}
}

</script>

<style lang="scss">

	@import '@css/variables';
	
	.base-button-rounded {
		width: var(--s4);
		height: var(--s4);
		border-radius: 100rem;
		transition-property: background-color;
		transition-duration: .2s;
	}

	.base-button-rounded .svg-icon {
		width: var(--s3);
		height: var(--s3);
	}

	.base-button-rounded.inactive {
		opacity: 0.5;
		pointer-events: none;
	}

	@media (max-width: $breakpoint-sm) {
		.base-button-rounded {
			width: 3rem;
			height: 3rem;
			border-radius: 100rem;
		}

		.base-button-rounded .svg-icon {
			width: var(--s2);
			height: var(--s2);
		}
	}

</style>