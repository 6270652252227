<template>
  <div class="button-base" v-click-outside="closeDropdown" :class="{opened: active, 'fit-width': fitWidth}" @focusout="clickOutsideHandler">
    <button :class="[`button--${type} `, {disabled, 'button-dropdown':isDropdown, active, textType}]" @click="clickHandler">
      <span v-if="text" :class="capitalize && 'capitalize-first'">{{ text }}</span>
      <base-icon v-if="icon" :name="iconCurrent" :style="'--svg-icon-color: '+iconColor"/>
      <!-- <img :src="iconCurrent"> -->
    </button>
    <div v-if="dropdownActive && !forceDropdown" :class="'dropdown flow '+type">
      <button v-for="option in dropdownList" :class="capitalize && 'capitalize-first'" @click.stop="selectOption(option)">{{option.label}}</button>
    </div>
  </div>
</template>

<script>
import validate from '@js/validations.js';
import button from '@mixins/button.js';
import dropdown from '@mixins/dropdown.js';

export default {
  name: 'BaseButton',
  mixins: [ button, dropdown ],
  props: {
    text: String,
    type: {
      type: String,
      required: false,
      default: "default",
    },
    fitWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    textType: {
    	type: String,
    	default: 'font--caption'
    },
    capitalize: {
      type: Boolean,
      required: false,
      default: true
    },
    iconColorParam: {
      type: String
    }
  },
  computed: {
    iconColor() {
      let rtn;
      if (!this.disabled) {
        rtn = this.type == "primary" ? "white" : "black";
      } else {
        rtn = "gray-light";
      }
      if(this.iconColorParam) {
        return this.iconColorParam;
      }
      return rtn;
    }
  }
}
</script>

<style lang="scss">
/**
 * BUTTON STYLES
 * Style for the button component
 */

.button-base {
  position: relative;
  --button-dropdown-height: 3.5rem;
  min-width: calc(var(--s5) * 1.5);

  &.fit-width {
    width: 100%;
  }

  &:not(.fit-width) {
    max-width: fit-content;
  }

  &.opened {
    z-index: 2;
  }

  > button {
    width: 100%;
    height: var(--button-dropdown-height);
    padding: 0 var(--s1);
    border: 1px solid var(--color-dark);
    cursor: pointer;
    color: var(--color-white);
  }

  button.button--default {
  	background-color: var(--color-gold);
  	color: var(--color-white);
  }

  button.button--variant2 {
  	background-color: var(--color-dark-gold);
  	color: var(--color-white);
  }

  button.button--variant3, button.button--variant5 {
  	background-color: transparent;
  	color: var(--color-black);
  	border: solid 0.125rem var(--color-black);
  	transition-property: background-color;
  	transition-duration: .5s;
  }

  button.button--variant3:hover, button.button--variant5:hover {
  	background-color: var(--color-light-gray);
  }

  button.button--variant4 {
  	background-color: var(--color-light-gray);
  	color: var(--color-black);
  	border: solid 0.125rem var(--color-black);
  	transition-property: background-color;
  	transition-duration: .5s;
  }

  button.button--variant4:hover {
  	background-color: var(--color-white);
  }

  button.button--variant6 {
  	background-color: var(--color-black-alpha);
  	color: var(--color-light-gray);
  	border: solid 2px var(--color-black);
  }

  button.button--variant7 {
    background-color: var(--color-white-alpha);
    backdrop-filter: blur(14px);
    color: var(--color-white);
    font-family: 'laica';
    font-size: calc(1.75 * var(--base-font-size)); /*28px*/
    line-height: 1.2em;

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 var(--s2);
    gap: var(--s2);
    
    transition: background-color 0.5s, color 0.5s;
  }
  button.button--variant7:hover {
    background-color: var(--color-white);
    color: var(--color-black);
  }
  
  button.button--variant7 .svg-icon {
    --svg-icon-color: currentColor;
  }

	button.button-dropdown {
		// width: 10rem;
		// height: var(--button-dropdown-height);
		// padding: 1rem 0.8rem;
		display: flex;
		flex-direction: row;
		gap: var(--s2);
		justify-content: space-between;
		align-items: center;
		position:relative;
		z-index: 1;
		white-space: nowrap;
		border: solid 2px var(--color-black);
	}

	button.icon svg {
		width: var(--s1);
		pointer-events: none;
	}


	button.disabled {
		background-color: var(--color-gray-ultralight);
		color: var(--color-gray-light);
    pointer-events: none;
	}

  .dropdown {
    --flow-space: var(--s0);
    text-align: left;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    // margin: var(--s1);
    background-color: var(--color-white);
    border-radius: calc(var(--radius) / 2) calc(var(--radius) / 2) 0 0;
    border: 0.09375rem solid var(--color-black-text);

    > button {
      display: block;
      padding: var(--s1);
      width: 100%;
      text-align: left;
    }
  }
}

.button-base.no-border button.button-dropdown {
	border: none;
}

.dropdown > button:hover {
	background-color: var(--color-light-gray);
}

.dropdown--variant1 .dropdown,
.dropdown--variant1 button span {
	background-color: transparent;
	color: var(--color-black);
}

.button-base.dropdown--variant1 button.button-dropdown  {
	background-color: transparent;
}

.button-base.dropdown--variant3 button.button-dropdown {
	background-color: var(--color-gray);
	padding: 0;
}

.button-base .variant3.dropdown {
	background-color: var(--color-gray);
}

.button-base .variant3.dropdown button {
	// display: flex;
	align-items: center;
	height: var(--button-dropdown-height);
}

.button-base.dropdown--variant3 button.button-dropdown span {
	border-bottom: 2px solid rgba(0,0,0,0);
}
.button-base.dropdown--variant3 button.button-dropdown:hover span {
	border-bottom: 2px solid var(--color-black);
}

.button-base.dropdown--variant3 .dropdown-flow {
	display: none;
}

.button--dropdown--variant2 .dropdown {
	background-color: var(--color-gray-light);
}

.button--dropdown--variant2 button span {
	color: var(--color-black);
}

</style>
