<template>
	<div :class="`cookies-container ${isMobile ? '' : 'small-radius'} extra-small-shadow ${(visible ? 'visible' : '')}`">
		<div class="cookies-description">
			<p>{{description[$i18n.locale]}}</p>
		</div>
		<div class="cookies-buttons stack-horizontal">
			<base-button v-for="button in buttons"
				:text="button.text[$i18n.locale]" 
				:type="button.type"
				:fit-width="isMobile"
				@clicked="clickButton(button.name)"
			/>
		</div>
	</div>
</template>

<script type="text/javascript">

import { mapGetters } from 'vuex';
import BaseButton from '@components/BaseButton.vue';
	
export default {
	name: 'InterfaceCookies',
	components: { BaseButton },
	data() {
		return {
			description: {
				it: `Questo sito utilizza cookie tecnici, propri e di terze parti, per garantire la corretta navigazione e analizzare il traffico e, con il tuo consenso, cookie di profilazione e altri strumenti di tracciamento di terzi per mostrare video e misurare l'efficacia delle attività di comunicazione istituzionale.`,
				en: `We use a selection of our own and third-party cookies on the pages of this website: Essential cookies, which are required in order to use the website; functional cookies, which provide better easy of use when using the website; performance cookies, which we use to generate aggregated data on website use and statistics; and marketing cookies, which are used to display relevant content and advertising.`
			},
			buttons: [
				{
					name: 'privacy_policy',
					text: {
						it: 'Privacy policy',
						en: 'Privacy policy'
					},
					type: 'variant3'
				},
				{
					name: 'deny_all',
					text: {
						it: 'Rifiuta tutti',
						en: 'Deny All'
					},
					type: 'variant4'
				},
				{
					name: 'accept_all',
					text: {
						it: 'Accetta tutti',
						en: 'Accept All'
					},
					type: 'variant4'
				}
			],
			visible: false
		}
	},
	computed: {
		...mapGetters(['isMobile'])
	},
	methods: {
		accept() {
			this.hideCookiesCont();
		  let d = new Date();
		  let exdays = 0.3;
		  window.localStorage.setItem('cookies', 'yes-cookies')
		  // this.setCookie('multi', '1', 30, 'Strict');
		  _paq.push(['rememberCookieConsentGiven'])
		  setTimeout(() => {
		  	document.querySelector('.cookies-container').style.display = 'none'
		  }, 500)
		},
		appear() {
			document.querySelector('.cookies-container').classList.add('visible')
		},
		openInfo() {
			window.open('https://privacy.unipv.it/', '_blank');
		},
		setCookie(name, value, days, sameSite) {
		  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

		  // Check if days parameter is provided
		  if (days) {
		    let expirationDate = new Date();
		    expirationDate.setDate(expirationDate.getDate() + days);
		    cookieString += `; expires=${expirationDate.toUTCString()}`;
		  }

		  // Check if sameSite parameter is provided
		  if (sameSite) {
		    cookieString += `; SameSite=${sameSite}`;
		  }

		  // Set the cookie
		  document.cookie = cookieString;
		},
		getCookie(cname) {
		  let name = cname + "=";
		  let decodedCookie = decodeURIComponent(document.cookie);
		  let ca = decodedCookie.split(';');
		  for(let i = 0; i <ca.length; i++) {
		    let c = ca[i];
		    while (c.charAt(0) == ' ') {
		      c = c.substring(1);
		    }
		    if (c.indexOf(name) == 0) {
		      return c.substring(name.length, c.length);
		    }
		  }
		  return "";
		},
		hideCookiesCont() {
			document.querySelector('.cookies-container').classList.remove('visible')
		},
		clickButton(buttonName) {
			switch(buttonName) {
				case 'privacy_policy':
					this.openInfo();
					break;
				case 'deny_all':
					this.denyCookies();
					break;
				case 'accept_all':
					this.accept();
					break;
			}
		},
		denyCookies() {
			window.localStorage.setItem('cookies', 'no-cookies');
			_paq.push(['deleteCookies']);
			this.hideCookiesCont();
		},
		getLocalStorageCookiesStatus() {
			return window.localStorage.getItem('cookies')
		}
	},
	mounted() {
		if(!this.getLocalStorageCookiesStatus()) {
			setTimeout(() => {
				this.appear();	
			}, 3000)
		}else {
		  document.querySelector('.cookies-container').style.display = 'none'
		}
	}
}

</script>

<style lang="scss">

	@import '@css/variables.scss';
		
	.cookies-container {
		position: fixed;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1.75rem;
		background-color: var(--color-gold);
		bottom: 2rem;
		height: fit-content;
		transform: translate(0, 200%);
		transition-property: transform;
		transition-duration: .5s;
		transition-timing-function: ease-out; 
		width: 85vw;
		left: 0;
		right: 0;
		margin: auto;
		padding: 1.5rem;
		z-index: 2;
	}

	.cookies-container.visible {
		transform: translate(0,0);
	}

	@media (max-width: $breakpoint-ml) {
		.cookies-container {
			width: calc(100% - var(--menu-size));
			left: var(--menu-size);
			flex-direction: column;
			bottom: 0;
			border-radius: 0;
			// align-items: center;
		}

		.cookies-description {
			width: 100%;
		}

		.cookies-buttons {
			width: fit-content;
		}
	}

	@media (max-width: $breakpoint-sm) {
		.cookies-container {
			width: 100%;
			left: 0;
		}
		.cookies-buttons {
			flex-direction: column;
			align-items: center;
			width: 100%;
		}
	}

</style>