import globals from '@js/globals.js';
import moment from 'moment-mini';
// import 'moment/locale/it'
// import 'moment/locale/en'
import { gsap, Power1 } from "gsap/dist/gsap.min.js";
import { Converter } from 'showdown/dist/showdown.min.js';
import cloneDeep from 'lodash.clonedeep';
import domtoimage from 'dom-to-image';

let converter = new Converter({simpleLineBreaks: true});

export default {
	isImageLoaded: async (imageName, time) => {
		await new Promise(resolve => {
			const interval = setInterval(() => {
				let img = typeof imageName == 'string' ? document.querySelector(imageName) : imageName
				if (img) {
					if(img.complete) {
						resolve()
					}else {
						img.onload = e => {
							resolve()
						}	
					}
					clearInterval(interval);
				}
			}, 1);
			setTimeout(() => {
				clearInterval(interval)
			}, time ? time : 1000)
		});
	},
	isElLoaded: async (item, time) => {
		await new Promise(resolve => {
			const interval = setInterval(() => {
				let el = typeof item == 'string' ? document.querySelector(item) : item
				if (el) {
					resolve()
					clearInterval(interval);
				}
			}, 1);
			setTimeout(() => {
				clearInterval(interval)
			}, time ? time : 1000)
		});
	},
	isItemNotNull: async (item, time) => {
		await new Promise(resolve => {
			const interval = setInterval(() => {
				if (item) {
					resolve()
					clearInterval(interval);
				}
			}, 1);
			setTimeout(() => {
				clearInterval(interval)
			}, time ? time : 1000)
		});
	},
	parseMediaUrl: function(image) {
		// console.log('image', image, image.length)
		return image.length ? 
			(image.startsWith('http') ? image : globals.STRAPI_URL + image)
			:
			''
	},
	capitalizeFirst: string => string.slice(0,1).toUpperCase() + string.slice(1, string.length),
	capitalizeWords: string => string.replace(/(^|\s)(\w)/g, (m, space, char) => space + char.toUpperCase()),
	isEmpty: obj => {
		for(var key in obj) {
			if(obj.hasOwnProperty(key))
				return false;
		}
		return true;
	},
	map: (value, low1, high1, low2, high2) => low2 + (high2 - low2) * (value - low1) / (high1 - low1),
	dataToTimestamp: dataString => (new Date(dataString)).getTime(),
	dateToCentury: date => {
		let rtn =  moment(`2000-01-01`)
		rtn.year(parseInt(Math.floor(date/100)*100))
		return rtn
	},
	centuryToRomanNumber: (value, lan) => {
    var numbers, numerals, result, i, len;
    let ac = ""

    let newValue = Math.abs(value)

    numbers  = [1000, 900,  500, 400,  100, 90,   50,  40,   10,  9,    5,   4,    1  ];
    numerals = ["M",  "CM", "D", "CD", "C", "XC", "L", "XL", "X", "IX", "V", "IV", "I"];
    result   = "";

    for (i = 0, len = numbers.length; i < len; i++) {
      while (newValue >= numbers[i]) {
        newValue -= numbers[i];
        result += numerals[i];
      }
    }
    if(lan == 'it' || !lan) {
    	value >= 0 ? '' :  'a.C.';
    	return result+' '+ac;
    }
    if(lan == 'en') {
    	let rtn = ''
    	switch(value) {
    		case 1:
    			rtn = '1st'
    			break;
    		case 2:
    			rtn = '1nd'
    			break;
    		case 3:
    			rtn = '3rd'
    			break;
    		default:
    			rtn = value >= 0 ? value.toString() + 'th' : Math.abs(value).toString() + ' B.C.';
    			break;
    	}
    	return rtn+ac
    }
  },
	annoToCentury: anno => Math.ceil(+anno/100),
	mimeToType: mime => mime.split('/')[0],
	downloadFile_old: async (file, type) => {
		let response = await fetch(file)
		let blob = await response.blob()
		let a = document.createElement("a");
		let mimeType = ''
		switch (type) {
			case 'audio':
				mimeType = 'audio/mpeg'
				break;
			case 'video':
				mimeType = 'video/mp4'
				break;
			case 'image':
				mimeType = 'image/jpeg'
				break;
		}
		a.href = URL.createObjectURL(new Blob([blob], {type: mimeType}));
		a.download = "prova.jpeg";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	},
	downloadFile: file => {
		var xhr = new XMLHttpRequest();
		xhr.open("GET", file, true);
		xhr.setRequestHeader('Access-Control-Allow-Origin', true)
		xhr.responseType = "blob";
		xhr.onload = function(){
			var urlCreator = window.URL || window.webkitURL;
			var imageUrl = urlCreator.createObjectURL(this.response);
			var tag = document.createElement('a');
			tag.href = imageUrl;
			tag.download = file.split('/')[file.split('/').length-1];
			document.body.appendChild(tag);
			tag.click();
			document.body.removeChild(tag);
		}
		xhr.send();
	},
	stopPropagation: e => e.stopPropagation(),
	createTimeline: (duration, onComplete, id) => {
		return gsap.timeline({
			defaults: {
				duration: duration,
				ease: Power1.easeInOut
			},
			...onComplete,
			paused: true,
			data: { id }
		});
	},
	allImagesLoaded: async () => Promise.all(
		Array.from(document.images)
		.filter(img => !img.complete)
		.filter(img => !img.closest('.sc-artifact-modal'))
		.map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))
	),
	allImagesDecoded: async () => {
		Promise.all(
			Array.from(document.images)
				.filter(img => !img.complete)
				.map(img => img.decode())
		)
	},
	allVideoLoaded: async () => {
		Promise.all(
			Array.from(document.querySelectorAll('video'))
				.filter(video => {
					console.log(video.readyState)
					return video.readyState != 4
				})
				.map(video => {
					console.log('video', video)
					return new Promise(resolve => {
						console.log('promise')
						video.onloadeddata = () => {
							console.log('resolve video')
							resolve()
						}
					})
				})
		)
	},
	isSafari: () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
	markdownToHtml: text => converter.makeHtml(text),
	URLToParams: () => {
		let href = location.href;
		let obj = {}
		if(href.indexOf('?') > -1) {
			href = href.slice(href.indexOf('?')+1);
			let arr = href.split('&')
			arr.forEach(el => {
				obj[el.slice(0, el.indexOf('='))] = el.slice(el.indexOf('=')+1)
			})
			return obj
		}else {
			return;
		}
	},
	paramsToURL: params => {
		let string = location.href.indexOf('?') > -1 ? location.href.slice(0, location.href.indexOf('?')+1) : location.href + '?';
		Object.keys(params).forEach(k => {
			string += `${k}=${params[k]}&`
		})

		return string.slice(0, -1)
	},
	filterNameToField: filter => {
		switch (filter) {
			case 'percorsi.titolo':
				return 'percorsi'
				break;
			default:
				return filter
				break;
		}
	},
	fieldToFilterName: name => {
		switch (name) {
			case 'percorsi':
				return 'percorsi.titolo'
				break;
			default:
				return name
				break;
		}
	},
	getPathAfterString: string => location.href.slice(location.href.indexOf(string), location.href.indexOf('?') > -1 ? location.href.indexOf('?') : undefined).split('/')[1],
	checkIfIsEdge: function(){ return /Edg/.test(navigator.userAgent)},
	urlToPicture: function(obj, formats) {
		// console.log('obj', cloneDeep(obj))
		let url = ''
		for(var f of formats) {
			if(url = obj[f]) {
				// console.log('current format', f)
				url = this.parseMediaUrl(obj[f].url)
				break;
			}
		}
		// console.log('url parsed', url)
		return url
	},
	areAvifSupported: () => {
		let subStringsArr = window.navigator.userAgent.split(' ')
		subStringsArr = subStringsArr.slice(subStringsArr.findIndex(el => el.indexOf('Gecko') > -1) - subStringsArr.length +1)
		return !subStringsArr.filter(el => {
			let type = el.split('/')[0]
			let numVersion = el.split('/')[1]
			switch (type) {
				case 'Edg':
						return true;
						break;
				case 'Chrome':
					return parseFloat(numVersion) < 85
					break;
				case 'Firefox':
					return parseFloat(numVersion) < 93
					break;
				case 'Version':
					return parseFloat(numVersion) < 16.1
					break;
				case 'CriOS':
					return true;
					break;
				default:
					return false
					break;
			}
		}).length
	},
	areAnimatedAvifSupported: () => {
		let subStringsArr = window.navigator.userAgent.split(' ')
		subStringsArr = subStringsArr.slice(subStringsArr.findIndex(el => el.indexOf('Gecko') > -1) - subStringsArr.length +1)
		return !subStringsArr.filter(el => {
			let type = el.split('/')[0]
			let numVersion = el.split('/')[1]
			switch (type) {
				case 'Edg':
						return true;
						break;
				case 'Chrome':
					return parseFloat(numVersion) < 85
					break;
				case 'Firefox':
					return parseFloat(numVersion) < 93
					break;
				case 'Version':
					return parseFloat(numVersion) < 16.4
					break;
				case 'CriOS':
					return true;
					break;
				default:
					return false
					break;
			}
		}).length
	},
	openBlankUrl: url => {
		window.open(url, '_blank');
	},
	// manageImagesExtension: url => url
	manageImagesExtension: function(url) {return this.areAvifSupported() ? url.replace(url.split('.')[url.split('.').length-1], 'avif') : url },
	dragscroll: {
		start: function(cb, cbClick) {
			var _window = window;
			var _document = document;
			var mousemove = 'mousemove';
			var mouseup = 'mouseup';
			var mousedown = 'mousedown';
			var EventListener = 'EventListener';
			var addEventListener = 'add'+EventListener;
			var removeEventListener = 'remove'+EventListener;
			var newScrollX, newScrollY;
			var mouseDownX, mouseDownY;

			var dragged = [];
			var reset = function(i, el) {
				console.log('reset')
				for (i = 0; i < dragged.length;) {
					el = dragged[i++];
					el = el.container || el;
					el[removeEventListener](mousedown, el.md, 0);
					_window[removeEventListener](mouseup, el.mu, 0);
					_window[removeEventListener](mousemove, el.mm, 0);
				}

				// cloning into array since HTMLCollection is updated dynamically
				dragged = [].slice.call(_document.getElementsByClassName('dragscroll'));
				for (i = 0; i < dragged.length;) {
					(function(el, lastClientX, lastClientY, pushed, scroller, cont){
						(cont = el.container || el)[addEventListener](
							mousedown,
							cont.md = function(e) {
								if (!el.hasAttribute('nochilddrag') ||
									_document.elementFromPoint(
										e.pageX, e.pageY
									) == cont
								) {
									pushed = 1;
									lastClientX = e.clientX;
									lastClientY = e.clientY;

									mouseDownX = e.clientX;
									mouseDownY = e.clientY;

									e.preventDefault();
								}
							}, 0
						);

						_window[addEventListener](
							mouseup, cont.mu = function(e) {
								if(Math.abs(mouseDownX - e.clientX) + Math.abs(mouseDownY - e.clientY) < 8) {
									if(cbClick) cbClick(e)

								}
								// console.log(mouseDownX, mouseDownY, e.clientX, e.clientY)
								pushed = 0;
							}, 0
						);

						_window[addEventListener](
							mousemove,
							cont.mm = function(e) {
								if (pushed) {
									if(cb) {
										cb();
									}

									(scroller = el.scroller||el).scrollLeft -=
										newScrollX = (- lastClientX + (lastClientX=e.clientX));
									scroller.scrollTop -=
										newScrollY = (- lastClientY + (lastClientY=e.clientY));
									if (el == _document.body) {
										(scroller = _document.documentElement).scrollLeft -= newScrollX;
										scroller.scrollTop -= newScrollY;
									}
								}
							}, 0
						);	
					 })(dragged[i++]);
				}
			}
			if (_document.readyState == 'complete') {
				reset();
			} else {
				_window[addEventListener]('load', reset, 0);
			}
		},
	},
	domToImage: async node => {
		let dataUrl = await domtoimage.toPng(node)
		var img = new Image();

		var link = document.createElement('a');
		console.log('link')
		link.download = 'my-image-name.jpeg';
		link.href = dataUrl;
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	},
	localizedScrollyTitle(scrolly, localScrollies, locale) {
		const localScrolly = localScrollies.find(ls => ls.nameCode == scrolly.attributes.link);
		return localScrolly ? localScrolly.name[locale] : scrolly.attributes.titolo;
	},
	loadTrackWithAjax(track, sub) {
		var xhttp = new XMLHttpRequest();

		xhttp.onreadystatechange = function() {
			if (this.readyState == 4 && this.status == 200 && this.responseText) {
				// If VTT fetch succeeded, replace the src with a BLOB URL.
				var blob = new Blob([this.responseText], { type: 'text/vtt' });
				track.setAttribute("src", URL.createObjectURL(blob));
			}
		};
		xhttp.open("GET", sub, true);
		xhttp.send();
	}
}
