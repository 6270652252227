import { createStore } from 'vuex'
import API from '@js/API.js';
import utils from '@js/utils.js';
import cloneDeep from 'lodash.clonedeep';
import i18n from '@js/i18n.js';

let resolveInit;

export default new createStore({
	state: {
		typeScreen: 'desktop',
		artifacts: [],
		artifactsMeta: 0,
		numElementsPage: 25,
		keyMoments: [],
		audioBookmarks: [],
		assetsTimeline: [],
		archiveSearchString: '',
		timelineActive: false,
		facetedParams: ['tipologia', 'secolo', 'percorsi.titolo', 'open_access', 'tag_serianni'],
		tagSerianniValues: [],
		faceted: [],
		scrollys: [],
		initPromise: null,
		currentPdfLink: ''
	},
	mutations: {
		setArtifacts(state, artifacts) {
			state.artifacts = artifacts;
		},
		setArtifactsMeta(state, artifactsMeta) {
			state.artifactsMeta = artifactsMeta
		},
		setTypeScreen(state, typeScreen) {
			state.typeScreen = typeScreen
		},
		setKeyMoments(state, keyMoments) {
			state.keyMoments = keyMoments
		},
		setAudioBookmarks(state, audioBookmarks) {
			state.audioBookmarks = audioBookmarks
		},
		setArchiveSearchString(state, archiveSearchString) {
			state.archiveSearchString = archiveSearchString
		},
		setTimelineActive(state, value) {
			state.timelineActive = value
		},
		setAssetsTimeline(state, value) {
			state.assetsTimeline = value
		},
		setFaceted(state, value) {
			state.faceted = value
		},
		setScrollys(state, scrollys) {
			state.scrollys = scrollys
		},
		createInitPromise(state) {
			state.initPromise = new Promise((resolve) => {
				resolveInit = resolve;
			})
		},
		setTagsSerianni(state, tags) {
			state.tagSerianniValues = tags;
		},
		setCurrentPdfLink(state, link) {
			state.currentPdfLink = link
		}
	},
	actions: {
		async initStore({commit, state}) {
			if (state.initPromise) return state.initPromise;
			
			commit('createInitPromise')
			commit('setScrollys', (await API.getScrollytellings()).data)
			resolveInit(true);
		},
		getArtifacts({commit, state}, obj) {
			commit('setArtifacts', [])
			commit('setArtifactsMeta', {
				pagination: {
					page: 0,
					pageSize: state.numElementsPage,
					total: 0
				}
			})
			return API.getArtifacts(obj)
				.then(res => res.json())
				.then(res => {
					let parsedRes = res.data.map(ar => {
						return {
							id: ar.id,
							titolo: ar.attributes.titolo,
							autore: ar.attributes.Autore,
							anno: ar.attributes.anno_testo,
							anteprima: ar.attributes.anteprima.data ? utils.parseMediaUrl(ar.attributes.anteprima.data.attributes.formats.small.url) : '',
						}
					})
					commit('setArtifacts', parsedRes)
					commit('setArtifactsMeta', res.meta)
					return res
				})
				.catch(err => console.log('err', err))
		},
		getArtifactsMS({commit, state}, obj) {
			commit('setArtifacts', [])
			commit('setArtifactsMeta', {
				pagination: {
					page: 0,
					pageSize: state.numElementsPage,
					total: 0
				}
			})
			return API.getArtifactsMS(obj)
				.then(res => res.json())
				.then(res => {
					// console.log('res hits', cloneDeep(res.hits))
					let parsedRes = res.hits.map(ar => {
						return {
							id: ar.id,
							titolo: ar.titolo,
							autore: ar.autore,
							anno: ar.anno_testo,
							anteprima: ar.anteprima ? ar.anteprima.formats : (ar.media ? ar.media[0].formats : null),
						}
					})
					// console.log('parsedRes', cloneDeep(parsedRes), cloneDeep(res))
					commit('setArtifacts', parsedRes)
					commit('setArtifactsMeta', {
						pagination: {
							page: obj.page * state.numElementsPage,
							pageSize: state.numElementsPage,
							total: res.estimatedTotalHits
						}
					})
					return res
				})
				.catch(err => console.log('err', err))
		},
		async getFacetedMS({commit, state}, obj) {
			let faceted = []
			let res = await API.getFacetedMS(obj, state.facetedParams)
			res = await res.json()
			let parseCentury = century => (century)*100
			let parsedValue = (k, facetValue) => (k == 'secolo' ? parseCentury(facetValue) : facetValue)
			Object.keys(res.facetDistribution).forEach(k => {
				Object.keys(res.facetDistribution[k]).forEach(facetValue => {
					faceted.push({
						type: utils.filterNameToField(k),
						name: parsedValue(k, facetValue),
						value: parsedValue(k, facetValue),
						count: res.facetDistribution[k][facetValue],
						isSelected: false
					})
				})
			})
			commit('setFaceted', faceted);
			return faceted
		},
		getKeyMoments({commit, state}, obj) {
			return API.getKeyMoments(obj)
				.then(res => res.json())
				.then(res => {
					commit('setKeyMoments', res.data)
					return res
				})
				.catch(err => console.log('err', err))
		},
		addAudioBookmark({commit, state}, obj) {
			let audioBookmarks = state.audioBookmarks
			audioBookmarks.push(obj)
			commit('setAudioBookmarks', audioBookmarks)
		},
		removeAudioBookmark({commit, state}, obj) {
			let audioBookmarks = state.audioBookmarks
			_.remove(audioBookmarks, el => el.id == obj.id)
			commit('setAudioBookmarks', audioBookmarks)
		},
		async getSerianniValues({commit, state}) {
			let res = await  API.getFacetedMS({}, ['tag_serianni'])
			res = await res.json()
			let tags = Object.keys(res.facetDistribution.tag_serianni)
			commit('setTagsSerianni', tags)
		},
		setCurrentPdfLink({commit}, link) {
			commit('setCurrentPdfLink', link)
		}
	},
	getters: {
		isMobile: state => state.typeScreen == 'mobile'
	}
})
